var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"png") : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"pngSmall") : stack1), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"slug") : stack1), depth0))
    + "\" class=\"view link-icon-detail related-icon\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" data-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"queryParams") : stack1), depth0))
    + "\">\n    <img src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"urlStaticImg") || (depth0 != null ? lookupProperty(depth0,"urlStaticImg") : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"urlStaticImg","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":30}}}) : helper)))
    + "loader.gif\" data-src=\""
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","uicon",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":52},"end":{"line":2,"column":139}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"!=","uicon",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":139},"end":{"line":2,"column":231}}})) != null ? stack1 : "")
    + "\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" width=\"64\" height=\"64\" class=\"lzy\">\n</a>";
},"useData":true});