var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"modal-palette-not-premium-user\" class=\"modal modal--lg modal-palette-not-premium-user\">\n    <div class=\"modal__container\">\n		<div class=\"content text__general--heading\">\n            <button class=\"modal__close modal__close--absolute modal__close--top-right button button--icon button--icon--only button--sm button--inverted nostyle\">\n				<i class=\"icon icon--lg icon--mg-lg icon--cross\"></i>\n			</button>\n            <div class=\"image-box\">\n\n            </div>\n            <div class=\"text-box\">\n                <p class=\"font-xl bold mg-bottom-lv2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"create") : stack1), depth0))
    + "</p>\n                <p class=\"font-md\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"subscribe") : stack1), depth0))
    + "</p>\n                <div class=\"feature\"><i class=\"icon icon--lg icon--check mg-right-lv2\"></i><p class=\"font-sm mg-bottom-lv1\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"iconsStikers") : stack1), depth0))
    + "</p></div>\n                <div class=\"feature\"><i class=\"icon icon--lg icon--check mg-right-lv2\"></i><p class=\"font-sm mg-bottom-lv1\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"noAttribution") : stack1), depth0))
    + "</p></div>\n                <div class=\"feature\"><i class=\"icon icon--lg icon--check mg-right-lv2\"></i><p class=\"font-sm mg-bottom-lv1\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"unlimitedDownloads") : stack1), depth0))
    + "</p></div>\n                <div class=\"feature\"><i class=\"icon icon--lg icon--check mg-right-lv2\"></i><p class=\"font-sm mg-bottom-lv1\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"unlimitedUse") : stack1), depth0))
    + "</p></div>\n                <div class=\"feature\"><i class=\"icon icon--lg icon--check mg-right-lv2\"></i><p class=\"font-sm mg-bottom-lv1\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"support") : stack1), depth0))
    + "</p></div>\n                <div class=\"feature\"><i class=\"icon icon--lg icon--check mg-right-lv2\"></i><p class=\"font-sm mg-bottom-lv3\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"noAds") : stack1), depth0))
    + "</p></div>\n                <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"urlPricing") : stack1), depth0))
    + "\" class=\"bj-button bj-button--yellow full-width\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"goPremium") : stack1), depth0))
    + "</a>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});