declare global {
    interface Window {
        UserSession: {
            userInfo: {
                session: {
                    premium: number;
                };
            };
        };
    }
}

const SELECTOR_SHOW = '.fi-premium';
const SELECTOR_HIDE = '.fi-not-premium';

window.addEventListener('userSessionLoaded', () => {
    Premium.check();
});

export class Premium {
    private static isPremium(): boolean {
        if (typeof window.UserSession.userInfo.session.premium === 'undefined') {
            return false;
        }

        return Boolean(window.UserSession.userInfo.session.premium);
    }

    static check(): void {
        const isPremium = this.isPremium();

        document.querySelectorAll(SELECTOR_SHOW).forEach(element => {
            element.classList.toggle('hidden', !isPremium);
        });
        document.querySelectorAll(SELECTOR_HIDE).forEach(element => {
            element.classList.toggle('hidden', isPremium);
        });
    }
}
