import { Notify } from 'BobjollNotify';
import User from 'Project/ts/pods/user/main';
import { History } from './history';

const notify = new Notify({
    User: {
        isLogged: User.isLogged,
        isPremiumFlaticon: User.isPremiumFlaticon,
        isPremiumFlaticonAnnual: User.isPremiumFlaticonAnnual,
        isPremiumFlaticonMonthly: User.isPremiumFlaticonMonthly,
        isPremiumFreepik: User.isPremiumFreepik,
        isPremiumFreepikAnnual: User.isPremiumFreepikAnnual,
        isPremiumFreepikMonthly: User.isPremiumFreepikMonthly,
        isRenewalCanceledFlaticon: User.isRenewalCanceledFlaticon,
        isRenewalCanceledFreepik: User.isRenewalCanceledFreepik,
        isRenewalCanceledPlus: User.isRenewalCanceledPlus,
        isInterestedInSponsor: User.isInterestedInSponsor,
        type: function() {
            let type = User.type();
            if ('Anonymous' === type) {
                type = 'Free';
            }
            return type as any;
        },
    },
    History: {
        getHistoryLength: () => History.get().length,
    },
});

export default notify;
