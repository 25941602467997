export const stringEncode = (str: string) => encodeURIComponent(str.replace(/%20|%2B/g, '+'));

export const capitalize = (word: string) => {
    if (word != '' && typeof word == 'string') {
        return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
        return word;
    }
};

export function filterText(str: string) {
    if (typeof str != 'undefined') {
        str = str.replace(/^\s+|\s+$|\(\)\'/g, '').toLowerCase();
        const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/,:;';
        const to = 'aaaaaeeeeeiiiiooooouuuunc-----';
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        return str
            .replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');
    }
    return str;
}
