import { qq, q, ww } from 'bobjoll/ts/library/dom';
import { Downloads } from 'Project/ts/common-app/downloads';
import { getModalDownload } from 'Project/ts/pods/modals/download/downloadModalService';
import { LazyModal } from 'Pods/modals/lazyModal';
import User from 'Project/ts/pods/user/main';
export class PackDetail {
    private static packId: string;

    public static init() {
        let downloadButtonElement = q('.btn-download-pack');
        if (downloadButtonElement) {
            if (downloadButtonElement.dataset.pack) {
                this.packId = downloadButtonElement.dataset.pack;
            }
        }
        Downloads.initializeDownload('pack', 'pack-detail');
        PackDetail.hidePackEquivalents('.similar-packs');
        window.addEventListener('resize', function() {
            PackDetail.hidePackEquivalents('.similar-packs');
        });
        PackDetail.handleListeners();
    }
    public static hidePackEquivalents(containerElement: string) {
        let boxesContainerElement = qq(containerElement + ' .box');
        let maxRows = 2;
        let row = 1;
        if (boxesContainerElement.length) {
            let visibleElements = 0;
            let offsetTop = (document.querySelector(containerElement + ' p') as HTMLElement)?.offsetHeight || 0;
            boxesContainerElement.forEach(box => {
                if (row <= maxRows) {
                    visibleElements++;
                    if (box.offsetTop != offsetTop) {
                        offsetTop = box.offsetTop;
                        row++;
                    }
                }
            });

            if (boxesContainerElement.length > visibleElements || (boxesContainerElement.length == visibleElements && row > 2)) {
                let moreElement = q(`${containerElement} .overlay-more`);
                if (moreElement) {
                    q(`${containerElement} .box:nth-child(${visibleElements})`)?.insertAdjacentElement('afterbegin', moreElement);
                }

                let hiddenElement = qq(`${containerElement} .box:nth-child(n+${visibleElements + 1})`);
                hiddenElement.forEach(element => {
                    element.style.display = 'none';
                });
            }
        }
    }

    private static handleListeners() {
        const detailPack = document.getElementById('detail-pack-aside');
        if (detailPack && !User.isPremium()) {
            let bntDownloadElement: HTMLButtonElement | null = detailPack.querySelector('.btn-download-pack');
            if (bntDownloadElement) {
                bntDownloadElement.addEventListener('click', () => {
                    bntDownloadElement?.removeAttribute('href');
                    bntDownloadElement ? getModalDownload(true, bntDownloadElement) : LazyModal.callModalDownloadOnlyForPremium();
                });
            }
        }
    }
}
