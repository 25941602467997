export const isExpiredByTimestamp = (timestamp: number) => {
    return new Date(timestamp) < new Date();
};

export const addHoursToTimestamp = (hours: number) => {
    const date = new Date();
    return new Date(new Date(date).setHours(date.getHours() + hours)).getTime();
};
export const addMinutesToTimestamp = (minutes: number) => {
    const date = new Date();
    return new Date(new Date(date).setMinutes(date.getMinutes() + minutes)).getTime();
};
