/* eslint-disable @typescript-eslint/camelcase */
import { ww } from 'bobjoll/ts/library/dom';
import { clearAuthStorage } from 'CommonApp/eventsAfterLoginHelper';
import { cookie } from 'Library/cookie';
import { FeatureFlag } from 'Library/featureFlag';
import getXHR from 'Partials/xhr';
import { urlRedirect } from 'Project/ts/common/urls.helper';
import { GrAuthMe, User } from 'Project/ts/pods/user/userSession.vm';

export const mapUserData = (user: User): GrAuthMe => {
    const userData = JSON.parse(user.value);

    return {
        avatar: userData.a,
        cc_will_expire: userData.cce,
        creation_date: userData.cd,
        email: userData.e,
        email_verified: userData.ev,
        first_buy_fi: userData.fbfi,
        first_buy_fr: userData.fbfp,
        id: userData.id,
        login: userData.l,
        newsletter: userData.nl,
        paused_subscription: userData.ps,
        pr_freq_fi: userData.prffi,
        pr_freq_fp: userData.prffi,
        premium: userData.pm,
        premium_flaticon: userData.pfi,
        premium_freepik: userData.pfp,
        profession: userData.pf,
        real_name: userData.rn,
        renewal_canceled_flaticon: userData.rcfi,
        renewal_canceled_freepik: userData.rcfp,
        renewal_canceled_plus: userData.rcpp,
        ts: userData.ts,
        uhash: userData.uh,
        downloadStats: {
            max: userData.ld,
            now: userData.d,
        },
    };
};

export const logoutSession = () => {
    localStorage.removeItem(`fi_${ww.UserSession.userInfo.session.id}`);
    const url = APP_URL + 'logout';

    getXHR(url, {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-type': 'application/json',
        },
        withCredentials: true,
    }).then(() => {
        if (typeof cookie.getItem('CB_URL') === 'string') {
            urlRedirect(cookie.getItem('CB_URL'));
        } else {
            urlRedirect();
        }
        cookie.removeItem('g_state');
        clearAuthStorage();
    });
};
