var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"icon__font__in__action\">\n    <div class=\"container\">\n    <h2 class=\"font-h6 mg-bottom-lv2\">"
    + ((stack1 = (lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"in_action_title") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":107}}})) != null ? stack1 : "")
    + "</h2>\n    <p class=\"mg-bottom-lv4\">"
    + ((stack1 = (lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"in_action_text") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":97}}})) != null ? stack1 : "")
    + "</p>\n    <div class=\"icon__font__in__action--examples row\">\n        <div>\n            <div class=\"phone-example\">\n                <div class=\"phone-example--text\">\n                    <p class=\"font-xs\">"
    + ((stack1 = (lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"in_action_mobile_app") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":9,"column":39},"end":{"line":9,"column":113}}})) != null ? stack1 : "")
    + "</p>\n                </div>\n                <div class=\"phone-example--buttons\">\n                    <span>\n                        <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"20\">\n                    </span>\n                    <span>\n                        <img src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/heart.svg\" width=\"20\" heiht=\"20\">\n                    </span>\n                    <span>\n                        <img src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/comment.svg\" width=\"20\" heiht=\"20\">\n                    </span>\n                    <span>\n                        <img src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/user.svg\" width=\"20\" heiht=\"20\">\n                    </span>                      \n                </div>\n\n            </div>\n        </div>\n        <div>\n            <div class=\"notifications-example\">\n                <div class=\"notifications-example--1\">\n                    <img class=\"mg-right-lv2\" src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"18\">\n                    <span class=\"font-xs medium\">"
    + ((stack1 = (lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"in_action_button") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":32,"column":49},"end":{"line":32,"column":119}}})) != null ? stack1 : "")
    + "</span>\n                </div>\n                <div class=\"notifications-example--2\">\n                    <img class=\"mg-right-lv2\" src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"18\">\n                    <span class=\"font-xs medium\">"
    + ((stack1 = (lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"in_action_button") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":36,"column":49},"end":{"line":36,"column":119}}})) != null ? stack1 : "")
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div>\n            <div class=\"menu-example\">\n                <div>\n                    <img class=\"mg-right-lv2\" src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"18\" heiht=\"18\">\n                    <span class=\"font-xs\">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n                </div>\n                <div>\n                    <img class=\"mg-right-lv2\" src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/briefcase.svg\" width=\"18\" heiht=\"18\">\n                    <span class=\"font-xs\">"
    + ((stack1 = alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"in_action_briefcase") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n                </div>\n                <div>\n                    <img class=\"mg-right-lv2\" src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/stats.svg\" width=\"18\" heiht=\"18\">\n                    <span class=\"font-xs\">"
    + ((stack1 = alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"in_action_stadistics") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n                </div>\n                <div>\n                    <img class=\"mg-right-lv2\" src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/dollar.svg\" width=\"18\" heiht=\"18\">\n                    <span class=\"font-xs\">"
    + ((stack1 = alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"in_action_currency") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div>\n            <div class=\"sizes-example\">\n                <div class=\"sizes-example--white\">\n                    <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"16\" heiht=\"16\">\n                    <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"20\" heiht=\"20\">\n                    <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"26\" heiht=\"26\">\n                    <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"32\" heiht=\"32\">\n                    <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"44\" heiht=\"44\">\n                </div>\n                <div class=\"sizes-example--black\">\n                    <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"44\" heiht=\"44\">\n                    <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"32\" heiht=\"32\">\n                    <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"26\" heiht=\"26\">\n                    <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"20\" heiht=\"20\">\n                    <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"16\" heiht=\"16\">\n                    \n                </div>\n            </div>\n        </div>\n        <div>\n            <div class=\"tablet-example\">\n                <div class=\"tablet-example--sidebar\">\n                    <img src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/home.svg\" width=\"16\" heiht=\"16\">\n                    <img src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/bell.svg\" width=\"16\" heiht=\"16\">\n                    <img src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/settings.svg\" width=\"16\" heiht=\"16\">\n                    <img src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/life-ring.svg\" width=\"16\" heiht=\"16\">\n\n                </div>\n                <div class=\"tablet-example--body\">\n                    <p class=\"medium mg-bottom-lv1\">"
    + ((stack1 = (lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"in_action_tablet") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":89,"column":52},"end":{"line":89,"column":122}}})) != null ? stack1 : "")
    + "</p>\n                    <span class=\"font-sm\">"
    + ((stack1 = alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"in_action_interface") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n                    <div class=\"tablet-example--body--frame yellow\">\n                        <div>\n                            <img src=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" width=\"28\">\n\n                        </div>\n\n                    </div>\n                    <div class=\"tablet-example--body--frame\">\n                        <div>\n                            <img src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/file.svg\" width=\"28\" heiht=\"28\">\n                        </div>\n                        \n                    </div>\n                    <div class=\"tablet-example--body--frame\">\n                        <div>\n                        <img src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/folder.svg\" width=\"28\" heiht=\"28\">\n                        </div>\n                        \n                    </div>\n                    \n                </div>\n            </div>\n        </div>\n    </div>\n\n    </div>\n    \n</section>";
},"useData":true});