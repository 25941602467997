var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"attribution\" data-attr-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":9,"column":55},"end":{"line":9,"column":66}}}) : helper)))
    + "\">\n                    <div class=\"row row--vertical-center row--horizontal-center mg-none\">\n\n                        <div class=\"attribution__video\">\n                            <video style=\"-webkit-mask-box-image: url('"
    + alias4(((helper = (helper = lookupProperty(helpers,"mask") || (depth0 != null ? lookupProperty(depth0,"mask") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mask","hash":{},"data":data,"loc":{"start":{"line":13,"column":71},"end":{"line":13,"column":81}}}) : helper)))
    + "'); mask-image: url('"
    + alias4(((helper = (helper = lookupProperty(helpers,"mask") || (depth0 != null ? lookupProperty(depth0,"mask") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mask","hash":{},"data":data,"loc":{"start":{"line":13,"column":102},"end":{"line":13,"column":112}}}) : helper)))
    + "');\">\n                                <source src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"video") || (depth0 != null ? lookupProperty(depth0,"video") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"video","hash":{},"data":data,"loc":{"start":{"line":14,"column":45},"end":{"line":14,"column":56}}}) : helper)))
    + "\" type=\"video/mp4\">\n                            </video>\n                        </div>\n\n                        <div class=\"content paragraph-readable full-width push-center-i\">\n                            <h2 class=\"first mg-bottom-lv2 font-h6 bold line-height-sm\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"author") : depth0),"!=",undefined,{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":32},"end":{"line":26,"column":44}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resourceType") : depth0),"==","uicons",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":32},"end":{"line":33,"column":44}}})) != null ? stack1 : "")
    + "                            </h2>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"catch") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":40,"column":35}}})) != null ? stack1 : "")
    + "\n                            <p class=\"font-sm\">\n                                "
    + ((stack1 = (lookupProperty(helpers,"language")||(depth0 && lookupProperty(depth0,"language"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,"<button data-modal=\"modal-attribution-new\" class=\"bj-button bj-button--link underline text__general--heading modal__trigger\" data-type=\"icon\">",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":46,"column":40},"end":{"line":46,"column":193}}}),"</button>",(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,"<a href=\"",((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"LICENSES") : stack1)) != null ? lookupProperty(stack1,"url") : stack1)) != null ? lookupProperty(stack1,"2") : stack1),"\" target=\"_blank\" class=\"underline text__general--heading\">",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":47,"column":40},"end":{"line":47,"column":146}}}),"</a>",{"name":"language","hash":{},"data":data,"loc":{"start":{"line":43,"column":32},"end":{"line":48,"column":35}}})) != null ? stack1 : "")
    + "\n                            </p>\n\n                            <div class=\"social-media-buttons\">\n                                <button class=\"bj-button--icon bj-button bj-button--pinterest track\" data-track-arguments=\"ga, event, attribution, "
    + alias4(((helper = (helper = lookupProperty(helpers,"resourceType") || (depth0 != null ? lookupProperty(depth0,"resourceType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resourceType","hash":{},"data":data,"loc":{"start":{"line":52,"column":147},"end":{"line":52,"column":165}}}) : helper)))
    + ", pinterest\" onclick=\"share.popup({network: 'pinterest'}); return false\" data-gtm-event=\"pinterest attribution\">\n                                    <i class=\"icon icon--pinterest\"></i>\n                                    \n                                </button>\n                                <button class=\"bj-button--icon bj-button bj-button--facebook track\" data-track-arguments=\"ga, event, attribution, "
    + alias4(((helper = (helper = lookupProperty(helpers,"resourceType") || (depth0 != null ? lookupProperty(depth0,"resourceType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resourceType","hash":{},"data":data,"loc":{"start":{"line":56,"column":146},"end":{"line":56,"column":164}}}) : helper)))
    + ", facebook\" onclick=\"share.popup({network: 'facebook'}); return false\">\n                                    <i class=\"icon icon--facebook\"></i>\n                                    \n                                </button>\n                                <button class=\"bj-button--icon bj-button bj-button--twitter track\" data-track-arguments=\"ga, event, attribution, "
    + alias4(((helper = (helper = lookupProperty(helpers,"resourceType") || (depth0 != null ? lookupProperty(depth0,"resourceType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resourceType","hash":{},"data":data,"loc":{"start":{"line":60,"column":145},"end":{"line":60,"column":163}}}) : helper)))
    + ", twitter\" onclick=\"share.popup({network: 'twitter'}); return false\">\n                                    <i class=\"icon icon--twitter\"></i>\n                                    \n                                </button>\n                                <span class=\"attribution__text\"></span>\n                                <div class=\"copy__text\">\n                                    <input type=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"license") || (depth0 != null ? lookupProperty(depth0,"license") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"license","hash":{},"data":data,"loc":{"start":{"line":66,"column":62},"end":{"line":66,"column":75}}}) : helper)))
    + "\">\n                                </div>\n                                <button class=\"bj-button bj-button--secondary bj-button--state copy__trigger\"\n                                    data-copy='<a href=\""
    + alias4(alias5(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"attribution_link")), depth0))
    + "\">"
    + ((stack1 = (lookupProperty(helpers,"capitalize")||(depth0 && lookupProperty(depth0,"capitalize"))||alias2).call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"attribution_text")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":69,"column":84},"end":{"line":69,"column":123}}})) != null ? stack1 : "")
    + "</a>'>\n                                    <i class=\"icon icon--sm icon--url\"></i>\n                                    <span class=\"active hide-mobile\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copy") : stack1), depth0))
    + "</span>\n                                    <span class=\"hide-mobile\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copied") : stack1), depth0))
    + "</span>\n                                </button>\n                            </div>\n\n                            <div class=\"row row--vertical-center mg-none download-problems-box\">\n                                <i class=\"icon icon--xs icon--bug text__general--heading\"></i>\n                                <a href=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"DOWNLOAD_PROBLEM") : stack1), depth0))
    + "\" id=\"download-problems\" class=\"button--link font-sm\" target=\"_blank\">\n                                    "
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"download_problems") : stack1), depth0))
    + "\n                                </a>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + ((stack1 = (lookupProperty(helpers,"language")||(depth0 && lookupProperty(depth0,"language"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,"<a class=\"underline text__general--heading bold\" href=\"",((stack1 = (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? lookupProperty(stack1,"url") : stack1),"\" data-filter=\"format=author&author=",((stack1 = (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),"\">",((stack1 = (depth0 != null ? lookupProperty(depth0,"author") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),"</a>",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":24,"column":44},"end":{"line":24,"column":196}}}),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":21,"column":36},"end":{"line":25,"column":39}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + ((stack1 = (lookupProperty(helpers,"language")||(depth0 && lookupProperty(depth0,"language"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,"Uicons",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":31,"column":44},"end":{"line":31,"column":61}}}),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":28,"column":36},"end":{"line":32,"column":39}}})) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <h6 class=\"first font-xl medium mg-bottom-lv2 line-height-sm hide-mobile\">\n                                "
    + ((stack1 = (lookupProperty(helpers,"language")||(depth0 && lookupProperty(depth0,"language"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"catch") : depth0),{"name":"language","hash":{},"data":data,"loc":{"start":{"line":38,"column":32},"end":{"line":38,"column":52}}})) != null ? stack1 : "")
    + "\n                            </h6>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"banner\">\n                    <h6 class=\"text-inverted first\">"
    + container.escapeExpression(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"dont_want_credit") : stack1), depth0))
    + "</h6>\n                    <p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"go_premium") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                </div>\n                \n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"banner\">\n                    <div class=\"text mg-none\">\n                        <h6 class=\"font-lg text-inverted first mg-bottom-lv1\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"dont_want_credit") : stack1), depth0))
    + "</h6>\n                        <p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"go_premium_detail") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                    </div>\n                    <a class=\"bj-button bj-button--flat bj-button--yellow text-inverted mg-left-lv3 track\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":99,"column":113},"end":{"line":99,"column":127}}}) : helper)))
    + "\" data-track-arguments=\"ga, event, premium, icon-attribute-modal\"><strong>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"autopromo") : stack1)) != null ? lookupProperty(stack1,"go_premium") : stack1), depth0)) != null ? stack1 : "")
    + "</strong></a>\n                </div>\n            \n\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"detail__download-confirmation\">\n    <div id=\"modal-attribution\" class=\"modal\" data-disable-mouse-up>\n        <div class=\"modal-attribution__wrapper\">\n            <div class=\"modal__container pd-none-i\">\n                <button class=\"detail__download-confirmation__close button button--sm button--pill button--icon button--icon--only button--inverted--hover\">\n                    <i class=\"icon icon--md icon--mg-sm icon--cross\"></i>\n                </button>\n"
    + ((stack1 = (lookupProperty(helpers,"randomArr")||(depth0 && lookupProperty(depth0,"randomArr"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"attributionIncentives") : depth0),{"name":"randomArr","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":85,"column":26}}})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"directDownload") : depth0),"==",true,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":87,"column":12},"end":{"line":103,"column":24}}})) != null ? stack1 : "")
    + "                \n        </div>\n\n    </div>\n</div>\n";
},"useData":true});