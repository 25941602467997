import { qq } from 'bobjoll/ts/library/gr/dom.q';

export default class Lazyload {
    public static run(parent: HTMLElement = document.body) {
        ([...qq('.lzy:not(.lazyload--done)', parent), ...qq('.lazyload:not(.lazyload--done)', parent)] as
            | HTMLImageElement[]
            | HTMLVideoElement[]).forEach((image: HTMLImageElement | HTMLVideoElement) => Lazyload.check(image));
    }

    private static check(image: HTMLImageElement | HTMLVideoElement) {
        if (Lazyload.inview(image)) {
            if (image.nodeName === 'VIDEO') {
                return this.lazyVideo(image as HTMLVideoElement);
            } else {
                return this.lazyImage(image as HTMLImageElement);
            }
        }
    }

    private static lazyVideo = (video: HTMLVideoElement) => {
        const resourceVideo = video.children[0] as HTMLSourceElement;
        const source = ((video.children.length && video.children[0]) as HTMLSourceElement).dataset.src || '';

        if (source) {
            if (video.dataset.poster) {
                video.poster = video.dataset.poster;
            }
            video.classList.add('lazyload--done');
            resourceVideo.src = source;
            video.load();
            video.poster = '';

            const showcaseContent = video.parent('.showcase__content');
            if (showcaseContent) {
                (showcaseContent as HTMLElement).style.backgroundColor = '#fff';
            }

            resourceVideo.src = source;
            resourceVideo.srcset = source;
        }
    };

    private static lazyImage = (image: HTMLImageElement) => {
        const source = image.dataset.src;
        const resourceImage = new Image();
        image.classList.add('lazyload--done');

        if (source) {
            resourceImage.src = source;
            resourceImage.onload = resourceImage.onerror = () => {
                const showcaseContent = image.parent('.showcase__content');
                if (showcaseContent) {
                    (showcaseContent as HTMLElement).style.backgroundColor = '#fff';
                }

                image.src = source;
                (image as HTMLImageElement).srcset = `${source} 4x`;
            };
        }
    };

    private static inview(resource: HTMLImageElement | HTMLVideoElement) {
        const bounding = resource.getBoundingClientRect();

        if (
            resource.classList.contains('image-overflow-croped') &&
            bounding.top <= (window.innerHeight || document.getPathValue('documentElement.clientHeight'))
        ) {
            return true;
        }

        return (
            bounding.width > 0 &&
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.top <= (window.innerHeight || document.getPathValue('documentElement.clientHeight')) &&
            bounding.right <= (window.innerWidth || document.getPathValue('documentElement.clientWidth'))
        );
    }
}

window.addEventListener('resize', () => setTimeout(Lazyload.run, 50));
