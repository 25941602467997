var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"modal-remove-palette\" class=\"modal modal--xs modal-remove-palette\">\n	<div class=\"modal__container\">\n		<div class=\"content text__general--heading\">\n			<button class=\"modal__close modal__close--absolute modal__close--top-right button button--icon button--icon--only button--sm button--inverted nostyle\">\n				<i class=\"icon icon--lg icon--mg-lg icon--cross\"></i>\n			</button>\n			<p class=\"font-xl semibold\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"areYouSure") : stack1), depth0))
    + "</p>\n			<p class=\"font-md mg-bottom-lv4\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"youWillLose") : stack1), depth0))
    + "</p>\n			<div class=\"modal-remove-palette-footer\">\n				<button class=\"bj-button bj-button--md bj-button--link-dark no-thanks-palette\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"noThanks") : stack1), depth0))
    + "</button>\n				<button class=\"remove-palette\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"colorPalette") : stack1)) != null ? lookupProperty(stack1,"modals") : stack1)) != null ? lookupProperty(stack1,"remove") : stack1), depth0))
    + "</button>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});