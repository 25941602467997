import 'Variables';

import { ww } from 'bobjoll/ts/library/dom';
import { InsertSettings } from 'BobjollNotifications';
import { Position } from 'BobjollNotifications';
import Notification from 'BobjollNotifications';
import { cookie } from 'Library/cookie';
import getXHR from 'Partials/xhr';
import { logoutSession } from 'Project/ts/pods/user/userSessionService';

import { updateUserDownloadBadgeWhenStatsChange } from './updateUserDownloadsBadge';
import UserSession from './userSession';
import { UserDownloadStats } from './userSession.vm';

export default class User {
    static api = `${BASE_URL}xhr/`;
    static interestedCookieName = 'interested_adb';
    static interestedPremiumContent = 'interested_prm';

    static notification = new Notification({
        fixed: true,
        recurrent: false,
        template: require('bobjoll/ts/views/hbs/alert-v1.0/element.html.hbs'),
    });

    static isLogged() {
        return '1' == window.getPathValue('gr.auth.logged');
    }

    static isFreepik() {
        return User.isLibraryLoaded() && ((gr.auth.me && gr.auth.me.email) || '').match(/\@freepik.com/) ? true : false;
    }

    static isPremium() {
        return 1 == window.getPathValue('gr.auth.me.premium') || false;
    }

    static isPremiumFreepik() {
        return '1' == window.getPathValue('gr.auth.me.premium_freepik');
    }

    static isPremiumFlaticon() {
        return '1' == window.getPathValue('gr.auth.me.premium_flaticon');
    }

    static isPremiumFreepikAnnual() {
        return 'year' == window.getPathValue('gr.auth.me.pr_freq_fp');
    }

    static isPremiumFreepikMonthly() {
        return 'month' == window.getPathValue('gr.auth.me.pr_freq_fp');
    }

    static isPremiumFlaticonAnnual() {
        return 'year' == window.getPathValue('gr.auth.me.pr_freq_fi');
    }

    static isPremiumFlaticonMonthly() {
        return 'month' == window.getPathValue('gr.auth.me.pr_freq_fi');
    }

    static isRenewalCanceledFlaticon() {
        return 1 === window.getPathValue('gr.auth.me.renewal_canceled_flaticon');
    }

    static isRenewalCanceledFreepik() {
        return 1 === window.getPathValue('gr.auth.me.renewal_canceled_freepik');
    }

    static isRenewalCanceledPlus() {
        return 1 === window.getPathValue('gr.auth.me.renewal_canceled_plus');
    }

    static alert(
        id: string,
        html: string,
        type: 'warning' | 'error' | 'success',
        position: keyof Position,
        timeout?: number,
    ) {
        const settings: InsertSettings = {
            id: id,
            class: `notification--${type}`,
            html: html,
            position: position,
        };

        if (timeout) {
            settings.timeout = timeout;
            settings.fixed = false;
        }

        User.notification.insert(settings);
    }

    static id() {
        return User.isLibraryLoaded() ? (gr.auth.logged ? gr.auth.me.id : 0) : 0;
    }

    static type() {
        return User.isLibraryLoaded()
            ? gr.auth.logged
                ? gr.auth.me.premium
                    ? 'Premium'
                    : 'Free'
                : 'Guest'
            : 'Guest';
    }

    static isLibraryLoaded() {
        return 'undefined' !== typeof gr && null !== gr;
    }

    static getData() {
        return gr.auth.me;
    }

    public static init() {
        if (ww.UserSession.userInfo.logged) {
            this.handleUserAvatar();
            this.checkPremiumUserICon();
            this.setLogoutBtn();
            updateUserDownloadBadgeWhenStatsChange();
        }
    }

    private static checkPremiumUserICon() {
        document.querySelector('#gr_user_menu .user-dropdown.gr_connected')?.classList.add('premium');
        const premiunBtnIcon = document.getElementById('premium-icon');

        this.isPremium() && ww.UserSession.userDomElement.userAvatarMenu && !premiunBtnIcon
            ? ww.UserSession.userDomElement.userAvatarMenu.insertAdjacentHTML(
                  'afterbegin',
                  '<i id="premium-icon" class="premium icon icon--premium" title="Premium user"></i>',
              )
            : '';
    }

    private static handleUserAvatar() {
        ww.UserSession.userDomElement = {
            userAvatarMenu: document.querySelector('#gr_user_menu_avatar'),
            userAvatarMenuName: document.querySelector('#gr_user_menu .username'),
            userAvatarImg: document.querySelector('.gr_user_avatar'),
            userAvatarMenuImg: document.querySelector('#gr_user_menu_avatar img'),
            userAvatarMenuNotConnected: document.querySelector('#gr_user_menu .gr_not-connected'),
            userAvatarMenuConnected: document.querySelector('#gr_user_menu .gr_connected'),
        };

        if (ww.UserSession.userDomElement.userAvatarMenuName) {
            ww.UserSession.userDomElement.userAvatarMenuName.innerHTML = ww.UserSession.userInfo.session.login || '';
        }

        if (ww.UserSession.userDomElement.userAvatarImg) {
            ww.UserSession.userDomElement.userAvatarImg.src = ww.UserSession.userInfo.session.avatar || '';
        }

        if (ww.UserSession.userDomElement.userAvatarMenuImg) {
            ww.UserSession.userDomElement.userAvatarMenuImg.alt = ww.UserSession.userInfo.session.login || '';
        }

        if (ww.UserSession.userDomElement.userAvatarMenuImg) {
            ww.UserSession.userDomElement.userAvatarMenuImg.src = ww.UserSession.userInfo.session.avatar || '';
        }

        if (ww.UserSession.userDomElement.userAvatarMenuNotConnected) {
            ww.UserSession.userDomElement.userAvatarMenuNotConnected.remove();
        }

        if (ww.UserSession.userDomElement.userAvatarMenuConnected) {
            ww.UserSession.userDomElement.userAvatarMenuConnected.style.display = 'inline-block';
        }
    }

    private static setLogoutBtn() {
        document.querySelector('#logout_link')?.addEventListener('click', () => {
            logoutSession();
        });
    }

    public static isInterestedInSponsor() {
        return Object.keys(User.getUserSponsorInterested()).length ? true : false;
    }

    public static getUserSponsorInterested() {
        const cookieInterested = cookie.getItem(User.interestedCookieName);
        return cookieInterested ? JSON.parse(cookieInterested) : {};
    }

    public static getInterestedInPremiumContent() {
        const cookieInterestedPremiumContent = cookie.getItem(User.interestedPremiumContent);
        return cookieInterestedPremiumContent ? JSON.parse(cookieInterestedPremiumContent) : null;
    }

    private static getUserStatsFromLocalStorage(): UserDownloadStats | undefined {
        return ww.UserSession.userInfo.session.downloadStats;
    }

    private static getUserStatsFromRemote(): Promise<UserDownloadStats> {
        return getXHR(`${FLATICON_DOWNLOAD_URL}ajax/download/user-stats`, {
            withCredentials: true,
        }).then((response: UserDownloadStats) => {
            window.dispatchEvent(
                new CustomEvent(`userDownloadStatsUpdated`, {
                    detail: response,
                }),
            );

            return response;
        });
    }

    public static isAllowedToDownload(): Promise<boolean> {
        return new Promise(resolve => {
            this.getUserStats().then(stats => {
                const allowed = stats.now < stats.max;
                resolve(allowed);
            });
        });
    }

    public static updateUserStats(delay: number = 0): void {
        setTimeout(() => this.getUserStatsFromRemote().then(), delay);
    }

    public static getUserStats(): Promise<UserDownloadStats> {
        return new Promise(resolve => {
            const valueInLocalStorage = this.getUserStatsFromLocalStorage();

            if (valueInLocalStorage) {
                resolve(valueInLocalStorage);
                return;
            }

            this.getUserStatsFromRemote().then(value => resolve(value));
        });
    }

    public static isActiveUser() {
        return (
            typeof ww.UserSession.userInfo.session.email_verified == 'undefined' ||
            ww.UserSession.userInfo.session.email_verified
        );
    }

    public static isPaused() {
        return User.isLogged() && ww.UserSession.userInfo.session.paused_subscription;
    }
}
