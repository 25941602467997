import getXHR from 'Partials/xhr';
import { buildUrlParams } from 'Pods/uicons/uicons.helpers';
import { SearchOptions } from 'Project/ts/pods/uicons/uicons.vm';

export const getUicons = (searchOptions: SearchOptions) => {
    const uiconsParams = buildUrlParams(
        searchOptions.searchTarget,
        searchOptions.weightFilterValue,
        searchOptions.cornerFilterValue,
        searchOptions.brandsFilterValue,
    );

    const uIconsGetUrl = !searchOptions.pagination
        ? `${APP_URL}ajax/search?${uiconsParams}&type=uicon`
        : searchOptions.searchTarget;

    return getXHR(uIconsGetUrl, {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-type': 'application/json',
        },
        withCredentials: true,
    });
};

export const getUiconTags = async (iconId: string | undefined) => {
    if (iconId) {
        const uIconsGetTagsUrl = `${urls.uicons.tagByUIconId}${iconId}`;

        return getXHR(uIconsGetTagsUrl, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-type': 'application/json',
            },
            withCredentials: true,
        });
    }
};

export const getUiconRelated = async (iconId: string | undefined) => {
    if (iconId) {
        const uIconsGetRelatedUrl = `${APP_URL}ajax/uicons/related/${iconId}`;

        return getXHR(uIconsGetRelatedUrl, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-type': 'application/json',
            },
            withCredentials: true,
        });
    }
};
