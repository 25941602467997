var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " animated--icon ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-page=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"icon--item "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1),"==","animated-icon",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":22},"end":{"line":1,"column":109}}})) != null ? stack1 : "")
    + "\"\n    data-name_detail=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"nameDetail") : stack1), depth0))
    + "\"\n    data-author=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"groupId") : stack1), depth0))
    + "\"\n    data-author_avatar=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"authorAvatar") : stack1), depth0))
    + "\"\n    data-author_name=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"teamName") : stack1), depth0))
    + "\"\n    data-author_website=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"teamWebsite") : stack1), depth0))
    + "\"\n    data-selection=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"selection") : stack1), depth0))
    + "\"\n    data-style_class=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"styleClass") : stack1), depth0))
    + "\"\n    data-author_icons=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"authorIcons") : stack1), depth0))
    + "\"\n    data-author_icons_text=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"authorIconsText") : stack1), depth0))
    + "\"\n    data-author_total_icons_text=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"authorTotalIconsText") : stack1), depth0))
    + "\"\n    data-category_id=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"categoryId") : stack1), depth0))
    + "\"\n    data-category_name=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"category") : stack1), depth0))
    + "\"\n    data-category_url=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"categoryUrl") : stack1), depth0))
    + "\"\n    data-class=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"class") : stack1), depth0))
    + "\"\n    data-color=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\"\n    data-craft=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"craft") : stack1), depth0))
    + "\"\n    data-background_color=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"backgroundColor") : stack1), depth0))
    + "\"\n    data-description=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n    data-family_id=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"familyId") : stack1), depth0))
    + "\"\n    data-family_name=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"familyName") : stack1), depth0))
    + "\"\n    data-group_id=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"groupId") : stack1), depth0))
    + "\"\n    data-icon_src=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"iconImg") : stack1), depth0))
    + "\"\n    data-id=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n    data-keyword_name=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"keywordName") : stack1), depth0))
    + "\"\n    data-keyword_link=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"keywordLink") : stack1), depth0))
    + "\"\n    data-license=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"license") : stack1), depth0))
    + "\"\n    data-name=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n    data-pack=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"packSlug") : stack1), depth0))
    + "\"\n    data-pack_id=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"packId") : stack1), depth0))
    + "\"\n    data-pack_items=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"packItems") : stack1), depth0))
    + "\"\n    data-pack_items_text=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"packItemsText") : stack1), depth0))
    + "\"\n    data-pack_name=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"packName") : stack1), depth0))
    + "\"\n    data-pack_sprite=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"packSprite") : stack1), depth0))
    + "\"\n    data-premium=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"premium") : stack1), depth0))
    + "\"\n    data-published=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"published") : stack1), depth0))
    + "\"\n    data-related=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"related") : stack1), depth0))
    + "\"\n    data-style=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"styleId") : stack1), depth0))
    + "\"\n    data-style_name=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"styleName") : stack1), depth0))
    + "\"\n    data-style_slug=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"styleSlug") : stack1), depth0))
    + "\"\n    data-style_color=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"styleColor") : stack1), depth0))
    + "\"\n    data-style_shape=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"styleshape") : stack1), depth0))
    + "\"\n    data-svg=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"svg") : stack1), depth0))
    + "\"\n    data-tags=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"tagsId") : stack1), depth0))
    + "\"\n    data-team_id=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"groupId") : stack1), depth0))
    + "\"\n    data-team_name=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"teamName") : stack1), depth0))
    + "\"\n    data-type=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"\n    data-png=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"png512") : stack1), depth0))
    + "\"\n    data-mp4=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"mp4") : stack1), depth0))
    + "\"\n    data-gif=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"gif") : stack1), depth0))
    + "\"\n    data-icon_type=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"iconType") : stack1), depth0))
    + "\"\n\n    data-groupId=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"groupId") : stack1), depth0))
    + "\"\n    data-packId=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"packId") : stack1), depth0))
    + "\"\n    data-prefix=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"prefix") : stack1), depth0))
    + "\"\n    data-is_editorial=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"is_editorial") : stack1), depth0))
    + "\"\n\n    data-raw=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"raw") : stack1), depth0))
    + "\"\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"page") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":4},"end":{"line":59,"column":61}}})) != null ? stack1 : "")
    + ">\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"holderComponent") || (depth0 != null ? lookupProperty(depth0,"holderComponent") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"holderComponent","hash":{},"data":data,"loc":{"start":{"line":60,"column":4},"end":{"line":60,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n</li>";
},"useData":true});