var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <section data-term=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"keyword") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" data-section=\"detail\" class=\"spirit-a hide-mobile soul-p-nsba    \n      "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","standard",{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":6},"end":{"line":3,"column":97}}})) != null ? stack1 : "")
    + "\">\n      <div class=\"preload full-width full-height\"></div>\n    </section>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " pd-left-lv3 pd-right-lv3 bg-aqua";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"UserSession") : stack1)) != null ? lookupProperty(stack1,"userInfo") : stack1)) != null ? lookupProperty(stack1,"session") : stack1)) != null ? lookupProperty(stack1,"premium") : stack1),"==",0,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":6,"column":12}}})) != null ? stack1 : "");
},"useData":true});