var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":90}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"header-premium header-premium--slim header-premium--bg2"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":67},"end":{"line":1,"column":97}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"content row row--vertical-center\">\n        <div class=\"row mg-none\">\n            <span class=\"ff--title font-h3 col col--center mg-none-i first heading-inverted\">"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"autopromo") : stack1)) != null ? lookupProperty(stack1,"search_premium_banner") : stack1)) != null ? lookupProperty(stack1,"claim_text") : stack1), depth0))
    + "</span>\n\n            <p class=\"col col--center mg-none-i text-inverted font-lg\">\n                    "
    + ((stack1 = alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"autopromo") : stack1)) != null ? lookupProperty(stack1,"search_premium_banner") : stack1)) != null ? lookupProperty(stack1,"description_freePng") : stack1), depth0)) != null ? stack1 : "")
    + "\n                <b class=\"block\">"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"autopromo") : stack1)) != null ? lookupProperty(stack1,"search_premium_banner") : stack1)) != null ? lookupProperty(stack1,"download_cancel") : stack1), depth0))
    + "</b>\n            </p>\n            <a href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"ACCOUNTS_BASE_URL") || (depth0 != null ? lookupProperty(depth0,"ACCOUNTS_BASE_URL") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"ACCOUNTS_BASE_URL","hash":{},"data":data,"loc":{"start":{"line":10,"column":21},"end":{"line":10,"column":42}}}) : helper)))
    + "premium?lang="
    + alias3(((helper = (helper = lookupProperty(helpers,"GR_LANGUAGE") || (depth0 != null ? lookupProperty(depth0,"GR_LANGUAGE") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias1,{"name":"GR_LANGUAGE","hash":{},"data":data,"loc":{"start":{"line":10,"column":55},"end":{"line":10,"column":70}}}) : helper)))
    + "\" class=\"track col--center mg-none-i bj-button bj-button--md bj-button--flat bj-button--yellow\" data-track-arguments=\"ga, event, premium, search-banner-filter-premium\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"autopromo") : stack1)) != null ? lookupProperty(stack1,"go_premium") : stack1), depth0))
    + "</a>\n        </div>\n    </div>\n</div>";
},"useData":true});