export class Swiper {
    private config: { [name: string]: any };
    private selector: HTMLElement;
    private ignoreElements: string;
    private innerElements: [];
    private selectorWidth: number;
    private currentSlide: number;
    private perPage: number;
    private transformProperty: any;
    private pointerDown: boolean;
    private drag: { startX: number; endX: number; startY: number; letItGo: null | boolean; preventClick: boolean };
    private sliderFrame: HTMLDivElement;
    private buttonPrev: HTMLElement;
    private buttonNext: HTMLElement;
    private breakPoint: number | null;
    private elementsIgnoreSwiper = ['TEXTAREA', 'OPTION', 'INPUT', 'SELECT'];
    private classesIgnoreSwiper = /menudots-vertical/;
    private screenWidthInitial = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    [key: string]: any;

    constructor(options: { [name: string]: any }, element?: HTMLElement) {
        this.config = Swiper.mergeSettings(options);
        if (element) {
            this.selector = element;
        } else if (this.config.selector) {
            this.selector = typeof this.config.selector === 'string' ? document.querySelector(this.config.selector) : this.config.selector;
        }
        if (this.selector === null) {
            throw new Error('Swiper selector wrong!');
        }
        this.resolveSlidesNumber();
        this.ignoreElements = this.config.ignoreElements ? this.config.ignoreElements : false;
        this.getChildren();
        this.breakPoint = this.config.breakPoint;
        this.selectorWidth = this.selector.offsetWidth;
        this.currentSlide = this.config.loop
            ? this.config.startIndex % this.innerElements.length
            : Math.max(0, Math.min(this.config.startIndex, this.innerElements.length - this.perPage));
        this.transformProperty = Swiper.webkitOrNot();
        this.getNavigation();
        [
            'resizeHandler',
            'touchstartHandler',
            'touchendHandler',
            'touchmoveHandler',
            'mousedownHandler',
            'mouseupHandler',
            'mouseleaveHandler',
            'mousemoveHandler',
            'clickHandler',
        ].forEach(method => {
            this[method] = this[method].bind(this);
        });
        this.initOnce();
    }

    static mergeSettings(options: { [name: string]: any }) {
        const settings: { [name: string]: any } = {
            breakPoint: 0,
            direction: false,
            draggable: true,
            duration: 200,
            easing: 'ease-out',
            fullWidth: true,
            ignoreElements: /--fake/,
            loop: false,
            maxWidth: 340,
            minWidth: 340,
            multipleDrag: true,
            navigation: {
                elementPrev: null,
                elementNext: null,
                disabledClass: 'disabled',
            },
            perPage: 1,
            selector: '.swiper',
            startIndex: 0,
            threshold: 20,
            onInit: () => {},
            onChange: () => {},
        };
        const userSettings = options;
        for (const attrname in userSettings) {
            settings[attrname] = userSettings[attrname];
        }
        return settings;
    }

    static webkitOrNot() {
        const style = document.documentElement.style;
        if (typeof style.transform === 'string') {
            return 'transform';
        }
        return 'WebkitTransform';
    }

    getChildren() {
        const children = this.selector.children;
        if (this.ignoreElements) {
            const onlyThisChildren = [];
            for (let i = 0; i < children.length; i++) {
                if (children[i].classList.value.match(this.ignoreElements)) {
                    continue;
                }
                onlyThisChildren.push(children[i]);
            }
            this.innerElements = [].slice.call(onlyThisChildren);
        } else {
            this.innerElements = [].slice.call(children);
        }
    }

    getNavigation() {
        if (this.config.navigation.elementNext && this.config.navigation.elementPrev) {
            const footerNavigation = this.selector.nextElementSibling;
            if (footerNavigation && footerNavigation.classList.contains('swiper__navigation')) {
                this.buttonPrev = footerNavigation.querySelector(this.config.navigation.elementPrev);
                this.buttonNext = footerNavigation.querySelector(this.config.navigation.elementNext);
                if (this.currentSlide <= 0) {
                    this.disableNavigation(this.buttonPrev);
                }
            }
        }
    }

    attachEvents() {
        window.addEventListener('resize', () => this.resizeHandler(false));
        if (this.selector && this.config.draggable) {
            this.pointerDown = false;
            this.drag = {
                startX: 0,
                endX: 0,
                startY: 0,
                letItGo: null,
                preventClick: false,
            };
            this.selector.addEventListener('touchstart', this.touchstartHandler);
            this.selector.addEventListener('touchend', this.touchendHandler, { passive: false });
            this.selector.addEventListener('touchmove', this.touchmoveHandler, { passive: false });
            this.selector.addEventListener('mousedown', this.mousedownHandler);
            this.selector.addEventListener('mouseup', this.mouseupHandler);
            this.selector.addEventListener('mouseleave', this.mouseleaveHandler);
            this.selector.addEventListener('mousemove', this.mousemoveHandler);
            this.selector.addEventListener('click', this.clickHandler);
        }
        if (this.buttonPrev && this.buttonNext) {
            this.buttonPrev.addEventListener('click', () => this.prev());
            this.buttonNext.addEventListener('click', () => this.next());
        }
    }

    detachEvents() {
        window.removeEventListener('resize', () => this.resizeHandler(false));
        if (this.selector) {
            this.selector.removeEventListener('touchstart', this.touchstartHandler);
            this.selector.removeEventListener('touchend', this.touchendHandler);
            this.selector.removeEventListener('touchmove', this.touchmoveHandler);
            this.selector.removeEventListener('mousedown', this.mousedownHandler);
            this.selector.removeEventListener('mouseup', this.mouseupHandler);
            this.selector.removeEventListener('mouseleave', this.mouseleaveHandler);
            this.selector.removeEventListener('mousemove', this.mousemoveHandler);
            this.selector.removeEventListener('click', this.clickHandler);
            if (this.buttonPrev && this.buttonNext) {
                this.buttonPrev.removeEventListener('click', () => this.prev());
                this.buttonNext.removeEventListener('click', () => this.next());
            }
        }
    }

    initOnce() {
        if (this.breakPoint && this.breakPoint > 0) {
            const swiper = this;
            window.addEventListener('resize', function createByBreakPoint() {
                if (swiper.breakPoint && swiper.breakPoint >= window.innerWidth) {
                    swiper.init();
                    window.removeEventListener('resize', createByBreakPoint);
                    swiper.enableNavigation();
                    swiper.checkNavigationAfterResize();
                    swiper.calculatePerPageFullWidth();
                }
            });
        } else {
            this.init();
        }
    }

    init() {
        this.attachEvents();
        this.selector.style.direction = this.config.direction ? 'rtl' : 'ltr';
        this.selector.style.position = 'relative';
        this.selector.style.display = 'grid';
        this.selector.style.gridTemplateColumns = '100%';
        this.buildSliderFrame();
        this.config.onInit.call(this);
        this.resizeHandler(true);
    }

    calculatePerPageFullWidth() {
        this.perPage = 0;
        let totalWidthElements = 0;
        for (let i = 0; i < this.innerElements.length; i++) {
            const element = this.innerElements[i] as HTMLElement | null;
            if (element) {
                const widthElement = element.clientWidth;
                totalWidthElements += widthElement;
                if (this.selectorWidth > totalWidthElements) {
                    this.perPage++;
                } else {
                    const elementHidePx = totalWidthElements - this.selectorWidth;
                    const elementShowPx = widthElement - elementHidePx;
                    const percVisible = (elementShowPx * 100) / widthElement;
                    this.perPage += percVisible / 100;
                    break;
                }
            }
        }
    }

    buildSliderFrame() {
        if (this.config.fullWidth) this.calculatePerPageFullWidth();
        const widthItem = this.selectorWidth / this.perPage;
        const itemsToBuild = this.config.loop ? this.innerElements.length + 2 * this.perPage : this.innerElements.length;
        this.sliderFrame = document.createElement('div');
        this.sliderFrame.style.width = `${widthItem * itemsToBuild}px`;
        this.enableTransition();
        if (this.config.draggable) this.selector.style.cursor = '-webkit-grab';

        const docFragment = document.createDocumentFragment();
        if (this.config.loop) {
            for (let i = this.innerElements.length - this.perPage; i < this.innerElements.length; i++) {
                const element = this.innerElements[i] as HTMLElement | null;
                if (element) {
                    const elementCopy = this.buildSliderFrameItem(element.cloneNode(true) as HTMLElement);
                    docFragment.appendChild(elementCopy);
                }
            }
        }
        for (let i = 0; i < this.innerElements.length; i++) {
            const element = this.innerElements[i] as HTMLElement | null;
            if (element) {
                if (this.ignoreElements) {
                    if (element.classList.value.match(this.ignoreElements)) {
                        continue;
                    }
                }
                element.style.width = '100%';
                element.style.minWidth = '100%';
                const elementCopy = this.buildSliderFrameItem(element);
                docFragment.appendChild(elementCopy);
            }
        }
        if (this.config.loop) {
            for (let i = 0; i < this.perPage; i++) {
                const element = this.innerElements[i] as HTMLElement | null;
                if (element) {
                    const elementCopy = this.buildSliderFrameItem(element.cloneNode(true) as HTMLElement);
                    docFragment.appendChild(elementCopy);
                }
            }
        }
        this.sliderFrame.appendChild(docFragment);
        this.sliderFrame.classList.add('swiper__row');
        this.selector.innerHTML = '';
        this.selector.appendChild(this.sliderFrame);
        this.slideToCurrent();
    }

    buildSliderFrameItem(element: HTMLElement) {
        const elementContainer = document.createElement('div');
        elementContainer.style.cssFloat = this.config.direction ? 'right' : 'left';
        elementContainer.style.width = 'auto';
        elementContainer.style.minWidth = `${this.config.minWidth}px`;
        elementContainer.style.maxWidth = `${this.config.maxWidth}px`;
        element.classList.add('swiper__item__done');
        elementContainer.appendChild(element);
        return elementContainer;
    }

    resolveSlidesNumber() {
        if (typeof this.config.perPage === 'number') {
            this.perPage = this.config.perPage;
        } else if (typeof this.config.perPage === 'object') {
            this.perPage = 1;
            for (const viewport in this.config.perPage) {
                if (window.innerWidth >= parseInt(viewport)) {
                    this.perPage = this.config.perPage[viewport];
                }
            }
        }
    }

    prev(howManySlides = 1, callback?: any) {
        this.enableNavigation();
        if (this.innerElements.length <= this.perPage) {
            return;
        }
        const beforeChange = this.currentSlide;
        if (this.config.loop) {
            const isNewIndexClone = this.currentSlide - howManySlides < 0;
            if (isNewIndexClone) {
                this.disableTransition();
                const mirrorSlideIndex = this.currentSlide + this.innerElements.length;
                const mirrorSlideIndexOffset = this.perPage;
                const moveTo = mirrorSlideIndex + mirrorSlideIndexOffset;
                const offset = (this.config.direction ? 1 : -1) * moveTo * (this.selectorWidth / this.perPage);
                const dragDistance = this.config.draggable ? this.drag.endX - this.drag.startX : 0;
                this.sliderFrame.style[this.transformProperty] = `translate3d(${offset + dragDistance}px, 0, 0)`;
                this.currentSlide = mirrorSlideIndex - howManySlides;
            } else {
                this.currentSlide = this.currentSlide - howManySlides;
            }
        } else {
            this.currentSlide = Math.max(this.currentSlide - howManySlides, 0);
        }
        if (beforeChange !== this.currentSlide) {
            this.slideToCurrent(this.config.loop);
            this.config.onChange.call(this);
            if (callback) {
                callback.call(this);
            }
        }
        if (this.currentSlide <= 0) {
            this.disableNavigation(this.buttonPrev);
        }
    }

    next(howManySlides = 1, callback?: any) {
        this.enableNavigation();
        if (this.innerElements.length <= this.perPage) {
            return;
        }
        const beforeChange = this.currentSlide;
        if (this.config.loop) {
            const isNewIndexClone = this.currentSlide + howManySlides > this.innerElements.length - this.perPage;
            if (isNewIndexClone) {
                this.disableTransition();
                const mirrorSlideIndex = this.currentSlide - this.innerElements.length;
                const mirrorSlideIndexOffset = this.perPage;
                const moveTo = mirrorSlideIndex + mirrorSlideIndexOffset;
                const offset = (this.config.direction ? 1 : -1) * moveTo * (this.selectorWidth / this.perPage);
                const dragDistance = this.config.draggable ? this.drag.endX - this.drag.startX : 0;
                this.sliderFrame.style[this.transformProperty] = `translate3d(${offset + dragDistance}px, 0, 0)`;
                this.currentSlide = mirrorSlideIndex + howManySlides;
            } else {
                this.currentSlide = this.currentSlide + howManySlides;
            }
        } else {
            this.currentSlide = Math.min(this.currentSlide + howManySlides, this.innerElements.length - this.perPage);
        }
        if (beforeChange !== this.currentSlide) {
            this.slideToCurrent(this.config.loop);
            this.config.onChange.call(this);
            if (callback) {
                callback.call(this);
            }
        }
        if (this.currentSlide >= this.innerElements.length - this.perPage) {
            this.disableNavigation(this.buttonNext);
        }
    }

    checkNavigationAfterResize() {
        if (this.currentSlide > 0 && this.currentSlide >= this.innerElements.length - this.perPage) {
            this.disableNavigation(this.buttonNext);
        } else if (this.currentSlide <= 0) {
            this.disableNavigation(this.buttonPrev);
        } else {
            this.enableNavigation();
        }
    }

    enableNavigation() {
        if (this.buttonPrev && this.buttonNext) {
            this.buttonPrev.classList.remove(this.config.navigation.disabledClass);
            this.buttonNext.classList.remove(this.config.navigation.disabledClass);
        }
    }

    disableNavigation(button: HTMLElement) {
        if (button) {
            button.classList.add(this.config.navigation.disabledClass);
        }
    }

    enableTransition() {
        this.sliderFrame.style.webkitTransition = `all ${this.config.duration}ms ${this.config.easing}`;
        this.sliderFrame.style.transition = `all ${this.config.duration}ms ${this.config.easing}`;
    }

    disableTransition() {
        this.sliderFrame.style.webkitTransition = `all 0ms ${this.config.easing}`;
        this.sliderFrame.style.transition = `all 0ms ${this.config.easing}`;
    }

    slideToCurrent(enableTransition = false) {
        const currentSlide = this.config.loop ? this.currentSlide + this.perPage : this.currentSlide;
        const offset = (this.config.direction ? 1 : -1) * currentSlide * (this.selectorWidth / this.perPage);
        if (enableTransition) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    this.enableTransition();
                    this.sliderFrame.style[this.transformProperty] = `translate3d(${offset}px, 0, 0)`;
                });
            });
        } else {
            this.sliderFrame.style[this.transformProperty] = `translate3d(${offset}px, 0, 0)`;
        }
    }

    updateAfterDrag() {
        const movement = (this.config.direction ? -1 : 1) * (this.drag.endX - this.drag.startX);
        const movementDistance = Math.abs(movement);
        const howManySliderToSlide = this.config.multipleDrag ? Math.ceil(movementDistance / (this.selectorWidth / this.perPage)) : 1;
        const slideToNegativeClone = movement > 0 && this.currentSlide - howManySliderToSlide < 0;
        const slideToPositiveClone = movement < 0 && this.currentSlide + howManySliderToSlide > this.innerElements.length - this.perPage;
        if (movement > 0 && movementDistance > this.config.threshold && this.innerElements.length > this.perPage) {
            this.prev(howManySliderToSlide);
        } else if (movement < 0 && movementDistance > this.config.threshold && this.innerElements.length > this.perPage) {
            this.next(howManySliderToSlide);
        }
        this.slideToCurrent(slideToNegativeClone || slideToPositiveClone);
    }

    ignoreElementsOnDragByClass(event: any) {
        if (event) {
            if (event.target.classList.value.match(this.classesIgnoreSwiper)) {
                return true;
            }
        }
        return false;
    }

    resizeHandler(firstTime: boolean) {
        if (!firstTime && this.screenWidthInitial === window.innerWidth) {
            return;
        }
        if (this.config.breakPoint > 0) {
            this.resizeByBreakPoint();
        } else {
            this.resizeSwiper();
        }
        if (this.buttonPrev && this.buttonNext) {
            this.checkNavigationAfterResize();
        }
    }

    resizeByBreakPoint() {
        if (this.config.breakPoint >= window.innerWidth) {
            this.resizeSwiper();
        } else if (this.config.breakPoint <= window.innerWidth) {
            const oldConfig = this.config;
            const oldSelector = this.selector;
            this.destroy(true);
            new Swiper(oldConfig, oldSelector);
        }
    }

    resizeSwiper() {
        this.resolveSlidesNumber();
        if (this.currentSlide + this.perPage > this.innerElements.length) {
            this.currentSlide = this.innerElements.length <= this.perPage ? 0 : this.innerElements.length - this.perPage;
        }
        this.selectorWidth = this.selector.offsetWidth;
        this.buildSliderFrame();
    }

    clearDrag() {
        this.drag = {
            startX: 0,
            endX: 0,
            startY: 0,
            letItGo: null,
            preventClick: this.drag.preventClick,
        };
    }

    touchstartHandler(event: any) {
        const ignoreSwiper = this.elementsIgnoreSwiper.indexOf(event.target.nodeName) !== -1;
        if (ignoreSwiper || this.ignoreElementsOnDragByClass(event)) {
            return;
        }
        event.stopPropagation();
        this.pointerDown = true;
        this.drag.startX = event.touches[0].pageX;
        this.drag.startY = event.touches[0].pageY;
    }

    touchendHandler(event: any) {
        event.stopPropagation();
        this.pointerDown = false;
        this.enableTransition();
        if (this.drag.endX > 0) {
            this.updateAfterDrag();
        }
        this.clearDrag();
    }

    touchmoveHandler(event: any) {
        event.stopPropagation();
        if (this.drag.letItGo === null) {
            this.drag.letItGo = Math.abs(this.drag.startY - event.touches[0].pageY) < Math.abs(this.drag.startX - event.touches[0].pageX);
        }
        if (this.pointerDown && this.drag.letItGo) {
            event.preventDefault();
            this.drag.endX = event.touches[0].pageX;
            this.sliderFrame.style.webkitTransition = `all 0ms ${this.config.easing}`;
            this.sliderFrame.style.transition = `all 0ms ${this.config.easing}`;
            const currentSlide = this.config.loop ? this.currentSlide + this.perPage : this.currentSlide;
            const currentOffset = currentSlide * (this.selectorWidth / this.perPage);
            const dragOffset = this.drag.endX - this.drag.startX;
            const offset = this.config.direction ? currentOffset + dragOffset : currentOffset - dragOffset;
            this.sliderFrame.style[this.transformProperty] = `translate3d(${(this.config.direction ? 1 : -1) * offset}px, 0, 0)`;
        }
    }

    mousedownHandler(event: any) {
        const ignoreSwiper = this.elementsIgnoreSwiper.indexOf(event.target.nodeName) !== -1;
        if (ignoreSwiper || this.ignoreElementsOnDragByClass(event)) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        this.pointerDown = true;
        this.drag.startX = event.pageX;
    }

    mouseupHandler(event: any) {
        event.stopPropagation();
        this.pointerDown = false;
        this.selector.style.cursor = '-webkit-grab';
        this.enableTransition();
        if (this.drag.endX > 0) {
            this.updateAfterDrag();
        }
        this.clearDrag();
    }

    mousemoveHandler(event: any) {
        event.preventDefault();
        if (this.pointerDown) {
            if (event.target.nodeName === 'A') {
                this.drag.preventClick = true;
            }
            this.drag.endX = event.pageX;
            this.selector.style.cursor = '-webkit-grabbing';
            this.sliderFrame.style.webkitTransition = `all 0ms ${this.config.easing}`;
            this.sliderFrame.style.transition = `all 0ms ${this.config.easing}`;
            const currentSlide = this.config.loop ? this.currentSlide + this.perPage : this.currentSlide;
            const currentOffset = currentSlide * (this.selectorWidth / this.perPage);
            const dragOffset = this.drag.endX - this.drag.startX;
            const offset = this.config.direction ? currentOffset + dragOffset : currentOffset - dragOffset;
            this.sliderFrame.style[this.transformProperty] = `translate3d(${(this.config.direction ? 1 : -1) * offset}px, 0, 0)`;
        }
    }

    mouseleaveHandler(event: any) {
        if (this.pointerDown) {
            this.pointerDown = false;
            this.selector.style.cursor = '-webkit-grab';
            this.drag.endX = event.pageX;
            this.drag.preventClick = false;
            this.enableTransition();
            this.updateAfterDrag();
            this.clearDrag();
        }
    }

    clickHandler(event: any) {
        if (this.drag.preventClick) {
            event.preventDefault();
        }
        this.drag.preventClick = false;
    }

    destroy(restoreMarkup = false, callback?: any) {
        this.detachEvents();
        this.selector.style.cursor = 'auto';
        if (restoreMarkup) {
            const slides = document.createDocumentFragment();
            for (let i = 0; i < this.innerElements.length; i++) {
                const swiperItems = this.innerElements[i] as HTMLElement;
                if (swiperItems) {
                    swiperItems.style.minWidth = '';
                    swiperItems.classList.remove('swiper__item__done');
                }
                slides.appendChild(this.innerElements[i]);
            }
            this.selector.innerHTML = '';
            this.selector.appendChild(slides);
            this.selector.removeAttribute('style');
        }
        if (callback) {
            callback.call(this);
        }
    }
}
