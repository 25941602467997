var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<span class=\"attribution__text\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copy_uicons") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n								<input type=\"text\" class=\"copy__text\" value=\""
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copy_uicons") : stack1), depth0)) != null ? stack1 : "")
    + "\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resourceData") : depth0),"!=",undefined,{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":54,"column":20}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<span class=\"attribution__text\">&lt;a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"keyword") : stack1)) != null ? lookupProperty(stack1,"link") : stack1), depth0))
    + "\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"keyword") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"&gt;"
    + alias2((lookupProperty(helpers,"capitalize")||(depth0 && lookupProperty(depth0,"capitalize"))||alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"keyword") : stack1)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":49,"column":155},"end":{"line":49,"column":195}}}))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"howtostart") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " - Flaticon&lt;/a&gt;</span>\n									<input type=\"text\" class=\"copy__text\" value='<a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"keyword") : stack1)) != null ? lookupProperty(stack1,"link") : stack1), depth0))
    + "\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"keyword") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\">"
    + alias2((lookupProperty(helpers,"capitalize")||(depth0 && lookupProperty(depth0,"capitalize"))||alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"keyword") : stack1)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":50,"column":162},"end":{"line":50,"column":202}}}))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"howtostart") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " - Flaticon</a>'>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<span class=\"attribution__text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"genericInfo") || (depth0 != null ? lookupProperty(depth0,"genericInfo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"genericInfo","hash":{},"data":data,"loc":{"start":{"line":52,"column":41},"end":{"line":52,"column":56}}}) : helper)))
    + "</span>\n									<input type=\"text\" class=\"copy__text\" value='"
    + alias4(((helper = (helper = lookupProperty(helpers,"genericInfo") || (depth0 != null ? lookupProperty(depth0,"genericInfo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"genericInfo","hash":{},"data":data,"loc":{"start":{"line":53,"column":54},"end":{"line":53,"column":69}}}) : helper)))
    + "'>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resourceData") : depth0),"!=",undefined,{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":143,"column":8},"end":{"line":149,"column":20}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"modal-attribution-new\" class=\"modal modal-attribution-new\">\n	<div class=\"modal__container\">\n		<div class=\"content\">\n			<button class=\"modal__close modal__close--absolute modal__close--top-right button button--icon button--icon--only button--sm button--inverted nostyle\">\n				<i class=\"icon icon--sm icon--mg-sm icon--cross\"></i>\n			</button>\n\n			<div class=\"content-header\">\n				<h3 class=\"content-header--title\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "</h3>\n				<p class=\"content-header--text\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"subtitle") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n			</div>\n\n			<div class=\"content-body\">\n				<div class=\"accordion2__menu\">\n                    <div class=\"accordion2__menu__box\">\n                        <p class=\"accordion2__menu--item active\" data-order=\"0\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"social") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                        <p class=\"accordion2__menu--item\" data-order=\"1\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"web") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                        <p class=\"accordion2__menu--item\" data-order=\"2\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"printed") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                        <p class=\"accordion2__menu--item\" data-order=\"3\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"video") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                        <p class=\"accordion2__menu--item\" data-order=\"4\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"apps") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                    </div>\n				</div>\n\n				<div class=\"accordion2__container\">\n					<div class=\"accordion2__content active\">\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"social_title") : stack1), depth0)) != null ? stack1 : "")
    + " (Pinterest, Facebook, Twitter, etc)</p>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"social_subtitle") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n						<div class=\"social-media-buttons\">\n							<button class=\"bj-button bj-button--pinterest\" onclick=\"share.popup({network: 'pinterest'}); return false\" data-gtm-event=\"pinterest attribution\">\n								<i class=\"icon icon--pinterest\"></i>\n								<span>Pinterest</span>\n							</button>\n							<button class=\"bj-button bj-button--facebook\" onclick=\"share.popup({network: 'facebook'}); return false\">\n								<i class=\"icon icon--facebook\"></i>\n								<span>Facebook</span>\n							</button>\n							<button class=\"bj-button bj-button--twitter\" onclick=\"share.popup({network: 'twitter'}); return false\">\n								<i class=\"icon icon--twitter\"></i>\n								<span>Twitter</span>\n							</button>\n							\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","uicon",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":7},"end":{"line":45,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"!=","uicon",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":7},"end":{"line":56,"column":19}}})) != null ? stack1 : "")
    + "							<button class=\"bj-button bj-button--secondary copy__trigger\">\n								<i class=\"icon icon--duplicate\"></i>\n								<span class=\"active\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copy") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n								<span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copied") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n							</button>\n						</div>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"faqs") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					</div>\n\n					<div class=\"accordion2__content\">\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"web_title") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"web_subtitle") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n						<div class=\"copy-box\">\n							\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","uicon",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":7},"end":{"line":74,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"!=","uicon",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":7},"end":{"line":85,"column":19}}})) != null ? stack1 : "")
    + "							<button class=\"bj-button bj-button--secondary copy__trigger\" data-track-arguments=\"\">\n								<i class=\"icon icon--duplicate\"></i>\n								<span class=\"active\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copy") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n								<span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copied") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n							</button>\n						</div>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"faqs") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					</div>\n\n					<div class=\"accordion2__content\">\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"printed_title") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"printed_subtitle") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"printed_subtitle_2") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"faqs") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					</div>\n\n					<div class=\"accordion2__content\">\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"video_title") : stack1), depth0)) != null ? stack1 : "")
    + " (Youtube, Vimeo, etc.)</p>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"video_subtitle") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n						<div class=\"copy-box\">\n						\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","uicon",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":7},"end":{"line":110,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"!=","uicon",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":7},"end":{"line":121,"column":19}}})) != null ? stack1 : "")
    + "							<button class=\"bj-button bj-button--secondary copy__trigger\" data-track-arguments=\"\">\n								<i class=\"icon icon--duplicate\"></i>\n								<span class=\"active\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copy") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n								<span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copied") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n							</button>\n						</div>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"faqs") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					</div>\n\n					<div class=\"accordion2__content\">\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"apps_title") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"apps_subtitle") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n						<div class=\"copy-box\">\n							\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","uicon",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":7},"end":{"line":139,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"!=","uicon",{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":7},"end":{"line":151,"column":19}}})) != null ? stack1 : "")
    + "							<button class=\"bj-button bj-button--secondary copy__trigger\" data-track-arguments=\"\">\n								<i class=\"icon icon--duplicate\"></i>\n								<span class=\"active\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copy") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n								<span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"copied") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n							</button>\n						</div>\n						<p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"faqs") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					</div>\n				</div>\n			</div>\n\n			<div class=\"modal__banner-footer\">\n				<p class=\"modal__banner-footer--title\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"footer_title") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n				<p class=\"modal__banner-footer--text\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"footer_subtitle") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});