import { qq, ww } from 'bobjoll/ts/library/dom';
import Scroll from 'Project/ts/library/scroll';

export class AnimationScroll {
    static elementsAnimation: HTMLElement[];

    public static setup() {
        AnimationScroll.getElementsToAnimate();
        AnimationScroll.addEventListeners();
    }

    private static getElementsToAnimate() {
        const elements = qq('.content--in');
        AnimationScroll.elementsAnimation = Array.prototype.slice.call(elements);
    }

    private static addEventListeners() {
        Scroll.add(() => AnimationScroll.animate());
    }

    private static animate() {
        AnimationScroll.elementsAnimation.forEach(function(element: HTMLElement) {
            if (AnimationScroll.elementInView(element)) {
                element.classList.add('active');
                if (element.dataset.animation && ww[element.dataset.animation]) {
                    ww[element.dataset.animation].play();
                }
            }
        });
    }

    private static elementInView(element: HTMLElement): boolean {
        const elementHeight = element.clientHeight;
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY || window.pageYOffset;
        const scrollPosition = scrollY + windowHeight;
        const elementPosition = element.getBoundingClientRect().top + scrollY + (elementHeight * 15) / 100;

        return scrollPosition > elementPosition;
    }
}
