import * as Pickr from '@simonwep/pickr/dist/pickr.es5.min';
import { q, ww } from 'bobjoll/ts/library/dom';
import { emptyElement } from 'Library/helpers';
import { copyNpmUicons, copyUrlCdnUicons } from 'Pods/copyPaste/copyToClipboard';
import { Uicons } from 'Pods/uicons';
import { getUiconRelated, getUiconTags } from 'Pods/uicons/serviceUi';
import { isFamilyBrands } from 'Pods/uicons/uicons.helpers';
import { IconRelatedData, UiconsTag } from 'Pods/uicons/uicons.vm';
import { UIcons } from 'Pods/uicons/uicons.vm';

export const handleUiconDetailStyleTag = (styleTagIcon: string) => {
    const styleTagElementCDN: HTMLElement | null = document.querySelector('.download-cdn p strong');
    const styleTagElementPack: HTMLElement | null = document.querySelector('.download-pack p strong');

    if (styleTagElementCDN) {
        styleTagElementCDN.innerText = styleTagIcon;
    }
    if (styleTagElementPack) {
        styleTagElementPack.innerText = styleTagIcon;
    }
};


export const getUiModalDetail = async (targetElement: HTMLElement, iconElement: HTMLAnchorElement) => {
    const template = require('Project/ts/pods/uicons/uicon-detail/uicon-detail.hbs');
    const renderTargetElement = document.querySelector('.uicons__icons li.active');
    let relatedUIcons: IconRelatedData[] = [];
    let uiconTags: UiconsTag[] = [];
    ww.Pickr = Pickr;

    Array.from(targetElement.querySelectorAll('a')).forEach((anchor: HTMLAnchorElement) => {
        let iconRelatedData: IconRelatedData = {
            class: anchor.dataset.class ? anchor.dataset.class : '',
            detailUrl: anchor.dataset.detailUrl ? anchor.dataset.detailUrl : '',
            family: anchor.dataset.corner ? anchor.dataset.corner : '',
            id: anchor.dataset.id ? anchor.dataset.id : '',
            name: anchor.dataset.name ? anchor.dataset.name : '',
            png: anchor.querySelector('img')?.dataset.src as string,
            prefix: anchor.dataset.prefix ? anchor.dataset.prefix : '',
            style: anchor.dataset.weight ? anchor.dataset.weight : '',
            svg: anchor.dataset.svg ? anchor.dataset.svg : '',
            teamId: anchor.dataset.teamid ? anchor.dataset.teamid : '',
        };

        relatedUIcons.push(iconRelatedData);
    });

    await getUiconRelated(iconElement.dataset.id).then(response => {
        relatedUIcons = Object.values(response.data.collection);
        uiconTags = [...response.data.tags];
    });

    document.querySelector('.uicons__detail--wrapper')?.parentElement?.remove();

    renderTargetElement?.insertAdjacentHTML(
        'afterend',
        template({
            messages: messages,
            appUrl: APP_URL,
            iconId: iconElement.dataset.id,
            iconName: iconElement.dataset.name,
            iconClassName: iconElement.dataset.class,
            iconUrl: iconElement.dataset.svg,
            iconCorner: iconElement.dataset.corner,
            iconWeight: iconElement.dataset.weight,
            isFamilyBrands: isFamilyBrands(iconElement.dataset.prefix),
            iconFamily: iconElement.dataset.prefix,
            packId: iconElement.dataset.packid,
            relatedUIcons,
            uiconTags,
            iconLicense: iconElement.dataset.license,
            global,
            detailUrl: iconElement.dataset.detailUrl,
        }),
    );

    if (iconElement.dataset.prefix) {
        Uicons.currentUiconsPrefix = iconElement.dataset.prefix;
    }

    copyUrlCdnUicons();
    copyNpmUicons();
    iconElement.dataset.prefix && isFamilyBrands(iconElement.dataset.prefix)
        ? handleUiconDetailStyleTag(iconElement.dataset.prefix)
        : handleUiconDetailStyleTag(`${iconElement.dataset.weight}/${iconElement.dataset.corner}`);

    if (iconElement.dataset.prefix) {
        Uicons.currentUiconsPrefix = iconElement.dataset.prefix;
    }

    copyUrlCdnUicons();
    copyNpmUicons();
    iconElement.dataset.prefix && isFamilyBrands(iconElement.dataset.prefix)
        ? handleUiconDetailStyleTag(iconElement.dataset.prefix)
        : handleUiconDetailStyleTag(`${iconElement.dataset.weight}/${iconElement.dataset.corner}`);

    document.querySelector('.uicons__detail  .button--close')?.addEventListener('click', () => {
        document.querySelector('.uicons__detail--wrapper')?.parentElement?.remove();
    });

    Uicons.handleUiconsEditorEvent();
};
