import { sessionStorage } from 'bobjoll/ts/library/storage';

export class History {
    private static readonly ns = 'history';
    private static history: string[] = [];

    public static add(url?: string) {
        History.history.unshift(url || window.location.href);
        if (10 < History.history.length) {
            History.history.pop();
        }
        sessionStorage.setItem(History.ns, 'history', JSON.stringify(History.history));
    }

    public static get() {
        return History.history;
    }

    public static hydrate() {
        const history = sessionStorage.getItem(History.ns, 'history');
        History.history = history ? JSON.parse(history) : [];
    }
}

History.hydrate();
History.add();

window.addEventListener('pushstate', (e: PopStateEvent) => {
    const state = e.state;

    if (state) {
        History.add(state.url);
    }
});