var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"modal-confirm-copy-content\" class=\"modal modal--xs modal-confirm-copy-content alignc\">\n    <div class=\"modal__container\">\n        <div class=\"content text__general--heading\">\n            <button class=\"modal__close modal__close--absolute modal__close--top-right button button--icon button--icon--only button--sm button--inverted nostyle\">\n                <i class=\"icon icon--lg icon--mg-lg icon--cross\"></i>\n            </button>\n            <h6 class=\"first title alignc\">\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"confirmCopyContent") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\n            </h6>\n            <p class=\"subtitle alignc font-sm\">\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"confirmCopyContent") : stack1)) != null ? lookupProperty(stack1,"subtitle") : stack1), depth0))
    + "\n            </p>\n            <div id=\"copy-image-progress\"\n                class=\"progress-bar\">\n                <p class=\"progress-bar__title alignc\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"confirmCopyContent") : stack1)) != null ? lookupProperty(stack1,"processing") : stack1), depth0))
    + "</p>\n                <div class=\"progress-bar__wrapper\">\n                    <div class=\"progress-bar__value\"></div>\n                </div>\n            </div>\n            <div class=\"modal-actions\">\n                <button\n                    class=\"bj-button bj-button--md button--outline bj-button--outline bj-button--secondary button__cancel\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"cancel") : stack1), depth0))
    + "</button>\n                <button id=\"confirm-download-button\"\n                    class=\"bj-button bj-button--md bj-button--green\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"confirmCopyContent") : stack1)) != null ? lookupProperty(stack1,"confirmCopy") : stack1), depth0))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});