import { ww } from 'bobjoll/ts/library/dom';
import { removeElement, toggleClass } from 'Library/helpers';
import { LazyModal } from 'Pods/modals/lazyModal';
import { Downloads } from 'Project/ts/common-app/downloads';

const detailViewTypes = {
    iconDetail: 'icon-detail',
    packDetail: 'pack-detail',
};

export const getModalDownload = (premiumFormat: boolean = false, buttonElement?: HTMLButtonElement) => {
    const template = require('Project/ts/pods/modals/download/downloadModal.hbs');
    const renderTargetElement =
        ww.view.section === detailViewTypes.iconDetail
            ? document.querySelector('.modal-download--target')
            : document.querySelector('.modal-download-pack--target');
    const resourceId =
        ww.view.section === detailViewTypes.iconDetail && !buttonElement
            ? document.getElementById('detail')?.dataset.elementid
            : (document.querySelector('.btn-download-pack') as HTMLElement)?.dataset.pack;

    if (document.querySelector('.modal-download-detail')) {
        removeElement(document.querySelector('.modal-download-detail') as HTMLElement);
    }

    renderTargetElement?.insertAdjacentHTML(
        'afterbegin',
        template({
            global,
            messages,
            premiumFormat,
            pricingUrl: `${APP_URL}pricing/${resourceId}`,
            resourceId: resourceId ? resourceId : null,
            resourceType: RESOURCE_TYPE,
        }),
    );

    handleEventsModalDownload();
};

const handleEventsModalDownload = () => {
    const modalDownloadElement = document.querySelector('.modal-download-detail') as HTMLElement;
    let popoverShow: HTMLUListElement | null = document.querySelector('.popover-content.show-menu');

    if (popoverShow) {
        toggleClass(popoverShow, 'show-menu', 'remove');
    }

    document
        .getElementById('detail')
        ?.querySelectorAll('button.popover-button ')
        .forEach((popoverButton: HTMLButtonElement) => {
            popoverButton?.addEventListener('click', () => {
                removeElement(modalDownloadElement);
            });
        });

    if (
        modalDownloadElement &&
        (ww.view.section === detailViewTypes.iconDetail || ww.view.section === detailViewTypes.packDetail)
    ) {
        toggleClass(modalDownloadElement, 'transition--mobile', 'add');
        modalDownloadElement.style.opacity = '1';
        modalDownloadElement.querySelector('.button--close')?.addEventListener('click', () => {
            removeElement(modalDownloadElement);
        });
    }

    if (ww.view.section === detailViewTypes.iconDetail) {
        Downloads.initializeDownload('icon', detailViewTypes.iconDetail);
        LazyModal.callModalAttribution();
    }
};
