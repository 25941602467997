var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"related-header row row--vertical-center row--space-between mg-none\">\n    <p class=\"mg-none\">"
    + alias3((lookupProperty(helpers,"ucwords")||(depth0 && lookupProperty(depth0,"ucwords"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"sectionName") : depth0),{"name":"ucwords","hash":{},"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":48}}}))
    + " "
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"icons") : stack1), depth0))
    + "</p>\n\n    <a class=\"link--arrow link--arrow--sm\" href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"sectionUrl") || (depth0 != null ? lookupProperty(depth0,"sectionUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"sectionUrl","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":65}}}) : helper)))
    + "\" title=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"sectionName") || (depth0 != null ? lookupProperty(depth0,"sectionName") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"sectionName","hash":{},"data":data,"loc":{"start":{"line":4,"column":74},"end":{"line":4,"column":91}}}) : helper)))
    + "\">\n        <span>"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"see_more") : stack1), depth0))
    + "</span>\n        <i class=\"icon icon--sm icon--right\"></i>\n    </a>\n</header>\n";
},"useData":true});