import { Currency } from 'Library/currency';

export function numberFormatCurrency(str: string) {
    const currency = Currency.requestCurrency();
    if (currency) {
        return Currency.format(str, currency);
    }
    return str;
}

export function numberFormatCurrencyDistinct(str1: string, str2: string, decimal: number = 2) {
    const currency = Currency.requestCurrency();
    if (currency) {
        let currencyStr = str1.indexOf(currency.CURRENCY) > -1 ? str1 : str2;
        return Currency.format(currencyStr, currency, decimal);
    }
    return str1;
}

export function numberNormalizer(n: number | string): number {
    return 'number' === typeof n ? n : parseInt(n.replace(/\D+/gi, '').trim());
}

export function capitalize(str: string) {
    try {
        return str.charAt(0).toUpperCase() + str.slice(1);
    } catch (err) {
        console.error(err);
    }

    return str;
}

export function normalizeString(str: string, lowercase = false) {
    let string = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    if (lowercase) string = string.toLowerCase();
    return string.trim();
}

export function addSlashes(str: string) {
    return str.replace(/'/g, "\\'");
}

export const removeElement = (element: HTMLElement | null) => {
    if (element) {
        element.remove();
    }
};

export const toggleClass = (element: HTMLElement | null, elementClassName: string, action: 'add' | 'remove') => {
    if (element) {
        element.classList[action](elementClassName);
    }
};

export const emptyElement = (element: HTMLElement | null) => {
    if (element) {
        element.innerHTML = '';
    }
};
