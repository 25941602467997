var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<div class=\"img\">\n					<img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"srcPath") || (depth0 != null ? lookupProperty(depth0,"srcPath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"srcPath","hash":{},"data":data,"loc":{"start":{"line":11,"column":15},"end":{"line":11,"column":26}}}) : helper)))
    + "\">					\n				</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a class=\"button button--md modal__close__register btn btn-warning track\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":20,"column":86},"end":{"line":20,"column":100}}}) : helper)))
    + "\" data-track-arguments=\"ga, event, premium, icon-btn-download\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"autopromo") : stack1)) != null ? lookupProperty(stack1,"go_premium") : stack1), depth0)) != null ? stack1 : "")
    + "</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a class=\"button button--md modal__close__register btn btn-warning track\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":23,"column":86},"end":{"line":23,"column":100}}}) : helper)))
    + "\" data-track-arguments=\"ga, event, premium, pack-btn-download\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"autopromo") : stack1)) != null ? lookupProperty(stack1,"go_premium") : stack1), depth0)) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"modal-funnel-premium-freePng\" class=\"modal-funnel-premium modal modal--xs\" data-disable-mouse-up>\n	<div class=\"modal__container\">\n		<div class=\"content pd-none-i text__general--heading\">\n			<button class=\"modal__close modal__close--absolute modal__close--top-right button button--icon button--icon--only button--sm button--inverted nostyle\">\n				<i class=\"icon icon--md icon--mg-md icon--cross\"></i>\n			</button>\n\n			<div class=\"row\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"srcPath") : depth0),"!=","",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":16}}})) != null ? stack1 : "")
    + "				<div class=\"text\">\n					<p class=\"text__items\">"
    + alias4(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"premium_download_icon_only_premium") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</p>\n					<p class=\"text__items\">"
    + alias4(alias3(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"premium_download_icon_only_premium") : stack1)) != null ? lookupProperty(stack1,"subtitle") : stack1), depth0))
    + "</p>\n				</div>\n				<button class=\"modal__close\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"icon") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":5},"end":{"line":21,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"icon") : depth0),"==",0,{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":5},"end":{"line":24,"column":17}}})) != null ? stack1 : "")
    + "				</button>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});