var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"modal-license\" class=\"modal modal--xs modal-license\">\n	<div class=\"modal__container\">\n		<div class=\"content pd-none-i text__general--heading\">\n			<button class=\"modal__close modal__close--absolute modal__close--top-right button button--icon button--icon--only button--sm button--inverted nostyle\">\n				<i class=\"icon icon--md icon--mg-md icon--cross\"></i>\n			</button>\n\n			<div class=\"alignc pd-lv5\">\n				<h6 class=\"font-h6 mg-bottom-lv2\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"license") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "</h6>\n				<p class=\"font-lg medium mg-bottom-lv4\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"license") : stack1)) != null ? lookupProperty(stack1,"subtitle") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n				<ul class=\"list--check alignl\">\n					<li class=\"mg-bottom-lv3\"><i class=\"icon icon--check icon--lg inline text__state--green mg-right-lv2\"></i><span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"license") : stack1)) != null ? lookupProperty(stack1,"use_1") : stack1), depth0)) != null ? stack1 : "")
    + "</span></li>\n					<li class=\"mg-bottom-lv3\"><i class=\"icon icon--check icon--lg inline text__state--green mg-right-lv2\"></i><span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"license") : stack1)) != null ? lookupProperty(stack1,"use_2") : stack1), depth0)) != null ? stack1 : "")
    + "</span></li>\n					<li class=\"mg-bottom-lv3\"><i class=\"icon icon--check icon--lg inline text__state--green mg-right-lv2\"></i><span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"license") : stack1)) != null ? lookupProperty(stack1,"use_3") : stack1), depth0)) != null ? stack1 : "")
    + "</span></li>\n					<li class=\"mg-bottom-lv3\"><i class=\"icon icon--check icon--lg inline text__state--green mg-right-lv2\"></i><span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"license") : stack1)) != null ? lookupProperty(stack1,"use_4") : stack1), depth0)) != null ? stack1 : "")
    + "</span></li>\n					<li><i class=\"icon icon--check icon--lg inline text__state--green mg-right-lv2\"></i><span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"license") : stack1)) != null ? lookupProperty(stack1,"use_5") : stack1), depth0)) != null ? stack1 : "")
    + "</span></li>\n				</ul>\n			</div>\n			<div class=\"modal-license__footer alignc font-xs\">\n				<p class=\"mg-none\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"license") : stack1)) != null ? lookupProperty(stack1,"summary") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});