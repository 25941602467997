import 'Variables';

export class Recaptcha {
    private static api = '//www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit';
    public static grecaptcha: any;
    public defaultSettings = {
        recaptcha: true,
        recaptchaSitekey: '',
    };
    public nameHandler: string = '';
    public loaded: RecaptchaActive = {};
    public disable: RecaptchaActive = {};
    public handlers: Handlers = {};
    public widgets: Widgets = {};

    constructor(name: string, recaptchaKey?: string) {
        this.nameHandler = name;
        this.loaded[name] = false;
        this.handlers[name] = null;
        this.disable[name] = false;
        this.widgets[name] = null;
        this.defaultSettings.recaptchaSitekey = recaptchaKey != null ? recaptchaKey : RE_CAPTCHA_KEY_INVISIBLE_V2;
    }

    public async load(): Promise<{}> {
        let recaptchaScript = document.getElementById(`recaptcha-sdk-${this.nameHandler}`);
        if (recaptchaScript) {
            recaptchaScript.parentNode?.removeChild(recaptchaScript);
        }
        const fjs = document.getElementsByTagName('script')[0];
        const js = document.createElement('script') as HTMLScriptElement;
        js.id = `recaptcha-sdk-${this.nameHandler}`;
        js.src = Recaptcha.api;

        if (fjs.parentNode) {
            fjs.parentNode.insertBefore(js, fjs);
        }

        return new Promise((resolve, reject) => {
            (window as any).recaptchaLoaded = resolve;
            this.loaded[this.nameHandler] = true;
            setTimeout(() => reject('Failed loading recaptcha!'), 10000);
        });
    }

    public addEventSubmit(form: HTMLFormElement, callback: Function) {
        this.handlers[this.nameHandler] = (event: Event) => {
            if (event instanceof Event) {
                event.preventDefault();
                event.stopPropagation();
            }

            if (this.disable[this.nameHandler]) {
                this.disable[this.nameHandler] = false;
                return;
            }
            callback(form, event);
        };
    }

    public renderToForm(form: HTMLFormElement, button: HTMLElement | null, buttonID: string) {
        if (this.defaultSettings.recaptcha && button && grecaptcha && 'number' !== typeof this.widgets[this.nameHandler]) {
            button.id = buttonID;

            this.widgets[this.nameHandler] = grecaptcha.render(buttonID, {
                sitekey: this.defaultSettings.recaptchaSitekey,
                callback: this.handlers[this.nameHandler],
            });
        } else {
            form.addEventListener('submit', this.handlers[this.nameHandler] as EventListener);
        }
    }

    public render(captchaElement: HTMLElement | null, callback: Function, show = false) {
        if (captchaElement) {
            const captchaOptions: CaptchaOptions = {
                sitekey: this.defaultSettings.recaptchaSitekey,
                callback,
            };

            if (!show) captchaOptions.size = 'invisible';

            if (this.widgets[this.nameHandler]) {
                grecaptcha.reset(this.widgets[this.nameHandler]);
            }

            try {
                this.widgets[this.nameHandler] = grecaptcha.render(captchaElement, captchaOptions, true);
            } catch (err) {
                console.error(err);
            }
        }
        return this.widgets[this.nameHandler];
    }

    public reset(button: HTMLElement | null, buttonID: string) {
        if (this.defaultSettings.recaptcha) {
            const widget = this.widgets[this.nameHandler];

            if (button && 'number' !== typeof widget) {
                button.id = buttonID;

                this.widgets[this.nameHandler] = grecaptcha.render(buttonID, {
                    sitekey: this.defaultSettings.recaptchaSitekey,
                    callback: this.handlers[this.nameHandler],
                });
            } else {
                grecaptcha.reset(widget);
            }
        }
    }
}

interface Handlers {
    [key: string]: null | FormHandlers;
}

interface RecaptchaActive {
    [key: string]: boolean;
}

interface Widgets {
    [key: string]: null | string;
}

interface CaptchaOptions {
    callback: Function;
    sitekey: string;
    size?: string;
}

type FormHandlers = (this: HTMLElement, event: Event) => void;
