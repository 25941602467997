var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-page=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"page") || (depth0 != null ? lookupProperty(depth0,"page") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"page","hash":{},"data":data,"loc":{"start":{"line":49,"column":28},"end":{"line":49,"column":36}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return " active ";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":52,"column":167},"end":{"line":52,"column":173}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"icon--item\"\n    data-author=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"groupId") || (depth0 != null ? lookupProperty(depth0,"groupId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"groupId","hash":{},"data":data,"loc":{"start":{"line":2,"column":17},"end":{"line":2,"column":28}}}) : helper)))
    + "\"\n    data-author_avatar=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"authorAvatar") || (depth0 != null ? lookupProperty(depth0,"authorAvatar") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"authorAvatar","hash":{},"data":data,"loc":{"start":{"line":3,"column":24},"end":{"line":3,"column":40}}}) : helper)))
    + "\"\n    data-author_name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"teamName") || (depth0 != null ? lookupProperty(depth0,"teamName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"teamName","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":34}}}) : helper)))
    + "\"\n    data-author_website=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"teamWebsite") || (depth0 != null ? lookupProperty(depth0,"teamWebsite") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"teamWebsite","hash":{},"data":data,"loc":{"start":{"line":5,"column":25},"end":{"line":5,"column":40}}}) : helper)))
    + "\"\n    data-selection=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"selection") || (depth0 != null ? lookupProperty(depth0,"selection") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"selection","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":33}}}) : helper)))
    + "\"\n    data-author_icons=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"authorIcons") || (depth0 != null ? lookupProperty(depth0,"authorIcons") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"authorIcons","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":38}}}) : helper)))
    + "\"\n    data-author_icons_text=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"authorIconsText") || (depth0 != null ? lookupProperty(depth0,"authorIconsText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"authorIconsText","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":47}}}) : helper)))
    + "\"\n    data-category_id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"categoryId") || (depth0 != null ? lookupProperty(depth0,"categoryId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"categoryId","hash":{},"data":data,"loc":{"start":{"line":9,"column":22},"end":{"line":9,"column":36}}}) : helper)))
    + "\"\n    data-category_name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"category") || (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":36}}}) : helper)))
    + "\"\n    data-category_url=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"categoryUrl") || (depth0 != null ? lookupProperty(depth0,"categoryUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"categoryUrl","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":38}}}) : helper)))
    + "\"\n    data-color=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"color") || (depth0 != null ? lookupProperty(depth0,"color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"color","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":25}}}) : helper)))
    + "\"\n    data-craft=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"craft") || (depth0 != null ? lookupProperty(depth0,"craft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"craft","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":25}}}) : helper)))
    + "\"\n    data-description=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":22},"end":{"line":14,"column":30}}}) : helper)))
    + "\"\n    data-family_id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"familyId") || (depth0 != null ? lookupProperty(depth0,"familyId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"familyId","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":32}}}) : helper)))
    + "\"\n    data-family_name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"familyName") || (depth0 != null ? lookupProperty(depth0,"familyName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"familyName","hash":{},"data":data,"loc":{"start":{"line":16,"column":22},"end":{"line":16,"column":36}}}) : helper)))
    + "\"\n    data-group_id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"groupId") || (depth0 != null ? lookupProperty(depth0,"groupId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"groupId","hash":{},"data":data,"loc":{"start":{"line":17,"column":19},"end":{"line":17,"column":30}}}) : helper)))
    + "\"\n    data-icon_src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconImg") || (depth0 != null ? lookupProperty(depth0,"iconImg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconImg","hash":{},"data":data,"loc":{"start":{"line":18,"column":19},"end":{"line":18,"column":30}}}) : helper)))
    + "\"\n    data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":19,"column":13},"end":{"line":19,"column":19}}}) : helper)))
    + "\"\n    data-icon_type =\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iconType") || (depth0 != null ? lookupProperty(depth0,"iconType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconType","hash":{},"data":data,"loc":{"start":{"line":20,"column":21},"end":{"line":20,"column":33}}}) : helper)))
    + "\"\n    data-keyword_name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"keywordName") || (depth0 != null ? lookupProperty(depth0,"keywordName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"keywordName","hash":{},"data":data,"loc":{"start":{"line":21,"column":23},"end":{"line":21,"column":38}}}) : helper)))
    + "\"\n    data-keyword_link=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"keywordLink") || (depth0 != null ? lookupProperty(depth0,"keywordLink") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"keywordLink","hash":{},"data":data,"loc":{"start":{"line":22,"column":23},"end":{"line":22,"column":38}}}) : helper)))
    + "\"\n    data-license=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"license") || (depth0 != null ? lookupProperty(depth0,"license") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"license","hash":{},"data":data,"loc":{"start":{"line":23,"column":18},"end":{"line":23,"column":29}}}) : helper)))
    + "\"\n    data-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":24,"column":15},"end":{"line":24,"column":23}}}) : helper)))
    + "\"\n    data-pack=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"packSlug") || (depth0 != null ? lookupProperty(depth0,"packSlug") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"packSlug","hash":{},"data":data,"loc":{"start":{"line":25,"column":15},"end":{"line":25,"column":27}}}) : helper)))
    + "\"\n    data-pack_id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"packId") || (depth0 != null ? lookupProperty(depth0,"packId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"packId","hash":{},"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":26,"column":28}}}) : helper)))
    + "\"\n    data-pack_items=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"packItems") || (depth0 != null ? lookupProperty(depth0,"packItems") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"packItems","hash":{},"data":data,"loc":{"start":{"line":27,"column":21},"end":{"line":27,"column":34}}}) : helper)))
    + "\"\n    data-pack_name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"packName") || (depth0 != null ? lookupProperty(depth0,"packName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"packName","hash":{},"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":28,"column":32}}}) : helper)))
    + "\"\n    data-pack_sprite=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"packSprite") || (depth0 != null ? lookupProperty(depth0,"packSprite") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"packSprite","hash":{},"data":data,"loc":{"start":{"line":29,"column":22},"end":{"line":29,"column":36}}}) : helper)))
    + "\"\n    data-premium=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"premium") || (depth0 != null ? lookupProperty(depth0,"premium") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"premium","hash":{},"data":data,"loc":{"start":{"line":30,"column":18},"end":{"line":30,"column":29}}}) : helper)))
    + "\"\n    data-published=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"published") || (depth0 != null ? lookupProperty(depth0,"published") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"published","hash":{},"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":31,"column":33}}}) : helper)))
    + "\"\n    data-related=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"related") || (depth0 != null ? lookupProperty(depth0,"related") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"related","hash":{},"data":data,"loc":{"start":{"line":32,"column":18},"end":{"line":32,"column":29}}}) : helper)))
    + "\"\n    data-style=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"styleId") || (depth0 != null ? lookupProperty(depth0,"styleId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"styleId","hash":{},"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":33,"column":27}}}) : helper)))
    + "\"\n    data-style_name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"styleName") || (depth0 != null ? lookupProperty(depth0,"styleName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"styleName","hash":{},"data":data,"loc":{"start":{"line":34,"column":21},"end":{"line":34,"column":34}}}) : helper)))
    + "\"\n    data-style_slug=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"styleSlug") || (depth0 != null ? lookupProperty(depth0,"styleSlug") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"styleSlug","hash":{},"data":data,"loc":{"start":{"line":35,"column":21},"end":{"line":35,"column":34}}}) : helper)))
    + "\"\n    data-tags=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tagsId") || (depth0 != null ? lookupProperty(depth0,"tagsId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tagsId","hash":{},"data":data,"loc":{"start":{"line":36,"column":15},"end":{"line":36,"column":25}}}) : helper)))
    + "\"\n    data-team_id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"team_id") || (depth0 != null ? lookupProperty(depth0,"team_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"team_id","hash":{},"data":data,"loc":{"start":{"line":37,"column":18},"end":{"line":37,"column":29}}}) : helper)))
    + "\"\n    data-team_name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"teamName") || (depth0 != null ? lookupProperty(depth0,"teamName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"teamName","hash":{},"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":38,"column":32}}}) : helper)))
    + "\"\n    data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":39,"column":15},"end":{"line":39,"column":23}}}) : helper)))
    + "\"\n    data-png=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"png512") || (depth0 != null ? lookupProperty(depth0,"png512") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"png512","hash":{},"data":data,"loc":{"start":{"line":40,"column":14},"end":{"line":40,"column":24}}}) : helper)))
    + "\"\n    data-svg=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"svg") || (depth0 != null ? lookupProperty(depth0,"svg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"svg","hash":{},"data":data,"loc":{"start":{"line":41,"column":14},"end":{"line":41,"column":21}}}) : helper)))
    + "\"\n    data-class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":42,"column":25}}}) : helper)))
    + "\"\n    data-prefix=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"prefix") || (depth0 != null ? lookupProperty(depth0,"prefix") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"prefix","hash":{},"data":data,"loc":{"start":{"line":43,"column":17},"end":{"line":43,"column":27}}}) : helper)))
    + "\"\n    data-name_detail=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"nameDetail") || (depth0 != null ? lookupProperty(depth0,"nameDetail") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nameDetail","hash":{},"data":data,"loc":{"start":{"line":44,"column":22},"end":{"line":44,"column":36}}}) : helper)))
    + "\"\n    data-description=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":45,"column":22},"end":{"line":45,"column":37}}}) : helper)))
    + "\"\n\n\n    data-raw=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"raw") || (depth0 != null ? lookupProperty(depth0,"raw") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"raw","hash":{},"data":data,"loc":{"start":{"line":48,"column":14},"end":{"line":48,"column":21}}}) : helper)))
    + "\"\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"page") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":4},"end":{"line":49,"column":44}}})) != null ? stack1 : "")
    + ">\n    \n    <div class=\"icon--holder\">\n        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"slug") || (depth0 != null ? lookupProperty(depth0,"slug") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":52,"column":17},"end":{"line":52,"column":25}}}) : helper)))
    + "\" class=\"view link-icon-detail related-icon "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"iconActiveId") : depth0),"==",(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":69},"end":{"line":52,"column":122}}})) != null ? stack1 : "")
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":52,"column":131},"end":{"line":52,"column":146}}}) : helper)))
    + "\" data-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":157},"end":{"line":52,"column":180}}})) != null ? stack1 : "")
    + "\" data-src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"params") || (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"params","hash":{},"data":data,"loc":{"start":{"line":52,"column":192},"end":{"line":52,"column":202}}}) : helper)))
    + "\">\n            <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"png") || (depth0 != null ? lookupProperty(depth0,"png") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"png","hash":{},"data":data,"loc":{"start":{"line":53,"column":22},"end":{"line":53,"column":29}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":53,"column":36},"end":{"line":53,"column":51}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":53,"column":60},"end":{"line":53,"column":75}}}) : helper)))
    + "\" width=\"44\" height=\"44\">\n        </a>    \n    </div>\n	\n    </li>";
},"useData":true});