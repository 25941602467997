var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"uicons__related--header related-header row row--vertical-center row--space-between mg-none\">\n    <h2 class=\"font-h6 bold\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"sectionName") || (depth0 != null ? lookupProperty(depth0,"sectionName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sectionName","hash":{},"data":data,"loc":{"start":{"line":2,"column":29},"end":{"line":2,"column":46}}}) : helper)))
    + "</h2>\n\n    <a class=\"link--arrow link--arrow--md ui-most\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"sectionUrl") || (depth0 != null ? lookupProperty(depth0,"sectionUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sectionUrl","hash":{},"data":data,"loc":{"start":{"line":4,"column":57},"end":{"line":4,"column":73}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"sectionName") || (depth0 != null ? lookupProperty(depth0,"sectionName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sectionName","hash":{},"data":data,"loc":{"start":{"line":4,"column":82},"end":{"line":4,"column":99}}}) : helper)))
    + "\">\n        <span>"
    + alias4(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"see_all_icons") : stack1), depth0))
    + "</span>\n        <i class=\"icon icon--md icon--right\"></i>\n    </a>\n</header>\n";
},"useData":true});