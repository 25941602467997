import { ww } from 'bobjoll/ts/library/dom';

export function elementInViewport(element: HTMLElement) {
    let top = element.offsetTop;
    let left = element.offsetLeft;
    const width = element.offsetWidth;
    const height = element.offsetHeight;

    while (element.offsetParent) {
        element = element.offsetParent as HTMLElement;
        top += element.offsetTop;
        left += element.offsetLeft;
    }

    return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        top + height <= window.pageYOffset + window.innerHeight &&
        left + width <= window.pageXOffset + window.innerWidth
    );
}

export const isHidden = (element: HTMLElement) => window.getComputedStyle(element).display === 'none';

export const isInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
    const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

    return vertInView && horInView;
};

export const checkStickyFooter = () => {
    let prefooterElement = document.querySelector('.prefooter') as HTMLElement;
    let footerElement = document.querySelector('#footer') as HTMLElement;
    let detailPackAsideElement = document.querySelector('#detail-pack-aside') as HTMLElement;
    let detailElement = document.querySelector('#detail') as HTMLElement;

    if (ww.view.section == 'pack-detail' && isInViewport(prefooterElement) && !detailPackAsideElement?.classList.contains('footer-visible')) {
        detailPackAsideElement?.classList.add('footer-visible');
    } else if (
        ww.view.section == 'pack-detail' &&
        !isInViewport(prefooterElement) &&
        !isInViewport(footerElement) &&
        detailPackAsideElement?.classList.contains('footer-visible')
    ) {
        detailPackAsideElement?.classList.remove('footer-visible');
    }

    if (ww.view.section == 'icon-detail' && isInViewport(footerElement) && !detailElement?.classList.contains('footer-visible')) {
        detailElement?.classList.add('footer-visible');
    } else if (ww.view.section == 'icon-detail' && !isInViewport(footerElement) && detailElement?.classList.contains('footer-visible')) {
        detailElement?.classList.remove('footer-visible');
    }
};

export const handleAnchorLanguage = () => {
    document.querySelectorAll('.language-selector a').forEach((anchor: any) => {
        anchor.addEventListener('click', () => {
            let urlfilteredLanguage = anchor.href.split('?')[0] + window.location.search;
            anchor.href = urlfilteredLanguage;
        });
    });
};
