import { qq } from 'bobjoll/ts/library/dom';
import Scroll from 'Library/scroll';

export class Anchor {
    private static readonly requestAnimationFrame = (
        (window as any).requestAnimationFrame ||
        (window as any).webkitRequestAnimationFrame ||
        (window as any).mozRequestAnimationFrame ||
        (window as any).msRequestAnimationFrame ||
        (window as any).oRequestAnimationFrame
    ).bind(window);

    private element: HTMLElement;
    private elementAnchors: HTMLElement[];
    private elementLinkAnchors: HTMLLinkElement[];
    private position = Anchor.getPosition();
    private prevElementHeight: number;
    private inWhatsNew: boolean = window.location.pathname.search(messages.common.whats_news_url) != -1 ? true : false;
    private inTermsOfUse: boolean = window.location.pathname.search('/legal') != -1 ? true : false;
    private headerHeight = document.getElementById('header')?.offsetHeight;

    constructor(options: AnchorOptions) {
        this.element = options.element;
        this.prevElementHeight = options.prevElement?.offsetHeight || 0;
        this.elementLinkAnchors = (qq('a', this.element) as HTMLLinkElement[]).filter(link =>
            (link.getAttribute('href') || '').match(/^#/i),
        );
        this.elementAnchors = this.elementLinkAnchors.reduce(Anchor.getAnchorElements, []);
        this.setup();
        this.elementLinkAnchors.forEach((anchor, index) => {
            anchor.addEventListener('click', (event: Event) => {
                if(!this.inTermsOfUse ) {
                    event.preventDefault();
                    event.stopPropagation();
                    Scroll.scrollTo(this.elementAnchors[index], 300, 'easeOutQuad', undefined, this.prevElementHeight);
                }
            });
        });
        this.inWhatsNew ? Anchor.scrollInsideWhatsNew(this.inWhatsNew) : '';
        
    }

    private callback(): void {
        let elementVisible = this.elementAnchors
            .filter(element => {
                if (element) {
                    let isElementWithinView = this.checkElementWithinView(element);
                    return isElementWithinView;
                }
                return false;
            })
            .shift();

        if (elementVisible) {
            let regex = new RegExp(`^#${elementVisible.id}$`, 'i');
            this.elementLinkAnchors.forEach(link => {
                let submenu = link.parent('.submenu') as HTMLElement | undefined;
                let method: 'add' | 'remove' = (link.getAttribute('href') || '').match(regex) ? 'add' : 'remove';
                link.classList[method]('active');
                if (submenu) {
                    submenu.classList[qq('.active', submenu).length ? 'add' : 'remove']('submenu--active');
                }
            });
        } else {
            this.elementLinkAnchors.forEach(link => link.classList.remove('active'));
        }
    }

    private requestAnimationFrameCallback() {
        let position = Anchor.getPosition();

        if (position !== this.position) {
            this.callback();
            this.position = Anchor.getPosition();
        }

        Anchor.requestAnimationFrame(() => this.requestAnimationFrameCallback());
    }

    private static scrollInsideWhatsNew(inWhatsNew: boolean) {
        let anchorToWhatsNew: HTMLElement | null = inWhatsNew
            ? document.querySelector(`a[href='${window.location.hash}']`)
            : null;
        const scrollAndCleanUrl = () => {
            history.replaceState(null, '', ' ');
            window.scroll(0, 0);
        };
        inWhatsNew ? scrollAndCleanUrl() : '';
        anchorToWhatsNew ? anchorToWhatsNew.click() : '';
    }

    private setup() {
        this.callback();
        this.requestAnimationFrameCallback();
    }

    private checkElementWithinView(element: HTMLElement) {
        let elementBounding = element.getBoundingClientRect();
        const viewPortHeight = window.innerHeight;

        if (!this.headerHeight) {
            return;
        }
        if (elementBounding.top > this.headerHeight || elementBounding.bottom > viewPortHeight / 2) {
            return true;
        }
        return false;
    }

    private static getPosition() {
        return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    }

    private static getAnchorElements(acc: HTMLElement[], link: HTMLLinkElement) {
        let element = document.getElementById((link.getAttribute('href') || '').replace('#', ''));

        if (element) {
            acc.push(element);
        }

        return acc;
    }
}

interface AnchorOptions {
    element: HTMLElement;
    prevElement?: HTMLElement;
}
