import { capitalize, numberFormatCurrency, numberFormatCurrencyDistinct } from 'Library/helpers';

import language from '../library/language';

var Handlebars = require('handlebars-template-loader/runtime');

Handlebars.registerHelper('get_length', function(obj) {
    if ('object' === typeof obj) {
        return Object.keys(obj).length;
    } else if (Array.isArray(obj)) {
        return obj.length;
    }

    return 0;
});

Handlebars.registerHelper('get_arr_length', function(obj) {
    var length = 0;

    if ('object' === typeof obj) {
        length = Object.keys(obj).length;
    } else if (Array.isArray(obj)) {
        length = obj.length;
    }

    return length - 1;
});

Handlebars.registerHelper('getUrlParams', function(url) {
    try {
        return url.split('?').pop();
    } catch (e) {
        return null;
    }
});

Handlebars.registerHelper('concat', function() {
    var outStr = '';

    for (var arg in arguments) {
        if ('length' !== arg && typeof arguments[arg] != 'object' && typeof arguments[arg] != 'function') {
            outStr += arguments[arg];
        }
    }

    return outStr;
});

Handlebars.registerHelper('compare', function(lvalue, operator, rvalue, options) {
    var operators, result;

    if (arguments.length < 3) {
        throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
    }

    if (options === undefined) {
        options = rvalue;
        rvalue = operator;
        operator = '===';
    }

    operators = {
        '==': function(l, r) {
            return l == r;
        },
        '===': function(l, r) {
            return l === r;
        },
        '!=': function(l, r) {
            return l != r;
        },
        '!==': function(l, r) {
            return l !== r;
        },
        '<': function(l, r) {
            return l < r;
        },
        '>': function(l, r) {
            return l > r;
        },
        '<=': function(l, r) {
            return l <= r;
        },
        '>=': function(l, r) {
            return l >= r;
        },
        typeof: function(l, r) {
            return typeof l == r;
        },
    };

    if (!operators[operator]) {
        throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator);
    }

    result = operators[operator](lvalue, rvalue);

    if (result) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('language', function(str) {
    return language.apply(null, arguments);
});

Handlebars.registerHelper('numberFormatCurrency', function(string) {
    return numberFormatCurrency(string);
});

Handlebars.registerHelper('numberFormatCurrencyDistinct', function(string1, string2) {
    return numberFormatCurrencyDistinct(string1, string2);
});

Handlebars.registerHelper('times', function(n, block) {
    var accum = '';

    if (!n) {
        n = 20;
    }

    for (var i = 0; i < n; ++i) {
        accum += block.fn(i);
    }

    return accum;
});

Handlebars.registerHelper('contains', function(arr, value, options) {
    if (arr.indexOf(value) >= 0) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('section', function(name, options) {
    if (!this._sections) this._sections = {};
    this._sections[name] = options.fn(this);
    return null;
});

Handlebars.registerHelper('randomArr', function(arr, options) {
    const index = Math.floor(Math.random() * arr.length);
    const data = {
        ...this,
        ...arr[index],
    };
    return options.fn({
        index,
        ...data,
    });
});

Handlebars.registerHelper('capitalize', function() {
    if (arguments[0]) {
        var str = arguments[0];

        return capitalize(str);
    }
});

Handlebars.registerHelper('if', function(conditional, options) {
    if (conditional) {
        return options.fn(this);
    }
});

Handlebars.registerHelper('ternary', function(test, yes, no) {
    return test ? yes : no;
});

Handlebars.registerHelper('ucwords', function(str) {
    return (str + '').replace(/^(.)|\s+(.)/g, function($1) {
        return $1.toUpperCase();
    });
});

Handlebars.registerHelper('toLowerCase', function(str) {
    return str.toLowerCase();
});

Handlebars.registerHelper('trim', function(str, find, direction) {
    if (direction == 'right') {
        var index = str.lastIndexOf(find);
    } else if (direction == 'right') {
        var index = str.indexOf(find);
    }
    if (index === -1) {
        return str;
    }
    var beginString = str.substring(0, index);
    var endString = str.substring(index + find.length);
    return beginString + endString;
});

Handlebars.registerHelper('lookup', function(obj, field) {
    return obj && obj[field];
});

Handlebars.registerHelper('numberFormat', function numberFormat(num) {
    var n = parseInt(num, 10).toString();
    return n.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});

/*Handlebars.registerHelper('isTrue', function(this, value, options) {
    if (value) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('isFalse', function(this, value, options) {
    if (!value) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});*/

Handlebars.registerHelper('replace', function(find, replace, string) {
    return string.replace(find, replace);
});

Handlebars.registerHelper('isOr', function(cond1A, cond1B, cond2A, cond2B, options) {
    if (cond1A == cond1B || cond2A == cond2B) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});
