import {sessionStorage as storage} from 'bobjoll/ts/library/storage';

const CACHE_NAMESPACE_JSON = 'cache-json';
const CACHE_NAMESPACE_EXPIRY = 'cache-expiry';

declare module "bobjoll/ts/library/storage" {
    interface ClientStorage {
        get(namespace: 'cache-json', key: string): boolean;
        set(namespace: 'cache-json', key: string, value: boolean, time: number): void;

        get(namespace: 'cache-expiry', key: string): boolean;
        set(namespace: 'cache-expiry', key: string, value: boolean, time: number): void;
    }
}

export default class Cache {
    public static set(key: string, value: any, time: number) {
        let expiry: Date | number = new Date();

        expiry.setSeconds(expiry.getSeconds() + time);

        expiry = expiry.getTime();

        
        try {
            Object.keys(value)
                .forEach(key => {
                    if (key.match(/^__/gi)) {
                        delete value[key];
                    }
                });
            value = JSON.stringify(value);
        } catch(e) {
            console.log('Cache Error, JSON.stringify: ', e);
            return;
        }

        try {
            storage.set(CACHE_NAMESPACE_JSON, key, value);
            storage.set(CACHE_NAMESPACE_EXPIRY, key, expiry);
        } catch(e) {
            if (Cache.QuotaExceeded(e)) {
                let stored: {key: string; size: number; expiration: number}[] = [];
                let size: number = 0;
 
                this.Each(function(key: string) {
                    let cacheJson: any = storage.get(CACHE_NAMESPACE_JSON, key);
                    let cacheExpiry: any = storage.get(CACHE_NAMESPACE_EXPIRY, key);

                    if (cacheJson && cacheExpiry) {
                        stored.push({
                            key: key,
                            size: cacheJson.length,
                            expiration: cacheExpiry
                        });

                        size += cacheJson.length;
                    }
                });

                stored.sort((a, b) => {
                    return b.expiration - a.expiration;
                });
                
                let targetSize = (value || '').length;

                while (size && targetSize > 0) {
                    let cache = stored.pop();

                    if (cache) {
                        Cache.Remove(cache.key);

                        size -= cache.size;
                        targetSize -= cache.size;
                    }                    
                }

                try {
                    storage.set(CACHE_NAMESPACE_JSON, key, value);
                    storage.set(CACHE_NAMESPACE_EXPIRY, key, expiry);
                } catch(e) {                   
                    stored.forEach( cache => Cache.Remove(cache.key) );

                    storage.set(CACHE_NAMESPACE_JSON, key, value);
                    storage.set(CACHE_NAMESPACE_EXPIRY, key, expiry);
                }
            }
        }
    }

    public static get(key: string) {
        Cache.FlushExpiredItem(key);

        let value: any = storage.get(CACHE_NAMESPACE_JSON, key);

        try {
            return JSON.parse(value);
        } catch(e) {
            return value;
        }
    }

    private static Remove(key: string) {
        storage.remove(CACHE_NAMESPACE_JSON, key);
        storage.remove(CACHE_NAMESPACE_EXPIRY, key);
    }

    private static Each(callback: Function) {
        let keys = storage.keys(CACHE_NAMESPACE_EXPIRY);

        keys.forEach((key: string) => {
            callback(key.replace(`${CACHE_NAMESPACE_EXPIRY}/`, ''));
        });
    }

    private static FlushExpiredItem(key: string) {
        let current = new Date().getTime();
        let expiration = parseInt((storage.get(CACHE_NAMESPACE_EXPIRY, key) as any));

        if (current >= expiration) {
            this.Remove(key);
        }
    }

    private static QuotaExceeded(e: Error) {
        return e.name.match(/QUOTA/i);
    }
}