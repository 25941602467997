import { ww, q } from 'bobjoll/ts/library/dom';
import { isHidden } from 'Project/ts/common/element';
import getXHR from 'Partials/xhr';

export const isAdBlockEnabled = () => {
    const adElement = q('.myTestAd');
    if (adElement) {
        return isHidden(adElement);
    }
    return 'undefined' === typeof ww.advertisement;
};

export const sendAdblockToLog = () => {
    getXHR(`${APP_URL}_ga?send&event&no-display`, {
        withCredentials: false,
    });
};
