/* eslint-disable @typescript-eslint/camelcase */
import { ww } from 'bobjoll/ts/library/dom';
import { EventTracker } from 'Library/eventTacker';

import { buyLicenseG4Event } from './ga4/buyLicenseG4Event';

const getTypeBackgroundImage = () => {
    const backgroundTypeImages = [
        {
            type: 'bag',
            url: `${URL_STATIC_IMG}craft-banner/bag.png`,
        },
        {
            type: 'cup',
            url: `${URL_STATIC_IMG}craft-banner/cup.png`,
        },
        {
            type: 'tshirt',
            url: `${URL_STATIC_IMG}craft-banner/t-shirt.png`,
        },
    ];

    const containerBackgroundElement: HTMLElement | null = document.querySelector('.banner-craft__image');
    if (containerBackgroundElement) {
        let typeShowing = containerBackgroundElement.style.backgroundImage;
        const type = backgroundTypeImages.find(type => typeShowing.includes(type.url));

        return type?.type;
    }
    return '';
};

export const handleListenersForEvents = () => {
    document.getElementById('buy-craft')?.addEventListener('click', (e: Event) => {
        e.preventDefault();
        localStorage.setItem('item_list_name', 'resource_detail');
        const buttonBuyElement = e.currentTarget as HTMLButtonElement;
        buttonBuyElement?.querySelector('.circle-spinner')?.classList.remove('hide');
        const eventData = {
            item_name: 'flaticon_na_merchandising-1',
            item_id: 'FI-MERCHANDISING-1',
            price: ww.CRAFT_PRICE,
            item_brand: 'flaticon',
            item_category: 'merchandising-1',
            item_category2: 'na',
            item_category3: 'full_price',
            item_list_name: 'resource_detail',
            coupon: ' ',
            index: 1,
            quantity: 1,
        };

        let currency = atob(localStorage.getItem('settings/c') || '');

        const currencyParsedList = {
            EURO: 'EUR',
            DOLLAR: 'USD',
        };

        buyLicenseG4Event(eventData, currencyParsedList[currency as keyof typeof currencyParsedList] ?? currency);

        setTimeout(() => {
            redirectPurchase();
        }, 100);
    });

    document.getElementById('more-info-craft')?.addEventListener('click', () => {
        EventTracker.send('ga4dl', {
            event: 'ga4event',
            event_name: 'more_info_merchandising_license',
            location: 'resource_detail_icon',
            interaction_type: 'click',
            type: getTypeBackgroundImage(),
        });
    });
};

export const redirectPurchase = () => {
    const resourceId = ww.Resource.resource.id;
    if (resourceId) {
        const url = `${MERCHANDISING_LICENSE_CHECK_URL}/${resourceId}`;
        ww.location.href = url;
    }
};
