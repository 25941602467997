var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "data-gtm-event=\"download thumbnail svg\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "bj-button--secondary";
},"5":function(container,depth0,helpers,partials,data) {
    return "btn-warning";
},"7":function(container,depth0,helpers,partials,data) {
    return "              <i class=\"icon icon--crown-filled mg-right-lv1\"></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-ga_params=\"contextmenu,click, downloadPNGSVG\"\n  class=\"track download-png-svg-list popover popover--bottom-right\"\n  data-track-arguments=\"ga, event, contextmenu, click, downloadPNGSVG, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n  <div class=\"popover-container tooltip tooltip--left\">\n    <button\n      class=\"popover-button tooltip__trigger tooltip__trigger--always bj-button bj-button--sm bj-button--green bj-button--icon\">\n      <i class=\"icon icon--download\"></i>\n    </button>\n    <div class=\"tooltip__content tooltip__content--permanent\">\n      <div class=\"content\">\n        <span>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"download") : stack1), depth0))
    + "</span>\n      </div>\n    </div>\n    <div class=\"popover-content\">\n      <div>\n        <span class=\"bold mg-bottom-lv2\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"downloadAsset") : stack1), depth0))
    + "</span>\n        <ul>\n          <li class=\"GA_CM_download-svg\">\n            <button onclick=\"Downloads.direct_download_icon_post(event, 'svg');\" "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isUserPremium") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":81},"end":{"line":19,"column":165}}})) != null ? stack1 : "")
    + " data-format=\"svg\" class=\"btn-svg bj-button "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isUserPremium") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":19,"column":209},"end":{"line":19,"column":293}}})) != null ? stack1 : "")
    + " mg-bottom-lv2\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isUserPremium") : depth0),"==",0,{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":22,"column":24}}})) != null ? stack1 : "")
    + "              SVG</button>\n          </li>\n          <li class=\"GA_CM_download-png\">\n            <button onclick=\"Downloads.direct_download_icon_post(event, 'png');\" class=\"bj-button bj-button--secondary track\" data-track-arguments=\"ga, event, contextmenu, click, download-png\" data-gtm-event=\"download thumbnail png\">PNG</button>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n</li>";
},"useData":true});