var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"modal-copy-svg-from-search-list-not-premium-user\" class=\"modal modal--xs modal-copy-svg-from-search-list-not-premium-user\">\n    <div class=\"modal__container\">\n\n        <div class=\"modal-body modal-download-detail__content pd-lv3 alignl font-sm\">\n                <p class=\"font-md bold mg-bottom-lv1\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"premiumSubscription") : stack1), depth0))
    + "</p>\n                <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"totalResources") : stack1), depth0))
    + "</p>\n                <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"unlimitedDownloads") : stack1), depth0))
    + "</p>\n                <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"noAttributionRequired") : stack1), depth0))
    + "</p>\n                <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"browseAdFree") : stack1), depth0))
    + "</p>\n                <a id=\"download-premium\" href="
    + alias2(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":10,"column":46},"end":{"line":10,"column":60}}}) : helper)))
    + " class=\"bj-button bj-button--flat bj-button--yellow mg-top-lv3 mg-bottom-lv3 track\" data-track-arguments=\"ga, event, premium, modal-download-premium\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"goPremium") : stack1), depth0))
    + "</a>                   \n\n                <a class=\"bj-button bj-button--md bj-button--ghost button--close modal__close\">\n                    <i class=\"icon--cross icon--xl\"></i>\n                </a>        \n        </div>\n\n    </div>\n</div>";
},"useData":true});