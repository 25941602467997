import 'Variables';

import { SVG, Svg } from '@svgdotjs/svg.js';
import { q, qq, ww } from 'bobjoll/ts/library/dom';
import { ViewTypes } from 'CommonApp/view';
import { EventTracker } from 'Library/eventTacker';
import { toggleClass } from 'Library/helpers';
import { copyNpmUicons, copyUrlCdnUicons } from 'Pods/copyPaste/copyToClipboard';
import SearchBarUi from 'Pods/uicons/searchBarUi';
import { getUiModalDetail } from 'Pods/uicons/uicon-detail/detailService';
import { UIcons } from 'Pods/uicons/uicons.vm';
import { Anchor } from 'Project/ts/common/anchor';
import { Downloads } from 'Project/ts/common-app/downloads';
import User from 'Project/ts/pods/user/main';
import Typed from 'typed.js';
import Lazyload from 'UI/lazyload';

export class Uicons {
    private static detail: HTMLElement | null;
    public static currentUiconsPrefix: string = 'rr';
    public static currentUiconsRelated: Record<string, UIcons>;
    private static packList: UiconsPackList = {
        br: 'uicons-bold-rounded',
        rs: 'uicons-regular-straight',
        ss: 'uicons-solid-straight',
        bs: 'uicons-bold-straight',
        rr: 'uicons-regular-rounded',
        sr: 'uicons-solid-rounded',
        ts: 'uicons-thin-straight',
        tr: 'uicons-thin-rounded',
        brands: 'uicons-brands',
    };

    private static stylesStringToStyleId: stylesStringToStyleIdTypes = {
        'regular-rounded': '1293',
        'bold-rounded': '1294',
        'solid-rounded': '1295',
        'thin-rounded': '1359',
        'regular-straight': '1290',
        'bold-straight': '1291',
        'solid-straight': '1292',
        'thin-straight': '1360',
        brands: '1356',
    };

    public static isEdited: boolean = false;
    public static svgCode: string;

    public static init() {
        Uicons.detail = q('.uicons__detail');
        Uicons.handleListeners();

        setTimeout(() => Lazyload.run(), 500);
    }

    public static initMin() {
        Uicons.handleListeners();
        copyUrlCdnUicons();
        copyNpmUicons();
        const getStartedNav = q('.uicons__sidebar');

        const getStartedContent = q('.uicons__get-started .uicons__text');

        const prevElement = q('.uicons__header');

        const allAccordionContents = qq('.uicons-accordion__content');
        const allAccordionHeadersArrows = qq('.uicons-accordion__header i');

        if (getStartedNav && getStartedContent && prevElement) {
            new Anchor({
                element: getStartedNav,
                prevElement: prevElement,
            });
        }

        qq('.uicons-accordion__header')?.forEach(accordionHeader => {
            accordionHeader.addEventListener('click', (event: Event) => {
                event.preventDefault();
                const targetAccordionHeader = event.currentTarget as HTMLElement;
                const targetAccordionHeaderArrow = targetAccordionHeader.querySelector('i') as HTMLElement;

                targetAccordionHeader?.nextElementSibling?.classList.toggle('active');
                targetAccordionHeaderArrow?.classList.toggle('rotated');

                allAccordionHeadersArrows.forEach(arrow => {
                    targetAccordionHeaderArrow !== arrow && arrow.classList.remove('rotated');
                });

                allAccordionContents.forEach(content => {
                    targetAccordionHeader.nextElementSibling !== content && content.classList.remove('active');
                });
            });
        });
    }

    public static initType() {
        let texts = ['^1000solid-rounded.css', '^1000outline-rounded.css', '^1000bold-rounded.css'];
        let elements = [
            document.querySelector('.solid-rounded'),
            document.querySelector('.outline-rounded'),
            document.querySelector('.bold-rounded'),
        ];

        var options = {
            strings: texts,
            typeSpeed: 70,
            backSpeed: 40,
            backDelay: 2000,
            startDelay: 500,
            loop: true,
            cursorChar: '',
            onStringTyped: (pos: number) => {
                elements.forEach(element => {
                    element?.classList.add('hide');
                });
                elements[pos]?.classList.remove('hide');
            },
        };
        new Typed('#typing', options);
    }

    private static handleListeners() {
        q('.uicons__detail .button--close')?.addEventListener('click', () => {
            Uicons.detail?.classList.remove('show');
        });

        qq('.uicons__selector li input').forEach((inputElement: HTMLInputElement) => {
            inputElement.addEventListener('change', () => {
                const currentGetStartedFilter = SearchBarUi.getUiconPrefixFilter();
                const cdnLink = `${CDN_URL_PREFIX}${Uicons.packList[currentGetStartedFilter]}/css/${Uicons.packList[currentGetStartedFilter]}.css`;

                this.showAndHideFilterResults(
                    this.currentUiconsPrefix,
                    currentGetStartedFilter,
                    document.querySelector('.icon__detail--container'),
                    document.querySelectorAll('.uicons__example ul li a'),
                );
                this.handleCdnUiconsCopy(cdnLink);
                Lazyload.run();
            });
        });

        const uiconsSelector = document.querySelector('.uicons__selector--wrapper');

        window.addEventListener('scroll', () => {
            if (uiconsSelector) {
                if (uiconsSelector.getBoundingClientRect().top <= 54) {
                    document.querySelector('.uicons__selector')?.classList.add('fixed');
                } else {
                    document.querySelector('.uicons__selector')?.classList.remove('fixed');
                }

                let element = document.querySelector('#footer');
                if (element) {
                    let position = element.getBoundingClientRect();
                    if (
                        (position.top >= 0 && position.bottom <= window.innerHeight) ||
                        (position.top < window.innerHeight && position.bottom >= 0)
                    ) {
                        document.querySelector('.uicons__detail')?.classList.remove('fixed');
                    } else {
                        document.querySelector('.uicons__detail')?.classList.add('fixed');
                    }
                }
            }
        });

        const copyBtn = document.querySelector('.uicon-detail-download .button--copy');

        copyBtn?.addEventListener('click', e => {
            const tempInput = document.createElement('input');
            tempInput.style.position = 'absolute';
            tempInput.style.left = '-1000px';
            tempInput.style.top = '-1000px';
            tempInput.value = (q('#uicons-code') as HTMLInputElement).value;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
            const buttonElement = e.currentTarget as HTMLElement;

            if (buttonElement) {
                const tooltipElement = buttonElement.closest('.tooltip')?.querySelector('.tooltip__content .content');

                if (tooltipElement) {
                    tooltipElement.innerHTML = messages.attribution.copied;
                    buttonElement.closest('.group__context')?.classList.add('copied');
                    setTimeout(function() {
                        buttonElement.closest('.group__context')?.classList.remove('copied');
                        tooltipElement.innerHTML = messages.common.copy;
                    }, 2000);
                }
            }
        });

        qq('.uicons__detail .button--download')?.forEach(element =>
            element.addEventListener('click', (e: Event) => {
                e.preventDefault();
                if (User.isLogged() && !User.isActiveUser()) {
                    User.alert(`inactive-user-error`, messages.errors.inactive_user, 'error', 'bottom-left');
                } else if (ww.Essentials && ww.Essentials.prechurnInfo?.unpaid) {
                    ww.Essentials.handleDownloadClick();
                } else {
                    const buttonElement = e.currentTarget as HTMLElement;
                    const downloadUiconForm = q('#download-uicon-form') as HTMLFormElement;
                    const format = buttonElement.dataset.format || 'png';
                    const size = format === 'png' ? buttonElement.dataset.size : '512';
                    const downloadEditedUiconForm = q('#download-edited-uicon-form') as HTMLFormElement;
                    const iconId = (downloadUiconForm.querySelector('#icon_id') as HTMLInputElement).value;

                    if (Uicons.isEdited && downloadEditedUiconForm) {
                        const inputFormatElement = downloadEditedUiconForm.querySelector('#format') as HTMLInputElement;
                        inputFormatElement.value = format;
                        const inputSizeElement = downloadEditedUiconForm.querySelector('#size') as HTMLInputElement;
                        inputSizeElement.value = size || '512';

                        const nameFormInput = downloadEditedUiconForm.querySelector('#name') as HTMLInputElement;
                        const iconName = nameFormInput?.value;

                        import('Pods/editor').then(module => {
                            const svgCode = module.EditorHelpers.resizeSvgToDefault(Uicons.svgCode);

                            downloadEditedUiconForm.action = `${FLATICON_DOWNLOAD_URL}download/icon/edited`;
                            (downloadEditedUiconForm.querySelector('#id') as HTMLInputElement).value = iconId;
                            (downloadEditedUiconForm.querySelector('#code') as HTMLInputElement).value = btoa(svgCode);
                            (downloadEditedUiconForm.querySelector('#name') as HTMLInputElement).value = iconName || '';

                            downloadEditedUiconForm.submit();
                        });
                    } else if (downloadUiconForm) {
                        const inputFormatElement = downloadUiconForm.querySelector('#format') as HTMLInputElement;
                        inputFormatElement.value = format;

                        if (format === 'png') {
                            const inputSizeElement = downloadUiconForm.querySelector('#size') as HTMLInputElement;

                            inputFormatElement.value = 'png';
                            inputSizeElement.value = size || '512';
                            document.querySelector('.download ul.size li.selected')?.classList.remove('selected');

                            if (buttonElement.classList.contains('bj-button--primary')) {
                                document
                                    .querySelector('.download ul.size li a[data-size="512"]')
                                    ?.parentElement?.classList.add('selected');
                            } else {
                                buttonElement.parentElement?.classList.add('selected');
                            }
                        }

                        downloadUiconForm.submit();
                        Downloads.trackDownload(iconId, format);

                        const dataToSend = {
                            event: 'ga4event',
                            event_name: 'file_download',
                            category: 'interface_icons',
                            location: 'resource_detail',
                            file_id: ww.Resource.resource.id,
                            file_name: ww.Resource.resource.name,
                            author_name: 'Uicons',
                            file_extension: format,
                            file_size: format === 'png' ? size || '512' : '512',
                            action: 'download',
                        };

                        ww.EventTracker.send('ga4dl', dataToSend);
                    }
                }
            }),
        );

        q('.copy-box button')?.addEventListener('click', e => {
            let tempInput = q('.copy-box .copy__text') as HTMLInputElement;
            tempInput.select();
            document.execCommand('copy');
            (e.currentTarget as HTMLElement).textContent = messages.attribution.copied;
        });

        if (ww.view.section !== ViewTypes.uiconDetail) {
            document.querySelectorAll('.uicons__detail .other-styles a').forEach((relatedAnchor: HTMLAnchorElement) => {
                relatedAnchor.addEventListener('click', async (e: Event) => {
                    e.preventDefault();
                    e.stopPropagation();

                    Uicons.isEdited = false;
                    await getUiModalDetail(
                        document.querySelector('.uicons__detail .other-styles') as HTMLElement,
                        relatedAnchor,
                    );
                    this.prepareDownloadForm(relatedAnchor);
                    Uicons.handleListeners();
                });
            });
        }

        if (User.isLogged()) {
            q('.bj-button--download')?.addEventListener('click', (e: Event) => {
                if (!User.isActiveUser()) {
                    User.alert(`inactive-user-error`, messages.errors.inactive_user, 'error', 'bottom-left');
                    return;
                }
                e.preventDefault();

                const buttonTargetElement = e.currentTarget as HTMLElement;

                const downloading = buttonTargetElement.classList.contains('button--loading-download');

                if (downloading) {
                    return;
                }

                const styleFamily = this.getWeightCornerAndPackid(buttonTargetElement).styleFamily;
                const packid = this.getWeightCornerAndPackid(buttonTargetElement).packid;
                EventTracker.send('ga', 'event', 'download-uicons', 'pack', `${styleFamily}`);

                const dataToSend = {
                    event: 'ga4event',
                    event_name: 'pack_download',
                    category: 'interface_icons',
                    location: 'icon_detail',
                    file_id: packid,
                    items_number: ww.TOTAL_UICONS_NUMBER_GROUPED_BY_STYLE[Uicons.stylesStringToStyleId[styleFamily]],
                    action: 'download',
                };

                ww.EventTracker.send('ga4dl', dataToSend);

                window.location.href = `${FLATICON_DOWNLOAD_URL}uicons/download-pack/${packid}?packKey=uicons-${styleFamily}`;

                if (!User.isPremium()) {
                    ww.Attribution.initialize('uicons');
                    ww.Attribution.show();
                }

                this.downloadPackButtonDownloading(buttonTargetElement);
                setTimeout(() => this.downloadPackButtonNoDownloading(buttonTargetElement), 1000);

                User.updateUserStats(3000);
            });
        }
    }

    private static downloadPackButtonDownloading(button: HTMLElement): void {
        const downloadingMessage = document.createElement('span');
        downloadingMessage.className = 'text-downloading';
        downloadingMessage.innerHTML = messages.download.downloading;

        button.appendChild(downloadingMessage);

        button.classList.add('button--loading-download');
    }

    private static downloadPackButtonNoDownloading(button: HTMLElement): void {
        const downloadingMessage = button.querySelectorAll('.text-downloading');

        downloadingMessage.forEach(item => item.remove());

        button.classList.remove('button--loading-download');
    }

    private static prepareDownloadForm(icon: HTMLElement) {
        const downloadForm = q('#download-uicon-form') as HTMLFormElement;
        downloadForm.method = 'get';
        downloadForm.action = `${FLATICON_DOWNLOAD_URL}download/icon/${icon.dataset.id}`;
        const inputId = q('input#icon_id', downloadForm) as HTMLInputElement;
        const inputTeam = q('input#team', downloadForm) as HTMLInputElement;

        if (inputId && inputTeam) {
            inputId.value = icon.dataset.id || '';
            inputTeam.value = icon.dataset.team_id || '';
        }
    }

    private static getWeightCornerAndPackid(
        target: HTMLElement | null,
    ): { styleFamily: string; packid: string | undefined } {
        const style = target?.dataset.style;
        const family = target?.dataset.family;
        const packid = target?.dataset.packid;
        let data = {
            styleFamily: `${style}-${family}`,
            packid,
        };
        if (family === 'brands') {
            data.styleFamily = `${family}`;
        }

        return data;
    }

    public static showAndHideFilterResults(
        currentUiconsPrefix: string,
        currentFilter: string,
        containerElement: HTMLElement | null,
        resultsList: NodeList,
    ) {
        currentUiconsPrefix = currentFilter;
        containerElement?.remove();

        resultsList.forEach((uIconExample: HTMLAnchorElement) => {
            if (uIconExample.dataset.prefix !== currentFilter) {
                toggleClass(uIconExample, 'hide', 'add');
            } else {
                toggleClass(uIconExample, 'hide', 'remove');
            }
        });
    }

    public static handleCdnUiconsCopy(cdnLink: string) {
        qq('.cdn-style-link').forEach(element => {
            element.setAttribute('value', `<link rel='stylesheet' href='${cdnLink}'>`);
        });
        qq('.cdn-style-import').forEach(element => {
            element.setAttribute('value', `@import url('${cdnLink}')`);
        });
    }

    public static handleUiconsEditorEvent() {
        if (ww.view.section !== 'icon-detail') {
            const buttonEditElement = document.querySelector('.btn-edit-icon');
            buttonEditElement?.addEventListener(
                'click',
                () => {
                    this.openEditor();
                },
                { passive: true },
            );
        }
    }

    public static loadSVG = (url: string) =>
        import('Pods/editor').then(async module => {
            const svg: SVGElement = await module.EditorHelpers.getSVGCode(url);
            let uiconHolder = document.querySelector('.uicon-detail-holder');

            if (uiconHolder && svg) {
                document.getElementById('uicons__detail-img')?.remove();
                uiconHolder.innerHTML = '';
                let viewBoxValues: string[] = [];

                if (svg.getAttribute('viewBox')) {
                    viewBoxValues = svg?.getAttribute('viewBox')?.split(' ') || [];
                }

                const draw: Svg = SVG()
                    .addTo('.uicon-detail-holder')
                    .size(256, 256);

                draw.attr({
                    x: '0',
                    y: '0',
                    viewBox: svg.getAttribute('viewBox')
                        ? `0 0 ${viewBoxValues ? viewBoxValues[2] : 0} ${viewBoxValues ? viewBoxValues[3] : 0}`
                        : '0 0 512 512',
                    style: 'enable-background:new 0 0 512 512',
                    'xml:space': 'preserve',
                });
                draw.svg('<g>' + svg.innerHTML + '</g>');

                module.Color.getColors(draw, 'uicon');
            }
        });

    private static openEditor() {
        let inModalView = document.querySelector('#detail-overlay');
        import('Pods/editor/editor').then(module => {
            ww.Editor = module.Editor;
            ww.Editor.init(inModalView, true);
        });
    }
}

interface UiconsPackList {
    [key: string]: string;
}

interface stylesStringToStyleIdTypes {
    [key: string]: string;
}
