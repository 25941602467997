var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div id=\"banner-craft\" class=\"banner-craft\">\n      <div class=\"banner-craft__container\">\n        <div class=\"banner-craft__image\">\n          <img src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" alt=\"Icon\" width=\"64\" height=\"64\">\n        </div>\n        <div class=\"banner-craft__text\">\n          <p class=\"bold font-sm mg-bottom-lv1 capitalize\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"banner_craft_title") : stack1), depth0))
    + "<span class=\"badge mg-left-lv2 uppercase\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"banner_craft_new") : stack1), depth0))
    + "</span></p>\n          <p class=\"font-sm regular mg-bottom-lv2\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"banner_craft_text") : stack1), depth0))
    + " <a href=\"\" id=\"more-info-craft\" class=\"bj-button bj-button--link\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"more_info") : stack1), depth0))
    + "</a></p>\n          <button data-resource=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" class=\"bj-button bj-button--sm bj-button--flat bj-button--white\" id =\"buy-craft\">\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"banner_craft_buy") : stack1), depth0))
    + "\n            <div class=\"circle-spinner banner-craft__spinner hide\"></div>\n          </button>\n        </div>\n      </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"craft") : stack1),"==",1,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":2},"end":{"line":17,"column":14}}})) != null ? stack1 : "");
},"useData":true});