import { ww } from 'bobjoll/ts/library/dom';
import { cookie } from 'Library/cookie';
import getXHR from 'Partials/xhr';
import { addMinutesToTimestamp, isExpiredByTimestamp } from 'Project/ts/common/date.helpers';
import { urlRedirect } from 'Project/ts/common/urls.helper';
import { User } from 'Project/ts/pods/user/userSession.vm';

export const getUserSession = async () => {
    const userFromLocalStorage = localStorage.getItem('session/user');
    const userParsed: User | null = userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;

    if (
        userParsed?.value.length &&
        userParsed?.expiry &&
        !isExpiredByTimestamp(userParsed.expiry) &&
        !IS_API_ORIGIN &&
        !hasDifferencesBetweenOriginAndLocalStorage(userParsed)
    ) {
        if (!userParsed.expiry) {
            setSessionExpiry(userParsed);
        }
        const response = {
            data: userParsed,
            success: true,
            local: true,
        };

        return new Promise(resolve => {
            resolve(response);
        });
    } else {
        return getUserSessionFromRepository();
    }
};

export const getUserSessionFromRepository = async () => {
    const getUserSessionUrl = `${APP_URL}ajax/user/get-basic-data`;

    return getXHR(getUserSessionUrl, {
        withCredentials: true,
    });
};

export const logoutSessionService = () => {
    const logoutSessionUrl = `${APP_URL}logout`;

    localStorage.removeItem(`fi_${ww.UserSession.userInfo.session.id}`);

    return getXHR(logoutSessionUrl, {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-type': 'application/json',
        },
        withCredentials: true,
    });
};

export const logoutSession = () => {
    logoutSessionService().then(() => {
        if (typeof cookie.getItem('CB_URL') === 'string') {
            urlRedirect(cookie.getItem('CB_URL'));
        } else {
            urlRedirect();
        }
        removeLocalStorageUserSession();
        cookie.removeItem('g_state');
    });
};

export const removeLocalStorageUserSession = () => {
    localStorage.removeItem('session/user');
};

export const refreshUserSession = () => {
    removeLocalStorageUserSession();
    getUserSession().then(response => {
        if (response.success) {
            const user = {
                value: JSON.stringify(response.data),
                expiry: response.expiry,
            };
            localStorage.setItem('session/user', JSON.stringify(user));
        }
    });
};

const setSessionExpiry = (user: User) => {
    user.expiry = addMinutesToTimestamp(5);
    localStorage.setItem('session/user', JSON.stringify(user));
};

export const updateUserSessionValue = async (newData: any) => {
    const currentUserSession = await getUserSession();
    const currentData = currentUserSession?.data;

    const currentValue = currentData ? JSON.parse(currentData.value) : null;

    if (!currentValue) {
        return;
    }

    const parsedNewValue = JSON.stringify({
        ...currentValue,
        ...newData,
    });

    const parsedData = JSON.stringify({
        ...currentData,
        value: parsedNewValue,
    });

    localStorage.setItem('session/user', parsedData);
};

const hasDifferencesBetweenOriginAndLocalStorage = (userInfo: User) => {
    const isPremiumInLocal = JSON.parse(userInfo.value).pm;
    if (isPremiumInLocal != IS_USER_PREMIUM) {
        return true;
    }

    return false;
};
