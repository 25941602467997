import { ww } from 'bobjoll/ts/library/dom';
import { ResourceConfig, ResourceData, ResourceTypes } from 'CommonApp/resource/resource.vm';
import { getNumberOfUiconsByStyleText, isFamilyBrands } from 'Pods/uicons/uicons.helpers';
import { isBannedCountry } from 'Project/ts/common-app/sponsor/common/isBannedCountry';
import { isFirefox, isSafari } from 'Project/ts/library/helpers/browser';
import { isMobile } from 'Library/helpers/mobile';

export const resourceDetailComponents = {
    aside: {
        addCollectionBtnComponent: require('Project/ts/pages/icon-detail/detailComponents/aside/addCollectionBtnComponent.hbs'),
        shareRrSsComponent: require('Project/ts/pages/icon-detail/detailComponents/aside/shareRrSsComponent.hbs'),
        authorComponent: require('Project/ts/pages/icon-detail/detailComponents/aside/authorComponent.hbs'),
        bannerComponent: require('Project/ts/pages/icon-detail/detailComponents/aside/bannerComponent.hbs'),
        downloads: {
            standardComponent: require('Project/ts/pages/icon-detail/detailComponents/aside/downloads/downloadStandardComponent.hbs'),
            extendedComponent: require('Project/ts/pages/icon-detail/detailComponents/aside/downloads/downloadExtendedComponent.hbs'),
            uiconComponent: require('Project/ts/pages/icon-detail/detailComponents/aside/downloads/downloadUiconComponent.hbs'),
        },
        licenseAndAttributionComponent: require('Project/ts/pages/icon-detail/detailComponents/aside/licenseAndAttributionComponent.hbs'),
        packComponent: require('Project/ts/pages/icon-detail/detailComponents/aside/packComponent.hbs'),
        styleComponent: require('Project/ts/pages/icon-detail/detailComponents/aside/styleComponent.hbs'),
    },
    content: {
        authorSearchComponent: require('Project/ts/pages/icon-detail/detailComponents/content/authorSearchComponent.hbs'),
        editButtonComponent: require('Project/ts/pages/icon-detail/detailComponents/content/editButtonComponent.hbs'),
        editUiconButtonComponent: require('Project/ts/pages/icon-detail/detailComponents/content/editButtonComponent.hbs'),
        resourcePreviewComponent: require('Project/ts/pages/icon-detail/detailComponents/content/resourcePreviewComponent.hbs'),
        savePinterestComponent: require('Project/ts/pages/icon-detail/detailComponents/content/savePinterestComponent.hbs'),
        detailStylesComponent: require('Project/ts/pages/icon-detail/detailComponents/content/detailStylesComponent.hbs'),
    },
    related: {
        relatedPacksComponent: require('Project/ts/pages/icon-detail/detailComponents/related/relatedPacksComponent.hbs'),
        relatedStylesComponent: require('Project/ts/pages/icon-detail/detailComponents/related/relatedStylesComponent.hbs'),
        relatedTagsComponent: require('Project/ts/pages/icon-detail/detailComponents/related/relatedTagsComponent.hbs'),
        relatedTagsAndStylesComponent: require('Project/ts/pages/icon-detail/detailComponents/related/relatedTagsAndStylesComponent.hbs'),
        sponsorAdobeComponent: require('Project/ts/pages/icon-detail/detailComponents/related/sponsorAdobeComponent.hbs'),
        googleAdsComponent: require('Project/ts/pages/icon-detail/detailComponents/related/googleAdsComponent.hbs'),
        animatedIconInActionComponent: require('Project/ts/pages/icon-detail/detailComponents/related/animatedIconInActionComponent.hbs'),
        iconFontInActionComponent: require('Project/ts/pages/icon-detail/detailComponents/related/iconFontInActionComponent.hbs'),
        tagsComponent: require('Project/ts/pages/icon-detail/detailComponents/related/tagsComponent.hbs'),
        stunningAnimatedIconsComponent: require('Project/ts/pages/icon-detail/detailComponents/related/stunningAnimatedIconsComponent.hbs'),
        findBestIconFontComponent: require('Project/ts/pages/icon-detail/detailComponents/related/findBestIconFontComponent.hbs'),
        iconFontDoubtsSupportComponent: require('Project/ts/pages/icon-detail/detailComponents/related/iconFontDoubtsSupportComponent.hbs'),
    },
    freepikSponsorComponent: require('Project/ts/pages/icon-detail/detailComponents/freepikSponsorComponent.hbs'),
};

export const renderResourceDetailComponents = (
    resourceConfig: ResourceConfig,
    resourceTypes: ResourceTypes,
    resourceData: ResourceData,
    globalInfo: {},
) => {
    const searchUrl = urls.common.urlSearch;
    const authorLinks = resourceConfig.detail.authorLinks;
    const downloadResourceFormats = resourceConfig.detail.downloads.formats;
    const styleLink = resourceConfig.detail.styleLink;
    const styleBookmark = resourceConfig.detail.styleBookmark;
    const resourcePreviewFormat = resourceConfig.detail.resourceFormat;
    const addCollectionBtnComponent = resourceConfig.detail.addcollectionBtn
        ? resourceDetailComponents.aside.addCollectionBtnComponent({
              resourceData,
              messages,
          })
        : null;
    const shareRrSsComponent = resourceDetailComponents.aside.shareRrSsComponent({
        showaddCollectionBtn: resourceConfig.detail.addcollectionBtn,
        resourceData,
        messages,
    });

    return {
        authorComponent: resourceConfig.detail.authorInfo
            ? resourceDetailComponents.aside.authorComponent({
                  authorLinks,
                  resourceTypes,
                  resourceData,
                  globalInfo,
                  messages,
              })
            : null,
        bannerComponent: resourceConfig.detail.stickerBanner
            ? resourceDetailComponents.aside.bannerComponent({
                  resourceData,
                  globalInfo,
                  messages,
              })
            : null,
        downloadComponent: resourceDetailComponents.aside.downloads[resourceConfig.detail.downloads.components]({
            addCollectionBtnComponent,
            shareRrSsComponent,
            downloadResourceFormats,
            showaddCollectionBtn: resourceConfig.detail.addcollectionBtn,
            resourceData,
            resourceTypes,
            globalInfo,
            messages,
            isFamilyBrands: isFamilyBrands(resourceData.familyName),
            numberOfUiconsByStyleText: getNumberOfUiconsByStyleText(resourceData.style.id),
            showCopyPaste: !(isFirefox || isSafari),
        }),
        licenseAndAttributionComponent: resourceDetailComponents.aside.licenseAndAttributionComponent({
            resourceTypes,
            resourceData,
            globalInfo,
            messages,
        }),
        packComponent: resourceConfig.detail.pack
            ? resourceDetailComponents.aside.packComponent({
                  resourceData,
                  globalInfo,
                  messages,
              })
            : null,
        styleComponent: resourceConfig.detail.styleInfo
            ? resourceDetailComponents.aside.styleComponent({
                  styleLink,
                  styleBookmark,
                  resourceData,
                  globalInfo,
                  messages,
              })
            : null,
        authorSearchComponent: resourceConfig.detail.autorSearchBar
            ? resourceDetailComponents.content.authorSearchComponent({ searchUrl, resourceData, messages })
            : null,
        editButtonComponent: resourceConfig.detail.editor
            ? resourceDetailComponents.content.editButtonComponent({ resourceData, messages, globalInfo })
            : null,
        editUiconButtonComponent: resourceConfig.detail.editorUicon
            ? resourceDetailComponents.content.editUiconButtonComponent({ resourceData, messages })
            : null,
        resourcePreviewComponent: resourceDetailComponents.content.resourcePreviewComponent({
            resourceTypes,
            resourcePreviewFormat,
            resourceData,
            playsinline: 'playsinline',
        }),
        savePinterestComponent: resourceDetailComponents.content.savePinterestComponent({ messages }),
        detailStylesComponent: !isFamilyBrands(resourceData.familyName)
            ? resourceDetailComponents.content.detailStylesComponent({})
            : null,
        relatedPacksComponent: resourceConfig.detail.relatedIconsPack
            ? resourceDetailComponents.related.relatedPacksComponent({
                  resourceData,
              })
            : null,
        relatedStylesComponent: resourceConfig.detail.relatedIconsStyle
            ? resourceDetailComponents.related.relatedStylesComponent({ resourceData })
            : null,
        relatedTagsComponent: resourceConfig.detail.relatedIconsTags
            ? resourceDetailComponents.related.relatedTagsComponent({ resourceData, globalInfo })
            : null,
        relatedTagsAndStylesComponent: resourceConfig.detail.relatedIconsTags
            ? resourceDetailComponents.related.relatedTagsAndStylesComponent({ resourceData, globalInfo })
            : null,
        sponsorAdobeComponent:
            resourceConfig.detail.sponsorAdobe && !isBannedCountry()
                ? resourceDetailComponents.related.sponsorAdobeComponent({
                      resourceData,
                      globalInfo,
                  })
                : null,
                googleAdsComponent:
        resourceConfig.detail.googleAds 
            ? resourceDetailComponents.related.googleAdsComponent({
                    globalInfo,
                    ffEnableGoogleAdsDetails: ww.FeatureFlag?.isEnabled('enable_google_ads_detail'),
                    isMobile: isMobile(),
                })
            : null,
        animatedIconInActionComponent:
            resourceData.type === resourceTypes.animatedIcon
                ? resourceDetailComponents.related.animatedIconInActionComponent({
                      resourceData,
                      globalInfo,
                      messages,
                  })
                : null,
        iconFontInActionComponent:
            resourceData.type === resourceTypes.uicon
                ? resourceDetailComponents.related.iconFontInActionComponent({
                      resourceData,
                      globalInfo,
                      messages,
                  })
                : null,
        tagsComponent: resourceConfig.detail.resourceTags
            ? resourceDetailComponents.related.tagsComponent({
                  resourceData,
                  globalInfo,
              })
            : null,
        stunningAnimatedIconsComponent:
            resourceData.type === resourceTypes.animatedIcon
                ? resourceDetailComponents.related.stunningAnimatedIconsComponent({
                      resourceData,
                      globalInfo,
                      messages,
                  })
                : null,
        findBestIconFontComponent:
            resourceData.type === resourceTypes.uicon
                ? resourceDetailComponents.related.findBestIconFontComponent({
                      resourceData,
                      globalInfo,
                      messages,
                  })
                : null,
        iconFontDoubtsSupportComponent:
            resourceData.type === resourceTypes.uicon
                ? resourceDetailComponents.related.iconFontDoubtsSupportComponent({
                      resourceData,
                      globalInfo,
                      messages,
                  })
                : null,
    };
};
