var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"modal-craft-info\" class=\"modal modal-craft-info\">\n	<div class=\"modal__container\">\n		<div class=\"content\">\n			<button class=\"modal__close modal__close--absolute modal__close--top-right button button--icon button--icon--only button--sm button--inverted nostyle\">\n				<i class=\"icon icon--sm icon--mg-sm icon--cross\"></i>\n			</button>\n      <div class=\"modal-craft-info__left\">\n        <img src=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"srcPath") || (depth0 != null ? lookupProperty(depth0,"srcPath") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"srcPath","hash":{},"data":data,"loc":{"start":{"line":8,"column":18},"end":{"line":8,"column":29}}}) : helper)))
    + "\" alt=\"\" width=\"128\" height=\"128\">\n      </div>\n      <div class=\"modal-craft-info__right\">\n        \n        <h6 class=\"font-xl\">"
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"craft_more_info") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h6>\n        <p class=\"mg-bottom-lv4 font-md\">"
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"craft_more_info") : stack1)) != null ? lookupProperty(stack1,"subtitle") : stack1), depth0))
    + "</p>\n\n        <div class=\"modal-craft-info__right__item mg-bottom-lv4\">\n          <div class=\"modal-craft-info__right__item__image\">\n          <i class=\"icon icon--xl icon--mockup text__general--icon\"></i>\n          </div>\n          <div class=\"modal-craft-info__right__item__text\">\n            <p class=\"font-sm bold mg-bottom-lv1\">"
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"craft_more_info") : stack1)) != null ? lookupProperty(stack1,"printed_title") : stack1), depth0))
    + "</p>\n            <p class=\"font-sm  mg-none\">"
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"craft_more_info") : stack1)) != null ? lookupProperty(stack1,"printed_text") : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n\n        <div class=\"modal-craft-info__right__item mg-bottom-lv4\">\n          <div class=\"modal-craft-info__right__item__image\">\n          <i class=\"icon icon--xl icon--devices text__general--icon\"></i>\n          </div>\n          <div class=\"modal-craft-info__right__item__text\">\n            <p class=\"font-sm bold mg-bottom-lv1\">"
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"craft_more_info") : stack1)) != null ? lookupProperty(stack1,"electronics_title") : stack1), depth0))
    + "</p>\n            <p class=\"font-sm  mg-none\">"
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"craft_more_info") : stack1)) != null ? lookupProperty(stack1,"electronics_text") : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        \n        <div class=\"modal-craft-info__right__buttons\">\n          <a href=\""
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"APP_URL") : stack1), depth0))
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"url") : stack1)) != null ? lookupProperty(stack1,"craft") : stack1), depth0))
    + "\" target=\"_blank\" class=\"bj-button bj-button--transparent track-ga4\" data-track-ga4-arguments=\"event:ga4event,event_name:more_info_merchandising_license,location:modal_more_info,interaction_type:click\">\n            <span>"
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"craft_more_info") : stack1)) != null ? lookupProperty(stack1,"learn_more") : stack1), depth0))
    + "</span>\n          </a>\n          <button class=\"bj-button bj-button--primary track-ga4\" id=\"buy-craft-modal\" data-track-ga4-arguments=\"event:ga4event,event_name:microfunnel_merchandising,location:modal_more_info,interaction_type:click,action:license_merchandising_buy,product_price:full_price,campaign_name:no_campaign,discount_type:0\">\n            <span>"
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"craft_more_info") : stack1)) != null ? lookupProperty(stack1,"buy_license") : stack1), depth0))
    + "</span>\n          </button>\n        </div>\n      </div>\n		</div>\n	</div>\n</div>";
},"useData":true});