export default class Scroll {
    private static readonly scrollKeys: { [name: string]: any } = { 37: 1, 38: 1, 39: 1, 40: 1 };
    private static readonly requestAnimationFrame = (
        (window as any).requestAnimationFrame ||
        (window as any).webkitRequestAnimationFrame ||
        (window as any).mozRequestAnimationFrame ||
        (window as any).msRequestAnimationFrame ||
        (window as any).oRequestAnimationFrame
    ).bind(window);

    public static callbacks: Function[] = [];
    private static instance: Scroll;
    private static position = Scroll.getPosition();

    constructor() {
        if (!Scroll.instance) {
            Scroll.instance = this;

            Scroll.callback();
        }
    }

    public static add(callback: Function): number | void {
        return Scroll.callbacks.push(callback);
    }

    public static remove(callbackOrIndex: number | Function) {
        try {
            if ('function' === typeof callbackOrIndex) {
                let callbackIndex = Scroll.callbacks.indexOf(callbackOrIndex);
                while (callbackIndex >= 0) {
                    Scroll.callbacks.splice(callbackIndex, 1);
                    callbackIndex = Scroll.callbacks.indexOf(callbackOrIndex);
                }
            } else {
                Scroll.callbacks.splice(callbackOrIndex, 1);
            }
        } catch (err) {}
    }

    public static clear() {
        Scroll.callbacks = [];
    }

    public static getPosition() {
        return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    }

    public static to(endX: number, endY: number, duration: number) {
        var startX = window.scrollX || window.pageXOffset,
            startY = window.scrollY || window.pageYOffset,
            distanceX = endX - startX,
            distanceY = endY - startY,
            startTime = new Date().getTime();

        duration = typeof duration !== 'undefined' ? duration : 400;

        // Easing function
        var easeInOutQuart = function(time: number, from: number, distance: number, duration: number) {
            if ((time /= duration / 2) < 1) return (distance / 2) * time * time * time * time + from;
            return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
        };

        var timer = window.setInterval(function() {
            var time = new Date().getTime() - startTime,
                newX = easeInOutQuart(time, startX, distanceX, duration),
                newY = easeInOutQuart(time, startY, distanceY, duration);
            if (time >= duration) {
                window.clearInterval(timer);
            }
            window.scrollTo(newX, newY);
        }, 1000 / 60);
    }

    public static scrollTo(destination: HTMLElement | number, duration = 200, easing = 'linear', callback?: Function, elementPrevOffset?: number) {
        const easings: { [key: string]: Function } = {
            linear(t: number) {
                return t;
            },
            easeInQuad(t: number) {
                return t * t;
            },
            easeOutQuad(t: number) {
                return t * (2 - t);
            },
            easeInOutQuad(t: number) {
                return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            },
            easeInCubic(t: number) {
                return t * t * t;
            },
            easeOutCubic(t: number) {
                return --t * t * t + 1;
            },
            easeInOutCubic(t: number) {
                return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
            },
            easeInQuart(t: number) {
                return t * t * t * t;
            },
            easeOutQuart(t: number) {
                return 1 - --t * t * t * t;
            },
            easeInOutQuart(t: number) {
                return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
            },
            easeInQuint(t: number) {
                return t * t * t * t * t;
            },
            easeOutQuint(t: number) {
                return 1 + --t * t * t * t * t;
            },
            easeInOutQuint(t: number) {
                return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
            },
        };

        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
        const offsetMainContainer = elementPrevOffset ? elementPrevOffset : 0;
        const documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight,
        );
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
        const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

        if ('requestAnimationFrame' in window === false) {
            window.scroll(0, destinationOffsetToScroll);
            if (callback) {
                callback();
            }
            return;
        }

        function scroll() {
            const now = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min(1, (now - startTime) / duration);
            const timeFunction = easings[easing](time);

            window.scroll(0, Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start) + offsetMainContainer);

            if (Math.ceil(window.pageYOffset) === Math.ceil(destinationOffsetToScroll + offsetMainContainer)) {
                if (callback) {
                    callback();
                }
                return;
            }

            requestAnimationFrame(scroll);
        }

        scroll();
    }

    public static disable() {
        (window as any).addEventListener('DOMMouseScroll', Scroll.preventDefault, false);
        (window as any).onwheel = Scroll.preventDefault;
        (window as any).onmousewheel = (document as any).onmousewheel = Scroll.preventDefault;
        (window as any).ontouchmove = Scroll.preventDefault;
        (document as any).onkeydown = Scroll.preventDefaultForScrollKeys;
    }

    public static enable(callback?: Function) {
        (window as any).removeEventListener('DOMMouseScroll', Scroll.preventDefault, false);
        (window as any).onmousewheel = (document as any).onmousewheel = null;
        (window as any).onwheel = null;
        (window as any).ontouchmove = null;
        (document as any).onkeydown = null;
    }

    private static callback() {
        const position = Scroll.getPosition();
        if (position !== Scroll.position) {
            const direction = position > Scroll.position ? 'down' : 'up';
            let removeFailedCallbacks: number[] = [];

            Scroll.callbacks.forEach((callback, index) => {
                try {
                    callback(position, direction);
                } catch (e) {
                    removeFailedCallbacks.push(index);
                    console.warn(e);
                }
            });

            removeFailedCallbacks.sort((a, b) => b - a).forEach(i => Scroll.callbacks.splice(i, 1));
            removeFailedCallbacks = [];
            Scroll.position = Scroll.getPosition();
        }

        Scroll.requestAnimationFrame(Scroll.callback);
    }

    private static preventDefault(e: Event) {
        e = e || window.event;

        if (e.preventDefault) {
            e.preventDefault();
        }

        Scroll.callbacks.forEach(function(callback) {
            try {
                callback(e);
            } catch (e) {
                console.warn(e);
            }
        });

        e.returnValue = false;
    }

    private static preventDefaultForScrollKeys(e: KeyboardEvent) {
        if (Scroll.scrollKeys[e.keyCode]) {
            Scroll.preventDefault(e);
        }
    }
}

export const scroll = new Scroll();
