import { isMobile } from 'Library/helpers/mobile';
import { qq, ww } from 'bobjoll/ts/library/dom';
import { toggleClass } from 'Library/helpers';
import { ViewTypes } from 'CommonApp/view';

export const animatedCardHomeOnHover = (): void => {
    getAnimatedIcons()?.forEach((iconElement: HTMLElement) => {
        const videoElement: HTMLVideoElement | null = iconElement.querySelector('video');
        if (videoElement) {
            iconElement.addEventListener('mouseenter', () => {
                videoElement?.play();
            });

            iconElement.addEventListener('mouseleave', () => {
                videoElement?.pause();
            });

            if (window.innerWidth <= 992 || isMobile()) {
                videoElement?.play();
            }
        }
    });
};

export const getAnimatedIcons = (): HTMLElement[] | null => {
    if (
        ww.view.section === ViewTypes.iconSearch ||
        ww.Resource.type == 'animated-icon' ||
        ww.view.section === ViewTypes.homeAnimatedIcons ||
        ww.view.section === ViewTypes.iconDetail ||
        ww.view.section === ViewTypes.home
    ) {
        return qq('.icon--item[data-icon_type="animated-icon"], .cards-products-home > .row .card a.video');
    }

    return null;
};

export const addAnimatedIconListeners = (): void => {
    if (!isMobile()) {
        getAnimatedIcons()?.forEach(element => {
            element.querySelectorAll('img:not(.static-image)').forEach((resourceImgElement: HTMLImageElement) => {
                const pngUrl: string | undefined = resourceImgElement?.dataset.src;

                element.addEventListener('mouseenter', () => {
                    if (element.dataset.gif) {
                        resourceImgElement.src = element.dataset.gif;
                        resourceImgElement.srcset = element.dataset.gif;
                    }

                    resourceImgElement.onload = () => {
                        toggleClass(resourceImgElement, 'pd-none', 'add');
                    };
                });

                element.addEventListener('mouseleave', () => {
                    if (pngUrl) {
                        resourceImgElement.src = pngUrl;
                        resourceImgElement.srcset = pngUrl;

                        resourceImgElement.onload = () => {
                            toggleClass(resourceImgElement, 'pd-none', 'remove');
                        };
                    }
                });
            });
        });
    }
};
