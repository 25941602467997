import { ww } from 'bobjoll/ts/library/dom';
import { toggleClass } from 'Library/helpers';
import { mapUserData } from 'Project/ts/common-app/sessionHelpers';
import { UserDomElement, UserGuestInfo, UserInfo } from 'Project/ts/pods/user/userSession.vm';
import { removeLocalStorageUserSession } from 'Project/ts/pods/user/userSessionService';

export default class UserSession {
    public static userInfo: UserInfo;
    public static userLogged: boolean;
    public static userDomElement: UserDomElement;
    private static userGuestInfo: UserGuestInfo = {
        id: 0,
        premium: 0,
        status: false,
    };

    public static init() {
        ww.dispatchEvent(new CustomEvent('authReady'));

        if (!USER_REGISTERED) {
            removeLocalStorageUserSession();
        }
        this.handleUserInfo();

        if (typeof ww.Collection == 'function') {
            ww.Collection.init();
        }
    }

    private static setGrAuth() {
        ww.gr = {
            ...ww.gr,
            auth: {
                ...this.userInfo,
                me: { ...this.userInfo.session },
            },
        };

        if (this.userInfo.session && this.userInfo.logged) {
            gr.auth.logged = this.userInfo.logged;
        }
        if (typeof gr.facebook !== 'undefined') {
            gr.facebook.connected = this.userInfo.session.status_facebook;
        }
        if (typeof gr.google !== 'undefined') {
            gr.google.connected = this.userInfo.session.status_google;
        }
        if (typeof gr.twitter !== 'undefined') {
            gr.twitter.connected = this.userInfo.session.status_twitter;
        }
    }

    private static setUSerInfo() {
        const userSession: string | null = localStorage.getItem('session/user');

        this.userInfo = {
            baseUrl: ACCOUNTS_BASE_URL,
            logged: USER_REGISTERED,
            session: userSession && USER_REGISTERED ? mapUserData(JSON.parse(userSession)) : this.userGuestInfo,
            firstLoad: true,
            crossLoginCounter: 0,
            crossLogoutCounter: 0,
        };
    }

    private static handleUserInfo() {
        this.setUSerInfo();

        if (this.userInfo) {
            this.setGrAuth();
            this.setGAuserType();
        }
    }

    private static setGAuserType() {
        const userType = !this.userInfo.logged ? 'guest' : this.userInfo.session.premium ? 'premium' : 'registered';
        ga('set', 'dimension1', userType);
        if (this.userInfo.session.id) {
            ga('set', 'userId', this.userInfo.session.id);
        }
    }

    public static handleUserApi() {
        const avatarApiImgElement: HTMLImageElement | null = document.querySelector('#avatar img');

        toggleClass(document.querySelector('.user-info'), 'hide', 'add');
        if (avatarApiImgElement) {
            avatarApiImgElement.src = gr.auth.me.avatar;
        }
        toggleClass(document.querySelector('#avatar'), 'hide', 'remove');
    }
}
