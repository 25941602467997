var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"stunning-animated-icons pd-left-lv3 pd-right-lv3\">\n  <div class=\"stunning-animated-icons__container\">\n\n    <div class=\"stunning-animated-icons__text\">\n      <h5 class=\"font-h5\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"stunning") : stack1), depth0))
    + "</h5>\n      <p class=\"mg-bottom-lv4\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"highQuality") : stack1), depth0))
    + "</p>\n      <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"animatedIconsMostDownloadedUrl") : stack1), depth0))
    + "\" class=\"bj-button bj-button--md bj-button--dark-blue\">\n        <span class=\"\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"explore") : stack1), depth0))
    + "</span>\n      </a>\n    </div>\n\n    <div class=\"stunning-animated-icons__video\">\n      <video  class=\"lzy image-overflow-croped\" width=\"720\" height=\"auto\"  preload=\"none\" style=\"background: transparent  url('"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "animated-icon-detail/animation.jpg') 50% 50% / fit no-repeat;\" autoplay=\"autoplay\" loop=\"true\" muted=\"muted\" playsinline >\n        <source src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\" data-src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "animated-icon-detail/animation.mp4\" src-set=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "animated-icon-detail/animation.mp4\" type=\"video/mp4\">\n      \n    </div>\n\n  </div>\n</div>";
},"useData":true});