var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <section class=\"doubts-support\">\n        <div class=\"container\">\n\n            <div class=\"doubts-support--doubts\">\n                <h6 class=\"font-md bold mg-bottom-lv2\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"doubts") : stack1), depth0)) != null ? stack1 : "")
    + "</h6>\n                <p class=\"font-sm\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"doubts_1") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n    \n            </div>\n            <div class=\"doubts-support--support\">\n                <h6 class=\"font-md bold mg-bottom-lv2\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"support") : stack1), depth0)) != null ? stack1 : "")
    + "</h6>\n                <p class=\"font-sm\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"support_1") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n            </div>\n        </div>\n\n    </section>";
},"useData":true});