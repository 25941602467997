declare global {
    interface Window {
        reloadAsyncPage: (e: PopStateEvent) => void;
    }
}

export const isResourcePage = (): boolean => {
    const state = history.state;

    return state && state.page === 'icon';
};

export const modalIsOpened = (): boolean => {
    return !!document.querySelector('#detail-overlay.loading');
};

export const isHashedUrl = (): boolean => {
    return document.location.toString().slice(-1) === '#';
};

/** Is necessary link the function to windows to be able to remove event listener in any context  */
window.reloadAsyncPage = function(e) {
    if (isHashedUrl()) {
        suspendReloadAsyncPage();
        history.go(-1); // Evitamos que se guarden en el historial la url son un hash vacío
        return;
    }

    if (modalIsOpened() && !isResourcePage()) {
        return; /** delegate closure modal to modal_detail.history.js */
    }

    if (!Boolean(e.state)) {
        return;
    }

    document.location.reload();
};

export function suspendReloadAsyncPage() {
    disableReloadAsyncPage();
    window.addEventListener('popstate', () => enableReloadAsyncPage(), { once: true });
}

export function enableReloadAsyncPage() {
    window.addEventListener('popstate', window.reloadAsyncPage, true);
}

export function disableReloadAsyncPage() {
    window.removeEventListener('popstate', window.reloadAsyncPage, true);
}
