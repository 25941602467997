import 'Variables';

import { qq } from 'bobjoll/ts/library/dom';
import { localStorage } from 'bobjoll/ts/library/storage';
import getXHR from 'Partials/xhr';

export class Currency {
    private static readonly api = `${BASE_URL}usercur`;
    private static readonly currency = '$';
    private static readonly currency_name = 'USD';
    private static readonly namespace = 'settings';
    private static readonly storage = localStorage;
    private static readonly globalVariables = ['BASIC_PRICE', 'MONTHLY_PRICE', 'YEARLY_PRICE'];

    private static request: Promise<response> | false = false;

    private static getStorage(): response | void {
        const data = {
            code: atob(Currency.storage.getItem(Currency.namespace, 'coc') || ''),
            cur: atob(Currency.storage.getItem(Currency.namespace, 'c') || ''),
        };

        if (('EURO' === data.cur || 'DOLLAR' === data.cur) && data.code != '') {
            return {
                cur: data.cur,
                code: data.code,
            };
        }
    }

    private static async requestAsync(): Promise<response> {
        return new Promise(async (resolve, reject) => {
            const response = await getXHR(Currency.api, {
                method: 'POST',
            });

            setTimeout(() => reject('Request timeout'), 10000);

            return resolve(response);
        });
    }

    private static async requestAsyncCountry(): Promise<countryDictionary | void> {
        try {
            let response: CurrencyRequestResponse | void = Currency.getStorage();

            if (!response) {
                if (!Currency.request) {
                    Currency.request = Currency.requestAsync();
                }

                if (Currency.request) {
                    response = await Currency.request;
                }
            }

            if (response) {
                return Currency.requestCountryCallback(response);
            }
        } catch (err) {
            console.error(`Failed to retrieve user country settings. ${err}`);
            return Currency.requestCountryCallback({ cur: 'DOLLAR', code: 'US' });
        }
    }

    private static async requestAsyncCurrency(): Promise<currencyDictionary | void> {
        try {
            let response: response | void = Currency.getStorage();

            if (!response) {
                if (!Currency.request) {
                    Currency.request = Currency.requestAsync();
                }

                if (Currency.request) {
                    response = await Currency.request;
                }
            }

            if ('object' === typeof response) {
                return Currency.requestCurrencyCallback(response);
            }
        } catch (err) {
            console.error(`Failed to retrieve user currency settings. ${err}`);
            return Currency.requestCurrencyCallback({ cur: 'DOLLAR', code: 'US' });
        }
    }

    private static requestCurrencyCallback(response: response): currencyDictionary {
        let currency = {
            LOCAL: 'en-US',
            CURRENCY: Currency.currency,
            CURRENCY_NAME: Currency.currency_name,
        };

        Currency.storage.setItem(Currency.namespace, 'c', btoa(response.cur));
        Currency.storage.setItem(Currency.namespace, 'coc', btoa(response.code));

        if ('EURO' === response.cur) {
            currency = {
                LOCAL: 'es-ES',
                CURRENCY: '€',
                CURRENCY_NAME: 'EUR',
            };
        }
        return currency;
    }

    private static requestCountryCallback(response: response): countryDictionary | void {
        if (response.code) {
            Currency.storage.setItem(Currency.namespace, 'coc', btoa(response.code));
            return {
                COUNTRY_CODE: response.code,
            };
        }
    }

    public static requestCurrency(): currencyDictionary | void {
        let response: response | void = Currency.getStorage();

        if (response) {
            return Currency.requestCurrencyCallback(response);
        }
    }

    public static async updateDomPrices() {
        const currency = await Currency.requestAsyncCurrency();

        if (currency) {
            document.body.classList.add(`currency-${'EUR' === currency.CURRENCY_NAME ? 'euro' : 'dollar'}`);

            qq('.price').forEach(price => {
                const number = Currency.format(price.innerHTML, currency);
                const matches = price.innerHTML.match(/(\$)?[0-9]+,?.?[0-9]+(\sUSD)?/gi);

                if (matches && matches.length) {
                    matches.forEach(match => (price.innerHTML = price.innerHTML.replace(match, number)));
                }
            });
        }
    }

    public static format(str: string, currency: currencyDictionary, decimal: number = 2) {
        if ('number' !== typeof decimal) {
            decimal = 2;
        }
        const numberRegExp = new RegExp(/([0-9]+,?.?[0-9]+)/g);
        const numberString = ((str.match(numberRegExp) || []).shift() || '').replace(',', '.');
        const numberContainsName = str.match(/USD/gi) ? true : false;

        if (numberString) {
            const number = parseFloat(numberString)
                .toFixed(decimal)
                .replace('.00', '');

            let numberStringFormated;

            if ('EUR' === currency.CURRENCY_NAME) {
                numberStringFormated = number + ' ' + currency.CURRENCY;
            } else {
                numberStringFormated = currency.CURRENCY + number;

                if (numberContainsName) {
                    numberStringFormated += ` ${currency.CURRENCY_NAME}`;
                }
            }

            if (LANGUAGE_SHORT != 'en') {
                numberStringFormated = numberStringFormated.replace(/\./gi, ',');
            }

            return numberStringFormated;
        }

        return str;
    }

    public static async get(): Promise<currencyDictionary | void> {
        const currency = await Currency.requestAsyncCurrency();
        if (currency) {
            const variables = Currency.globalVariables.reduce(
                function(acc: { [name: string]: any }, variable: string) {
                    if ((window as any)[variable]) {
                        acc[variable] = Currency.format((window as any)[variable], currency);
                    }

                    return acc;
                },
                { ...currency },
            );

            return variables as currencyDictionary;
        }
    }

    public static async getCountry(): Promise<string | void> {
        const response = await Currency.requestAsyncCountry();

        if (response) {
            return response.COUNTRY_CODE;
        }
    }

    public static clear() {
        Currency.request = false;
    }
}

type currency = 'EURO' | 'DOLLAR';

type CurrencyRequestResponse = response | string;

interface response {
    cur: currency;
    code: string;
}

interface currencyDictionary {
    LOCAL: string;
    CURRENCY: string;
    CURRENCY_NAME: string;
}

interface countryDictionary {
    COUNTRY_CODE: string;
}

interface currencyVariablesDictionary extends currencyDictionary {
    BASIC_PRICE: string;
    MONTHLY_PRICE: string;
    YEARLY_PRICE: string;
}
