function isHangulWord(str: string) {
    const match = str.match(/[\u3131-\uD79D]/giu);
    return match ? match.length === str.length : false;
}

function isCyrillicWord(str: string) {
    return /^[\u0400-\u04FF ]+$/.test(str);
}

function filterText(str: string) {
    if (typeof str != 'undefined') {
        str = str.replace(/^\s+|\s+$|\(\)\'/g, '').toLowerCase();
        const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/,:;';
        const to = 'aaaaaeeeeeiiiiooooouuuunc-----';
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        return str
            .replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');
    }
    return str;
}

function filterSpecialChars(str: string) {
    return str.replace(/[\*\%\?\-\+\/\}\{_\:\,;\.<>\&\#\"@\+\=\~]+/g, '');
}

export const textHelpers = {
    isCyrillicWord,
    isHangulWord,
    filterText,
    filterSpecialChars,
};
