var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li  data-ga_params=\"contextmenu,click,"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"ga") : stack1)) != null ? lookupProperty(stack1,"download_png") : stack1), depth0))
    + "\" class=\"track GA_CM_download-png tooltip tooltip--left\" data-track-arguments=\"ga, event, contextmenu, click, download-png\">\n    <button class=\"tooltip__trigger tooltip__trigger--always bj-button bj-button--sm bj-button--green bj-button--icon\" onclick=\"Downloads.direct_download_icon_post(event, 'png');\"><i class=\"icon icon--download\"></i></button>\n    <div class=\"tooltip__content tooltip__content--permanent\">\n        <div class=\"content\">\n            <span>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"download_png") : stack1), depth0))
    + "</span>\n        </div>\n    </div>\n</li>";
},"useData":true});