var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "uicon";
},"3":function(container,depth0,helpers,partials,data) {
    return "bg-white";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"editButtonComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"editUiconButtonComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"isOr")||(depth0 && lookupProperty(depth0,"isOr"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"family") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),"362",((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"family") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),"363",{"name":"isOr","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":128},"end":{"line":21,"column":219}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "sticker--shadow";
},"12":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"tagsComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section id=\"detail\" class=\"detail new-detail "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","uicon",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":106}}})) != null ? stack1 : "")
    + "\" data-elementId=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-icon_type=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\">\n	<div class=\"detail__inner detail--v2 gallery\">\n		<div class=\"row detail__top mg-none\">\n			<section class=\"detail__content col col--stretch mg-none pd-none "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceTypes") : depth0)) != null ? lookupProperty(stack1,"animatedIcon") : stack1),{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":68},"end":{"line":4,"column":151}}})) != null ? stack1 : "")
    + "\"> \n				<div class=\"detail__content__inner row row--horizontal-center mg-none\">\n					<div class=\"fullwidth detail__icon__holder\">\n						<div class=\"detail__icon__holder--header row row--space-between row--vertical-center mg-none\">\n							<div class=\"row row--vertical-center full-width mg-none pd-lv2\">\n								"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"savePinterestComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"!=","uicon",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":20}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","uicon",{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":15,"column":20}}})) != null ? stack1 : "")
    + "								<i id=\"detail-close-mobile\" class=\"close-detail icon icon--cross\"></i>\n							</div>\n						</div>\n						<i id=\"detail-prev-mobile\" class=\"icon icon--prev detail__nav--prev\"></i>\n						<i id=\"detail-next-mobile\" class=\"icon icon--next detail__nav--next\"></i>\n						<div class=\"row row--vertical-center mg-none full-height detail__icon__inner "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","sticker",{"name":"compare","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":83},"end":{"line":21,"column":231}}})) != null ? stack1 : "")
    + "\">\n							<div class=\"col mg-none\">\n								"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"resourcePreviewComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n							</div>\n						</div>\n						"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"detailStylesComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n					</div>\n					"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"authorSearchComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				</div>\n			</section>\n			\n			<aside class=\"detail__sidebar col--stretch\"> \n				"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"bannerComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"downloadComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"licenseAndAttributionComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"authorComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				<div class=\"pd-top-lv3 more-icons font-sm "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceTypes") : depth0)) != null ? lookupProperty(stack1,"uicon") : stack1),{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":46},"end":{"line":37,"column":119}}})) != null ? stack1 : "")
    + "\">\n					"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"packComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n					"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"styleComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				</div>\n				<div class=\"group group-date hidden\">\n					"
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"published") : stack1), depth0))
    + ":\n					<span class=\"publish_date\"><strong>"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"published") : stack1), depth0))
    + "</strong></span>\n				</div>\n			</aside>\n		</div>\n		\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","uicon",{"name":"compare","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":2},"end":{"line":50,"column":14}}})) != null ? stack1 : "")
    + "\n		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"relatedUiconsComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n		\n		<div class=\"pd-left-lv3 pd-right-lv3\">\n			"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"sponsorAdobeComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n		</div>\n\n		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"googleAdsComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n		\n		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"relatedTagsComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"relatedPacksComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"relatedStylesComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"animatedIconInActionComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"iconFontInActionComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"!=","uicon",{"name":"compare","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":2},"end":{"line":67,"column":14}}})) != null ? stack1 : "")
    + "		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"findBestIconFontComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"stunningAnimatedIconsComponent") : stack1), depth0)) != null ? stack1 : "")
    + "		\n		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"freepikSponsorComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n		"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"iconFontDoubtsSupportComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n		\n		<form id=\"download-form\" method=\"get\" action=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"FLATICON_DOWNLOAD_URL") : stack1), depth0))
    + "download/icon/"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"> \n			<input type=\"hidden\" id=\"icon_id\" name=\"icon_id\" value=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"author\" name=\"author\" value=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"team\" name=\"team\" value=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"keyword\" name=\"keyword\" value=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"pack\" name=\"pack\" value=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"pack") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"style\" name=\"style\" value=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"style_id\" name=\"style_id\" value=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"format\" name=\"format\" />\n			<input type=\"hidden\" id=\"color\" name=\"color\" />\n			<input type=\"hidden\" id=\"colored\" name=\"colored\" value=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"size\" name=\"size\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"initialDownloadSize") || (depth0 != null ? lookupProperty(depth0,"initialDownloadSize") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"initialDownloadSize","hash":{},"data":data,"loc":{"start":{"line":84,"column":53},"end":{"line":84,"column":76}}}) : helper)))
    + "\"/>\n			<input type=\"hidden\" id=\"selection\" name=\"selection\" value=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"selection") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"type\" name=\"type\" value=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\" />\n		</form>\n	</div>\n</section>\n";
},"useData":true});