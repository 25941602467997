var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"find-best-icon-font\">\n    <div class=\"container\">\n        <div class=\"find-best-icon-font--content\">\n            <div class=\"find-best-icon-font--content--text\">\n                <span class=\"mg-none font-lg\">"
    + ((stack1 = (lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"find_best_1") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":5,"column":46},"end":{"line":5,"column":111}}})) != null ? stack1 : "")
    + "</span>\n                <p class=\"mg-none font-lg\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"find_best_2") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n            </div>\n            <a href=\""
    + container.escapeExpression(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"see_all_icons_url") : stack1), depth0))
    + "\" class=\"bj-button bj-button--green\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"find_best_see_all") : stack1), depth0)) != null ? stack1 : "")
    + "</a>\n        </div>\n    </div>\n</section>";
},"useData":true});