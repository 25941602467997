import { ww } from 'bobjoll/ts/library/dom';
import { cookie } from 'Library/cookie';
import { emptyElement, removeElement, toggleClass } from 'Library/helpers';
import Scroll from 'Library/scroll';
import { Uicons } from 'Pods/uicons/index';
import { getUicons } from 'Pods/uicons/serviceUi';
import { buildUrlParams } from 'Pods/uicons/uicons.helpers';
import {
    PaginationEventOptions,
    SearchOptions,
    UIcons,
    UiconsPaginationMeta,
    UIconsResponse,
} from 'Pods/uicons/uicons.vm';
import { getUrlParam, setUrlNavigator, updateUrlState } from 'Project/ts/common/urls.helper';
import User from 'Project/ts/pods/user/main';
import Lazyload from 'UI/lazyload';

export class SearchBarUi {
    public static inputSearch = document.querySelector('.uicons__selector input[type=search]') as HTMLInputElement;
    public static cleanSearchButton = document.querySelector(
        '.uicons__search .uicons__clean-search',
    ) as HTMLButtonElement;
    private static spinner: string = '<div id="detail-spinner" class="circle-spinner"></div>';
    private static uIconsResultUl: HTMLUListElement | null = document.querySelector('.uicons--results');
    private static searchIcon: HTMLButtonElement | null = document.querySelector(
        '.uicons__search .uicons__selector__group-search .search-button',
    );
    private static paginationBar: HTMLElement | null = document.querySelector('.pagination-bar');
    private static paginationBarLeftButttons: HTMLElement | null =
        SearchBarUi.paginationBar &&
        SearchBarUi.paginationBar.querySelector('.pagination-left-buttons .pagination-buttons');
    private static paginationBarRightButtons: HTMLElement | null =
        SearchBarUi.paginationBar && SearchBarUi.paginationBar.querySelector('.pagination-right-buttons');

    public static init() {
        this.handleEventListener();

        this.uIconsResultUl = document.querySelector('.uicons-list');

        this.handleToggleFilters();
        const searchFiltersInput = document.getElementById('search__filters') as HTMLInputElement | null;

        if (searchFiltersInput) {
            searchFiltersInput.checked === true ? cookie.setItem('fo', '1') : cookie.setItem('fo', '0');
        }
    }

    private static handleEventListener() {
        document
            .querySelectorAll('.uicons__search input[data-filter-value]')
            .forEach((filterElement: HTMLInputElement) => {
                filterElement.removeEventListener('change', this.filtersInputListenerHandler);
            });

        document
            .querySelectorAll('.uicons__search input[data-filter-value]')
            .forEach((filterElement: HTMLInputElement) => {
                filterElement.addEventListener('change', this.filtersInputListenerHandler);
            });

        document.querySelectorAll('.filterLabel-tag')?.forEach((filterTag: HTMLInputElement) => {
            if (filterTag.dataset.filter) {
                this.handleFiltersLabelEvents(filterTag.dataset.filter);
            }
        });

        if (document.querySelector('.filterLabels .clear-all')) {
            this.handleClearAllFiltersEvent();
        }
    }

    private static handleToggleFilters = () => {
        document.getElementById('search__filters')?.addEventListener('change', e => {
            const inputCheckBox = e.currentTarget as HTMLInputElement;
            if (inputCheckBox.checked === true) {
                cookie.setItem('fo', '1');
            } else {
                cookie.setItem('fo', '0');
            }
            inputCheckBox.classList.add('show-filters-in-mobile');
        });
    };

    private static filtersInputListenerHandler(event: Event) {
        const inputElementTarget = event.target as HTMLInputElement;
        INFINITY.currentPage = 1;
        SearchBarUi.searchBarActions();

        if (inputElementTarget.checked && inputElementTarget.dataset.filterValue) {
            SearchBarUi.renderFiltersLabels(inputElementTarget.dataset.filterValue);
        }

        if (!inputElementTarget.checked && inputElementTarget.dataset.filterValue) {
            SearchBarUi.removeFiltersLabelById(inputElementTarget.dataset.filterValue);
        }

        if (inputElementTarget.dataset.filterType) {
            SearchBarUi.setCollapsedFilters(inputElementTarget.dataset.filterType);
        }
    }

    private static setCollapsedFilters = (filterType: string): void => {
        const inputsByFilterType = Array.from(document.querySelectorAll(`.uicons-filter input[name=${filterType}]`));
        const collapsedItemSpan = document.querySelector(`.collapsed-filters__item.${filterType}`);
        if (!collapsedItemSpan) {
            return;
        }
        if (inputsByFilterType.some((input: HTMLInputElement) => input.checked === true)) {
            collapsedItemSpan.classList.add('active');
        } else {
            collapsedItemSpan.classList.remove('active');
        }
    };

    private static searchBarActions() {
        let inputSearchValue: string, searchValue: string;

        inputSearchValue = getUrlParam('word') || '';

        this.inputSearch = document.querySelector('#header--search input[type="search"]') as HTMLInputElement;

        searchValue = inputSearchValue ? inputSearchValue : this.inputSearch.value ? this.inputSearch.value : '';

        const brandsFilterElement = document.querySelector('.uicons__search input[name="brands"]:checked');
        let weightFilters: string[] = [];
        let cornerFilters: string[] = [];

        document
            .querySelectorAll('.uicons__search input[name="weight"]:checked')
            .forEach((weightFilterElement: HTMLInputElement) => {
                if (weightFilterElement.dataset.filterValue) {
                    weightFilters.push(weightFilterElement.dataset.filterValue);
                }
            });

        document
            .querySelectorAll('.uicons__search input[name="corner"]:checked')
            .forEach((cornerFilterElement: HTMLInputElement) => {
                if (cornerFilterElement.dataset.filterValue) {
                    cornerFilters.push(cornerFilterElement.dataset.filterValue);
                }
            });

        Uicons.currentUiconsPrefix = this.getUiconPrefixFilter();

        ww.view.section === 'uicons-tag' && inputSearchValue
            ? this.redirect(
                  buildUrlParams(
                      inputSearchValue,
                      weightFilters.length ? `${weightFilters.join('+')}` : '',
                      cornerFilters.length ? `${cornerFilters.join('+')}` : '',
                      brandsFilterElement ? '1' : '',
                  ),
              )
            : this.searchUiAjax({
                  searchTarget: searchValue,
                  weightFilterValue: weightFilters.length ? `${weightFilters.join('+')}` : '',
                  cornerFilterValue: cornerFilters.length ? `${cornerFilters.join('+')}` : '',
                  brandsFilterValue: brandsFilterElement ? '1' : '',
              });
    }

    private static redirect(url: string) {
        location.href = `${urls.uicons.urlSearchParams}?${url}`;
    }

    private static urlQuery(
        searchTerm?: string,
        weightFilterValue?: string,
        cornerFilterValue?: string,
        brandFilterValue?: string,
    ) {
        const filterParams = {
            word: searchTerm ? searchTerm : '',
            weight: weightFilterValue ? weightFilterValue : '',
            corner: cornerFilterValue ? cornerFilterValue : '',
            brands: brandFilterValue ? brandFilterValue : '',
        };

        let arrayFinal: string[] = [];

        Object.entries(filterParams).forEach(array => {
            if (array[1]) {
                arrayFinal.push(`${array[0]}=${array[1]}`);
            }
        });

        if (arrayFinal.length) {
            return `?${arrayFinal.join('&')}&type=uicon`;
        }

        return document.location.pathname;
    }

    private static searchUiAjax(searchOptions: SearchOptions) {
        getUicons(searchOptions)
            .then(response => {
                this.paginationBar && toggleClass(this.paginationBar, 'hide', 'remove');

                const areFiltersIn404View = document.querySelector('.empty-search');

                if (response.items.length > 0) {
                    if (areFiltersIn404View) {
                        ww.location.href = SearchBarUi.urlQuery(
                            searchOptions.searchTarget,
                            searchOptions.weightFilterValue,
                            searchOptions.cornerFilterValue,
                            searchOptions.brandsFilterValue,
                        );
                    } else {
                        if (this.uIconsResultUl) this.uIconsResultUl.innerHTML = '';

                        this.renderListUiconsOnSearch(response.items);
                        ww.Resource.handleResourceListener();
                    }
                } else {
                    (ww.location.href = SearchBarUi.urlQuery(
                        searchOptions.searchTarget,
                        searchOptions.weightFilterValue,
                        searchOptions.cornerFilterValue,
                        searchOptions.brandsFilterValue,
                    )),
                        'uicon new search',
                        'update url search';

                    emptyElement(this.uIconsResultUl);
                    emptyElement(document.querySelector('.uicons_pagination .buttons-box'));
                    this.paginationBar && toggleClass(this.paginationBar, 'hide', 'add');
                }

                INFINITY.initPage = 1;
                INFINITY.total = response.total;
                INFINITY.pages = response.pages;
                INFINITY.pagination_update();
                INFINITY.check_pagination_buttons();

                Lazyload.run();

                setUrlNavigator(window.location.href.replace(/[0-9]+/g, ''), '', '');
                searchOptions.pagination && searchOptions.paginationTarget
                    ? updateUrlState(searchOptions.paginationTarget, 'uicon pagination', 'update url search')
                    : updateUrlState(
                          SearchBarUi.urlQuery(
                              searchOptions.searchTarget,
                              searchOptions.weightFilterValue,
                              searchOptions.cornerFilterValue,
                              searchOptions.brandsFilterValue,
                          ),
                          'uicon new search',
                          'update url search',
                      );
            })
            .catch(err => {
                if (err) {
                    User.alert(
                        'notification-copyClipboard',
                        messages.uicons.error_search,
                        'error',
                        'bottom-left',
                        4500,
                    );
                }
            });
    }

    private static renderListUiconsOnSearch(uIconsList: UIcons[] | UIconsResponse[]) {
        uIconsList.forEach((liElement: UIcons | UIconsResponse): void => {
            if (!this.uIconsResultUl) {
                return;
            }

            liElement = liElement as UIconsResponse;
            this.uIconsResultUl.insertAdjacentHTML(
                'beforeend',
                `<li class="icon--item" data-id="${liElement.id}" data-name="${liElement.name}" data-tags="${liElement.tags}" data-pack_id="${liElement.packId}" data-team_id="${liElement.team_id}" data-family_name="${liElement.familyName}" data-style="${liElement.styleId}" data-style_name="${liElement.styleName}" data-prefix="${liElement.prefix}" data-svg="${liElement.svg}" data-class="${liElement.class}" data-detail-url="${liElement.detailUrl}" data-license="${liElement.license}" data-icon_type="uicon">
                            <div class="icon--holder">
                                <a href="${liElement.slug}" class="track view link-icon-detail" data-track-arguments="ga, event, uicons, ${liElement.familyName}-${liElement.styleName}, ${liElement.name}" data-id="${liElement.id}" title="${liElement.name}" data-src="${liElement.queryParams}">
                                    <img src="${URL_STATIC_IMG}loader.gif"  data-src="${liElement.png}" width="26" class="lzy">
                                </a>
                                <div class="overlay" aria-hidden="true"> </div>
                            </div>
                        </li>`,
            );
        });
    }

    private static uIconspagination(uiconsPaginationInfo?: UiconsPaginationMeta) {
        const paginationBtnPrevElement: HTMLAnchorElement | null =
            this.paginationBarLeftButttons && this.paginationBarLeftButttons.querySelector('.pagination-prev');
        const paginationBtnNextElement: HTMLAnchorElement | null =
            this.paginationBarLeftButttons && this.paginationBarLeftButttons.querySelector('.pagination-next');
        const paginationSmBtnPrevElement: HTMLAnchorElement | null =
            this.paginationBarRightButtons && this.paginationBarRightButtons.querySelector('.pagination-prev');
        const paginationSmBtnNextElement: HTMLAnchorElement | null =
            this.paginationBarRightButtons && this.paginationBarRightButtons.querySelector('.pagination-next');
        const paginationInputElement: HTMLInputElement | null =
            this.paginationBarRightButtons && this.paginationBarRightButtons.querySelector('#pagination-page');
        const paginationTotalElement: HTMLSpanElement | null =
            this.paginationBarRightButtons && this.paginationBarRightButtons.querySelector('#pagination-total');
        const PaginationOptions = {
            prev: 'prev',
            next: 'next',
            base: 'base',
        };

        if (uiconsPaginationInfo) {
            this.renderUIconsPaginationButtons(
                paginationBtnPrevElement,
                paginationBtnNextElement,
                paginationSmBtnPrevElement,
                paginationSmBtnNextElement,
                uiconsPaginationInfo,
            );

            paginationBtnPrevElement &&
                this.setPaginationElementInfo(uiconsPaginationInfo, paginationBtnPrevElement, PaginationOptions.prev);
            paginationSmBtnPrevElement &&
                this.setPaginationElementInfo(uiconsPaginationInfo, paginationSmBtnPrevElement, PaginationOptions.prev);
            paginationBtnNextElement &&
                this.setPaginationElementInfo(uiconsPaginationInfo, paginationBtnNextElement, PaginationOptions.next);
            paginationSmBtnNextElement &&
                this.setPaginationElementInfo(uiconsPaginationInfo, paginationSmBtnNextElement, PaginationOptions.next);
            paginationInputElement &&
                this.setPaginationElementInfo(uiconsPaginationInfo, paginationInputElement, PaginationOptions.base);

            if (paginationTotalElement) {
                paginationTotalElement.innerText = uiconsPaginationInfo.totalPages.toString();
            }
        }
    }

    private static setPaginationElementInfo(
        uiconsPaginationInfo: UiconsPaginationMeta,
        paginationElement: HTMLAnchorElement | HTMLInputElement,
        paginationOption: string,
    ) {
        const paginationUrlOptions: Record<string, string> = {
            prev: uiconsPaginationInfo.previousUrl ? uiconsPaginationInfo.previousUrl : '',
            next: uiconsPaginationInfo.nextUrl,
            base: uiconsPaginationInfo.baseUrl,
        };
        const paginationAjaxUrlOptions: Record<string, string> = {
            prev: uiconsPaginationInfo.previousAjaxUrl ? uiconsPaginationInfo.previousAjaxUrl : '',
            next: uiconsPaginationInfo.nextAjaxUrl,
            base: uiconsPaginationInfo.baseAjaxUrl,
        };

        paginationElement.dataset.ajaxUrl = paginationAjaxUrlOptions[paginationOption];
        paginationElement.dataset.url = paginationUrlOptions[paginationOption];

        if (paginationElement instanceof HTMLAnchorElement && uiconsPaginationInfo.previousUrl) {
            paginationElement.href = paginationUrlOptions[paginationOption];
        }

        if (paginationElement instanceof HTMLInputElement) {
            paginationElement.value = uiconsPaginationInfo.currentPage.toString();
            paginationElement.dataset.totalPages = uiconsPaginationInfo.totalPages.toString();
            paginationElement.dataset.firstAjaxUrl = uiconsPaginationInfo.firstAjaxUrl;
            paginationElement.dataset.firstUrl = uiconsPaginationInfo.firstUrl;
        }
    }

    private static renderUIconsPaginationButtons(
        paginationBtnPrevElement: HTMLAnchorElement | null,
        paginationBtnNextElement: HTMLAnchorElement | null,
        paginationSmBtnPrevElement: HTMLAnchorElement | null,
        paginationSmBtnNextElement: HTMLAnchorElement | null,
        uiconsPaginationInfo: UiconsPaginationMeta,
    ) {
        const uipaginationBtnPrevElement = uiconsPaginationInfo.previousPage
            ? `
            <a class="uicons_pagination-btn pagination-prev bj-button bj-button--secondary bj-button--outline" href=${uiconsPaginationInfo.previousUrl} data-ajax-url=${uiconsPaginationInfo.previousAjaxUrl} data-url=${uiconsPaginationInfo.previousUrl}>
                <i class="icon icon--md icon--left"></i>
                <span>${uiconsPaginationInfo.previousText}</span>
            </a>`
            : '';

        const uipaginationBtnNextElement = uiconsPaginationInfo.nextPage
            ? `
            <a class="uicons_pagination-btn pagination-next bj-button bj-button--primary" href=${uiconsPaginationInfo.nextUrl} data-ajax-url=${uiconsPaginationInfo.nextAjaxUrl} data-url=${uiconsPaginationInfo.nextUrl}>
                <span>${uiconsPaginationInfo.nextText}</span>
                <i class="icon icon--md icon--right"></i>
            </a>`
            : '';

        if (uiconsPaginationInfo.previousPage && !paginationBtnPrevElement) {
            this.paginationBarLeftButttons?.insertAdjacentHTML('afterbegin', uipaginationBtnPrevElement);
            SearchBarUi.handlePaginationEvents(
                { allPaginationButton: false, paginationButton: true, paginationInput: false },
                this.paginationBarLeftButttons?.querySelector('.pagination-prev') as HTMLAnchorElement,
            );
        }

        if (!uiconsPaginationInfo.previousPage && paginationBtnPrevElement) {
            removeElement(paginationBtnPrevElement);
        }

        if (uiconsPaginationInfo.nextPage && !paginationBtnNextElement) {
            this.paginationBarLeftButttons?.insertAdjacentHTML('beforeend', uipaginationBtnNextElement);
            SearchBarUi.handlePaginationEvents(
                { allPaginationButton: false, paginationButton: true, paginationInput: false },
                this.paginationBarLeftButttons?.querySelector('.pagination-next') as HTMLAnchorElement,
            );
        }

        if (!uiconsPaginationInfo.nextPage && paginationBtnNextElement) {
            removeElement(paginationBtnNextElement);
        }

        uiconsPaginationInfo.previousPage
            ? toggleClass(paginationSmBtnPrevElement, 'disabled', 'remove')
            : toggleClass(paginationSmBtnPrevElement, 'disabled', 'add');

        uiconsPaginationInfo.nextPage
            ? toggleClass(paginationSmBtnNextElement, 'disabled', 'remove')
            : toggleClass(paginationSmBtnNextElement, 'disabled', 'add');
    }

    public static handlePaginationEvents(
        paginationEventOptions: PaginationEventOptions,
        paginationBtnElement?: HTMLAnchorElement,
    ) {
        const allPaginationButton =
            this.paginationBar && this.paginationBar.querySelectorAll('a.uicons_pagination-btn');
        const paginationInputElement: HTMLInputElement | null =
            this.paginationBarRightButtons && this.paginationBarRightButtons.querySelector('#pagination-page');

        if (paginationEventOptions.allPaginationButton) {
            allPaginationButton?.forEach((paginationButtonElement: HTMLAnchorElement) => {
                paginationButtonElement.onclick = () => {
                    this.searchOnPagination({
                        searchTarget: paginationButtonElement.dataset.ajaxUrl
                            ? paginationButtonElement.dataset.ajaxUrl
                            : '',
                        pagination: true,
                        paginationTarget: paginationButtonElement.dataset.url
                            ? paginationButtonElement.dataset.url
                            : '',
                    });
                    return false;
                };
            });
        }

        if (paginationEventOptions.paginationButton && paginationBtnElement) {
            paginationBtnElement.onclick = () => {
                this.searchOnPagination({
                    searchTarget: paginationBtnElement.dataset.ajaxUrl ? paginationBtnElement.dataset.ajaxUrl : '',
                    pagination: true,
                    paginationTarget: paginationBtnElement.dataset.url ? paginationBtnElement.dataset.url : '',
                });
                return false;
            };
        }

        if (paginationEventOptions.paginationInput) {
            paginationInputElement?.addEventListener('change', function() {
                if (this.dataset.totalPages && parseInt(this.value) <= parseInt(this.dataset.totalPages)) {
                    if (
                        this.dataset.firstAjaxUrl &&
                        this.dataset.ajaxUrl &&
                        this.dataset.firstUrl &&
                        this.dataset.url
                    ) {
                        SearchBarUi.searchOnPagination({
                            searchTarget:
                                parseInt(this.value) <= 1
                                    ? this.dataset.firstAjaxUrl
                                    : this.dataset.ajaxUrl.replace(/[\[\]']+/g, this.value),
                            pagination: true,
                            paginationTarget:
                                parseInt(this.value) <= 1
                                    ? this.dataset.firstUrl
                                    : this.dataset.url.replace(/[\[\]']+/g, this.value),
                        });
                    }
                } else {
                    User.alert('notification-search', messages.search.max_page_number, 'warning', 'bottom-left', 4500);
                }
            });
        }
    }

    private static searchOnPagination(searchOptions: SearchOptions) {
        SearchBarUi.searchUiAjax(searchOptions);
        Scroll.to(0, 0, 700);
    }

    public static getUiconPrefixFilter() {
        const weightFilterElement: HTMLButtonElement | null = document.querySelector(
            '.uicons__search input[name="weight"]:checked',
        );
        const cornerFilterElement: HTMLButtonElement | null = document.querySelector(
            '.uicons__search input[name="corner"]:checked',
        );
        const brandsFilterElement: HTMLButtonElement | null = document.querySelector(
            '.uicons__search input[name="brands"]:checked',
        );

        if (brandsFilterElement) {
            return 'brands';
        } else {
            return `${weightFilterElement?.dataset.prefix ? weightFilterElement.dataset.prefix : 'r'}${
                cornerFilterElement?.dataset.prefix ? cornerFilterElement.dataset.prefix : 'r'
            }`;
        }
    }

    private static renderFiltersLabels(filterElementName: string) {
        const clearAllButtonElement = `<span class="font-sm clear-all mg-left-lv1">${messages.uicons.clear_all}</span>`;
        const filtersLabelsContainerElement: HTMLElement | null = document.querySelector('.filterLabels');

        let filterName = messages.uicons[filterElementName];
        let containerOfNewFilterLabel = '.filterLabels';

        containerOfNewFilterLabel = '.filterLabels__body';
        if (!document.querySelector('.filterLabels .filterLabels__header')) {
            SearchBarUi.renderHeaderFilterApplied();
            SearchBarUi.handleClearAllFiltersEvent();
        }

        document
            .querySelector(containerOfNewFilterLabel)
            ?.insertAdjacentHTML(
                'afterbegin',
                `<span id="${filterElementName}-filterLabel" data-filter="${filterElementName}" class="font-sm filterLabel-tag mg-right-lv1">${filterName}<i class="icon icon--sm icon--cross"></i></span>`,
            );

        if (filtersLabelsContainerElement?.childElementCount === 1) {
            filtersLabelsContainerElement.insertAdjacentHTML('beforeend', clearAllButtonElement);
            this.handleClearAllFiltersEvent();
        }

        this.handleFiltersLabelEvents(filterElementName);
    }

    private static removeFiltersLabelById(filterElementName: string) {
        removeElement(document.getElementById(`${filterElementName}-filterLabel`));

        if (document.querySelectorAll('.filterLabel-tag').length === 0) {
            const filtersAppliedContainer = document.querySelector('.filterLabels') as HTMLElement;
            if (filtersAppliedContainer) {
                SearchBarUi.setEmptyAppliedFiltersContainer(filtersAppliedContainer);
            } else {
                removeElement(document.querySelector('.clear-all'));
            }
        }
    }

    private static renderHeaderFilterApplied = () => {
        const filtersApplied = Array.from(document.querySelectorAll('.uicons__search input:checked'));
        const filtersAppliedContainer = document.querySelector('.filterLabels') as HTMLElement;
        if (!filtersAppliedContainer) {
            return;
        }
        if (filtersApplied.length) {
            filtersAppliedContainer.innerHTML = `
            <div class="filterLabels__header full-width">
                <p class="title font-sm bold text__general--heading mg-bottom-lv2">
                    ${messages.filters.appliedFilters}
                </p>
         
                <span class="font-sm mg-bottom-lv2 clear-all">${messages.filters.clearAll} <i class="icon icon--xs icon--cross mg-left-lv1"></i></span>
                
            </div>
            <div class="filterLabels__body full-width">
            </div>
            <hr class="full-width">
            `;
        } else {
            SearchBarUi.setEmptyAppliedFiltersContainer(filtersAppliedContainer);
        }
    };

    public static handleClearAllFiltersEvent() {
        document.querySelector('.clear-all')?.addEventListener('click', () => {
            document
                .querySelectorAll('.uicons__search input[data-filter-value]')
                .forEach((filterElement: HTMLInputElement) => {
                    filterElement.checked = false;
                });
            const filtersAppliedContainer = document.querySelector('.filterLabels') as HTMLElement;

            if (filtersAppliedContainer) {
                SearchBarUi.setEmptyAppliedFiltersContainer(filtersAppliedContainer);
                const collapsedItems = Array.from(document.querySelectorAll('.collapsed-filters__item'));
                if (collapsedItems) {
                    collapsedItems.forEach(collapsedItem => collapsedItem.classList.remove('active'));
                }
            }

            document.querySelectorAll('.filterLabel-tag').forEach((filterLabelElement: HTMLInputElement) => {
                removeElement(filterLabelElement);
            });

            removeElement(document.querySelector('.clear-all'));

            const wordParam: string | null = getUrlParam('word');
            if (wordParam) {
                updateUrlState(SearchBarUi.urlQuery(wordParam), 'new uicon search', 'update url search');
            }

            SearchBarUi.searchBarActions();
        });
    }

    public static handleFiltersLabelEvents(filterElementName: string) {
        document.getElementById(`${filterElementName}-filterLabel`)?.addEventListener('click', () => {
            const inputFilterElement: HTMLInputElement | null = document.querySelector(
                `.uicons__search input[data-filter-value="${filterElementName}"]`,
            );

            this.removeFiltersLabelById(filterElementName);
            inputFilterElement?.click();
        });
    }
    private static setEmptyAppliedFiltersContainer = (filtersAppliedContainer: HTMLElement) => {
        if (filtersAppliedContainer) {
            filtersAppliedContainer.innerHTML = '';
        }
    };
}

export default SearchBarUi;
