import { ww } from 'bobjoll/ts/library/dom';

export const isFamilyBrands = (uIconsFamily?: string): boolean => {
    const currentUIconsFamily = uIconsFamily && uIconsFamily === 'brands' ? true : false;
    return currentUIconsFamily;
};

export const buildUrlParams = (
    searchTarget: string,
    weightFilter?: string,
    cornerFilter?: string,
    brandsFilter?: string,
) => {
    const searchTerm = `word=${searchTarget}`;
    const weightFilterValue = weightFilter ? `&weight=${weightFilter}` : '';
    const cornerFilterValue = cornerFilter ? `&corner=${cornerFilter}` : '';
    const brandsFilterValue = brandsFilter ? `&brands=${brandsFilter}` : '';
    return `${searchTerm}${weightFilterValue}${cornerFilterValue}${brandsFilterValue}`;
};

type TotalUiconsByStyle = Record<number, string>;

const getTotalUiconsByStyle = (): TotalUiconsByStyle => {
    const totalUiconsByStyle: TotalUiconsByStyle | undefined = ww.TOTAL_UICONS_GROUPED_BY_STYLE;

    if (!totalUiconsByStyle) {
        return {};
    }

    return totalUiconsByStyle;
};

export const getNumberOfUiconsByStyleText = (styleId: string): string | null => {
    const id = parseInt(styleId);
    const text = getTotalUiconsByStyle()[id];

    return text ?? null;
};
