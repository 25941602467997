import { ww } from 'bobjoll/ts/library/dom';
import { ResourceConfig } from 'CommonApp/resource/resource.vm';

const defaultResourceConfig: ResourceConfig = {
    detail: {
        resourceFormat: 'png',
        editor: true,
        editorUicon: false,
        autorSearchBar: true,
        stickerBanner: true,
        downloads: {
            components: 'standardComponent',
            formats: {
                free: {
                    png: {
                        format: 'png',
                        title: messages.detail.download_free_icon_in_png,
                        name: 'PNG',
                        sizes: ['512', '256', '128', '64', '32', '24', '16'],
                        defaultSize: '512',
                    },
                },
                premium: {
                    svg: {
                        format: 'svg',
                        title: messages.detail.download_vector_icon_in_svg,
                        name: 'SVG',
                    },
                },
                others: {
                    eps: {
                        format: 'eps',
                        title: messages.detail.download_vector_icon_in_eps,
                        name: 'EPS',
                        description: '- Encapsulated PostScript Document',
                        premium: true,
                    },
                    psd: {
                        format: 'psd',
                        title: messages.detail.download_vector_icon_in_psd,
                        name: 'PSD',
                        description: '- Adobe Photoshop Document',
                        premium: true,
                    },
                    css: {
                        format: 'base64',
                        title: messages.detail.download_vector_icon_in_base64,
                        name: 'CSS',
                        description: '- Cascading Style Sheet',
                        premium: true,
                    },
                },
            },
        },
        addcollectionBtn: true,
        copyBtn: true,
        copyFormat: 'png',
        authorInfo: true,
        authorLinks: true,
        pack: true,
        styleInfo: true,
        styleLink: true,
        styleBookmark: true,
        sponsorAdobe: true,
        googleAds: true,
        ads: true,
        relatedPackLink: true,
        relatedIconsStyle: true,
        relatedIconsTags: true,
        relatedIconsPack: true,
        resourceTags: true,
    },

    previewResourceList: {
        contextMenu: true,
        copyBtn: true,
        addCollectionBtn: true,
        animatedIcon: false,
        directDownloadBtn: true,
    },

    search: {
        groupBypack: true,
        groupByStyle: true,
        labelForAllResources: messages.common.allIcons,
        resultsList: {
            sponsorAdobe: true,
            ads: true,
        },
    },
};

export const resourceConfigTypes: Record<string, ResourceConfig> = {
    standard: { ...defaultResourceConfig },

    sticker: {
        detail: {
            ...defaultResourceConfig.detail,
            autorSearchBar: false,
            stickerBanner: false,
            addcollectionBtn: false,
            sponsorAdobe: false,
            ads: false,
            relatedIconsStyle: false,
            relatedIconsTags: false,
        },
        previewResourceList: {
            ...defaultResourceConfig.previewResourceList,
            addCollectionBtn: false,
        },
        search: {
            ...defaultResourceConfig.search,
            groupByStyle: true,
            labelForAllResources: messages.common.allStickers,
            resultsList: {
                ...defaultResourceConfig.search.resultsList,
                sponsorAdobe: false,
                ads: false,
            },
        },
    },

    'animated-icon': {
        detail: {
            ...defaultResourceConfig.detail,
            resourceFormat: 'mp4',
            autorSearchBar: false,
            stickerBanner: false,
            downloads: {
                ...defaultResourceConfig.detail.downloads,
                components: 'extendedComponent',
                formats: {
                    free: {
                        gif: {
                            format: 'gif',
                            title: messages.detail.download_free_icon_in_png,
                            name: 'GIF',
                        },
                        mp4: {
                            format: 'mp4',
                            title: messages.detail.download_free_icon_in_png,
                            name: 'MP4',
                        },
                        png: {
                            format: 'png',
                            title: messages.detail.download_free_icon_in_png,
                            name: 'PNG',
                            sizes: ['512'],
                        },
                    },
                    premium: {
                        aep: {
                            format: 'aep',
                            title: messages.detail.download_vector_icon_in_svg,
                            name: 'AEP',
                        },
                        json: {
                            format: 'lottie',
                            title: messages.detail.download_vector_icon_in_svg,
                            name: 'JSON',
                        },
                        svg: {
                            format: 'svg',
                            title: messages.detail.download_vector_icon_in_svg,
                            name: 'SVG',
                        },
                        eps: {
                            format: 'eps',
                            title: messages.detail.download_vector_icon_in_svg,
                            name: 'EPS',
                        },
                        psd: {
                            format: 'psd',
                            title: messages.detail.download_vector_icon_in_svg,
                            name: 'PSD',
                        },
                    },
                },
            },
            addcollectionBtn: false,
            authorLinks: false,
            pack: false,
            styleLink: false,
            styleBookmark: false,
            sponsorAdobe: false,
            ads: false,
            relatedPackLink: false,
            relatedIconsStyle: false,
            relatedIconsTags: false,
        },

        previewResourceList: {
            ...defaultResourceConfig.previewResourceList,
            contextMenu: false,
            copyBtn: false,
            addCollectionBtn: false,
            animatedIcon: false,
            directDownloadBtn: false,
        },

        search: {
            ...defaultResourceConfig.search,
            groupBypack: false,
            groupByStyle: false,
            labelForAllResources: messages.common.allAnimatedIcons,
            resultsList: {
                ...defaultResourceConfig.search.resultsList,
                sponsorAdobe: false,
                ads: false,
            },
        },
    },

    uicon: {
        detail: {
            ...defaultResourceConfig.detail,
            resourceFormat: 'svg',
            editor: false,
            editorUicon: true,
            autorSearchBar: false,
            stickerBanner: false,
            downloads: {
                ...defaultResourceConfig.detail.downloads,
                components: 'uiconComponent',
                formats: {
                    ...defaultResourceConfig.detail.downloads.formats,
                    free: {
                        png: {
                            format: 'png',
                            title: messages.detail.download_free_icon_font_in_png,
                            name: 'PNG',
                            sizes: ['512', '256', '128', '64', '32', '24', '16'],
                        },
                        svg: {
                            format: 'svg',
                            title: messages.detail.download_free_icon_font_in_svg,
                            name: 'SVG',
                        },
                        Android: {
                            format: 'android',
                            title: messages.detail.download_free_icon_font_for_android,
                            name: 'Android',
                        },
                        iOs: {
                            format: 'ios',
                            title: messages.detail.download_free_icon_font_for_ios,
                            name: 'iOS',
                        },
                    },
                    premium: {},
                },
            },
            addcollectionBtn: false,
            authorInfo: false,
            authorLinks: false,
            pack: false,
            styleInfo: false,
            styleLink: false,
            styleBookmark: false,
            sponsorAdobe: false,
            ads: false,
            relatedPackLink: false,
            relatedIconsStyle: false,
            relatedIconsTags: true,
            relatedIconsPack: false,
            resourceTags: true,
        },

        previewResourceList: {
            ...defaultResourceConfig.previewResourceList,
            contextMenu: true,
            copyBtn: false,
            addCollectionBtn: false,
            animatedIcon: false,
            directDownloadBtn: false,
        },

        search: {
            ...defaultResourceConfig.search,
            groupBypack: false,
            groupByStyle: false,
            labelForAllResources: messages.common.allInterfaceIcons,
            resultsList: {
                ...defaultResourceConfig.search.resultsList,
                sponsorAdobe: false,
                ads: false,
            },
        },
    },
};

export const resourceTypes = {
    icon: 'icon',
    standard: 'standard',
    sticker: 'sticker',
    animatedIcon: 'animated-icon',
    uicon: 'uicon',
};
