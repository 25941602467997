var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <article class=\"item\" data-position=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":80,"column":53},"end":{"line":80,"column":63}}}) : helper)))
    + "\">\n                    <div class=\"content\">\n                        <a rel=\"nofollow\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":82,"column":48},"end":{"line":82,"column":55}}}) : helper)))
    + "\" class=\"link track\" target=\"_blank\">\n                            <div class=\"unicorn__mask rb\">\n                                <img class=\"lazyload\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":84,"column":59},"end":{"line":84,"column":65}}}) : helper)))
    + "\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"urlImage") || (depth0 != null ? lookupProperty(depth0,"urlImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"urlImage","hash":{},"data":data,"loc":{"start":{"line":84,"column":72},"end":{"line":84,"column":84}}}) : helper)))
    + "\">\n                            </div>\n                        </a>\n                    </div>\n                </article>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n    .spirit-a{\n        position: relative;\n        height: unset;\n    }\n\n    #unicorn .unicorn > section{\n        background-color: #EFF3F6;\n        padding: 20px;\n        border-radius: 8px 8px 0px 0px;\n    }\n\n    #unicorn .unicorn__row {\n        display: grid;\n        grid-template-columns: repeat(auto-fill, minmax(120px, auto));\n        gap: 5px;\n        height: 120px;\n        overflow: hidden;\n    }\n\n    #unicorn .item {\n        margin-bottom: 30px;\n        height: 120px;\n        overflow: hidden;\n    }\n    \n    #unicorn .item .content{\n        height: 100%;\n    }\n\n    #unicorn .item .content .unicorn__mask.rb{\n        height: 100%;\n        background: white;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n    #unicorn .item img {\n        display: block;\n        margin: auto;\n        object-fit: cover;\n        height: 63px;\n    }\n\n    #unicorn .unicorn footer {\n        display: flex;\n        background-color: #EFF3F6;\n        padding: 0 20px 20px;\n        justify-content: space-between;\n        border-radius: 0px 0px 8px 8px;\n\n    }\n\n    #unicorn .unicorn footer .footer-sponsor{\n        display: flex;\n        align-items: center;\n    }\n\n    #unicorn .unicorn footer .unicorn__more > a div{\n        display: flex;\n        align-items: center;\n        \n    }\n\n    #unicorn .unicorn footer .footer-sponsor a b:hover{\n        color: black;\n    }\n\n    body[data-section='404'] .spirit-a {\n        height: 0px;\n    }\n</style>\n\n<section id=\"unicorn\">\n    <img height=\"0\" width=\"0\" src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"trackerImpSponsor") || (depth0 != null ? lookupProperty(depth0,"trackerImpSponsor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"trackerImpSponsor","hash":{},"data":data,"loc":{"start":{"line":75,"column":35},"end":{"line":75,"column":56}}}) : helper)))
    + "\" style=\"position:absolute;visibility:hidden;\" border=\"0\" />\n    <div class=\"unicorn\">\n        <section>\n            <div class=\"unicorn__row\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"results") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":16},"end":{"line":89,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </section>\n        <footer>\n            <div class=\"footer-sponsor\">\n                <p class=\"mg-none-i font-xs row row--vertical-center\">\n                    "
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"sponsor") : stack1)) != null ? lookupProperty(stack1,"sponsored_by") : stack1), depth0))
    + "\n                    <a href=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"logo") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" target=\"_blank\" class=\"unicorn__logo mg-left-lv2\">\n                        <img src=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"logo") : depth0)) != null ? lookupProperty(stack1,"imageUrl") : stack1), depth0))
    + "\" alt=\"Adobe Stock\" width=\"100\" height=\"22\" class=\"adobe-logo block\">\n                    </a>\n                    <a rel=\"nofollow\" href=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"coupon") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" class=\"content link--text link-inverted bold mg-left-lv2 font-xs text__state--blue\"\n                    target=\"_blank\">\n                        <b>"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"sponsor") : stack1)) != null ? lookupProperty(stack1,"coupon_title") : stack1), depth0))
    + "</b> \n                    </a>\n                </p>\n            </div>\n            <article class=\"unicorn__more\">\n                <a rel=\"nofollow\" href=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"showMore") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" class=\"content link--text link-inverted bold\"\n                    target=\"_blank\">\n                    <div>\n                        <span class=\"font-sm mg-right-lv2\">"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"show_more") : stack1), depth0))
    + "</span>\n                        <i class=\"icon icon--right-small\"></i>\n                    </div>\n                </a>\n            </article>\n        </footer>\n    </div>\n</section>";
},"useData":true});