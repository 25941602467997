var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section id=\"detail\" class=\"detail new-detail\" data-elementId=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-icon_type=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\" \ndata-raw=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"hasRaw") : stack1), depth0))
    + "\">\n	<div class=\"detail__inner detail--v2 gallery\">\n		<div class=\"row detail__top mg-none\">\n			<section class=\"detail__content col col--stretch mg-none pd-none\"> \n				<div class=\"detail__content__inner row row--horizontal-center mg-none\">\n					<div class=\"fullwidth detail__icon__holder\">\n						<div class=\"detail__icon__holder--header row row--space-between row--vertical-center mg-none\">\n							<div class=\"row row--vertical-center full-width mg-none pd-lv2\">\n								"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"savePinterestComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n								"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"editButtonComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n								<i id=\"detail-close-mobile\" class=\"close-detail icon icon--cross\"></i>\n							</div>\n						</div>\n						<i id=\"detail-prev-mobile\" class=\"icon icon--prev detail__nav--prev\"></i>\n						<i id=\"detail-next-mobile\" class=\"icon icon--next detail__nav--next\"></i>\n						<div class=\"row row--vertical-center mg-none full-height detail__icon__inner\">\n							<div class=\"col mg-none\">\n								"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"resourcePreviewComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n							</div>\n						</div>\n                        "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"detailStylesComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n					</div>\n					"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"authorSearchComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				</div>\n			</section>\n			<aside class=\"detail__sidebar col--stretch\"> \n				"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"bannerComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"downloadComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n\n				"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"licenseAndAttributionComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"authorComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				<div class=\"pd-top-lv3 pd-bottom-lv3 more-icons font-sm\">\n					"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"packComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n					"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"styleComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n				</div>\n				<div class=\"group group-date hidden\">\n					"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"published") : stack1), depth0))
    + ":\n					<span class=\"publish_date\"><strong>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"published") : stack1), depth0))
    + "</strong></span>\n				</div>\n			</aside>\n		</div>\n		"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"tagsComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n\n		"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"googleAdsComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n\n		"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"relatedTagsAndStylesComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n\n		"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceComponents") : depth0)) != null ? lookupProperty(stack1,"sponsorAdobeComponent") : stack1), depth0)) != null ? stack1 : "")
    + "\n\n		<h2 class=\"font-h6 bold pd-left-lv3 pd-right-lv3 mg-top-lv5 search-other-categories\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"cardsProducts") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h2>\n		<div class=\"cards-products-home circles pd-left-lv3 pd-right-lv3\">\n			<a href=\""
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"url_icons") : stack1), depth0)) != null ? stack1 : "")
    + "\" class=\"track icons-circle\" data-track-arguments=\"ga, event, home, subhome-icons\">\n			<div class=\"cards-products-home__item\">\n				<div class=\"cards-products-home__item__image\">\n				<div class=\"circle\">\n					<div class=\"image\">\n						<img class=\"lzy\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "home/cards-products-section/1px.png\" srcset=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "home/cards-products-section/circles/icons.png\" alt=\"icons\" width=\"114\" height=\"114\">\n					</div>\n				</div>\n				</div>\n				<div class=\"cards-products-home__item__text\">\n				<h6 class=\"font-lg mg-bottom-lv1 capitalize\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"icons") : stack1), depth0))
    + "</h6>\n				<p class=\"regular font-sm\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"cardsProducts") : stack1)) != null ? lookupProperty(stack1,"iconDescription") : stack1), depth0))
    + "</p>\n				</div>\n			</div>\n			</a>\n			<a href=\""
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"url_uicons") : stack1), depth0)) != null ? stack1 : "")
    + "\" class=\"track uicons-circle\" data-track-arguments=\"ga, event, home, subhome-uicons\">\n			<div class=\"cards-products-home__item\">\n				<div class=\"cards-products-home__item__image\">\n				<div class=\"circle\">\n					<div class=\"image\">\n						<img class=\"lzy\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "home/cards-products-section/1px.png\" srcset=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "home/cards-products-section/circles/interface.png\" alt=\"interface icons\" width=\"114\" height=\"114\">\n					</div>\n				</div>\n				</div>\n				<div class=\"cards-products-home__item__text\">\n				<h6 class=\"font-lg mg-bottom-lv1 capitalize\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"interface_icons") : stack1), depth0))
    + "</h6>\n				<p class=\"regular font-sm\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"cardsProducts") : stack1)) != null ? lookupProperty(stack1,"uiconDescription") : stack1), depth0))
    + "</p>\n				</div>\n			</div>\n			</a>\n			<a href=\""
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"url_animated_icons") : stack1), depth0)) != null ? stack1 : "")
    + "\" class=\"track video aicons-circle\" data-track-arguments=\"ga, event, home, subhome-animated-icons\">\n			<div class=\"cards-products-home__item\">\n				<div class=\"cards-products-home__item__image\">\n				<div class=\"circle\">\n					<div class=\"image\">\n						<img class=\"lzy\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "home/cards-products-section/1px.png\" srcset=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "home/cards-products-section/circles/animated.png\" alt=\"animated icons\" width=\"114\" height=\"114\">\n					</div>\n				</div>\n				</div>\n				<div class=\"cards-products-home__item__text\">\n				<h6 class=\"font-lg mg-bottom-lv1 capitalize\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"animated_icons") : stack1), depth0))
    + "</h6>\n				<p class=\"regular font-sm\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"cardsProducts") : stack1)) != null ? lookupProperty(stack1,"animated_icons_description") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n				</div>\n			</div>\n			</a>\n			<a href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"url_stickers") : stack1), depth0))
    + "\" class=\"track stickers-circle\" data-track-arguments=\"ga, event, home, subhome-stickers\">\n			<div class=\"cards-products-home__item\">\n				<div class=\"cards-products-home__item__image\">\n				<div class=\"circle\">\n					<div class=\"image\">\n						<img class=\"lzy\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "home/cards-products-section/1px.png\" srcset=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "home/cards-products-section/circles/stickers.png\" alt=\"stickers\" width=\"114\" height=\"114\">\n					</div>\n				</div>\n				</div>\n				<div class=\"cards-products-home__item__text\">\n				<h6 class=\"font-lg mg-bottom-lv1 capitalize\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"stickers") : stack1), depth0))
    + "</h6>\n				<p class=\"regular font-sm\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"cardsProducts") : stack1)) != null ? lookupProperty(stack1,"sticker_description") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n				</div>\n			</div>\n			</a>\n		</div>\n		\n		<form id=\"download-form\" method=\"get\" action=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"FLATICON_DOWNLOAD_URL") : stack1), depth0))
    + "download/icon/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"> \n			<input type=\"hidden\" id=\"icon_id\" name=\"icon_id\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"author\" name=\"author\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"team\" name=\"team\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"keyword\" name=\"keyword\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"pack\" name=\"pack\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"pack") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"style\" name=\"style\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"style_id\" name=\"style_id\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"format\" name=\"format\" />\n			<input type=\"hidden\" id=\"color\" name=\"color\" />\n			<input type=\"hidden\" id=\"colored\" name=\"colored\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"size\" name=\"size\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"initialDownloadSize") || (depth0 != null ? lookupProperty(depth0,"initialDownloadSize") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"initialDownloadSize","hash":{},"data":data,"loc":{"start":{"line":126,"column":53},"end":{"line":126,"column":76}}}) : helper)))
    + "\"/>\n			<input type=\"hidden\" id=\"selection\" name=\"selection\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"selection") : stack1), depth0))
    + "\" />\n			<input type=\"hidden\" id=\"type\" name=\"type\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\" />\n		</form>\n	</div>\n</section>\n";
},"useData":true});