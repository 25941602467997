export async function writeClipImg(pngTarget) {
    const imgUrl = pngTarget;
    const dataPng = await fetch(imgUrl);
    const blobPng = await dataPng.blob();

    navigator.clipboard.write([
        new ClipboardItem({
            [blobPng.type]: blobPng,
        }),
    ]);
}

export async function readClipImgSafari(pngTarget) {
    const imgUrl = pngTarget;
    const dataPng = await fetch(imgUrl);
    const blobPng = await dataPng.blob();
    return blobPng;
}

export async function writeClipImgSafari(blobPng) {
    await navigator.clipboard.write([
        new ClipboardItem({
            [blobPng.type]: blobPng,
        }),
    ]);
}

export async function writeClipSvg(svgTarget, iconId) {
    const svgUrl = svgTarget;
    let plainSvg = await fetch(svgUrl).then(r => r.text());
    const blobType = {
        text: 'text/plain',
        html: 'text/html',
    };
    plainSvg = createElementFromHTML(plainSvg).querySelector('svg');
    plainSvg.setAttribute('id', `fi_${iconId}`);
    plainSvg = plainSvg.outerHTML;

    const blobPlainSvg = new Blob([plainSvg], { type: blobType.text });

    await navigator.clipboard.write([
        new ClipboardItem({
            [blobPlainSvg.type]: blobPlainSvg,
        }),
    ]);
}

export async function readClipSvgSafari(svgTarget, iconId) {
    const svgUrl = svgTarget;
    let plainSvg = await fetch(svgUrl).then(r => r.text());
    const blobType = {
        text: 'text/plain',
        html: 'text/html',
    };
    plainSvg = createElementFromHTML(plainSvg).querySelector('svg');
    plainSvg.setAttribute('id', `fi_${iconId}`);
    plainSvg = plainSvg.outerHTML;

    const blobPlainSvg = new Blob([plainSvg], { type: blobType.text });

    return {
        blobPlainSvg,
    };
}

export async function writeClipSvgSafari({ blobPlainSvg }) {
    await navigator.clipboard.write([
        new ClipboardItem({
            [blobPlainSvg.type]: blobPlainSvg,
        }),
    ]);
}

export async function writeClipText(textTarget) {
    const blobPlainText = new Blob([textTarget], { type: 'text/plain' });
    await navigator.clipboard.write([
        new ClipboardItem({
            [blobPlainText.type]: blobPlainText,
        }),
    ]);
}

const createElementFromHTML = function(htmlString) {
    let divElement = document.createElement('div');
    divElement.innerHTML = htmlString.trim();

    return divElement;
};
