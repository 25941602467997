export function initProgressiveImages() {
    document.querySelectorAll('.placeholder').forEach((placeholder: HTMLDivElement) => {
        let small = placeholder.querySelector('.img-small') as HTMLImageElement;
        let img = new Image();
        img.src = small?.src;
        img.alt = small?.alt;
        img.title = small?.title;
        img.onload = function() {
            small?.classList.add('loaded');
        };
        let imgLarge = new Image(small.width, small.height) as HTMLImageElement;
        if (placeholder.dataset.png) {
            imgLarge.src = placeholder.dataset.png;
            imgLarge.alt = small?.alt;
            imgLarge.title = small?.title;
            imgLarge.onload = function() {
                small.parentNode?.removeChild(small);
                imgLarge.classList.add('loaded');
            };
            placeholder.appendChild(imgLarge);
        }
    });
}
