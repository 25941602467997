var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"tooltip tooltip--left\">\n    <button class=\"add-remove icon icon--addcollection tooltip__trigger tooltip__trigger--always  bj-button bj-button--sm bj-button--white bj-button--icon\" data-gtm-event=\"add_to_collection thumbnail\"></button>\n    <div class=\"tooltip__content tooltip__content--permanent\">\n        <div class=\"content\">\n            <span >"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"collection") : stack1)) != null ? lookupProperty(stack1,"add_icon") : stack1), depth0))
    + "</span>\n        </div>\n    </div>\n</li> ";
},"useData":true});