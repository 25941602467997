import { ww } from 'bobjoll/ts/library/dom';
import { numberFormat } from 'Project/ts/library/helpers/numberFormat';

import { UserDownloadStats } from './userSession.vm';

let badge: HTMLElement | undefined | null = undefined;

const getBadge = (): HTMLElement | null => {
    if (typeof badge !== 'undefined') {
        return badge;
    }

    badge = document.getElementById('icons_downloaded_counters');

    return badge;
};

const isEssentialBannerActive = (): boolean => {
    const essentialsBanner = document.getElementById('essentials');

    return Boolean(essentialsBanner && essentialsBanner.classList.contains('active'));
};

const isInvalidUser = (): boolean => {
    return !Boolean(ww.UserSession.userInfo.session.id);
};

const updateBadge = (stats: UserDownloadStats): void => {
    if (isEssentialBannerActive()) {
        return;
    }

    if (isInvalidUser()) {
        return;
    }

    badge = getBadge();

    if (!badge) {
        return;
    }

    const { now, max } = stats;

    badge.classList.remove('hidden');
    badge.innerHTML = `${numberFormat(now)}/${numberFormat(max)}`;
};

export function updateUserDownloadBadgeWhenStatsChange(): void {
    window.addEventListener('userDownloadStatsUpdated', (event: CustomEvent<UserDownloadStats>) => {
        updateBadge(event.detail);
    });

    window.addEventListener('userSessionLoaded', () => {
        const { downloadStats } = ww.UserSession.userInfo.session;

        if (downloadStats) {
            updateBadge(downloadStats);
        }
    });
}
