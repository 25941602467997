import { ww } from 'bobjoll/ts/library/dom';

export const getTypeUser = () => {
    let guestUser: string | null = ww.UserSession.userInfo.logged ? null : 'anonymous';
    let premiumUser: string = ww.UserSession.userInfo.session.premium == 0 ? 'registered' : 'payment-user';
    let currentUSer: string = guestUser ? guestUser : premiumUser;
    return currentUSer;
};

export const getUserId = () => {
    return ww.UserSession.userInfo.session.id || 'nn';
};

export const getOriginalClientId = () => {
    const url = new URL(window.location.href);
    const clientId = url.searchParams.get('fp-ga');
    if (clientId) {
        return clientId.split('GA1.1.')[1] || 'nn';
    }
    return 'nn';
};

export const getTypeView = () => {
    const specificViews: View = {
        'icon-search': RESOURCE_TYPE == 'standard' ? 'icon-search' : `${RESOURCE_TYPE}-search`,
        'icon-detail': RESOURCE_TYPE == 'standard' ? 'icon-detail' : `${RESOURCE_TYPE}-detail`,
        'pack-search': RESOURCE_TYPE == 'standard' ? 'icon-pack-search' : `${RESOURCE_TYPE}-pack-search`,
    };
    let currentView = ww.view.section
        ? specificViews[ww.view.section]
            ? specificViews[ww.view.section]
            : ww.view.section
        : 'nn';

    return currentView;
};

interface View {
    [key: string]: string;
}
