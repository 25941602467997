declare var STATIC_URL: string;
declare var URL_STATIC_IMG: string;
declare var FLATICON_URL_STATIC: string;
declare var gr: any;
declare var USER: any;
declare var APP_URL: string;
declare var BASE_URL: string;
declare var LANGUAGE: string;
declare var LANGUAGE_SHORT: string;
declare var URL_CRM_LOG: string;
declare var LANDING: boolean;
declare var ONE_PERCENT_CHANCE: number;
declare var advertisement: boolean | undefined;
declare var URL_CAMPAIGN: string;
declare var MONTHLY_SUBSCRIPTION_PRICE: string;
declare var USA_MONTHLY_SUBSCRIPTION_PRICE: string;
declare var ANNUAL_SUBSCRIPTION_PRICE: string;
declare var USA_ANNUAL_SUBSCRIPTION_PRICE: string;
declare var ANNUAL_SUBSCRIPTION_PRICE_PER_MONTH: string;
declare var USA_ANNUAL_SUBSCRIPTION_PRICE_PER_MONTH: string;
declare var languageStrings: {
    [name: string]: string;
};
declare var messages: any;
declare var urls: any;
declare var LICENSES: any;
declare var SUPPORT_URL: string;
declare var SPONSOR_CACHE: string;
declare var SPONSOR_FREEPIK: string;
declare var SUPPORT_LANG: string;
declare var ACCOUNTS_URL: string;
declare let grecaptcha: any;
declare var PROF_RE_CAPTCHA_KEY_INVISIBLE: string;
declare var RE_CAPTCHA_KEY_INVISIBLE_V2: string;
declare var GR_LANGUAGE: string;
declare var SPONSOR_CURRENT_PAGE: number;
declare var ACCOUNTS_BASE_URL: string;
declare var TOTAL_PREMIUM_ICONS: string;
declare var GOOGLE_IDP_URL_LOGIN: string;
declare var GOOGLE_IDP_URL_REGISTER: string;
declare var VISIT_PLANS_PRICING: number;
declare var ICON_EDIT_STATIC_ORIGIN: string;
declare var ICON_EDIT_STATIC_DESTINY: string;
declare var CSRF_TOKEN_NAME: string;
declare var CSRF_TOKEN: string;
declare var ESSENTIALS_STATUS: string;
declare var ESSENTIALS_DATE: number;
declare var PACK_LICENSE_URL: string;
declare var USER_REGISTERED: boolean;
declare var MONTHLY_PRICE: string;
declare var YEARLY_PRICE: string;
declare var IS_UICONS_VIEW: boolean;
declare var FLATICON_URL: string;
declare var ACTIVE_CAMPAIGN: any;
declare var INFINITY: any;
declare var FLATICON_UPLOAD_ICONS_URL: any;
declare var EXCLUSIVE_CAMPAIGN: any;
declare var USER_COUNTRY_CODE: string;
declare let optimizeTests: any;
declare var RESOURCE_TYPE: string;
declare let FLATICON_DOWNLOAD_URL: string;
declare let UPDATE_BILLING_INFO: boolean;
declare let CDN_URL_PREFIX: string;
declare var IS_API_ORIGIN: boolean;
declare let COUPON_PRICING: string;
declare let MERCHANDISING_LICENSE_CHECK_URL: string;
declare let AMOUNT_IN_RELATED_BY_TAG_AND_STYLE: number;
declare let IS_USER_PREMIUM: boolean;
declare let GOOGLE_ONE_TAP_LOGIN_URL: string;
declare let HAS_USER_BILLING_IN_INDIA: boolean;
declare let SPONSOR: string;

if (typeof URL_STATIC_IMG === 'undefined') URL_STATIC_IMG = 'https://www.flaticon.com/media/';
if (typeof languageStrings === 'undefined') languageStrings = {};
if (typeof ACTIVE_CAMPAIGN === 'undefined') ACTIVE_CAMPAIGN = '';
if (typeof EXCLUSIVE_CAMPAIGN === 'undefined') EXCLUSIVE_CAMPAIGN = null;
