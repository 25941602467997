import { q, qq, ww } from 'bobjoll/ts/library/dom';
import { EventTracker } from 'Library/eventTacker';
import xhr from 'Partials/xhr';
import { Recaptcha } from 'Project/ts/common-app/recaptcha/recaptcha';

import { Downloads } from '../downloads';

const downloadValidateAPI = `${BASE_URL}ajax/download/validate`;
const recaptchaObserver = new MutationObserver(challengeCallbackFunction);

let trackerDownload: TrackerDownload;

export const recaptchaDownload = async (button: HTMLElement, event: Event, tracker: TrackerDownload) => {
    event.preventDefault();
    event.stopPropagation();

    trackerDownload = tracker;
    trackerDownload.button = button;

    if (!button.classList.contains('button--icon')) {
        if (button.classList.contains('GA_CM_download-png')) {
            let iconElement = q('i', button);
            iconElement?.classList.add('icon--spinner', 'animation-spinner');
        } else {
            button.classList.add('button--loading-download');
            const textDownloading = document.createElement('span');
            textDownloading.className = 'text-downloading';
            textDownloading.innerHTML = messages.download.downloading;
            button.appendChild(textDownloading);
        }
    }

    disableOrEnableDownloads(trackerDownload.list ? trackerDownload.list : false);

    const validateURL = ww.Resource.resource
        ? `${downloadValidateAPI}?resourceType=${trackerDownload.type}&resourceId=${ww.Resource.resource.id}`
        : `${downloadValidateAPI}?resourceType=${trackerDownload.type}`;

    try {
        const request = await xhr(validateURL, {
            withCredentials: true,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });

        const activateCaptcha = request.getPathValue('isManual');

        if (!activateCaptcha) {
            initDownload();
            return;
        }

        if (activateCaptcha) {
            initializeCaptcha(button);
        }
    } catch (err) {
        if (err.status === 429) {
            let modalDownload = document.getElementById('modal-download') as HTMLInputElement;
            if (modalDownload) {
                modalDownload.checked = false;
                document.querySelector('body')?.classList.remove('modal-open');
            }
            window.location.href = `${APP_URL}${messages.common.url_downloads_limit}`;
        } else {
            console.error('Error validate download ', err);
        }
    }
};

const initializeCaptcha = function(button: HTMLElement) {
    let uniqCaptcha = button.dataset.captcha ? button.dataset.captcha : 'free';
    let captchaElement = q(`#captcha-download-${uniqCaptcha}`);
    let recaptchaId = trackerDownload.recaptcha.widgets[trackerDownload.recaptcha.nameHandler];
    if (captchaElement && (recaptchaId || recaptchaId == '0')) {
        grecaptcha.reset(recaptchaId);

        const newCaptcha = captchaElement.cloneNode(false) as HTMLElement;
        uniqCaptcha = `${uniqCaptcha}-${new Date().getTime()}`;
        newCaptcha.id = `captcha-download-${uniqCaptcha}`;

        captchaElement.parentNode?.insertBefore(newCaptcha, captchaElement.nextSibling);
        captchaElement.parentNode?.removeChild(captchaElement);

        captchaElement = q(`#captcha-download-${uniqCaptcha}`);
        recaptchaId = null;
    }

    button.dataset.captcha = uniqCaptcha;
    trackerDownload.uniqCaptcha = uniqCaptcha;
    trackerDownload.captchaElement = captchaElement ? captchaElement : undefined;

    if (captchaElement && !recaptchaId && recaptchaId != '0') {
        recaptchaId = trackerDownload.recaptcha.render(captchaElement, captchaSubmitDownload, false);
    }
    captchaChallengeAppear();
    grecaptcha.execute(recaptchaId);
};

const disableOrEnableDownloads = function(disable: boolean) {
    if (trackerDownload.disableOtherDownloads) {
        const downloadButtons = qq(trackerDownload.disableOtherDownloads);
        if (downloadButtons.length > 0) {
            downloadButtons.forEach(button => {
                if (button !== trackerDownload.button) {
                    button.classList[disable ? 'add' : 'remove']('disabled');
                }
            });
        }
    }
};

const captchaSubmitDownload = function(token: string) {
    try {
        initDownload(token);
    } catch (err) {
        console.error('Error submit captcha download', err);
    }
    grecaptcha.reset(trackerDownload.recaptcha.widgets[trackerDownload.recaptcha.nameHandler]);
};

const initDownload = function(token?: string) {
    disableOrEnableDownloads(false);
    if (token) {
        const formDownloadElement =
            trackerDownload.type == 'icon'
                ? ww.download_form
                    ? ww.download_form
                    : q('#download-form')
                : q('#form-download-pack');
        if (formDownloadElement) {
            const inputTokenElement = q('input[name="token"]', formDownloadElement) as HTMLInputElement;
            if (inputTokenElement) {
                inputTokenElement.value = token;
            } else {
                let inputToken = document.createElement('input');
                inputToken.type = 'hidden';
                inputToken.name = 'token';
                inputToken.value = token;
                formDownloadElement.appendChild(inputToken);
            }
        }
    }
    if (trackerDownload.callbackEvents) trackerDownload.callbackEvents(trackerDownload.button);
    clearDownloadButton();
    Downloads.openAttributionModal();
};

const clearDownloadButton = function() {
    const textDownloading = qq('.text-downloading', trackerDownload.button);
    textDownloading.forEach(item => {
        item?.parentNode?.removeChild(item);
    });

    trackerDownload.button?.classList.remove('button--loading-download');

    if (trackerDownload.button?.classList.contains('GA_CM_download-png')) {
        let iconElement = q('i', trackerDownload.button);
        iconElement?.classList.remove('icon--spinner', 'animation-spinner');
    }
};

const captchaChallengeAppear = function() {
    const targetElement = document.body;
    const observerConfig = {
        childList: true,
        attributes: false,
        attributeOldValue: false,
        characterData: false,
        characterDataOldValue: false,
        subtree: false,
    };
    recaptchaObserver.observe(targetElement, observerConfig);
};

function challengeCallbackFunction(mutationRecords: any) {
    mutationRecords.forEach((mutationRecord: any) => {
        if (mutationRecord.addedNodes.length) {
            const recaptchaParentContainer = mutationRecord.addedNodes[0];
            const recaptchaIframe = recaptchaParentContainer.querySelectorAll('iframe[title*="recaptcha"]');
            if (recaptchaIframe.length) {
                recaptchaObserver.disconnect();

                recaptchaIframe.forEach((iframes: HTMLIFrameElement) => {
                    setTimeout(function() {
                        const containerIframe = iframes.parentNode?.parentNode as HTMLDivElement;
                        let captchaType = 'invisible';
                        if (containerIframe?.style.visibility == 'visible') {
                            captchaType = 'manual';
                        }
                        EventTracker.sendINT('send', 'event', captchaType);
                    }, 1500);
                });
            }
        }
    });
}

export interface TrackerDownload {
    button?: HTMLElement;
    callbackEvents?: Function;
    captchaElement?: HTMLElement;
    detail?: any;
    disableOtherDownloads?: string;
    downloadData?: any;
    type?: any;
    recaptcha: Recaptcha;
    uniqCaptcha?: string;
    userType?: string;
    list?: boolean;
}
