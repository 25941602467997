var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"button button--sm button--pinterest bg-pinterest button--icon\" onclick=\"share.popup({network: 'pinterest'});return false;\" data-gtm-event=\"pinterest detail\">\n  <i class=\"icon icon--pinterest text-inverted\"></i>\n  <span>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"savePinterest") : stack1), depth0))
    + "</span>\n</button>";
},"useData":true});