const md5 = require('crypto-js/md5');
const extend = require('bobjoll/ts/library/extend');

import Cache from 'Library/cache';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

const defaultOptions: AxiosRequestConfig = { withCredentials: true };
const xhrActivePromises: XHRActivePromises = {};
const getXHRAsync = async function(url: string, settings: any, cache?: boolean, cacheCallback?: (body: JSON) => JSON) {
    axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        var config = err.config;
        // If config does not exist or the retry option is not set, reject
        if (!config || !config.retry) return Promise.reject(err);

        if (err.response.status === 429) {
            config.retry = 0;
        }

        // Set the variable for keeping track of the retry count
        config.__retryCount = config.__retryCount || 0;

        // Check if we've maxed out the total number of retries
        if (config.__retryCount >= config.retry) {
            // Reject with the error status
            if (err.response.status === 429) {
                return Promise.reject({ status: err.response.status });
            }

            // Reject with the error
            return Promise.reject(err);
        }

        // Increase the retry count
        config.__retryCount += 1;

        // Create new promise to handle exponential backoff
        var backoff = new Promise(function(resolve) {
            setTimeout(function() {
                resolve();
            }, config.retryDelay || 1);
        });

        // Return the promise in which recalls axios to retry the request
        return backoff.then(function() {
            return axios(config);
        });
    });
    settings.retry = 3;
    settings.retryDelay = 1000;

    const response = await axios(url, settings);
    let body = response.data;

    if (response.status === 204) {
        throw { status: 204 };
    }

    if (response.status === 429) {
        throw { status: 429 };
    }

    if (response.status !== 200 && response.status !== 201) {
        throw body;
    }

    if (cache) {
        let time = getMaxAge(response);

        if (cacheCallback) {
            body = cacheCallback(body);
        }

        Cache.set(url, body, time);
    }
    return body;
};

const getXHR = async function(url: string, options?: AxiosRequestConfig, cacheJSON?: boolean, cacheCallback?: (body: JSON) => JSON) {
    if (cacheJSON) {
        const cache = Cache.get(url);

        if (cache) {
            return cache;
        }
    }

    const settings = extend(defaultOptions, options);

    let body: JSON;
    let key = url;

    if (settings.body) {
        const params = 'string' == typeof settings.body ? settings.body : settings.body.entries ? formDataToString(settings.body) : '';
        key = `${md5(url + params)}`;
    }

    try {
        if (!xhrActivePromises[key]) {
            xhrActivePromises[key] = getXHRAsync(url, settings, cacheJSON, cacheCallback);
        }
        body = await xhrActivePromises[key];

        delete xhrActivePromises[key];
        return body;
    } catch (e) {
        setTimeout(() => delete xhrActivePromises[key], 30000);

        if (e.status === 429) {
            throw e;
        }

        if (!e.status) {
            throw Error(`Request to "${url}" has failed. Error: ${e}`);
        }

        throw e;
    }
};

export default getXHR;

export function getMaxAge(response: AxiosResponse) {
    let cache = response.headers['x-accel-expires-debug'];
    let cacheMaxAge = (cache || '0').match(/\d+/);
    return cacheMaxAge ? parseFloat(cacheMaxAge[0]) : 300;
}

export class XHR {
    public static FormData(fields: { [name: string]: any }) {
        const body = new FormData();
        Object.keys(fields).forEach(function(key: string) {
            body.append(key, fields[key]);
        });
        return body;
    }
}

export function formDataToString(formData: FormData) {
    var obj: { [name: string]: any } = {};
    formData.forEach(function(value, key) {
        obj[key] = value;
    });
    return JSON.stringify(obj);
}

export function httpBuildQuery(searchParametersData: any) {
    var searchParameters = new URLSearchParams();

    Object.keys(searchParametersData).forEach(function(parameterName) {
        searchParameters.append(parameterName, searchParametersData[parameterName]);
    });

    return searchParameters.toString();
}
interface XHRActivePromises {
    [name: string]: Promise<JSON>;
}

export interface XhrResponse {
    messages?: string[];
    success: boolean;
}
