var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),"!=",0,{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":13,"column":36}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                          <span class=\"font-sm\" data-value=\"\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"family") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"font-sm\" data-value=\"\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"all_styles") : stack1), depth0))
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"search-style-word\">\n  <div class=\"search-holder\">\n      <form  action=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"searchUrl") || (depth0 != null ? lookupProperty(depth0,"searchUrl") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"searchUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":21},"end":{"line":3,"column":34}}}) : helper)))
    + "\" method=\"get\" class=\"author_search fake-search row\">\n          <input type=\"hidden\" name=\"author_id\" value=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"groupId") : stack1), depth0))
    + "\">\n          <input type=\"hidden\" name=\"style_id\" value=\""
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n          <input type=\"hidden\" name=\"type\" value=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\">\n          <div class=\"popover popover-left\">\n              <div class=\"popover-container\">\n                  <button type=\"button\" class=\"style__selector popover-button btn row row--vertical-center mg-none nostyle track\" data-qa=\"search-style-word\" data-track-arguments=\"ga, event, search, search-style-word, button\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),"!=","",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":22},"end":{"line":14,"column":34}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),"==",0,{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":22},"end":{"line":17,"column":34}}})) != null ? stack1 : "")
    + "\n                      <i class=\"icon icon--sm icon--caret-down\"></i>\n                  </button>\n                  <div class=\"popover-content\">\n                      <ul id=\"authorStyles-list\" class=\"style-id__list\">\n                        <li class=\"font-sm "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),"==",0,{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":43},"end":{"line":23,"column":102}}})) != null ? stack1 : "")
    + "\" data-value=\"all-styles\"><span class=\"icon icon--check mg-right-lv1\"></span>"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"all_styles") : stack1), depth0))
    + "</li>																	\n                      </ul>\n                  </div>\n              </div>\n          </div>\n          <input type=\"search\" name=\"word\" placeholder=\""
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"search_author_resources") : stack1), depth0))
    + "\" class=\"font-sm tag-field__input autocomplete\" data-qa=\"search-bar\" value=\"\" maxlength=\"50\" spellcheck=\"false\" autocomplete=\"off\">\n          <button type=\"button\" class=\"clean-search__button\">\n              <i class=\"icon icon--cross\"></i>\n          </button>\n          <button type=\"button\"  class=\"bj-button bj-button--icon nostyle search__button\">\n              <i class=\"icon icon--md icon--search push-center\"></i>\n          </button>\n      </form>\n  </div>\n</section>\n";
},"useData":true});