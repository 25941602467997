var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"animated-icon-in-action pd-left-lv3 pd-right-lv3\">\n  <header>\n    <h2 class=\"font-h6 mg-bottom-lv1\">"
    + alias3((lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"see") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":106}}}))
    + "</h2>\n    <p class=\"mg-bottom-lv3\">"
    + alias3((lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"look") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":97}}}))
    + "</p>\n  </header>\n\n  <div class=\"row\">\n\n    <article>\n      <div class=\"image-example-interface mg-bottom-lv2\">\n        <div>\n          <img \n            src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\" \n            data-src=\""
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" \n            alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n            title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n            width=\"40\" \n            height=\"40\"\n            class=\"lzy mg-right-lv3\">\n\n          <div>\n            <p class=\"bold font-xs mg-none\">"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</p>\n            <p class=\"font-xs mg-none\">"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"looksGreat") : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n      </div>\n      <p class=\"font-sm alignc medium\">"
    + alias3((lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"onNotification") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":27,"column":39},"end":{"line":27,"column":117}}}))
    + "</p>\n    </article>\n                      \n    <article>\n      <div class=\"image-example-mobile-interface mg-bottom-lv2\">\n        <div class=\"mobile-body\">\n          <p class=\"font-xs mg-bottom-lv3\">"
    + alias3((lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"mobileInterface") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":33,"column":43},"end":{"line":33,"column":122}}}))
    + "</p>\n          <div class=\"mobile-menu\">\n\n            <img src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\"\n            data-src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "animated-icon-detail/location.svg\"\n            alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n            title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n            width=\"38\"\n            height=\"38\"\n            class=\"lzy static-image\">\n\n            <img \n            src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\" \n            data-src=\""
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"gif") : stack1), depth0))
    + "\" \n            alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n            title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n            width=\"38\" \n            height=\"38\"\n            class=\"lzy\">\n\n            <img src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\"\n            data-src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "animated-icon-detail/paperclip.svg\"\n            alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n            title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n            width=\"38\"\n            height=\"38\"\n            class=\"lzy static-image\">\n\n            <img src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\"\n            data-src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "animated-icon-detail/trash-can.svg\"\n            alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n            title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n            width=\"38\"\n            height=\"38\"\n            class=\"lzy static-image\">\n\n          </div>\n\n        </div>\n      </div>\n      <p class=\"font-sm alignc medium\">"
    + alias3((lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"mobile") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":73,"column":39},"end":{"line":73,"column":109}}}))
    + "</p>\n    </article>\n\n    <article>\n      <div class=\"image-example-menu mg-bottom-lv2\">\n        <div class=\"image-example-menu__container\">\n\n          <div class=\"image-example-menu__item\">\n            <img \n            src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\" \n            data-src=\""
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"gif") : stack1), depth0))
    + "\" \n            alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n            title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n            width=\"38\" \n            height=\"38\"\n            class=\"lzy mg-right-lv3\">\n\n            <div>\n              <p class=\"font-xs mg-none\">"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</p>\n            </div>\n          </div>\n\n          <div class=\"image-example-menu__item\">\n            <img src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\"\n            data-src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "animated-icon-detail/briefcase.svg\"\n            alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n            title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n            width=\"38\"\n            height=\"38\"\n            class=\"lzy mg-right-lv3 static-image\">\n            <div>\n              <p class=\"font-xs mg-none\">"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"briefcase") : stack1), depth0))
    + "</p>\n            </div>\n          </div>\n\n          <div class=\"image-example-menu__item\">\n            <img src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\"\n            data-src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "animated-icon-detail/worldwide.svg\"\n            alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n            title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"\n            width=\"38\"\n            height=\"38\"\n            class=\"lzy mg-right-lv3 static-image\">\n            <div>\n              <p class=\"font-xs mg-none\">"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"worldwide") : stack1), depth0))
    + "</p>\n            </div>\n          </div>\n\n\n        </div>\n      </div>\n      <p class=\"font-sm alignc medium\">"
    + alias3((lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"menu") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":124,"column":39},"end":{"line":124,"column":107}}}))
    + "</p>\n    </article>\n\n    <article>\n      <div class=\"image-example-scalable mg-bottom-lv2\">\n        <div class=\"image-example-scalable__container\">\n\n          <div class=\"image-example-scalable__item\">\n            <div>\n              <img \n              src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\" \n              data-src=\""
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"gif") : stack1), depth0))
    + "\" \n              alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n              title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n              width=\"36\" \n              height=\"36\"\n              class=\"lzy\">\n            </div>\n              <p class=\"font-xs mg-none\">1x</p>\n          </div>\n\n          <div class=\"image-example-scalable__item\">\n            <div>\n              <img \n                src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\" \n                data-src=\""
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"gif") : stack1), depth0))
    + "\" \n                alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n                title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n                width=\"54\" \n                height=\"54\"\n                class=\"lzy\">\n            </div>\n              <p class=\"font-xs mg-none\">1.5x</p>\n          </div>\n\n          <div class=\"image-example-scalable__item\">\n            <div>\n              <img \n                src=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "loader.gif\" \n                data-src=\""
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"gif") : stack1), depth0))
    + "\" \n                alt=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n                title=\""
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\" \n                width=\"72\" \n                height=\"72\"\n                class=\"lzy\">\n            </div>\n              <p class=\"font-xs mg-none\">2x</p>\n          </div>\n\n        </div>\n      </div>\n      <p class=\"font-sm alignc medium\">"
    + alias3((lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||alias2).call(alias1,"%s",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"animatedIconDetail") : stack1)) != null ? lookupProperty(stack1,"sizes") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":175,"column":39},"end":{"line":175,"column":108}}}))
    + "</p>\n    </article>\n\n  </div>\n</section>";
},"useData":true});