var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "data-gtm-event=\"copy thumbnail svg\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "btn-warning";
},"5":function(container,depth0,helpers,partials,data) {
    return "bj-button--secondary";
},"7":function(container,depth0,helpers,partials,data) {
    return "            <i class=\"icon icon--crown-filled mg-right-lv1\"></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li data-ga_params=\"contextmenu,click, copyPNGSVG\"\n  class=\"track copy-png-svg-list popover popover--bottom-right\"\n  data-track-arguments=\"ga, event, contextmenu, click, copyPNG, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceListData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n  <div class=\"popover-container tooltip tooltip--left\">\n    <button\n      class=\"popover-button tooltip__trigger tooltip__trigger--always bj-button bj-button--sm bj-button--white bj-button--icon\">\n      <i class=\"icon icon--copy-image\"></i>\n    </button>\n    <div class=\"tooltip__content tooltip__content--permanent\">\n      <div class=\"content\">\n        <i class=\"icon icon--info icon--xs mg-right-lv1\"></i>\n        <span>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"copySvgNotPremiumUser") : stack1)) != null ? lookupProperty(stack1,"countDownloading") : stack1), depth0))
    + "</span>\n      </div>\n    </div>\n    <div class=\"popover-content\">\n      <div>\n\n        <span class=\"bold mg-bottom-lv2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"copySvgNotPremiumUser") : stack1)) != null ? lookupProperty(stack1,"copyToClipboard") : stack1), depth0))
    + "</span>\n        <button "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isUserPremium") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":96}}})) != null ? stack1 : "")
    + " data-copy-format=\"svg\" class=\"copysvg--button bj-button "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isUserPremium") : depth0),"==",0,{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":153},"end":{"line":19,"column":209}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isUserPremium") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":210},"end":{"line":19,"column":275}}})) != null ? stack1 : "")
    + " track mg-bottom-lv2\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isUserPremium") : depth0),"==",0,{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":10},"end":{"line":22,"column":22}}})) != null ? stack1 : "")
    + "          SVG\n        </button>\n        <button data-copy-format=\"png\" class=\"copypng--button bj-button bj-button--secondary track\" data-gtm-event=\"copy thumbnail png\">PNG</button>\n      </div>\n    </div>\n  </div>\n</li>";
},"useData":true});