var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"btn-"
    + alias4(((helper = (helper = lookupProperty(helpers,"format") || (depth0 != null ? lookupProperty(depth0,"format") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"format","hash":{},"data":data,"loc":{"start":{"line":115,"column":21},"end":{"line":115,"column":31}}}) : helper)))
    + " "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"format") : depth0),"==","png",{"name":"compare","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":32},"end":{"line":115,"column":82}}})) != null ? stack1 : "")
    + "\">\n						<div class=\"row\">\n							\n							<a href=\"#\" class=\"btn col mg-none bj-button bj-button--primary button--download track\" data-format=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"format") || (depth0 != null ? lookupProperty(depth0,"format") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"format","hash":{},"data":data,"loc":{"start":{"line":118,"column":108},"end":{"line":118,"column":118}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":118,"column":127},"end":{"line":118,"column":138}}}) : helper)))
    + "\" data-track-arguments=\"ga, event, download-uicons, icon, "
    + alias4(((helper = (helper = lookupProperty(helpers,"format") || (depth0 != null ? lookupProperty(depth0,"format") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"format","hash":{},"data":data,"loc":{"start":{"line":118,"column":196},"end":{"line":118,"column":206}}}) : helper)))
    + "\" >\n								<span class=\"mg-none\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":119,"column":30},"end":{"line":119,"column":40}}}) : helper)))
    + "</span>\n							</a>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"format") : depth0),"==","png",{"name":"compare","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":7},"end":{"line":136,"column":19}}})) != null ? stack1 : "")
    + "						</div>\n					</div>		\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "btn-size";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<div class=\"popover popover-bottom\">\n									<div class=\"popover-container\" id=\"select-size\">\n										<button class=\"popover-button\">\n											<i class=\"icon icon--caret-down text-inverted\"></i>\n										</button>\n										<div class=\"popover-content font-xs alignr\">\n											<ul class=\"size\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"downloadResourceFormats") : depths[1])) != null ? lookupProperty(stack1,"free") : stack1)) != null ? lookupProperty(stack1,"png") : stack1)) != null ? lookupProperty(stack1,"sizes") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":11},"end":{"line":131,"column":20}}})) != null ? stack1 : "")
    + "											</ul>\n										</div>\n									</div>\n								</div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "												<li><a href=\"#\" class=\"button--download track\" data-size=\""
    + alias2(alias1(depth0, depth0))
    + "\" data-track-arguments=\"ga, event, download-uicons, icon, "
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"format") : depths[1]), depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "px</a></li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n				<div class=\"accordion mg-none accordion--ready active\" data-closable=\"true\">\n\n					<div class=\"accordion__container\">\n						<span class=\"accordion__link font-md link\">\n							CDN\n							<i class=\"icon icon--md icon--fold floatr icon--mg-sm\"></i>\n						</span>\n						<div class=\"accordion__content\">\n							<div class=\"download-cdn\">\n							<p class=\"font-sm\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"cdn_text") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n								<div class=\"group--inputs\">\n\n									<div class=\"group group--inputs-cdn mg-bottom-lv2\">\n										<div class=\"group__input\">\n											<input class=\"size--md cdn-style-link cdn--input\" type=\"text\" readonly=\"\" value=\"<link rel='stylesheet' href='https://cdn-uicons.flaticon.com/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"UICONS_LAST_VERSION") : stack1), depth0))
    + "/uicons-"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isFamilyBrands") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":159,"column":197},"end":{"line":159,"column":332}}})) != null ? stack1 : "")
    + "/css/uicons-"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isFamilyBrands") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":159,"column":344},"end":{"line":159,"column":479}}})) != null ? stack1 : "")
    + ".css'>\">\n										</div>\n										<div class=\"group__context pd-none\">\n											<button class=\"bj-button bj-button--md bj-button--icon bj-button--link-dark copyCdn--button\">\n											<i class=\"icon icon--duplicate icon--sm\"></i>\n											</button>\n										</div>\n									</div>\n\n									<div class=\"group group--inputs-cdn\">\n										<div class=\"group__input\">\n											<input class=\"size--md cdn-style-import cdn--input\" type=\"text\" readonly=\"\" value=\"@import url('https://cdn-uicons.flaticon.com/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"UICONS_LAST_VERSION") : stack1), depth0))
    + "/uicons-"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isFamilyBrands") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":170,"column":183},"end":{"line":170,"column":318}}})) != null ? stack1 : "")
    + "/css/uicons-"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isFamilyBrands") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":170,"column":330},"end":{"line":170,"column":465}}})) != null ? stack1 : "")
    + ".css')\">\n										</div>\n										<div class=\"group__context pd-none\">\n											<button class=\"bj-button bj-button--md bj-button--icon bj-button--link-dark copyCdn--button\">\n											<i class=\"icon icon--duplicate icon--sm\"></i>\n											</button>\n										</div>\n									</div>\n								</div>\n\n							\n							</div>\n						</div>\n					</div>\n\n					<div class=\"accordion__container\">\n						<span class=\"accordion__link font-md link\">\n							"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"full_style") : stack1), depth0)) != null ? stack1 : "")
    + "\n							<i class=\"icon icon--md icon--fold floatr icon--mg-sm\"></i>\n						</span>\n						<div class=\"accordion__content\">\n							<div class=\"download-pack\">\n								<p class=\"font-sm\">\n									"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"download_text") : stack1), depth0)) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"numberOfUiconsByStyleText") : depth0),"!=",null,{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":194,"column":9},"end":{"line":196,"column":21}}})) != null ? stack1 : "")
    + "								</p>\n								<div class=\"row\">\n									<a href=\"#\" id=\"download-pack-uicons\" data-prefix=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"prefix") : stack1), depth0))
    + "\" class=\"bj-button bj-button--outline bj-button--download bj-button--gray is-registered mg-lv1\" data-style=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"styleName") : stack1), depth0))
    + "\" data-family=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"familyName") : stack1), depth0))
    + "\" data-packid=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"pack") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n										<i class=\"icon icon--download\"></i>\n										<span class=\"capitalize\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"isFamilyBrands") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":202,"column":11},"end":{"line":206,"column":23}}})) != null ? stack1 : "")
    + "											</span><span class=\"mg-left-lv1-i\">pack</span>											\n									</a>\n								</div>\n							</div>\n						</div>\n					</div>\n\n					<div class=\"accordion__container\">\n						<span class=\"accordion__link font-md link\">\n							Package manager\n							<i class=\"icon icon--md icon--fold floatr icon--mg-sm\"></i>\n						</span>\n						<div class=\"accordion__content\">\n							<div class=\"package-manager\">\n								<p class=\"font-sm mg-none\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"packageManagerText") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n								<p class=\"font-sm mg-none\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"findOutMoreHere") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n								<div class=\"group--inputs\">\n									<div class=\"group group--inputs-npm mg-bottom-lv2 mg-top-lv2\">\n										<div class=\"group__input\">\n											<input class=\"size--md npm-style-link npm--input\" type=\"text\" readonly=\"\" value=\"npm i @flaticon/flaticon-uicons\">\n										</div>\n										<div class=\"group__context pd-none\">\n											<button class=\"bj-button bj-button--md bj-button--icon bj-button--link-dark copyNpm--button\">\n											<i class=\"icon icon--duplicate icon--sm\"></i>\n											</button>\n										</div>\n									</div>\n								</div>\n							</div>\n						</div>\n					</div>\n\n				</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"familyName") : stack1), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"styleName") : stack1), depth0))
    + "-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"familyName") : stack1), depth0));
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										"
    + ((stack1 = (lookupProperty(helpers,"replace")||(depth0 && lookupProperty(depth0,"replace"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"%s",(depth0 != null ? lookupProperty(depth0,"numberOfUiconsByStyleText") : depth0),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"download_size_advice") : stack1),{"name":"replace","hash":{},"data":data,"loc":{"start":{"line":195,"column":10},"end":{"line":195,"column":92}}})) != null ? stack1 : "")
    + "										\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "												"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"familyName") : stack1), depth0))
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "												"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"styleName") : stack1), depth0))
    + "/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"familyName") : stack1), depth0))
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"no-registered alignc\">\n\n						<img src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/uicons.svg\" alt=\"uicons logo\" width=\"100\">\n						<p class=\"mg-none\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"register_to_download") : stack1), depth0))
    + "</p>\n						<a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"GOOGLE_IDP_URL_REGISTER") : stack1), depth0))
    + "\" class=\"bj-button bj-button--primary track\" data-track-arguments=\"ga, event, uicons, register\">\n							"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"register_start") : stack1), depth0))
    + "\n						</a>\n					</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"uicon-detail pd-bottom-lv2-i\">\n	<div class=\"uicon-detail__header mg-bottom-lv3\">\n		<div class=\"uicon-detail__header--logo\">\n			<img src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"URL_STATIC_IMG") : stack1), depth0))
    + "uicons/detail/uicons.svg\" alt=\"uicons logo\" width=\"100\">\n\n			<span class=\"tooltip tooltip--bottom mg-left-lv1\">\n\n                    <i class=\"tooltip__trigger tooltip__trigger--always icon icon--info\"></i>\n\n                    <div class=\"tooltip__content\">\n                        <div class=\"content alignl font-xs\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"uicons_info") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n                    </div>\n                </span>\n		</div>\n		<p class=\"mg-none font-sm\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"uicons") : stack1)) != null ? lookupProperty(stack1,"need_help") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n\n	</div>\n  <h1 class=\"mg-none font-xl\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"nameDetail") : stack1), depth0))
    + "</h1>\n  <div class=\"uicon-version\"></div>\n</div>\n\n<div class=\"uicon-detail-download uicons__detail\">	\n\n		<div class=\"copy-class\">\n			<div class=\"group group--inputs mg-bottom-lv2\">\n				<div class=\"group__input\">\n					<code>\n						<span class=\"purple\">&lt;i</span> \n						<span class=\"green mg-left-lv1\">class=</span>\n						<span class=\"icon-classname\" id=\"uicons__detail-classname\">\"fi "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"class") : stack1), depth0))
    + "\"</span>\n						<span class=\"purple\">&gt;&lt;/i&gt;</span>\n					</code>\n					<input id=\"uicons-code\" type=\"hidden\" value=\"<i class=&quot;fi "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"class") : stack1), depth0))
    + "&quot;></i>\" >\n				</div>\n				<div class=\"group__context pd-none\">\n					<span class=\"tooltip tooltip--left\">\n						<button class=\"bj-button bj-button--sm  bj-button--ghost button--copy tooltip__trigger tooltip__trigger--always\">\n							<i class=\"icon icon--duplicate icon--sm\"></i>\n							<i class=\"icon icon--check icon--md\"></i>\n						</button>\n						<div class=\"tooltip__content\">\n							<div class=\"content capitalize\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"copy") : stack1), depth0))
    + "</div>\n						</div>\n					</span>\n\n				</div>\n			</div>  \n		</div>\n\n\n		<div class=\"copy-share mg-bottom-lv3\">\n\n			<span class=\"btn-copy detail__copypng tooltip tooltip--bottom\">\n				<button class=\"full-width copypng--button tooltip__trigger tooltip__trigger--always bj-button bj-button--secondary bj-button--outline track\" data-track-arguments=\"ga, event, detail-view, copyPNG, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-gtm-event=\"copy detail png\">\n						<i class=\"icon icon--copy-image\"></i>\n						<span>Copy PNG</span>\n				</button>\n				<div class=\"tooltip__content\">\n						<div class=\"content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"copyPNG") : stack1), depth0))
    + "</div>\n				</div>\n			</span>\n\n			<div class=\"btn-share share popover popover-share\">\n				<div class=\"full-width popover-container popover-not-close\">\n					<button class=\"full-width popover-button bj-button bj-button--secondary bj-button--outline\">\n						<i class=\"icon icon--share\"></i><span>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"share") : stack1), depth0))
    + "</span>\n					</button>\n					<ul class=\"popover-content\">\n						<li class=\"mg-bottom-lv2\">\n							<a href=\"#\" onclick=\"share.popup({network: 'pinterest'});return false;\" class=\"bj-button bj-button--sm bj-button--pinterest full-width\"\n								title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"share_in_pt") : stack1), depth0))
    + "\" data-gtm-event=\"pinterest detail\">\n								<i class=\"icon icon--pinterest\"></i><span>Pinterest</span>\n							</a>\n						</li>\n						<li class=\"mg-bottom-lv2\">\n							<a href=\"#\" onclick=\"share.popup({network: 'facebook'});return false;\" class=\"bj-button bj-button--sm bj-button--facebook full-width\"\n								title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"share_in_fb") : stack1), depth0))
    + "\">\n								<i class=\"icon icon--facebook\"></i><span>Facebook</span>\n							</a>\n						</li>\n						<li class=\"mg-bottom-lv2\">\n							<a href=\"#\" onclick=\"share.popup({network: 'twitter'});return false;\" class=\"bj-button bj-button--sm bj-button--twitter full-width\"\n								title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"share_in_tw") : stack1), depth0))
    + "\">\n								<i class=\"icon icon--twitter\"></i><span>Twitter</span>\n							</a>\n						</li>\n						<li>\n							<div class=\"group\">\n								<div class=\"group__context\">\n									<span>\n										<i class=\"icon icon--url\"></i>\n									</span>\n								</div>\n								<div class=\"group__input\">\n									<input class=\"input-share-url size--sm font-sm\" type=\"text\" value=\"\">\n								</div>\n							</div>\n						</li>\n					</ul>\n				</div>\n			</div>\n\n		</div>\n\n\n\n		<div class=\"fi-premium-icon\" id=\"fi-premium-download-buttons\">\n\n\n\n				<div class=\"download download-action mg-bottom-lv4\" id=\"download\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"downloadResourceFormats") : depth0)) != null ? lookupProperty(stack1,"free") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":5},"end":{"line":139,"column":14}}})) != null ? stack1 : "")
    + "				</div>\n\n\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"USER_REGISTERED") : stack1),"==",1,{"name":"compare","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":4},"end":{"line":240,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"USER_REGISTERED") : stack1),"==",0,{"name":"compare","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":241,"column":4},"end":{"line":250,"column":16}}})) != null ? stack1 : "")
    + "\n\n	</div>\n	    <form id=\"download-uicon-form\" method=\"get\" action=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"FLATICON_DOWNLOAD_URL") : stack1), depth0))
    + "download/icon/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n            <input type=\"hidden\" id=\"icon_id\" name=\"icon_id\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"/>\n            <input type=\"hidden\" id=\"team\" name=\"team\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"/>\n            <input type=\"hidden\" id=\"format\" name=\"format\" value=\"png\" />\n            <input type=\"hidden\" id=\"colored\" name=\"colored\" value=\"0\" />\n            <input type=\"hidden\" id=\"type\" name=\"type\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\" />\n            <input type=\"hidden\" id=\"size\" name=\"size\" value=\"512\" />\n        </form>\n        <form id=\"download-edited-uicon-form\" method=\"post\" action=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"FLATICON_DOWNLOAD_URL") : stack1), depth0))
    + "download/icon/edited"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n            <input type=\"hidden\" id=\"id\" name=\"id\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"/>\n            <input type=\"hidden\" id=\"name\" name=\"name\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\"/>\n            <input type=\"hidden\" id=\"format\" name=\"format\" value=\"svg\" />\n            <input type=\"hidden\" id=\"code\" name=\"code\" value=\"\" />\n            <input type=\"hidden\" id=\"color\" name=\"color\" value=\"1\" />\n            <input type=\"hidden\" id=\"type\" name=\"type\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\" />\n            <input type=\"hidden\" id=\"size\" name=\"size\" value=\"512\" />\n        </form>\n\n</div>";
},"useData":true,"useDepths":true});