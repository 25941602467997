var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"author\">\n		<div class=\"row row--vertical-center mg-none\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"authorLinks") : depth0),"==",1,{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":3},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"authorLinks") : depth0),"==",0,{"name":"compare","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":3},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + "			<div>\n				<button class=\"bj-button bj-button--sm bj-button--outline btn--follow js_follow mg-left-lv2\" id=\"js_follow-author\" data-type=\"author\" data-elementid=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"groupId") : stack1), depth0))
    + "\">\n					<div class=\"state--inactive\">\n						<span>"
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"follow") : stack1), depth0))
    + "</span>\n					</div>\n					<div class=\"state--active\">\n						<span class=\"normal\">"
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"following") : stack1), depth0))
    + "</span>\n						<span class=\"hover\">"
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"unfollow") : stack1), depth0))
    + "</span>\n					</div>\n				</button>\n			</div>\n		</div>\n	</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"col mg-none\">\n				<input type=\"hidden\" id=\"author-url\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" />\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"url") : stack1),"!=",((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"freepik_url") : stack1),{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":10,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"url") : stack1),"==",((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"attribution") : stack1)) != null ? lookupProperty(stack1,"freepik_url") : stack1),{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":16}}})) != null ? stack1 : "")
    + "				<span class=\"avatar avatar-60\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\n					<img src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"avatar") : stack1), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" width=\"40\" height=\"40\">\n				</span>\n				<div class=\"col mg-none mg-left-lv2-i\">\n					<span class=\"username\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n					<span class=\"resources\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"totalIconsText") : stack1), depth0))
    + "</span>\n				</div>\n				</a>	\n			</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<a class=\"row row--vertical-center mg-none\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a class=\"row row--vertical-center mg-none\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"APP_URL") : stack1), depth0))
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"url") : stack1)) != null ? lookupProperty(stack1,"authors") : stack1), depth0))
    + "/freepik\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"col mg-none\">\n				<input type=\"hidden\" id=\"author-url\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" />	\n				<div class=\"row row--vertical-center mg-none\">\n					<span class=\"avatar avatar-60\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">\n						<img src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"avatar") : stack1), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" width=\"40\" height=\"40\">\n					</span>\n					<div class=\"col mg-none mg-left-lv2-i\">\n						<span class=\"username\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n						<span class=\"resources\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"author") : stack1)) != null ? lookupProperty(stack1,"totalIconsText") : stack1), depth0))
    + "</span>\n					</div>\n				</div>	\n			</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"author__holder\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"team") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":51,"column":8}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});