import { cookie } from 'Library/cookie';

export function storeUrl(url: string) {
    var not = ['downloads-abuse', 'downloads-limit', 'limite-descargas', 'abuso-descargas', 'user/follow/author/list'];
    if (!url) {
        url = window.location.href;
    }
    var ok = true;

    not.forEach(function(urlNot: string) {
        if (url.indexOf(urlNot) != -1) {
            ok = false;
        }
    });

    if (ok) {
        var cbUrl = null;
        try {
            var urlObj = new URL(url);
            var urlParams = urlObj.searchParams;
            var now = Date.now().toString();
            if (!urlParams.has('k')) {
                urlParams.append('k', now);
            } else {
                urlParams.set('k', now);
            }
            cbUrl = urlObj.origin + urlObj.pathname + '?' + urlParams.toString();
        } catch (e) {
            cbUrl = url;
        }

        var date = new Date();
        cookie.setItem(`CB_URL`, cbUrl, {
            expires: new Date(date.getTime() + 60 * 60 * 1000),
        });
    }
}

export const getUrlParam = (param: string) => {
    let urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
};

export const urlRedirect = (data?: any) => {
    if (typeof data == 'undefined') {
        if ((window.location.href.match(/#/g) || []).length > 0) window.location.reload();
        else window.location.href = window.location.href;
    } else if (typeof data.redirect_url != 'undefined') window.location.href = data.redirect_url;
    else window.location.href = data;
};

export const cleanQueryString = (query: any) => {
    if (query != '' && typeof query !== 'undefined') {
        // limpiamos los parametros de style y pack
        // query = query.replace(/pack_id=(\d)*/g, "").replace(/style_id=(\d)*/g, "");
        query = query.replace(/pack_id=(\d)*/g, '');
        // limpiar la query de dobles &&, & finales y valores vacios
        query = query
            .replace('&&', '&')
            .replace(/[^=&]+=(&|$)/g, '')
            .replace(/^&|&$/, '');
    }
    return query;
};

export const urlWithoutPag = (url: string) => {
    let pag = detectPagination(url);
    let out = null;
    if (pag != 0) {
        out = url.substring(0, url.lastIndexOf('/'));
    } else {
        var qs = window.location.search;
        if (qs != '' && url == window.location.href) {
            out = url.substring(0, url.lastIndexOf('?'));
        } else {
            out = url;
        }
    }
    return out;
};

export const detectPagination = (url: string) => {
    let pag = 0;
    let init = url.lastIndexOf('/');
    let end = url.length;

    if (url.lastIndexOf('?') != -1) {
        end = url.lastIndexOf('?');
    }

    if (init != -1) {
        pag = parseInt(url.substring(init + 1, end));
    }

    return isNaN(pag) ? 0 : pag;
};

export const getUrlNavigation = (url: string, pag: string, query: string) => {
    // Remove trailing '/' characters
    var nStrip = 0;
    while (url.length > nStrip && url[url.length - (nStrip + 1)] == '/') {
        nStrip += 1;
    }
    if (nStrip > 0) {
        url = url.slice(0, -nStrip);
    }

    if (pag != '' && typeof pag !== 'undefined' && parseInt(pag) > 1) {
        url = url + '/' + pag;
    }

    if (query != '' && typeof query !== 'undefined') {
        // limpiar la query de dobles &&, & finales y valores vacios
        query = cleanQueryString(query);
        url = url.replace('///$/', '/');
        url = url.replace('#', '');
        url = query.lastIndexOf('?') == -1 ? url + '?' + query : url + query;
    }
    return url;
};

export const setUrlNavigator = (url: string, pag: string, query: string) => {
    var urlEnd = getUrlNavigation(url, pag, query);

    if ('history' in window) {
        if (typeof window.history.pushState == 'function') {
            urlEnd.indexOf('undefined') == -1
                ? window.history.pushState('', document.title, urlEnd)
                : window.history.pushState('', document.title, APP_URL);
        }
    }
};

export const updateUrlState = (nextUrl: string, nextTitle: string, stateInfo: string) => {
    const nextState = { additionalInformation: stateInfo };

    window.history.pushState(nextState, nextTitle, nextUrl);
};
