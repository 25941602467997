import { ww } from 'bobjoll/ts/library/dom';
import { EventTracker } from 'Library/eventTacker';

const urlTypes: UrlType[] = [
    {
        urlParam: 'sign-up',
        urlValue: 'email',
        localStorageValue: 'authGA4.signUpOK',
        event: 'sign_up_ok',
    },
    {
        urlParam: 'verify-email',
        urlValue: 'email',
        location: '/sign-up',
        localStorageValue: 'authGA4.verifyEmail',
        event: 'sign_up_verified_account',
    },
    {
        urlParam: 'log-in',
        urlValue: 'email',
        localStorageValue: 'authGA4.logIn',
        event: 'login_ok',
    },
    {
        urlParam: 'update-password',
        urlValue: 'email',
        localStorageValue: 'authGA4.updatePassword',
        event: 'password_updated',
    },
];

export const handleLoginEventsListener = () => {
    const logged = ww.UserSession.userInfo.logged;
    if (!logged) {
        clearAuthStorage();
        return;
    }
    const url = new URL(window.location.href);
    const urlLoginType = urlTypes.find(urlType => url.searchParams.get(urlType.urlParam));
    if (!urlLoginType) return;
    if (localStorage.getItem(urlLoginType.localStorageValue)) return;
    const pageLocation = urlLoginType.location
        ? `${urlLoginType.location}${url.searchParams.get(urlLoginType.urlParam) === 'email' ? '/mail' : ''}`
        : url.toString();
    EventTracker.sendGA4Event(urlLoginType.event, pageLocation);
    localStorage.setItem(urlLoginType.localStorageValue, urlLoginType.urlParam);
    ww.hj('event', 'nps-post-login');
};

export const clearAuthStorage = () => {
    const arrayOfKeys = Object.keys(localStorage);

    arrayOfKeys.forEach(key => {
        if (key.startsWith('authGA4.')) {
            localStorage.removeItem(key);
        }
    });
};

interface UrlType {
    urlParam: string;
    urlValue: string;
    location?: string;
    localStorageValue: string;
    event: string;
}
