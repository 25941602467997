/* eslint-disable @typescript-eslint/camelcase */
import { createPopper } from '@popperjs/core';
import { ww } from 'bobjoll/ts/library/dom';
import { resourceTypes } from 'CommonApp/resource/resourceConfigService';
import { cookie } from 'Library/cookie';
import { EventTracker } from 'Library/eventTacker';
import { capitalize, filterText } from 'Library/helpers/text';
import getXHR from 'Partials/xhr';
import { FilteredResults, FilterParams, FiltersValue, ItemData, QueryParams } from 'Pods/filters/filters.vm';
import { cleanQueryString, setUrlNavigator, urlWithoutPag } from 'Project/ts/common/urls.helper';
import { LazyModal } from 'Project/ts/pods/modals/lazyModal';
import User from 'Project/ts/pods/user/main';
import Lazyload from 'UI/lazyload';

export default class NewFilters {
    private static allPackviewFilterInputs: HTMLInputElement[] = Array.from(
        document.querySelectorAll('.pack-view__filters input'),
    );
    private static packId: HTMLFormElement | null = null;
    private static popoverEquivalentsElement = document.getElementById('popover-equivalents') as HTMLElement | null;
    private static mainsearchStyleIdElement = document.getElementById(
        'main-search-style_id',
    ) as HTMLInputElement | null;
    private static doSubmit: number = 1;
    private static queryString: string;
    private static searchFormCss: HTMLFormElement | null = null;
    private static orderPopularityInput = document.getElementById('order_by-popularity') as HTMLInputElement;
    private static orderDateInput = document.getElementById('order_by-date') as HTMLInputElement;
    private static filtersValue: FiltersValue = {
        color: {
            black: 'black',
            multicolor: 'color',
            gradient: 'gradient',
        },

        shape: {
            outline: 'outline',
            fill: 'fill',
            linealColor: 'lineal-color',
            handDrawn: 'hand-drawn',
        },
    };

    public static init() {
        this.setCheckedPackviewFilterInput();
        this.setCheckedPopoverEquivalentsInput();
        this.setDefaultMostDownloadPage();
        this.setDefaultLatestIconsPage();
        this.searchInPack();
        this.sendEventScrollSearchRelatedPack();
        this.handleFiltersEvents();
    }

    private static handleFiltersEvents() {
        const filtersBodyElements: HTMLElement | null = document.querySelector('#form_filters .filters-body');
        const spinnerLoader: HTMLElement | null = document.querySelector('#form_filters .circle-spinner');
        window.addEventListener('load', () => {
            if (filtersBodyElements && spinnerLoader) {
                filtersBodyElements.style.display = 'block';
                spinnerLoader.style.display = 'none';
            }
            this.loadPackEquivalent();
            this.handleFilterOrder();
            this.queryStringToForm();
            this.setFilterEquivalences();
            this.setCookieFlaticonSelection();
            this.handleSortByTitle();
            this.handleToggleFilters();
            document
                .querySelector('.filters-applied__header .clear-all')
                ?.addEventListener('click', NewFilters.resetFilters);

            this.handleGroupedCheckboxFilters();
            this.handlePlainColorButton();

            if (NewFilters.inIconGroupByRecentTrending()) {
                this.handleGroupedCheckboxFilters();
            }
            if (ww.FeatureFlag.isEnabled('filter_group_by')) {
                this.handleViewAsFilter();
            }
        });
    }

    private static inIconSearchWithPlainColor() {
        return ww.view.section === 'icon-search' || ww.view.section === '404';
    }

    private static inIconGroupByRecentTrending() {
        return ww.view.section === 'icon-search' || ww.view.section === 'pack-search';
    }

    private static handlePlainColorButton = () => {
        const filterColorsContainer = document.getElementById('form_filters');

        if (!filterColorsContainer) {
            return;
        }

        const plainColorButton = filterColorsContainer.querySelector('.item-plain-color');

        if (!plainColorButton) {
            return;
        }

        const colorOptions: HTMLInputElement[] = Array.from(
            filterColorsContainer.querySelectorAll('input[type=checkbox]') ?? [],
        );

        const plainColorOptions: HTMLInputElement[] = Array.from(
            filterColorsContainer.querySelectorAll('.plain-colors input[type=checkbox], input#color-black') ?? [],
        );

        const tooglePlainColorButton = () => {
            const isPlainColorSelected = plainColorOptions.some(item => item.checked && item.id !== 'color-black');
            const isBlackPlainColorSelected = plainColorOptions.some(item => item.checked && item.id === 'color-black');

            NewFilters.handleSetMainColorButton(plainColorButton, isPlainColorSelected, isBlackPlainColorSelected);
        };

        colorOptions.forEach((option: HTMLInputElement) => {
            option.addEventListener('change', tooglePlainColorButton);
        });

        const togglePlainColorsContainer = () => {
            const isSelected = plainColorButton.classList.contains('item-plain-color-selected');

            NewFilters.handleSetMainColorButton(plainColorButton);

            if (!isSelected) {
                return;
            }

            plainColorOptions.filter(item => item.checked).forEach(item => item.click());
        };

        plainColorButton.addEventListener('click', togglePlainColorsContainer);
    };

    private static handleSetMainColorButton = (
        plainColorButton: Element,
        isPlainColorSelected?: boolean,
        isBlackPlainColorSelected?: boolean,
    ) => {
        const inputColorColor = document.getElementById('color-color') as HTMLInputElement;
        if ((inputColorColor.checked === true || isPlainColorSelected) && !isBlackPlainColorSelected) {
            plainColorButton.classList.add('item-plain-color-opened', 'item-plain-color-selected');
        } else {
            plainColorButton.classList.remove('item-plain-color-opened', 'item-plain-color-selected');
            inputColorColor.checked = false;
        }
    };

    private static handleGroupedCheckboxFilters = () => {
        const groups: HTMLElement[] = Array.from(
            document
                .getElementById('form_filters')
                ?.querySelectorAll('#filters--colors, #filters--shape, #filters--sort-by') ?? [],
        );

        groups.forEach(group => {
            const inputRadioToggle: HTMLInputElement[] = Array.from(
                group?.querySelectorAll('input[type=checkbox]') ?? [],
            );

            inputRadioToggle.forEach(input => {
                input.addEventListener('click', e => {
                    if (!input.checked) {
                        return;
                    }

                    const currentTarget = e.currentTarget as HTMLInputElement;

                    if (
                        currentTarget.id === 'color-black' ||
                        currentTarget.id === 'color-color' ||
                        currentTarget.id === 'color-gradient'
                    ) {
                        inputRadioToggle.filter(item => item !== input).forEach(item => (item.checked = false));
                    } else {
                        inputRadioToggle
                            .filter(item => item !== input && item.id !== 'color-color')
                            .forEach(item => (item.checked = false));
                    }
                });
            });
        });
    };

    public static handleViewAsFilter = () => {
        const inputsSearchGroup: HTMLInputElement[] = Array.from(
            document.querySelectorAll('input[name="search-group"]'),
        );

        const inputSelected = inputsSearchGroup.find(input => input.checked);
        const labelToSelect = document.querySelector(`#filters--group-by [for=${inputSelected?.id}]`);

        document.querySelectorAll('#filters--group-by label').forEach(label => {
            label.classList.remove('active');
        });

        if (labelToSelect) {
            labelToSelect.classList.add('active');
        }
    };

    private static handleToggleFilters = () => {
        document.getElementById('search__filters')?.addEventListener('change', e => {
            const inputCheckBox = e.currentTarget as HTMLInputElement;
            if (inputCheckBox.checked === true) {
                cookie.setItem('fo', '1');
            } else {
                cookie.setItem('fo', '0');
            }
            inputCheckBox.classList.add('show-filters-in-mobile');
        });
    };

    private static setCheckedPackviewFilterInput() {
        let inputTargetElement: HTMLInputElement | null | undefined =
            this.packId && this.packId.value
                ? this.allPackviewFilterInputs.find(inputElement => inputElement.value === this.packId?.value)
                : null;

        if (this.allPackviewFilterInputs.length > 0) {
            this.packId = document.getElementById('pack_id') as HTMLFormElement;
        }

        if (inputTargetElement) {
            inputTargetElement.checked = true;
        }
    }

    private static setCheckedPopoverEquivalentsInput() {
        let popoverInputTarget: HTMLInputElement | null =
            this.popoverEquivalentsElement && this.mainsearchStyleIdElement
                ? this.popoverEquivalentsElement.querySelector(
                      `input[data-styleid="${this.mainsearchStyleIdElement.value}"]`,
                  )
                : null;
        popoverInputTarget ? (popoverInputTarget.checked = true) : '';
    }

    private static setInputParameter(key: string, value: string) {
        NewFilters.searchFormCss = document.querySelector('section.search-holder form');
        if (NewFilters.searchFormCss) {
            if (document.getElementById(`main-search-${key}`)) {
                (document.getElementById(`main-search-${key}`) as HTMLInputElement).value = value;
            }
        }
    }

    private static changeRelatedLinks() {
        let linksElementsArray: HTMLAnchorElement[] = Array.from(document.querySelectorAll('.related-search a'));
        if (linksElementsArray.length > 0) {
            linksElementsArray.forEach(related => {
                let linkRelated = related.href.replace(/\?(.)*/, '');
                related.href = `${linkRelated}${NewFilters.formToQuerystring()}`;
            });
        }
    }

    private static formToQuerystring() {
        let queryStringOutput = '';
        const excludeInputsName: string[] = ['search-type', 'search-group'];
        const searchBarElement: HTMLFormElement | null = document.querySelector('.main_search_bar');
        if (searchBarElement) {
            const inputs = searchBarElement.querySelectorAll('input');
            let queryStringInputArray: string[] = [];
            if (inputs.length) {
                inputs.forEach(field => {
                    if (excludeInputsName.indexOf(field.name)) {
                        NewFilters.pushFiltersInput(queryStringInputArray, field);
                    }
                });

                queryStringInputArray.length > 0 ? (queryStringOutput = `?${queryStringInputArray.join('&')}`) : '';
            }
        }

        return queryStringOutput;
    }

    private static pushFiltersInput(inputFilterArray: string[], inputFilterElement: HTMLInputElement) {
        inputFilterArray.push(
            `${encodeURIComponent(inputFilterElement.name)}=${encodeURIComponent(inputFilterElement.value)}`,
        );
        return inputFilterArray;
    }

    private static loadPackEquivalent() {
        this.allPackviewFilterInputs.forEach((inputElement: HTMLInputElement) => {
            inputElement.addEventListener('click', event => {
                const targetElement = event.target as HTMLInputElement;
                const packId = targetElement.value;
                const {
                    icontype: iconType,
                    dropdown: equivalentPackStyleValue,
                    stylelink: equivalentPackStyleLink,
                    stylename: equivalentPackStyleName,
                    styleid: equivalentPackStyleId,
                    licenselink: equivalentPackLicenselink,
                    premium: equivalentPackPremium,
                }: DOMStringMap = targetElement.dataset;
                const packName = document.querySelector('.pack-view__header--title span.title')?.textContent as string;
                const packEquivalentIcon = document.querySelector('h1.pack-view__header--title i') as HTMLElement;
                const searchStyleItem = document.querySelector('.tag-field__item') as HTMLElement;
                const packEquivalentStyleAnchorElement = document.querySelector(
                    '.author .style-link',
                ) as HTMLAnchorElement;
                const formDownloadPack = document.getElementById('form-download-pack') as HTMLFormElement;
                const buttonDownloadDivElement = document.querySelector(
                    '.sidebar__download__buttons > div',
                ) as HTMLElement;
                const licenseElement = document.getElementById('pack-lincense') as HTMLElement;
                const buttonPatternElement = document.querySelector('.btn-pattern') as HTMLButtonElement;
                const stylePackAnchorElement = document.querySelector('.pack-view .style-link a') as HTMLAnchorElement;

                if (packName && equivalentPackStyleValue) {
                    this.handlePackEquivalentH1(packName, equivalentPackStyleValue);
                }

                if (packEquivalentIcon && equivalentPackPremium) {
                    this.handlePackEquivalentIcon(packEquivalentIcon, equivalentPackPremium);
                }

                if (searchStyleItem && equivalentPackStyleId && equivalentPackStyleValue) {
                    this.handlePackEquivalentSearchStyleItem(
                        searchStyleItem,
                        equivalentPackStyleId,
                        equivalentPackStyleValue,
                    );
                }

                if (packEquivalentStyleAnchorElement && equivalentPackStyleLink && equivalentPackStyleValue) {
                    this.handlePackEquivalentHeaderBreadcrumb(
                        packEquivalentStyleAnchorElement,
                        equivalentPackStyleLink,
                        equivalentPackStyleValue,
                    );
                }

                if (formDownloadPack && packId) {
                    this.handlePackEquivalentDownloadForm(formDownloadPack, packId);
                }

                if (buttonPatternElement && equivalentPackPremium) {
                    this.handlePackEquivalentPatternButton(buttonPatternElement, equivalentPackPremium);
                }

                if (
                    stylePackAnchorElement &&
                    equivalentPackStyleLink &&
                    equivalentPackStyleName &&
                    equivalentPackStyleId
                ) {
                    this.handlePackEquivalentStyle(
                        stylePackAnchorElement,
                        equivalentPackStyleLink,
                        equivalentPackStyleName,
                        equivalentPackStyleId,
                    );
                }

                if (packId && iconType) {
                    this.handlePackEquivalentgetIcons(packId, iconType);
                }

                history.pushState({ page: 'packs' }, '', targetElement.dataset.packlink);
                this.doSubmit = 1;
                this.axSearch(0);
                LazyModal.callModalLicense();
                LazyModal.callModalAttribution();
            });
        });
    }

    private static handlePackEquivalentH1(packName: string, packStyleValue: string) {
        const packViewHeaderTitle = document.querySelector('.pack-view__header--title span.title');
        const packViewHeaderTitleStyle = document.querySelector('.pack-view__header--title span.title-style');
        packViewHeaderTitle ? (packViewHeaderTitle.innerHTML = capitalize(packName)) : '';
        packViewHeaderTitleStyle ? (packViewHeaderTitleStyle.innerHTML = capitalize(packStyleValue)) : '';
    }

    private static handlePackEquivalentIcon(packEquivalentIcon: HTMLElement, equivalentPackPremium: string) {
        packEquivalentIcon ? packEquivalentIcon.classList.remove('icon', 'icon--premium', 'flaticon-selection') : '';
        equivalentPackPremium == '1' ? packEquivalentIcon.classList.add('icon', 'icon--premium') : '';
    }

    private static handlePackEquivalentSearchStyleItem(
        searchStyleItem: HTMLElement,
        equivalentPackStyleId: string,
        equivalentPackStyleValue: string,
    ) {
        if (searchStyleItem) {
            searchStyleItem.dataset.value = equivalentPackStyleId;
            searchStyleItem.innerHTML = `${messages.filters.style}: ${equivalentPackStyleValue} <span class="remove">×</span>`;
            this.setInputParameter('style_id', equivalentPackStyleId);
        }
    }

    private static handlePackEquivalentHeaderBreadcrumb(
        packEquivalentStyleAnchorElement: HTMLAnchorElement,
        equivalentPackStyleLink: string,
        equivalentPackStyleValue: string,
    ) {
        if (packEquivalentStyleAnchorElement && typeof packEquivalentStyleAnchorElement != 'undefined') {
            const packEquivalentStyleAnchorChildren = packEquivalentStyleAnchorElement.querySelector('.style');
            packEquivalentStyleAnchorElement.href = equivalentPackStyleLink;
            packEquivalentStyleAnchorChildren
                ? (packEquivalentStyleAnchorChildren.innerHTML = equivalentPackStyleValue)
                : '';
        }
    }

    private static handlePackEquivalentDownloadForm(formDownloadPack: HTMLFormElement, packId: string) {
        if (formDownloadPack != null && typeof formDownloadPack != 'undefined') {
            const formDownloadPackTarget = document.querySelector(
                '#form-download-pack [name="pack_id"]',
            ) as HTMLFormElement;
            const buttonDownloadPackTarget = document.querySelector('.btn-download-pack') as HTMLButtonElement;
            formDownloadPackTarget ? (formDownloadPackTarget.value = packId) : '';
            buttonDownloadPackTarget ? (buttonDownloadPackTarget.dataset.pack = packId) : '';
        }
    }

    private static handlePackEquivalentPatternButton(
        buttonPatternElement: HTMLButtonElement,
        equivalentPackPremium: string,
    ) {
        if (buttonPatternElement) {
            if (equivalentPackPremium == '1' && !User.isPremium()) {
                buttonPatternElement.setAttribute('disabled', 'disabled');
                buttonPatternElement.classList.add('disabled');
            } else if (equivalentPackPremium && !User.isPremium()) {
                buttonPatternElement.removeAttribute('disabled');
                buttonPatternElement.classList.remove('disabled');
            }
        }
    }

    private static handlePackEquivalentStyle(
        stylePackAnchorElement: HTMLAnchorElement,
        equivalentPackStyleLink: string,
        equivalentPackStyleName: string,
        equivalentPackStyleId: string,
    ) {
        if (stylePackAnchorElement != null) {
            const buttonBookmarkElement = document.querySelector(
                '.pack-view .style-link .btn--bookmark',
            ) as HTMLButtonElement;
            stylePackAnchorElement.href = equivalentPackStyleLink;
            stylePackAnchorElement.innerHTML = equivalentPackStyleName;
            buttonBookmarkElement ? (buttonBookmarkElement.dataset.elementid = equivalentPackStyleId) : '';
        }
    }

    private static handlePackEquivalentgetIcons(packId: string, iconType: string) {
        let packEquivalentRoute =
            iconType === 'sticker' ? '/ajax/hex/get_stickers_by_pack_id/' : '/ajax/hex/get_icons_by_pack_id/';
        if (typeof GR_LANGUAGE !== 'undefined' && ['en', 'es'].includes(GR_LANGUAGE)) {
            INFINITY.axScript = packEquivalentRoute + packId;
        } else {
            INFINITY.axScript = '/' + GR_LANGUAGE + packEquivalentRoute + packId;
        }
    }

    private static axSearch(pushUrl?: number) {
        if (this.doSubmit == 1 && INFINITY) {
            this.doSubmit = 0;
            pushUrl = !pushUrl ? pushUrl : 1;
            const formFilter = document.getElementById('form_filters') as HTMLFormElement;
            const pagination = document.querySelector('section.pagination') as HTMLElement;
            let filterParams: FilterParams = {};
            let filteredParams: string[] = [];
            let filteredParamsQuery: string;

            INFINITY.activatedInfinity = 1;
            INFINITY.initPage = 1;

            if (pushUrl && pagination) {
                pagination.classList.add('hidden');
            }

            let formFilterData = this.serializeArray(formFilter).filter((item: HTMLInputElement) => {
                return item.name !== 'style';
            });

            for (const filter in formFilterData) {
                if (formFilterData.hasOwnProperty(filter)) {
                    if (formFilterData[filter].value == '0' || !formFilterData[filter].value.length) continue;
                    if (formFilterData[filter].value == 'all') continue;
                    if (formFilterData[filter].name.includes('-')) {
                        formFilterData[filter].name = formFilterData[filter].name.split('-').pop() as string;
                    }
                    if (formFilterData[filter].value !== resourceTypes.icon) {
                        filterParams[formFilterData[filter].name] = formFilterData[filter].value;
                    }
                }
            }

            for (const newFilter in filterParams) {
                filteredParams.push(`${newFilter}=${filterParams[newFilter]}`);
            }

            filteredParamsQuery = filteredParams.join('&');

            filteredParamsQuery = cleanQueryString(filteredParamsQuery);

            let url = `${BASE_URL}${window.location.pathname.slice(1)}`;
            url = urlWithoutPag(url);

            let lastPath = url.split('/').pop();

            const areFiltersIn404View = ww.view.section === '404';

            if (lastPath == 'packs' || ww.view.section === 'pack-search') {
                setUrlNavigator(url, '', filteredParamsQuery);
            }
            if (document.body.dataset.section === 'style-search' || areFiltersIn404View) {
                setUrlNavigator(url, '', filteredParamsQuery);
                window.location.reload();
            }

            if (lastPath == 'search' || lastPath == 'resultados' || ww.view.section === 'icon-search') {
                let urlRelatedPacks = `${APP_URL}ajax/packs/slider?${filteredParamsQuery}`;
                setUrlNavigator(url, '', filteredParamsQuery);

                this.loadRelatedPacks(urlRelatedPacks);
            }

            INFINITY.initPage = INFINITY.topPage = INFINITY.bottomPage = 1;

            ww.window.scroll(0, 0);

            INFINITY.cache_items = [];

            INFINITY.setUrl = false;

            document.getElementById('pagination-spinner')?.classList.remove('hidden');

            INFINITY.infinityLoaderBtn(INFINITY.axScript, 'clear');
            Lazyload.run();
        }
    }

    private static loadRelatedPacks(url: string) {
        getXHR(url, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-type': 'application/json',
            },
            withCredentials: true,
        }).then((data: FilteredResults) => {
            let arrItems = data.items || [];
            let htmlToInject = '';

            if (ww.view.section === 'pack-search' && !arrItems.length) {
                window.location.reload();
            }

            if (arrItems.length > 1) {
                const packTpl = ww.Handlebars.tag('pack-box');

                arrItems.forEach((item: ItemData) => {
                    htmlToInject += packTpl(item);
                });
            }

            if (document.querySelectorAll('section.search-pack-result').length) {
                const searchPackResultBox = document.querySelector('section.search-pack-result .box-scroll');
                document.querySelector('section.search-pack-result')?.classList.toggle('hidden', arrItems.length < 2);
                searchPackResultBox ? (searchPackResultBox.innerHTML = htmlToInject) : '';
            }
        });
    }

    private static setDefaultMostDownloadPage() {
        window.location.pathname == messages.url.most_download && this.orderPopularityInput
            ? (this.orderPopularityInput.checked = true)
            : '';
    }

    private static setDefaultLatestIconsPage() {
        window.location.pathname == messages.url.latest_icons && this.orderDateInput
            ? (this.orderDateInput.checked = true)
            : '';
    }

    private static sendEventScrollSearchRelatedPack() {
        document.querySelector('.search-pack-result .box-scroll article a')?.addEventListener('click', () => {
            let scroll = document.querySelector('.search-pack-result div.box-scroll')?.scrollLeft ? 1 : 0;
            ga('send', 'event', 'Search', 'Related Pack', scroll);
        });
    }

    private static setCollapsepFiltersActive(inputElementTarget: HTMLInputElement) {
        const collapseItem = document.querySelector(
            `.collapsed-filters__item.${inputElementTarget.name}`,
        ) as HTMLElement | null;

        if (inputElementTarget?.value === 'all' || inputElementTarget?.checked === false) {
            collapseItem?.classList.remove('active');
        } else {
            collapseItem?.classList.add('active');
        }
        const isSomeFilterActive =
            document.querySelectorAll('.collapsed-filters__item.active').length > 0 ? true : false;
        const collapsedFiltersBadge: HTMLElement | null = document.querySelector('.collapsed-filters .icon--filter');

        if (isSomeFilterActive && collapsedFiltersBadge) {
            collapsedFiltersBadge.classList.add('active');
        } else {
            collapsedFiltersBadge?.classList.remove('active');
        }
    }

    private static handleFilterOrder() {
        Array.from(document.querySelectorAll('#form_filters input')).forEach(inputElement => {
            inputElement.addEventListener('change', event => {
                let inputElementTarget = event.currentTarget as HTMLInputElement;

                this.setSearchGroupFilter(inputElementTarget);
                this.setStyleFilters(inputElementTarget);
                this.setChoiceFilter(inputElementTarget);
                this.setCraftFilter(inputElementTarget);
                this.redirectPagesOrderDefault(inputElementTarget);
                this.changeRelatedLinks();
                this.setFilterEquivalences();
                this.setCollapsepFiltersActive(inputElementTarget);
                this.renderFiltersApplied(inputElementTarget);
                this.doSubmit = 1;

                this.axSearch();
            });
        });
    }

    private static setSearchGroupFilter(inputElementTarget: HTMLInputElement) {
        if (inputElementTarget.name == 'search-group') {
            let searchGroupfilterElement = document.querySelector(
                'form[name=search-form] input[name=search-group]',
            ) as HTMLInputElement;
            inputElementTarget.value == 'packs'
                ? (searchGroupfilterElement.checked = true)
                : (searchGroupfilterElement.checked = false);
        }
    }

    private static setStyleFilters(inputElementTarget: HTMLInputElement) {
        if (inputElementTarget.name == 'style' || inputElementTarget.name == 'shape') {
            if (inputElementTarget.dataset.style && inputElementTarget.dataset.type) {
                let elementInput: HTMLInputElement | null = document.querySelector(
                    `#form_filters input[name="main-${inputElementTarget.dataset.type}"]`,
                );
                this.setInputParameter(inputElementTarget.dataset.type, inputElementTarget.dataset.style);
                elementInput ? (elementInput.value = inputElementTarget.dataset.style) : '';
            }
        } else {
            this.setInputParameter(inputElementTarget.name, inputElementTarget.value);
        }
    }

    private static setChoiceFilter(inputElementTarget: HTMLInputElement) {
        if (inputElementTarget.name == 'choice' && inputElementTarget.checked == false) {
            const inputChoiceElement = document.querySelector(
                'input[name="choice"][type="hidden"]',
            ) as HTMLInputElement;
            inputChoiceElement ? (inputChoiceElement.value = '0') : '';
        }
    }

    private static setCraftFilter(inputElementTarget: HTMLInputElement) {
        if (inputElementTarget.name == 'craft' && inputElementTarget.checked == false) {
            const inputCraftElement = document.querySelector('input[name="craft"][type="hidden"]') as HTMLInputElement;
            inputCraftElement ? (inputCraftElement.value = '0') : '';
        } else if (inputElementTarget.name == 'craft') {
            EventTracker.send('ga4dl', {
                event: 'ga4event',
                event_name: 'filter_selection',
                filter_name: 'merchandising_license: true',
            });
        }
    }

    private static redirectPagesOrderDefault(inputElementTarget: HTMLInputElement) {
        if (inputElementTarget.checked) {
            if (inputElementTarget.getAttribute('data-value')) {
                if (inputElementTarget.name == 'order_by') {
                    const dropdownPopoverOrderElement = document.querySelector(
                        '.dropdown-popover-order',
                    ) as HTMLElement;
                    NewFilters.getQueryString();
                    inputElementTarget.dataset.value
                        ? NewFilters.redirectLastIcons(inputElementTarget.dataset.value)
                        : '';
                    inputElementTarget.dataset.value
                        ? NewFilters.redirectTopIcons(inputElementTarget.dataset.value)
                        : '';

                    if (
                        dropdownPopoverOrderElement &&
                        window.getComputedStyle(dropdownPopoverOrderElement).visibility === 'hidden'
                    ) {
                        NewFilters.doSubmit = 1;
                        NewFilters.axSearch();
                        return;
                    }
                }
            } else {
                ga('send', 'event', 'Search', 'Flaticon Selection');
            }
        }
    }

    private static getQueryString() {
        this.queryString = this.queryString ? this.queryString : '';
        if (window.location.search) {
            this.queryString = window.location.search;
            this.queryString = this.queryString
                .replace(/order_by=(\d)*/g, '')
                .replace(/&&/, '&')
                .replace(/^&|&$/, '')
                .replace(/\?&/, '?');

            this.queryString = this.queryString.length > 1 ? this.queryString : '';
        }
    }

    private static redirectLastIcons(elementDatasetValue: string) {
        if (
            elementDatasetValue == 'date' &&
            (window.location.pathname.indexOf(messages.url.most_download) == 0 ||
                window.location.pathname.indexOf(messages.url.most_download) == 3)
        ) {
            window.location.href = `${APP_URL.replace(/\/$/g, '')}${messages.url.latest_icons}${this.queryString}`;
            return;
        }
    }

    private static redirectTopIcons(elementDatasetValue: string) {
        if (
            elementDatasetValue == 'popularity' &&
            (window.location.pathname.indexOf(messages.url.latest_icons) == 0 ||
                window.location.pathname.indexOf(messages.url.latest_icons) == 3)
        ) {
            window.location.href = `${APP_URL.replace(/\/$/g, '')}${messages.url.most_download}${this.queryString}`;
            return;
        }
    }

    private static queryStringToForm() {
        let searchParametersRawArray: string[] = window.location.search.slice(1).split('&');
        let searchParametersObj: QueryParams = {};
        let colorType = '';

        searchParametersRawArray.forEach((searchParameter: string) => {
            let searchParameterArray: string[] = searchParameter.split('=');
            let key: string = searchParameterArray[0];
            let value = decodeURIComponent(searchParameterArray[1] || '');

            if (
                [
                    'word',
                    'license',
                    'order_by',
                    'style_id',
                    'family_id',
                    'author_id',
                    'category_id',
                    'pack_id',
                    'type',
                    'editable_stroke',
                ].indexOf(searchParameterArray[0]) == -1
            ) {
                searchParameterArray[0] = decodeURIComponent(filterText(searchParameterArray[0]) || 'error');
                key = filterText(searchParameterArray[0]);
            }
            searchParametersObj[key] = value;

            let inputFormFiltersElements = Array.from(
                document.querySelectorAll(`#form_filters input[name='${key}']`),
            ) as HTMLInputElement[];
            if (typeof inputFormFiltersElements != 'undefined' && inputFormFiltersElements.length) {
                inputFormFiltersElements.forEach(inputFormFiltersElement => {
                    if (inputFormFiltersElement.type == 'radio' || inputFormFiltersElement.type == 'checkbox') {
                        if (
                            inputFormFiltersElement.name == key &&
                            inputFormFiltersElement.value == value.replace(' ', '-')
                        ) {
                            inputFormFiltersElement.checked = true;
                        }
                    }

                    if (inputFormFiltersElement.type == 'hidden') {
                        inputFormFiltersElement.value = value;
                    }
                });
            }

            let inputSearchbarFilterElements = Array.from(
                document.querySelectorAll(`form.main_search_bar input[name='${key}']`),
            ) as HTMLInputElement[];

            if (typeof inputSearchbarFilterElements != 'undefined' && inputSearchbarFilterElements.length) {
                inputSearchbarFilterElements.forEach(inputSearchbarFilterElement => {
                    if (inputSearchbarFilterElement.type == 'hidden') {
                        inputSearchbarFilterElement.value = value;
                    }
                });
            }
        });

        const inputFormFiltersStyleElements: HTMLInputElement[] = Array.from(
            document.querySelectorAll('#form_filters input[name=style]'),
        );
        if (typeof inputFormFiltersStyleElements != 'undefined' && inputFormFiltersStyleElements.length) {
            inputFormFiltersStyleElements.forEach(element => {
                if (element.type == 'radio') {
                    if (element.id == colorType) {
                        element.checked = true;
                    }
                }
            });
        }
    }

    private static setCookieFlaticonSelection() {
        document.querySelector('#form_filters .premium-advise button')?.addEventListener('click', () => {
            const formFilterpremiumAdvise: HTMLElement | null = document.querySelector('#form_filters .premium-advise');
            cookie.setItem('flaticon_selection', '1', {
                expires: new Date(new Date().getTime() + 60 * 60 * 1000),
            });
            formFilterpremiumAdvise ? (formFilterpremiumAdvise.style.display = 'none') : '';
        });
    }

    private static searchInPack() {
        if (document.getElementById('input-search-in-pack')) {
            document.addEventListener('DOMContentLoaded', () => {
                const searchPackInput = document.getElementById('input-search-in-pack') as HTMLInputElement;
                const resetPackInput = document.querySelector(
                    '.search_in_pack_wrapper .reset-input',
                ) as HTMLInputElement;
                const wordFilterParameter = document.querySelector('#form_filters #word') as HTMLInputElement;
                searchPackInput.parentNode?.insertBefore(resetPackInput, searchPackInput.nextSibling);

                searchPackInput.addEventListener(
                    'awesomplete-selectcomplete',
                    event => {
                        let targetElement = event.target as HTMLInputElement;

                        wordFilterParameter ? (wordFilterParameter.value = targetElement.value) : '';
                        NewFilters.doSubmit = 1;
                        NewFilters.axSearch();
                    },
                    false,
                );
                resetPackInput.addEventListener(
                    'click',
                    () => {
                        wordFilterParameter ? wordFilterParameter.value.trim() == '' : '';
                        wordFilterParameter ? (wordFilterParameter.value = '') : '';
                        NewFilters.doSubmit = 1;
                        NewFilters.axSearch();
                    },
                    false,
                );
                searchPackInput.addEventListener(
                    'keyup',
                    event => {
                        let targetElement = event.target as HTMLInputElement;
                        let key = event.keyCode || event.charCode;
                        if (key == 8 || key == 46) {
                            if (wordFilterParameter.value.trim() == '' && targetElement.value == '') {
                                return;
                            } else if (targetElement.value == '') {
                                resetPackInput.click();
                            }
                        }

                        if (key == 13) {
                            wordFilterParameter.value = targetElement.value.trim();
                            NewFilters.doSubmit = 1;
                            NewFilters.axSearch();
                        }
                    },
                    false,
                );
            });
        }
    }

    private static serializeArray(form: HTMLFormElement): { value: string; name: string }[] {
        let arr: { value: string; name: string }[] = [];

        Array.prototype.slice.call(form.elements).forEach((field: HTMLFormElement) => {
            if (!field.name || field.disabled || ['file', 'reset', 'submit', 'button'].indexOf(field.type) > -1) return;

            if (['checkbox', 'radio'].indexOf(field.type) > -1 && !field.checked) return;

            if (
                field.value === this.filtersValue.shape.handDrawn &&
                arr.find(input => input.value === this.filtersValue.color.gradient)
            ) {
                return;
            }

            arr.push({
                name: field.name,
                value: field.value,
            });
        });

        return arr;
    }

    private static handleSortByTitle() {
        if (document.querySelector('.group-by') && document.querySelector('.group-by .popover-button span')) {
            let groupBySelectTitle = document.querySelector('.group-by .popover-button span');
            const labelsGroupBy = Array.from(document.querySelectorAll('.group-by label'));
            labelsGroupBy.forEach(label => {
                label.addEventListener('click', (e: Event) => {
                    const target = e.currentTarget as HTMLElement;
                    labelsGroupBy.map(element => element.classList.remove('active'));

                    groupBySelectTitle ? (groupBySelectTitle.innerHTML = target?.innerHTML) : '';
                    const label = target as HTMLElement;
                    label?.classList.add('active');
                });
            });
        } else {
            if (document.querySelector('.sort-by') && document.querySelector('.sort-by .popover-button span')) {
                let sortBySelectTitle = document.querySelector('.sort-by .popover-button span');
                Array.from(document.querySelectorAll('.sort-by input')).forEach(input => {
                    input.addEventListener('change', (e: Event) => {
                        sortBySelectTitle
                            ? (sortBySelectTitle.innerHTML = (e.target as HTMLElement)?.dataset.title as string)
                            : '';
                    });
                });
            }
        }
    }

    private static setFilterEquivalences() {
        if (NewFilters.inIconSearchWithPlainColor()) {
            NewFilters.setFilterEquivalencesWithPlainColors();
            return;
        }

        const linealColorFilter = document.getElementById('shape-linealColor') as HTMLInputElement;
        const handDrawnFilter = document.getElementById('shape-handDrawn') as HTMLInputElement;
        const colorBlackFilter = document.getElementById('color-black') as HTMLInputElement;
        const colorGradientFilter = document.getElementById('color-gradient') as HTMLInputElement;
        const shapeAllFilter = document.getElementById('shape-all') as HTMLInputElement;
        const colorAllFilter = document.getElementById('color-all') as HTMLInputElement;

        const allFilters = Array.from(document.querySelectorAll('.filters-body .item'));

        document
            .getElementById('filters--shape')
            ?.querySelectorAll('label')
            .forEach((filter: HTMLLabelElement) => {
                filter.classList.remove('disabled');
            });

        document
            .getElementById('filters--colors')
            ?.querySelectorAll('label')
            .forEach((filter: HTMLLabelElement) => {
                filter.classList.remove('disabled');
            });

        if (colorBlackFilter?.checked === true && linealColorFilter && shapeAllFilter) {
            linealColorFilter.parentElement?.classList.add('disabled');
            linealColorFilter.checked === true ? (shapeAllFilter.checked = true) : '';
        }

        if (linealColorFilter?.checked === true && colorBlackFilter && colorAllFilter) {
            colorBlackFilter.parentElement?.classList.add('disabled');
            colorBlackFilter.checked === true ? (colorAllFilter.checked = true) : '';
        }

        if (colorGradientFilter?.checked === true && handDrawnFilter && shapeAllFilter) {
            handDrawnFilter.parentElement?.classList.add('disabled');
            handDrawnFilter.checked === true ? (shapeAllFilter.checked = true) : '';
        }

        if (handDrawnFilter?.checked === true && colorGradientFilter && colorAllFilter) {
            colorGradientFilter.parentElement?.classList.add('disabled');
            colorGradientFilter.checked === true ? (colorAllFilter.checked = true) : '';
        }

        NewFilters.setFilterEquivalenceWithRawSvg();
    }

    private static setFilterEquivalencesWithPlainColors() {
        const plainColorFilter = document.querySelector('.item-plain-color') as HTMLInputElement;
        const colorGradientFilter = document.getElementById('color-gradient') as HTMLInputElement;
        const handDrawnFilter = document.getElementById('shape-hand-drawn') as HTMLInputElement;
        const blackColorFilter = document.getElementById('color-black') as HTMLInputElement;
        const linealColorFilter = document.getElementById('shape-lineal-color') as HTMLInputElement;

        const enableAllShapeFilters = () => {
            document
                .getElementById('filters--shape')
                ?.querySelectorAll('label')
                .forEach(enable);
        };

        const enableAllColorsFilters = () => {
            document
                .getElementById('filters--colors')
                ?.querySelectorAll('label')
                .forEach(enable);
        };

        const enable = (filterLabel: HTMLLabelElement): void => {
            const input = filterLabel.querySelector('input');
            if (input) {
                input.disabled = false;
            }

            filterLabel.classList.remove('disabled');
        };

        const disable = (filter: HTMLInputElement): void => {
            filter.disabled = true;
            filter.parentElement?.classList.add('disabled');
            filter.checked = false;
        };

        enableAllShapeFilters();
        enableAllColorsFilters();

        if (blackColorFilter.checked) {
            this.handlePlainColorButton();

            disable(linealColorFilter);
        }

        if (colorGradientFilter.checked) {
            plainColorFilter.classList.remove('item-plain-color-opened');
            disable(handDrawnFilter);
        }

        if (handDrawnFilter.checked) {
            disable(colorGradientFilter);
        }

        NewFilters.setFilterEquivalenceWithRawSvg();
    }

    private static setFilterEquivalenceWithRawSvg() {
        const editableStrokeFilter = document.getElementById('filter-editable_stroke') as HTMLInputElement;
        const shapeFillFilter = document.getElementById('shape-fill') as HTMLInputElement;
        let handDrawnFilter = document.getElementById('shape-hand-drawn') as HTMLInputElement;

        if (handDrawnFilter?.checked === true || shapeFillFilter?.checked === true) {
            editableStrokeFilter?.parentElement?.classList.add('disabled');
        } else {
            editableStrokeFilter?.parentElement?.classList.remove('disabled');
        }

        if (editableStrokeFilter?.checked === true) {
            handDrawnFilter?.parentElement?.classList.add('disabled');
            handDrawnFilter.checked = false;
            handDrawnFilter.disabled = true;
            shapeFillFilter?.parentElement?.classList.add('disabled');
            shapeFillFilter.checked = false;
            shapeFillFilter.disabled = true;
        }

        const allFilters = Array.from(document.querySelectorAll('.filters-body .item'));

        if (allFilters) {
            const tooltip = document.querySelector('#tooltip-popper') as HTMLElement;
            const showFunction = (popperInstance: any) => {
                tooltip?.setAttribute('data-show', '');
                popperInstance.update();
            };

            const hideFunction = () => {
                tooltip?.removeAttribute('data-show');
            };

            const showEvents = ['mouseenter', 'focus'];
            const hideEvents = ['mouseleave', 'blur'];

            allFilters.forEach(filter => {
                if (filter.classList.contains('disabled') && tooltip) {
                    filter.classList.add('disabledTooltip');
                    const popperInstance = createPopper(filter, tooltip, {
                        placement: 'right',
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    });
                    showEvents.forEach(event => {
                        filter.addEventListener(event, function(e: Event) {
                            const target = e.currentTarget as HTMLElement;
                            if (target.classList.contains('disabled')) {
                                showFunction(popperInstance);
                            }
                        });
                    });

                    hideEvents.forEach(event => {
                        filter.addEventListener(event, hideFunction);
                    });
                } else {
                    filter.classList.remove('disabledTooltip');
                }
            });
        }
    }

    private static renderFiltersApplied = (inputElementTarget: HTMLInputElement) => {
        let filtersApplied;

        if (ww.view.section === 'icon-search' || ww.view.section === '404') {
            filtersApplied = NewFilters.equivalencesForPlainColorsAndBlack();
        } else {
            filtersApplied = Array.from(
                document.querySelectorAll('.search-view-filters-aside .filters-body__container input:checked'),
            ).filter((element: HTMLInputElement) => element.value != 'all');
        }

        if (filtersApplied.length > 0) {
            const filtersAppliedContainer = document.getElementById('filters-applied');
            if (filtersAppliedContainer) {
                filtersAppliedContainer.innerHTML = `
                <div class="filters-applied__header full-width">
                    <p class="title font-sm bold text__general--heading mg-bottom-lv2">
                    ${messages.filters.appliedFilters}
                    </p>
                    <p class="title font-xs text__general--heading clear-all regular mg-bottom-lv2">
                    ${messages.filters.clearAll} <i class="icon icon--xs icon--cross mg-left-lv1"></i>
                    </p>
                </div>
                <div class="labels">
                    <div class="filterLabels"></div>
                </div>
                <hr class="full-width">`;
            }

            const labelsContainer = document.querySelector('#filters-applied .labels .filterLabels');
            if (labelsContainer) {
                labelsContainer.innerHTML = '';
            }
            filtersApplied.forEach((item: HTMLElement) => {
                let labelFor = item.id;

                const label = `<label for="${labelFor}" class="filterLabel-tag capitalize">${item.dataset.labelName}<i class="icon icon--sm icon--cross"></i></label>`;
                if (labelsContainer) {
                    labelsContainer.insertAdjacentHTML('afterbegin', label);
                }
            });

            document
                .querySelector('.filters-applied__header .clear-all')
                ?.addEventListener('click', NewFilters.resetFilters);
        } else {
            NewFilters.setEmptyAppliedFiltersContainer();
        }
    };

    private static equivalencesForPlainColorsAndBlack = () => {
        const allFiltersChecked = Array.from(
            document.querySelectorAll('.search-view-filters-aside .filters-body__container input:checked'),
        );

        const isPlainColorFilterAndAllColorsSelected = () => {
            return allFiltersChecked.filter((element: HTMLInputElement) => element.dataset.type === 'color').length > 1;
        };

        if (isPlainColorFilterAndAllColorsSelected()) {
            return allFiltersChecked.filter((element: HTMLInputElement) => element.value != 'color');
        }

        return allFiltersChecked;
    };

    private static resetFilters = () => {
        const filtersApplied = Array.from(
            document.querySelectorAll('.search-view-filters-aside .filters-body__container input:checked'),
        );
        filtersApplied.forEach((input: HTMLInputElement) => input.click());

        NewFilters.setEmptyAppliedFiltersContainer();
        NewFilters.doSubmit = 1;
        NewFilters.axSearch();
    };

    private static setEmptyAppliedFiltersContainer = () => {
        const filtersAppliedContainer = document.getElementById('filters-applied');
        if (filtersAppliedContainer) {
            filtersAppliedContainer.innerHTML = '';
        }
    };
}
