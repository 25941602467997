var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"font-md bold mg-bottom-lv1\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"premiumSubscription") : stack1), depth0))
    + "</p>\n            <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"totalResources") : stack1), depth0))
    + "</p>\n            <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"unlimitedDownloads") : stack1), depth0))
    + "</p>\n            <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"noAttributionRequired") : stack1), depth0))
    + "</p>\n            <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"browseAdFree") : stack1), depth0))
    + "</p>\n            <a id=\"download-premium\" href="
    + alias2(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":42},"end":{"line":9,"column":56}}}) : helper)))
    + " class=\"bj-button bj-button--flat bj-button--yellow mg-top-lv3 mg-bottom-lv3 track\" data-track-arguments=\"ga, event, premium, modal-download-premium\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"goPremium") : stack1), depth0))
    + "</a>                   \n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"premiumFormat") : depth0),"==",0,{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":14,"column":24}}})) != null ? stack1 : "")
    + "            <a class=\"bj-button bj-button--md bj-button--ghost button--close\">\n                <i class=\"icon--cross icon--xl\"></i>\n            </a>        \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <hr class=\"mg-bottom-lv3 block\">\n                <p class=\"font-md bold mg-bottom-lv2\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"attributionRequired") : stack1), depth0))
    + "<button class=\"bj-button bj-button--link modal__trigger track\" data-track-arguments=\"ga, event, modal-download, click-how-attribute, "
    + alias2(((helper = (helper = lookupProperty(helpers,"resourceId") || (depth0 != null ? lookupProperty(depth0,"resourceId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resourceId","hash":{},"data":data,"loc":{"start":{"line":12,"column":234},"end":{"line":12,"column":248}}}) : helper)))
    + "\" data-modal=\"modal-attribution-new\"><i class=\"icon icon--md icon--info inline-block mg-left-lv2\"></i></button></p>\n                <button id=\"download-free\" class=\"bj-button bj-button--green\"><b>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"freeDownload") : stack1), depth0))
    + "</b></button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"UserSession") : stack1)) != null ? lookupProperty(stack1,"userInfo") : stack1)) != null ? lookupProperty(stack1,"session") : stack1)) != null ? lookupProperty(stack1,"premium") : stack1),"==",0,{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":65,"column":20}}})) != null ? stack1 : "")
    + "            <a class=\"bj-button bj-button--md bj-button--ghost button--close\">\n                <i class=\"icon--cross icon--xl\"></i>\n            </a>    \n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"font-md bold mg-bottom-lv1\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"goPremium") : stack1), depth0))
    + "</p>\n            <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"totalResourcesAnimated") : stack1), depth0))
    + "</p>\n            <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"unlimitedDownloads") : stack1), depth0))
    + "</p>\n            <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"noAttributionRequired") : stack1), depth0))
    + "</p>\n            <p class=\"mg-bottom-lv1\"><i class=\"icon icon--md icon--check inline-block text__state--green mg-right-lv2\"></i>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"browseAdFree") : stack1), depth0))
    + "</p>\n            <div class=\"row\">\n                <a id=\"download-premium\" href="
    + alias2(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":28,"column":46},"end":{"line":28,"column":60}}}) : helper)))
    + " class=\"col col--stretch bj-button bj-button--flat bj-button--yellow btn-size mg-top-lv3 mg-lv1 mg-bottom-lv3 track\" data-track-arguments=\"ga, event, premium, modal-download-premium\">AEP</a>\n                <a id=\"download-premium\" href="
    + alias2(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":29,"column":46},"end":{"line":29,"column":60}}}) : helper)))
    + " class=\"col col--stretch bj-button bj-button--flat bj-button--yellow btn-size mg-top-lv3 mg-lv1 mg-bottom-lv3 track\" data-track-arguments=\"ga, event, premium, modal-download-premium\">JSON</a>\n                <a id=\"download-premium\" href="
    + alias2(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":30,"column":46},"end":{"line":30,"column":60}}}) : helper)))
    + " class=\"col col--stretch bj-button bj-button--flat bj-button--yellow btn-size mg-top-lv3 mg-lv1 mg-bottom-lv3 track\" data-track-arguments=\"ga, event, premium, modal-download-premium\">SVG</a>\n                <a id=\"download-premium\" href="
    + alias2(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":31,"column":46},"end":{"line":31,"column":60}}}) : helper)))
    + " class=\"col col--stretch bj-button bj-button--flat bj-button--yellow btn-size mg-top-lv3 mg-lv1 mg-bottom-lv3 track\" data-track-arguments=\"ga, event, premium, modal-download-premium\">EPS</a>\n                <a id=\"download-premium\" href="
    + alias2(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":32,"column":46},"end":{"line":32,"column":60}}}) : helper)))
    + " class=\"col col--stretch bj-button bj-button--flat bj-button--yellow btn-size mg-top-lv3 mg-lv1 mg-bottom-lv3 track\" data-track-arguments=\"ga, event, premium, modal-download-premium\">PSD</a>            \n            </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,(depth0 != null ? lookupProperty(depth0,"premiumFormat") : depth0),"==",0,{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":42,"column":24}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <hr class=\"mg-bottom-lv3 block\">\n            <p class=\"font-md bold mg-bottom-lv2\">"
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"attributionRequired") : stack1), depth0))
    + "<button class=\"bj-button bj-button--link modal__trigger track\" data-track-arguments=\"ga, event, modal-download, click-how-attribute, "
    + alias1(((helper = (helper = lookupProperty(helpers,"resourceId") || (depth0 != null ? lookupProperty(depth0,"resourceId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resourceId","hash":{},"data":data,"loc":{"start":{"line":36,"column":230},"end":{"line":36,"column":244}}}) : helper)))
    + "\" data-modal=\"modal-attribution-new\"><i class=\"icon icon--md icon--info inline-block mg-left-lv2\"></i></button></p>\n            <div class=\"row\">                    \n                <button id=\"download-free\" class=\"btn col bj-button bj-button--flat bj-button--green btn-size mg-lv1\" data-format=\"gif\" title=\"Download free animated icon in GIF format\" data-type=\"animated-icon\" data-captcha=\"animated-icon\"><span>GIF</span></button>\n                <button id=\"download-free\" class=\"btn col bj-button bj-button--flat bj-button--green btn-size mg-lv1\" data-format=\"mp4\" title=\"Download free animated icon in MP4 format\" data-type=\"animated-icon\" data-captcha=\"animated-icon\"><span>MP4</span></button>                \n                <button id=\"download-free\" class=\"btn col bj-button bj-button--flat bj-button--green btn-size mg-lv1\" data-format=\"png\" title=\"Download free animated icon in PNG format\" data-type=\"animated-icon\" data-captcha=\"animated- icon\"><span>PNG</span></button>				            \n            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"font-md bold mg-bottom-lv1\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"downloadFormats") : stack1), depth0))
    + "</p>\n            <div class=\"fi-premium-icon\" id=\"fi-premium-download-buttons\">\n                <div class=\"pd-top-lv2-i pd-bottom-lv1-i\">\n                    <div class=\"mg-none download\" id=\"download\">\n                        <p class=\"font-md bold mg-none\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"animatedFormats") : stack1), depth0))
    + "</p>\n                        <div class=\"row mg-top-lv1 mg-bottom-lv3\">\n                            <a href=\"#\" class=\"btn col mg-none bj-button bj-button--primary\" data-format=\"aep\" title=\"Download free animated icon in AEP format\" data-type=\"animated-icon\" data-captcha=\"animated-icon\"><span>AEP</span></a>\n                            <a href=\"#\" class=\"btn mg-left-lv2-i col mg-none bj-button bj-button--primary\" data-format=\"lottie\" title=\"Download free animated icon in JSON format\" data-type=\"animated-icon\" data-captcha=\"animated-icon\"><span>JSON</span></a>\n                            <a href=\"#\" class=\"btn mg-left-lv2-i col mg-none bj-button bj-button--primary\" data-format=\"gif\" title=\"Download free animated icon in GIF format\" data-type=\"animated-icon\" data-captcha=\"animated-icon\"><span>GIF</span></a>\n                            <a href=\"#\" class=\"btn mg-left-lv2-i col mg-none bj-button bj-button--primary\" data-format=\"mp4\" title=\"Download free animated icon in MP4 format\" data-type=\"animated-icon\" data-captcha=\"animated-icon\"><span>MP4</span></a>                            \n                        </div>\n                        <p class=\"font-md bold mg-none\">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"download") : stack1)) != null ? lookupProperty(stack1,"staticFormats") : stack1), depth0))
    + "</p>\n                        <div class=\"row mg-top-lv1\">                                                        \n                            <a href=\"#\" class=\"btn col mg-none bj-button bj-button--primary\" data-format=\"svg\" title=\"Download free animated icon in SVG format\" data-type=\"animated-icon\" data-captcha=\"animated-icon\"><span>SVG</span></a>\n                            <a href=\"#\" class=\"btn mg-left-lv2-i col mg-none bj-button bj-button--primary\" data-format=\"eps\" title=\"Download free animated icon in EPS format\" data-type=\"animated-icon\" data-captcha=\"animated-icon\"><span>EPS</span></a>\n                            <a href=\"#\" class=\"btn mg-left-lv2-i col mg-none bj-button bj-button--primary\" data-format=\"psd\" title=\"Download free animated icon in PSD format\" data-type=\"animated-icon\" data-captcha=\"animated-icon\"><span>PSD</span></a>\n                            <a href=\"#\" class=\"btn mg-left-lv2-i col mg-none bj-button bj-button--primary\" data-format=\"png\" title=\"Download free animated icon in PNG format\" data-type=\"animated-icon\" data-captcha=\"animated-icon\"><span>PNG</span></a>\n                        </div>								                                                                                          \n                    </div>\n                </div>\n			</div>               \n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-download-detail\">\n    <div class=\"modal-body modal-download-detail__content pd-lv3 alignl font-sm\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resourceType") : depth0),"!=","animated-icon",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":18,"column":20}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resourceType") : depth0),"==","animated-icon",{"name":"compare","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":69,"column":20}}})) != null ? stack1 : "")
    + "        \n    </div>\n</div>";
},"useData":true});