var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section id=\"fi-premium-sp-freepik\" class=\"related fi-not-premium hide-mobile\">\n	<header class=\"related-header\"></header>\n	"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"freepikSponsor") || (depth0 != null ? lookupProperty(depth0,"freepikSponsor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"freepikSponsor","hash":{},"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":3,"column":23}}}) : helper))) != null ? stack1 : "")
    + "\n</section>";
},"useData":true});