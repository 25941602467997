import 'Variables';

import { q, qq, ww } from 'bobjoll/ts/library/dom';
import Logs from 'Library/logs';
import { getUrlParam } from 'Project/ts/common/urls.helper';
import { recaptchaDownload } from 'Project/ts/common-app/recaptcha/downloads-recaptcha';
import { Attribution } from 'Project/ts/partials/attributionService';
import User from 'Project/ts/pods/user/main';
export class Downloads {
    private static resourceType: ResourceType;
    private static gaCreditArgumentsCopy: string[] | null = [];
    private static iconDetailView: string = 'icon-detail';

    public static async initializeDownload(resourceType: ResourceType, view: string) {
        this.resourceType = resourceType;
        let downloadButtonElements = this.getDownloadButtonElementsByTypeResource(
            resourceType,
            User.isPremiumFlaticon(),
        );
        if ((view == 'icon-search' || view == 'style-search') && User.isPremiumFlaticon()) {
            downloadButtonElements = qq(
                '.search-result li[class*="GA_CM_download"], .group-styles li[class*="GA_CM_download"]',
            );
        }
        if (downloadButtonElements?.length) {
            downloadButtonElements[0].insertAdjacentHTML(
                'beforebegin',
                `<div id="captcha-download-${resourceType}${!User.isPremiumFlaticon() ? '-free' : ''}"></div>`,
            );
            if (document.querySelector('#detail.new-detail.uicon')) {
                return;
            }
            this.addListeners(downloadButtonElements, resourceType);
        }

        if (view == 'pack-detail' && User.isPremiumFlaticon()) {
            const downloadButtonElements = qq('.search-result li[class*="GA_CM_download"]');
            if (downloadButtonElements.length) {
                downloadButtonElements[0].insertAdjacentHTML('beforebegin', `<div id="captcha-download-icon"></div>`);
                this.addListeners(downloadButtonElements, 'icon');
            }
        }
    }

    public static fillBase64Form(response: string) {
        const img = `<img src="${response}" />`;
        const b64ImageElement = q('#b64Image') as HTMLInputElement;
        if (b64ImageElement) {
            b64ImageElement.value = img;
        }

        const b64CssElement = q('#b64css') as HTMLInputElement;
        if (b64CssElement) {
            const css = `<style type="text/css"> div.image { max-width: 256px; max-height: 256px; background-image: url(${response})}</style>`;
            b64CssElement.value = css;
        }

        let modalBase64Element = document.getElementById('modal-base64') as HTMLInputElement;
        const modalBase64 = document.querySelector('div.modal-base64') as HTMLElement;
        if (modalBase64Element) {
            let authorIconInfo = document.querySelector('#detail')
                ? document.querySelector('#detail')
                : document.querySelector('#detail-pack-aside');

            if (authorIconInfo) {
                const authorLink = authorIconInfo.querySelector('.author a') as HTMLLinkElement;
                const authorTitle = authorIconInfo.querySelector('.author .username') as HTMLLinkElement;
                if (authorLink && authorTitle && modalBase64) {
                    const licence = `<div>${messages.attribution.icons_made_by} <a href="${authorLink.href}" title="${authorTitle.title}">${authorTitle.title}</a> from <a href="${APP_URL}" title="Flaticon">${window.location.host}</a></div>`;
                    const modalBase64Textarea = modalBase64.querySelector(
                        '.attribution__wrapper .copy-holder span textarea',
                    ) as HTMLTextAreaElement;
                    modalBase64Textarea.textContent = licence;
                    modalBase64.querySelector('.input_atributtion_text')?.setAttribute('value', licence);
                }
            }

            this.cleanCopyBase64Button();
            modalBase64Element.checked = true;
            const modalCloseButton = document.querySelector('.modal-base64 .modal__close');
            modalCloseButton?.addEventListener('click', function() {
                modalBase64Element.checked = false;
                document.querySelector('body')?.classList.remove('modal-open');
            });
        }
    }

    public static cleanCopyBase64Button() {
        const copyAttributtionButtons = document.querySelectorAll('.fi-modal.modal-base64 .copy-attributtion');
        copyAttributtionButtons.forEach(copyAttributtionButton => {
            copyAttributtionButton.addEventListener('click', (e: Event) => {
                let buttonTargetElement = e.target as HTMLElement;
                let copyTargetElement = buttonTargetElement
                    .closest('.copy-holder')
                    ?.querySelector('textarea') as HTMLTextAreaElement;
                copyTargetElement ? copyTargetElement.select() : '';
                document.execCommand('copy');

                if (copyAttributtionButton.children[0].classList.contains('active')) {
                    copyAttributtionButton.children[1].classList.add('active');
                    copyAttributtionButton.children[0].classList.remove('active');
                    return;
                }
            });
        });
    }

    public static trackDownload(itemId: string, format?: string, logType: string = 'download') {
        let searchTypeElement = document.querySelector(
            '.header--search .radio-group input[name="search-type"][type="radio"]:checked',
        ) as HTMLInputElement;
        let searchType = '';
        if (searchTypeElement) {
            searchType = searchTypeElement.value;
        }

        searchTypeElement = document.querySelector(
            '.header--search .radio-group input[name="type"]:checked',
        ) as HTMLInputElement;
        if (searchTypeElement) {
            searchType = `${searchTypeElement.value}${
                document.querySelector(
                    '.header--search .radio-group input[name="search-group"][data-type="packs"]:checked',
                )
                    ? ' packs'
                    : ''
            }`;
        }

        searchType = ww.view.section == 'pack-detail' ? 'packs' : searchType;

        let alternativeSearchDom = q('#alternative-search');
        let alternativeSearch = '';

        let trSearch = decodeURIComponent(getUrlParam('word') || 'undefined');

        if (trSearch === 'undefined') {
            trSearch = decodeURIComponent(getUrlParam('term') || 'undefined');
        }

        let trRelatedId = decodeURIComponent(getUrlParam('related_id') || 'undefined');
        let trOrigin = decodeURIComponent(getUrlParam('origin') || 'undefined');

        if (trOrigin === 'undefined' && ww.Resource.resource) {
            let paramsFromResourceType = new URLSearchParams(ww.Resource.resource.queryParams);
            trOrigin = paramsFromResourceType.get('origin') || 'undefined';
        }

        if (alternativeSearchDom !== null) {
            let alternativeSearchType = alternativeSearchDom.dataset.alternativeSearchType;
            let originalTerm = alternativeSearchDom.dataset.originalTerm;

            if (alternativeSearchType !== 'undefined' && originalTerm !== 'undefined') {
                alternativeSearch = `|alternativeSearchType,${alternativeSearchType}|originalTerm,${originalTerm}`;
            }
        }

        if (trRelatedId !== 'undefined' && itemId !== trRelatedId) {
            alternativeSearch += `|related_id,${trRelatedId}`;
        }

        if (trOrigin !== 'undefined' && trOrigin !== null) {
            alternativeSearch += `|origin,${trOrigin}`;
        }

        let iconFormat = '';
        if (format) {
            iconFormat = `|format,${format}`;
        }

        const resourceType = this.getResourceType(logType);

        if (typeof itemId != 'undefined' && trSearch !== 'undefined') {
            Logs.pushLog(
                `afterSearch|type,${logType}|search,${trSearch}|url,${window.location.href}|item_id,${itemId}|search_type,${searchType}|icon_type,${resourceType}${alternativeSearch}${iconFormat}`,
                true,
            );
        }

        User.updateUserStats(3000);
    }

    private static addListeners(downloadButtonElements: HTMLElement[], resourceType: ResourceType) {
        downloadButtonElements.forEach(element => {
            element.dataset.type = resourceType;
            element.dataset.captcha = `${resourceType}${!User.isPremiumFlaticon() ? '-free' : ''}`;
            element.onclick = this.handlerDownload;
            element.onauxclick = this.handlerDownload;
        });
    }

    private static getDownloadButtonElementsByTypeResource(resourceType: ResourceType, isPremium: boolean) {
        if (ww.view.section == this.iconDetailView && document.querySelector('.modal-download-detail')) {
            if (resourceType == 'icon') {
                return isPremium
                    ? qq(
                          '#detail #download a.btn, #detail li[class*="GA_CM_download"], #download .btn-svg a:not(.popover-external-link)',
                      )
                    : qq('.modal-download-detail #download-free');
            } else if (resourceType == 'pack') {
                return isPremium ? qq('.btn-download-pack') : qq('.fi-modal #download-free');
            }

            return null;
        } else {
            if (resourceType == 'icon') {
                return isPremium
                    ? qq(
                          '#detail #download a.btn, #detail li[class*="GA_CM_download"], #download .btn-svg a:not(.popover-external-link)',
                      )
                    : qq('.modal-download #download-free');
            } else if (resourceType == 'pack') {
                return isPremium ? qq('.btn-download-pack') : qq('.fi-modal #download-free');
            }

            return null;
        }
    }

    private static async handlerDownload(e: Event) {
        e.stopPropagation();
        e.preventDefault();

        let editorElement = document.querySelector('.detail__editor') as HTMLElement;
        const element = e.currentTarget as HTMLElement;

        if (!editorElement.classList.contains('hide')) {
            if (ww && ww.Editor) {
                ww.Editor.finalDownload(element);
            }
            return;
        }

        if (typeof ww.recaptchaModule == 'undefined') {
            ww.recaptchaModule = await import('Project/ts/common-app/recaptcha/recaptcha');
            const loadRecaptchaDownload = () =>
                new Promise(async resolve => {
                    ww.DownloadRecaptcha = new ww.recaptchaModule.Recaptcha(`safe-download`);
                    await ww.DownloadRecaptcha.load();
                    resolve(element);
                });
            loadRecaptchaDownload().then((element: HTMLElement) => {
                Downloads.fireDownload(e, element);
            });
        } else {
            Downloads.fireDownload(e, element);
        }
    }

    private static fireDownload(e: Event, element: HTMLElement) {
        if (User.isLogged() && !User.isActiveUser()) {
            User.alert(`inactive-user-error`, messages.errors.inactive_user, 'error', 'bottom-left');
        } else if (ww.Essentials) {
            ww.Essentials.handleDownloadClick();
        } else {
            const resourceType = element.dataset.type;
            if (resourceType && resourceType !== 'collection') {
                let format = element.dataset.format;
                let formFormat = q('#format') as HTMLInputElement;
                if (ww.view.section == this.iconDetailView) {
                    formFormat = q('#detail form #format') as HTMLInputElement;
                }
                if (format && formFormat) {
                    formFormat.value = format;
                }

                let callback =
                    resourceType == 'icon'
                        ? ww.Downloads.download_final
                        : resourceType == 'pack'
                        ? ww.Downloads.download_pack
                        : '';
                const trackerDownload = {
                    recaptcha: ww.DownloadRecaptcha,
                    callbackEvents: callback,
                    type: resourceType,
                    button: element,
                };
                recaptchaDownload(element, e, trackerDownload);
            }
        }
    }

    private static applyGaAttributionCredit() {
        const copyTextInput = document.querySelector('.copy__text input') as HTMLInputElement;
        copyTextInput?.select();

        if (this.resourceType === 'icon') {
            this.gaCreditArgumentsCopy = ['send', 'event', 'Detail View', 'Select Credit Attribution Link'];
        }
        if (this.resourceType === 'pack') {
            this.gaCreditArgumentsCopy = ['send', 'event', 'Pack', 'Select Credit Attribution Link'];
        }
        if (this.resourceType === 'collection') {
            this.gaCreditArgumentsCopy = null;
        }

        document.execCommand('copy');
        if (this.gaCreditArgumentsCopy) {
            ga(
                this.gaCreditArgumentsCopy[0],
                this.gaCreditArgumentsCopy[1],
                this.gaCreditArgumentsCopy[2],
                this.gaCreditArgumentsCopy[3],
            );
        }
    }

    private static getResourceType(logType: string): string {
        if (ww.Resource.resource) {
            return ww.Resource.resource.type;
        }

        if (ww.view.section == 'pack-detail') {
            return (document.querySelector('#form-download-pack [name="iconType"]') as HTMLInputElement).value;
        }

        if (logType == 'add_collection') {
            return 'standard';
        }

        return 'undefined';
    }

    public static openAttributionModal = () => {
        // TODO Revisar duplicidad
        Attribution.show();
        if (!ww.UserSession.userInfo.session || ww.UserSession.userInfo.session.premium === 0) {
            Attribution.initialize();
            Attribution.show();
            document.querySelector('.copy__trigger')?.addEventListener('click', Downloads.applyGaAttributionCredit);

            return true;
        } else {
            return false;
        }
    };
}

export type ResourceType = 'icon' | 'pack' | 'collection' | 'uicons';
