import { ww } from 'bobjoll/ts/library/dom';

class Share {
    private Networks: Networks;

    constructor() {
        this.Networks = {
            facebook: {
                name: 'Facebook',
                class: 'facebook',
                url: 'https://www.facebook.com/sharer.php?u={url}',
                defaultMeta: {
                    attr: 'name',
                    name: 'facebook',
                },
            },
            twitter: {
                name: 'Twitter',
                class: 'twitter',
                url: 'https://twitter.com/intent/tweet?url={url}&text={title}&via={origin}&hashtags={hashtags}',
                defaultMeta: {
                    attr: 'name',
                    name: 'twitter',
                },
            },
            pinterest: {
                name: 'Pinterest',
                class: 'pinterest',
                url:
                    'https://pinterest.com/pin/create/bookmarklet/?media={image}&description={description}&url={url}&is_video={video}',
                defaultMeta: {
                    attr: 'name',
                    name: 'pinterest',
                },
            },
        };
    }

    public popup(
        settings: NetworkData,
        { width, height }: { width: number; height: number } = { width: 600, height: 300 },
    ) {
        let url = this.generateURL(settings);

        if (url) {
            let px = Math.floor(((screen.availWidth || 1024) - width) / 2);
            let py = Math.floor(((screen.availHeight || 700) - height) / 2);
            let options = `
                width=${width},
                height=${height},
                left=${px},
                top=${py},
                location=0,
                menubar=0,
                toolbar=0,
                status=0,
                scrollbars=1,
                resizable=1
            `;

            ga('send', 'event', 'Social', settings.network, settings.url);

            window.open(url, 'social', options);
        }
    }

    private generateURL(data: NetworkData): string | void {
        let network = this.Networks[data.network];
        data.url = data.url !== undefined ? data.url : document.location.href;
        if (data.url.indexOf('#') >= 0) {
            data.url = data.url.substr(0, data.url.indexOf('#'));
        }

        data.url = encodeURI(data.url);

        if (network) {
            let url = JSON.parse(JSON.stringify(network.url));
            if (network.defaultMeta) {
                if (data.title == undefined) {
                    let title: HTMLMetaElement | null = document.querySelector(
                        `meta[${network.defaultMeta.attr}='${network.defaultMeta.name}:title']`,
                    );

                    if (data.url.includes('/collections')) {
                        data.title =
                            document.querySelector('#collections .collection.expanded .collection-name')?.textContent ||
                            '';
                    } else {
                        data.title = title !== null ? title.content : '';
                    }
                }

                if (data.description == undefined) {
                    let description: HTMLMetaElement | null = document.querySelector(
                        `meta[${network.defaultMeta.attr}='${network.defaultMeta.name}:description']`,
                    );
                    data.description = description !== null ? description.content : '';
                    data.description = data.description.replace(/#/gi, '%23');
                }

                if (data.image == undefined) {
                    let image: HTMLMetaElement | null = document.querySelector(
                        `meta[${network.defaultMeta.attr}='${network.defaultMeta.name}:image']`,
                    );

                    if (data.url.includes('/collections')) {
                        data.image = ww.URL_GENERIC_IMAGE_SHARE;
                    } else {
                        data.image = image !== null ? image.content : '';
                    }
                }

                if (data.origin == undefined) {
                    let origin: HTMLMetaElement | null = document.querySelector(
                        `meta[${network.defaultMeta.attr}='${network.defaultMeta.name}:origin']`,
                    );
                    data.origin = origin !== null ? origin.content : 'flaticon';
                }

                if (data.hashtags == undefined) {
                    let hashtags: HTMLMetaElement | null = document.querySelector(
                        `meta[${network.defaultMeta.attr}='${network.defaultMeta.name}:hashtags']`,
                    );
                    data.hashtags = hashtags !== null ? hashtags.content : '';
                }
            } else {
                let title: HTMLMetaElement | null = document.querySelector(`meta[property='og:title']`);
                data.title = title !== null ? title.content : '';
                let description: HTMLMetaElement | null = document.querySelector(`meta[property='og:description']`);
                data.description = description !== null ? description.content : '';
                let image: HTMLMetaElement | null = document.querySelector(`meta[property='og:image']`);
                data.image = image !== null ? image.content : '';
            }

            let properties = Object.keys(data);
            properties.forEach(function(property) {
                let value = data[property].replace('|', '');
                let arg = new RegExp('{' + property + '}', 'g');

                url = url.replace(arg, value);
            });

            return url;
        }
    }
}

interface Networks {
    [name: string]: Network;
}

interface Network {
    name: string;
    class: string;
    url: string;
    defaultMeta?: Meta;
}

type SocialNetworks = keyof Networks;

interface NetworkData {
    network: SocialNetworks;
    url: string;
    title?: string;
    image?: string;
    description?: string;
    message?: string;
    video?: boolean;
    hashtags?: string;
    [name: string]: any;
}

interface Meta {
    attr: string;
    name: string;
}

export var share = new Share();
