var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<a href=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":44,"column":13},"end":{"line":44,"column":27}}}) : helper)))
    + "\" class=\"bj-button bj-button--yellow btn-premium mg-bottom-lv3 track\" data-track-arguments=\"ga, event, premium, search-crown\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"autopromo") : stack1)) != null ? lookupProperty(stack1,"go_premium") : stack1), depth0))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"fi-modal modal-premium\">\n	<input class=\"modal-state\" id=\"modal-premium\" type=\"checkbox\">\n	<div class=\"modal-fade-screen\">\n		<div class=\"modal-inner modal-inner-big alignc\">\n			<label class=\"modal-close\" for=\"modal-premium\"></label>\n\n			<h3 class=\"font-h5 mg-bottom-lv4\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"premium") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "</h3>\n\n			<div class=\"row  mg-none alignl mg-bottom-lv4-i\">\n				<div class=\"col__xs--6 pd-none-i mg-bottom-lv3-i\">\n					<div class=\"row row--vertical-center mg-none\">\n						<div class=\"mg-right-lv3\">\n							<img src=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"URL_STATIC_IMG") || (depth0 != null ? lookupProperty(depth0,"URL_STATIC_IMG") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"URL_STATIC_IMG","hash":{},"data":data,"loc":{"start":{"line":13,"column":17},"end":{"line":13,"column":37}}}) : helper)))
    + "features/diamond.svg\" width=\"64\" height=\"64\" alt=\"Premium\">\n						</div>\n						<p class=\"mg-none\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"premium") : stack1)) != null ? lookupProperty(stack1,"benefit_1") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					</div>\n				</div>\n				<div class=\"col__xs--6 pd-none-i mg-bottom-lv3-i\">\n					<div class=\"row row--vertical-center mg-none\">\n						<div class=\"mg-right-lv3\">\n							<img src=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"URL_STATIC_IMG") || (depth0 != null ? lookupProperty(depth0,"URL_STATIC_IMG") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"URL_STATIC_IMG","hash":{},"data":data,"loc":{"start":{"line":21,"column":17},"end":{"line":21,"column":37}}}) : helper)))
    + "features/downloads.svg\" width=\"64\" height=\"64\" alt=\"Premium\">\n						</div>\n						<p class=\"mg-none\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"premium") : stack1)) != null ? lookupProperty(stack1,"benefit_2") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					</div>\n				</div>\n				<div class=\"col__xs--6 pd-none-i mg-bottom-lv3-i\">\n					<div class=\"row row--vertical-center mg-none\">\n						<div class=\"mg-right-lv3\">\n							<img src=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"URL_STATIC_IMG") || (depth0 != null ? lookupProperty(depth0,"URL_STATIC_IMG") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"URL_STATIC_IMG","hash":{},"data":data,"loc":{"start":{"line":29,"column":17},"end":{"line":29,"column":37}}}) : helper)))
    + "features/ads.svg\" width=\"64\" height=\"62.73\" alt=\"No ads\">\n						</div>\n						<p class=\"mg-none\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"premium") : stack1)) != null ? lookupProperty(stack1,"benefit_3") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					</div>\n				</div>\n				<div class=\"col__xs--6 pd-none-i mg-bottom-lv3-i\">\n					<div class=\"row row--vertical-center mg-none\">\n						<div class=\"mg-right-lv3\">\n							<img src=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"URL_STATIC_IMG") || (depth0 != null ? lookupProperty(depth0,"URL_STATIC_IMG") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"URL_STATIC_IMG","hash":{},"data":data,"loc":{"start":{"line":37,"column":17},"end":{"line":37,"column":37}}}) : helper)))
    + "features/license.svg\" width=\"64\" height=\"65.3\" alt=\"License\">\n						</div>\n						<p class=\"mg-none\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"premium") : stack1)) != null ? lookupProperty(stack1,"benefit_4") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					</div>\n				</div>\n			</div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"premiumUser") : depth0),"==",false,{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":3},"end":{"line":45,"column":15}}})) != null ? stack1 : "")
    + "			<p class=\"mg-none\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"faqs") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n			\n		</div>\n	</div>\n</div>";
},"useData":true});