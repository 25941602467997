import 'Variables';
import Fetch from 'Partials/xhr';
import { localStorage } from 'bobjoll/ts/library/storage';

export default class Logs {
    private static readonly localStorageKey = 'logEvents';
    public static readonly dataKey = 'le';
    private static readonly splitGroups = '|';
    private static readonly splitEvent = ',';
    private static readonly apiUrls: any = {
        event: APP_URL + 'ajax/logs/event',
        download: APP_URL + 'ajax/logs/download',
        afterSearch: APP_URL + 'ajax/logs/after_search',
    };
    private static readonly namespace = 'Logs';

    public static pushLog(logDataString: string, direct?: boolean) {
        let events: LogEvents = Logs.parseDataToLogEvents(logDataString);
        if (direct) {
            Logs.sendLog(events);
        } else {
            let queue = Logs.addToQueue(events);
            localStorage.setItem(Logs.namespace, Logs.localStorageKey, JSON.stringify(queue));
        }
    }

    private static parseDataToLogEvents(logDataString: string): LogEvents {
        let dataGroups = logDataString.split(Logs.splitGroups);
        let type = dataGroups.shift() || '';
        let listEvents: DataEvent[] = [];

        dataGroups.forEach(element => {
            let eventParts = element.split(Logs.splitEvent);
            let event: DataEvent = {
                name: eventParts[0],
                value: eventParts[1],
            };
            listEvents.push(event);
        });

        let events: LogEvents = {
            type: type,
            list: listEvents,
        };

        return events;
    }

    public static consumeQueue() {
        let queue = Logs.getQueue();
        if (queue.length > 0) {
            queue.forEach(element => {
                Logs.sendLog(element);
            });
            Logs.clearQueue();
        }
    }

    private static getQueue(): LogEvents[] {
        const queue = localStorage.getItem(Logs.namespace, Logs.localStorageKey);
        return queue ? JSON.parse(queue) : [];
    }

    private static addToQueue(logEvents: LogEvents) {
        let queue = Logs.getQueue();
        queue.push(logEvents);
        return queue;
    }

    private static clearQueue() {
        localStorage.removeItem(Logs.namespace, Logs.localStorageKey);
    }

    private static async sendLog(logEvents: LogEvents) {
        let url: any = Logs.apiUrls[logEvents.type];
        const response = await Fetch(url, {
            method: 'POST',
            data: JSON.stringify(logEvents.list),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });

        return response.success || false;
    }
}

interface DataEvent {
    name: string;
    value: string;
}

interface LogEvents {
    type: string;
    list: DataEvent[];
}
