import { ww } from 'bobjoll/ts/library/dom';
import { modal } from 'bobjoll/ts/partials/modal-v1.0';
import { redirectPurchase } from 'Project/ts/pods/craft/index';
import User from 'Project/ts/pods/user/main';

export class LazyModal {
    public static template: any;
    public static modalElement: string;
    public static modalTargetElement: any;
    public static modalId: any;
    public static removeTargetHandler: HTMLElement;
    public static modalCloseButton: HTMLElement;

    private static callLazyModalClick(modalName: string) {
        if (document.querySelectorAll(`[data-modal=${modalName}`).length > 0) {
            document.querySelectorAll(`[data-modal=${modalName}`).forEach(iconPremiumButton => {
                iconPremiumButton.addEventListener('click', () => {
                    this.template = require(`Project/ts/pages/modals/${modalName}.hbs`);
                    this.modalElement = `<div class="fi-modal ${modalName}"></div>`;
                    document.querySelector('body')?.insertAdjacentHTML('beforeend', this.modalElement);
                    this.modalTargetElement = document.querySelector(`.${modalName}`);
                    let pricingRoute = `${FLATICON_URL}${messages.url.pricing}`;
                    const resourceData = ww.Resource.resource;
                    let genericInfo = '';

                    if (modalName == 'modal-attribution-new') {
                        if (resourceData) {
                            messages.attribution.howtostart = ww.Attribution.getStartAttributionMessage(
                                resourceData.type,
                            );
                            messages.attribution.type = ww.Attribution.getAttributionTypeMessage(resourceData.type);
                        } else {
                            genericInfo = (document.querySelector('.copy__text input') as HTMLInputElement)?.value;
                        }
                    }

                    if (this.modalTargetElement) {
                        this.modalTargetElement.innerHTML = this.template({
                            messages: messages,
                            URL_STATIC_IMG: URL_STATIC_IMG,
                            BASE_URL: BASE_URL,
                            premiumUser: User.isPremium(),
                            pricingUrl: pricingRoute,
                            resourceData: resourceData,
                            viewSection: ww.view.section,
                            genericInfo: genericInfo,
                        });
                    }

                    this.modalId = document.querySelector(`.${modalName} input`);

                    modal.show(modalName);
                    this.checkModalInput();
                    this.eraseModal(modalName);

                    ww.dispatchEvent(new CustomEvent(`modal-loaded-${modalName}`));
                });
            });
        }
    }

    public static callLazyModalEvent(
        modalName: string,
        icon: string | null = null,
        slideInAnimation: boolean | null = null,
    ) {
        this.template = require(`Project/ts/pages/modals/${modalName}.hbs`);
        this.modalElement = `<div class="fi-modal ${modalName}"></div>`;
        document.querySelector('body')?.insertAdjacentHTML('beforeend', this.modalElement);
        this.modalTargetElement = document.querySelector(`.${modalName}`);
        let pricingRoute;
        let urlImgPath;

        if (icon) {
            let elementDataTarget = (document.querySelector(`li[data-id="${icon}"]`) as HTMLElement)
                ? (document.querySelector(`li[data-id="${icon}"]`) as HTMLElement)
                : (document.querySelector(`div[data-id="${icon}"]`) as HTMLElement);
            let data: DOMStringMap = elementDataTarget.dataset;
            urlImgPath = data.icon_src ? data.icon_src : elementDataTarget.querySelector('img')?.src;
            pricingRoute = `${FLATICON_URL}${messages.url.pricing}/${data.id}`;
            if (data.pack_id) {
                pricingRoute = `${FLATICON_URL}${messages.url.pricing}/${data.pack_id}`;
            }
        } else {
            pricingRoute = `${FLATICON_URL}${messages.url.pricing}`;
            urlImgPath = '';
        }

        if (this.modalTargetElement) {
            this.modalTargetElement.innerHTML = this.template({
                messages: messages,
                URL_STATIC_IMG: URL_STATIC_IMG,
                premiumUser: User.isPremium(),
                pricingUrl: pricingRoute,
                srcPath: urlImgPath,
                icon: icon ? true : false,
            });
        }

        this.modalId = document.querySelector(`.${modalName} input`);

        modal.show(modalName);
        slideInAnimation == true ? this.slideInAnimationModal() : '';
        this.checkModalInput();
        this.eraseModal(modalName);
    }

    public static checkModalInput() {
        if (this.modalId) {
            this.modalId.checked = true;
        }
    }

    public static slideInAnimationModal() {
        if (this.modalTargetElement.querySelector('.modal__container')) {
            document.querySelector('body')?.classList.contains('overflow-hidden')
                ? document.querySelector('body')?.classList.remove('overflow-hidden')
                : '';
            this.modalTargetElement.querySelector('.modal__container').classList.add('slide-in');
        }
    }

    public static eraseModal(modalName: string) {
        this.removeTargetHandler = this.modalTargetElement.querySelector('.modal-fade-screen')
            ? this.modalTargetElement.querySelector('.modal-fade-screen')
            : this.modalTargetElement.querySelector(`#${modalName}`);
        this.modalCloseButton = this.modalTargetElement.querySelector('.modal__close')
            ? this.modalTargetElement.querySelector('.modal__close')
            : this.modalTargetElement.querySelector('.modal-close');

        this.removeTargetHandler.addEventListener(
            'click',
            function(e) {
                if (LazyModal.removeTargetHandler == e.target) {
                    LazyModal.modalTargetElement.remove();
                    document.querySelector('body')?.classList.remove('overflow-hidden');
                }
            },
            { passive: true },
        );

        if (this.modalCloseButton) {
            this.modalCloseButton.addEventListener('click', () => {
                this.modalTargetElement.remove();
                document.querySelector('body')?.classList.remove('overflow-hidden');
            });
        }
    }

    public static callModalPremium() {
        this.callLazyModalClick('modal-premium');
    }

    public static callModalLicense() {
        this.callLazyModalClick('modal-license');
    }
    public static callModalAttribution() {
        this.callLazyModalClick('modal-attribution-new');
    }
    public static callModalRemovePalette() {
        this.callLazyModalClick('modal-remove-palette');
    }
    public static callModalPaletteNotPremiumUser() {
        this.callLazyModalClick('modal-palette-not-premium-user');
    }
    public static callModalCopySvgFromSearchListNotPremiumUser() {
        this.callLazyModalEvent('modal-copy-svg-from-search-list-not-premium-user');
    }
    public static callModalConfirmCopyContent() {
        this.callLazyModalClick('modal-confirm-copy-content');
    }
    public static callFunnelPremium(icon?: string) {
        this.callLazyModalEvent('modal-funnel-premium-add-icon', icon, true);
    }
    public static callModalDownloadOnlyForPremium(icon?: string) {
        this.callLazyModalEvent('modal-funnel-premium-freePng', icon, true);
    }
    public static callModalCraftInfo(icon?: string) {
        this.callLazyModalEvent('modal-craft-info', icon, true);
        document.getElementById('buy-craft-modal')?.addEventListener('click', () => {
            redirectPurchase();
        });
    }
}
