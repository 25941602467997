var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"pd-top-lv3 pd-bottom-lv2-i\">\n	<h1 class=\"mg-none font-xl\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"nameDetail") : stack1), depth0))
    + "</h1>\n</div>\n\n<div class=\"detail__mobile-holder only-share modal-download--target download-extended\">\n	<div class=\"fi-premium-icon\">\n		<div class=\"download download-action animated-icon--download\" id=\"download\">\n			<button class=\"btn-png btn col mg-none bj-button bj-button--primary download-dropdown-button\">\n				<i class=\"icon icon--download\"></i>\n				<span>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"download") : stack1), depth0))
    + "</span>\n			</button>\n			<span class=\"detail__copypng tooltip tooltip--bottom\">\n				<button class=\"copypng--button tooltip__trigger tooltip__trigger--always bj-button bj-button--secondary bj-button--outline bj-button--square track\" data-track-arguments=\"ga, event, detail-view, copyPNG, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-gtm-event=\"copy detail png\">\n					<i class=\"icon icon--copy-image\"></i>\n				</button>\n				<div class=\"tooltip__content\">\n					<div class=\"content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"common") : stack1)) != null ? lookupProperty(stack1,"copyPNG") : stack1), depth0))
    + "</div>\n				</div>\n			</span>\n				"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"addCollectionBtnComponent") || (depth0 != null ? lookupProperty(depth0,"addCollectionBtnComponent") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"addCollectionBtnComponent","hash":{},"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":20,"column":35}}}) : helper))) != null ? stack1 : "")
    + "\n				"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"shareRrSsComponent") || (depth0 != null ? lookupProperty(depth0,"shareRrSsComponent") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"shareRrSsComponent","hash":{},"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":21,"column":28}}}) : helper))) != null ? stack1 : "")
    + "\n		</div>							\n	</div>						\n</div>";
},"useData":true});