var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            bj-button--link\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            bj-button--secondary bj-button--outline bj-button--square\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"btn-share share popover popover-share\">\n    <div class=\"popover-container popover-not-close\">\n        <button class=\"popover-button bj-button \n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","standard",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":6,"column":20}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"!=","standard",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":20}}})) != null ? stack1 : "")
    + "\n            \">\n            <i class=\"icon icon--share\"></i><span class=\"hide-mobile\">"
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"share") : stack1), depth0))
    + "</span>\n        </button>\n        <ul class=\"popover-content\">\n            <li class=\"mg-bottom-lv2\">\n                <a href=\"#\" onclick=\"share.popup({network: 'pinterest'});return false;\" class=\"bj-button bj-button--sm bj-button--pinterest full-width\"\n                    title=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"share_in_pt") : stack1), depth0))
    + "\" data-gtm-event=\"pinterest detail\">\n                    <i class=\"icon icon--pinterest\"></i><span>Pinterest</span>\n                </a>\n            </li>\n            <li class=\"mg-bottom-lv2\">\n                <a href=\"#\" onclick=\"share.popup({network: 'facebook'});return false;\" class=\"bj-button bj-button--sm bj-button--facebook full-width\"\n                    title=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"share_in_fb") : stack1), depth0))
    + "\">\n                    <i class=\"icon icon--facebook\"></i><span>Facebook</span>\n                </a>\n            </li>\n            <li class=\"mg-bottom-lv2\">\n                <a href=\"#\" onclick=\"share.popup({network: 'twitter'});return false;\" class=\"bj-button bj-button--sm bj-button--twitter full-width\"\n                    title=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"share_in_tw") : stack1), depth0))
    + "\">\n                    <i class=\"icon icon--twitter\"></i><span>Twitter</span>\n                </a>\n            </li>\n            <li>\n                <div class=\"group\">\n                    <div class=\"group__context\">\n                        <span>\n                            <i class=\"icon icon--url\"></i>\n                        </span>\n                    </div>\n                    <div class=\"group__input\">\n                        <input class=\"input-share-url size--sm font-sm\" type=\"text\" value=\"\">\n                    </div>\n                </div>\n            </li>\n        </ul>\n    </div>\n</div>\n";
},"useData":true});