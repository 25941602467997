import { delegate, q, qq, ww } from 'bobjoll/ts/library/dom';
import { modal, ModalPrintSettings } from 'BobjollModal';
import { EventTracker } from 'Library/eventTacker';
import { getMobileNavigatorVersion } from 'Library/helpers/mobile';
import { capitalize } from 'Library/helpers/text';
import { ResourceType } from 'Project/ts/common-app/downloads';
import { LazyModal } from 'Project/ts/pods/modals/lazyModal';

require('Project/ts/templates/helpers');

export class Attribution {
    public static attributionIncentives: DetailAttributionIncentive[];
    private static modalSettings: ModalPrintSettings;
    public static resourceType: ResourceType;
    private static directDownload: boolean;
    private static originaldetailContentElementHeight: number | undefined;
    private static detailContentElement: HTMLElement | null;
    public static fromCollections: boolean = false;
    private static modalAttributionHeight: number | undefined;
    private static iconDetailView: string = 'icon-detail';
    private static uiconDetailView: string = 'uicons-detail-page';

    public static initialize(resourceType?: ResourceType) {
        const resourceData = ww.Resource.resource;
        if (resourceType) {
            this.resourceType = resourceType;
        }
        const modalAttributionView = document.querySelector('.detail__download-confirmation');
        if (modalAttributionView) {
            modalAttributionView.remove();
        }
        const attributionDefaults = {
            description: messages.attribution.description,
            title: messages.attribution.title,
        };
        let license = '';
        let authorData;
        let typeCollection = document.querySelector('.modal-collection');
        if (this.resourceType == 'uicons') {
            license = `<div>${messages.attribution.uicons_made_by} <a href="${APP_URL}uicons" title="Flaticon">Flaticon</a></div>`;
        } else if (
            resourceType !== 'icon' &&
            typeCollection &&
            !typeCollection.classList.contains('hidden') &&
            ww.view.section !== this.iconDetailView
        ) {
            this.resourceType = 'collection';
            authorData = null;
            let collectionLicense = typeCollection.querySelector('.input_atributtion_text') as HTMLInputElement;
            if (collectionLicense) {
                license = collectionLicense.value;
            }
        } else {
            this.resourceType =
                document.querySelector('.modal-pack') &&
                !document.querySelector('.modal-pack')?.classList.contains('hidden')
                    ? 'pack'
                    : 'icon';
            authorData = resourceData.author;

            if (resourceData) {
                license = `<a href="${resourceData.keyword.link}" title="${
                    resourceData.keyword.name
                } ${ww.Attribution.getAttributionTypeMessage(resourceData.type)}">${capitalize(
                    resourceData.keyword.name,
                )} ${ww.Attribution.getStartAttributionMessage(resourceData.type)} ${
                    resourceData.author.name
                } - Flaticon</a>`;
            } else {
                license = `<div>${messages.attribution.icons_made_by} <a href="${authorData?.url}" title="${authorData?.name}">${authorData?.name}</a> from <a href="${APP_URL}" title="Flaticon">${window.location.host}</a></div>`;
            }
        }

        let global = Attribution.getGlobalVariables();
        global.HOW_ATTRIBUTE = `${SUPPORT_URL}/article/Attribution-How-when-and-where-FI?${SUPPORT_LANG}`;
        global.ACCOUNTS_URL = GOOGLE_IDP_URL_REGISTER;
        global.DOWNLOAD_PROBLEM = `${APP_URL}profile/support`;

        if (license) {
            Attribution.attributionIncentives = [
                {
                    ...attributionDefaults,
                    catch: messages.attribution.unicorn_text,
                    mask: `${URL_STATIC_IMG}video/unicorn/mask.svg`,
                    video: `${URL_STATIC_IMG}video/unicorn/video.mp4`,
                    loop: {
                        end: {
                            start: 3,
                        },
                    },
                },
                {
                    ...attributionDefaults,
                    catch: messages.attribution.designer_text,
                    mask: `${URL_STATIC_IMG}video/sad/mask.svg`,
                    video: `${URL_STATIC_IMG}video/sad/video.mp4`,
                    loop: {
                        beginning: {
                            start: 0,
                            end: 2.06,
                        },
                        end: {
                            start: 4,
                        },
                    },
                },
                {
                    ...attributionDefaults,
                    catch: messages.attribution.rex_text,
                    mask: `${URL_STATIC_IMG}video/rex/mask.svg`,
                    video: `${URL_STATIC_IMG}video/rex/video.mp4`,
                    loop: {
                        beginning: {
                            start: 0,
                            end: 3,
                        },
                        end: {
                            start: 5,
                            end: 8,
                        },
                    },
                },
                {
                    ...attributionDefaults,
                    catch: messages.attribution.karma_text,
                    mask: `${URL_STATIC_IMG}video/karma/mask.svg`,
                    video: `${URL_STATIC_IMG}video/karma/video.mp4`,
                    loop: {
                        beginning: {
                            start: 0,
                            end: 3,
                        },
                        end: {
                            start: 5,
                            end: 9,
                        },
                    },
                },
            ];
            let template = require('Project/ts/templates/parts/attribution.hbs');
            const downloadSection = document.querySelector('.fi-modal.modal-download');

            if (downloadSection || this.resourceType == 'uicons') {
                const pricingUrl = `${FLATICON_URL}${messages.url.pricing}`;
                this.directDownload = !!document.querySelector('.direct-download');
                const isEditorViewHide = (document.querySelector('.detail__editor') as HTMLElement)?.classList.contains(
                    'hide',
                );

                if (!this.directDownload && !this.fromCollections && isEditorViewHide) {
                    document.querySelector('.detail__content')?.insertAdjacentHTML(
                        'beforeend',
                        template({
                            attributionIncentives: Attribution.attributionIncentives,
                            author: authorData,
                            global: global,
                            license: license,
                            messages: messages,
                            resourceType: Attribution.resourceType,
                            directDownload: this.directDownload,
                            pricingUrl,
                        }),
                    );

                    const modalAttribution = document.querySelector('#modal-attribution') as HTMLElement;
                    modalAttribution?.classList.add('modal-attribution-detail');
                    document.body?.classList.remove('overflow-hidden');

                    if (document.body.dataset.section === this.iconDetailView) {
                        modalAttribution.style.zIndex = '10';
                    }
                    if (document.body.dataset.section === 'icon-search') {
                        const banner = document.querySelector('.banner') as HTMLElement;
                        if (banner.offsetWidth >= 890) {
                            banner.style.backgroundPosition = 'left';
                            banner.style.backgroundSize = 'cover';
                        }
                    }
                } else {
                    document.body.insertAdjacentHTML(
                        'beforeend',
                        template({
                            attributionIncentives: Attribution.attributionIncentives,
                            author: authorData,
                            global: global,
                            license: license,
                            messages: messages,
                            resourceType: Attribution.resourceType,
                            directDownload: true,
                        }),
                    );
                }

                window.addEventListener('download-show', function(e: CustomEvent) {
                    const attributionIncentiveElement = (q(
                        '#modal-detail.active .detail__download-confirmation .attribution',
                    ) || q('.detail__download-confirmation .attribution')) as HTMLElement;

                    if (attributionIncentiveElement && attributionIncentiveElement.dataset.attrId) {
                        const attributionIncentiveIndex = parseInt(attributionIncentiveElement.dataset.attrId) || 0;
                        const attributionOptions = Attribution.attributionIncentives[attributionIncentiveIndex];
                        const copyElement = q('.copy__trigger', attributionIncentiveElement);
                        const copyInput = q('.copy__text input', attributionIncentiveElement) as HTMLInputElement;
                        const videoElement = q(
                            '.attribution__video video',
                            attributionIncentiveElement,
                        ) as HTMLVideoElement;
                        var playPromise;
                        if (copyElement && videoElement) {
                            videoElement.currentTime = attributionOptions.loop?.beginning?.start || 0;
                            videoElement.style.maskImage = `url('${attributionOptions.mask}')`;
                            videoElement.volume = 0;

                            videoElement.ontimeupdate = function() {
                                if (videoElement.currentTime >= (attributionOptions.loop?.beginning?.end || 0)) {
                                    videoElement.currentTime = attributionOptions.loop?.beginning?.start || 0;
                                    playPromise = videoElement.play();
                                }
                            };

                            videoElement.onended = function() {
                                videoElement.currentTime = attributionOptions.loop?.end?.start || 0;
                                playPromise = videoElement.play();
                            };

                            qq('.social-media-buttons .bj-button', attributionIncentiveElement).forEach(element => {
                                element.onmouseup = function() {
                                    videoElement.ontimeupdate = null;
                                    playPromise = videoElement.play();
                                    if (element.classList.contains('copy__trigger')) {
                                        copyInput.select();
                                        document.execCommand('copy');
                                        copyElement.textContent = messages.attribution.copied;
                                        EventTracker.send(
                                            'ga',
                                            'event',
                                            'attribution',
                                            Attribution.resourceType,
                                            'web',
                                        );
                                    }
                                };
                            });

                            if (attributionOptions.loop?.beginning) {
                                playPromise = videoElement.play();
                            }

                            if (playPromise !== undefined) {
                                playPromise
                                    .then(_ => {
                                        // Automatic playback started!
                                        // Show playing UI.
                                    })
                                    .catch(error => {
                                        // Auto-play was prevented
                                        // Show paused UI.
                                    });
                            }
                        }
                    }
                });

                delegate('.detail__download-confirmation__close', 'click', function(this: HTMLButtonElement) {
                    const detail = this.parent('.detail__download-confirmation');
                    if (detail) {
                        detail.classList.remove('show');
                        modal.hide();

                        if (Attribution.detailContentElement) {
                            Attribution.detailContentElement.style.minHeight = `${Attribution.originaldetailContentElementHeight}px`;
                        }

                        Attribution.initialize();
                    }

                    if (document.querySelector('body')?.classList.contains('overflow-hidden')) {
                        document.querySelector('body')?.classList.remove('overflow-hidden');
                    }
                });

                delegate('#download-problems', 'click', function(this: HTMLButtonElement) {
                    q('.detail__download-confirmation__close')?.click();
                });
            }
        }

        if (getMobileNavigatorVersion().split(' ')[0] == 'Safari') {
            let attrVideo = q('.attribution__video');

            if (attrVideo) {
                attrVideo.classList.add('hide-safari-mobile');
            }
        }
    }

    private static resizeDetailContentELement(modalAttributionHeight: number | undefined, onResize?: boolean) {
        if (this.detailContentElement) {
            if (!this.originaldetailContentElementHeight) {
                this.originaldetailContentElementHeight = this.detailContentElement.offsetHeight;
            }

            if (onResize) {
                modalAttributionHeight = document.getElementById('modal-attribution')?.offsetHeight;
            }

            this.detailContentElement.style.minHeight = `${modalAttributionHeight}px`;
        }
    }

    public static show() {
        modal.show('modal-attribution');

        if (!this.directDownload) {
            this.modalAttributionHeight = document.getElementById('modal-attribution')?.offsetHeight;
            this.detailContentElement = document.querySelector('.detail__content');

            this.resizeDetailContentELement(this.modalAttributionHeight);

            window.addEventListener(
                'resize',
                function() {
                    Attribution.resizeDetailContentELement(Attribution.modalAttributionHeight, true);
                },
                true,
            );
        }

        if (
            (ww.view.section === this.iconDetailView || ww.view.section === this.uiconDetailView) &&
            document.querySelector('body')?.classList.contains('overflow-hidden')
        ) {
            document.querySelector('body')?.classList.remove('overflow-hidden');
        }

        let modalView = document.getElementById('modal-download');

        if (modalView) {
            const element = q('.detail__download-confirmation') || q('.detail .detail__download-confirmation');
            if (element) {
                const showFreeDownload = new CustomEvent(`download-show`);
                element.classList.add('show');
                window.dispatchEvent(showFreeDownload);
            }
        }

        LazyModal.callModalAttribution();
    }

    private static getGlobalVariables() {
        const globalVariables = [
            'BASE_URL',
            'LANGUAGE',
            'URL_STATIC_IMG',
            'LICENSES',
            'SUPPORT_URL',
            'SUPPORT_LANG',
            'ACCOUNTS_URL',
            'APP_URL',
            'HOW_ATTRIBUTE',
        ];
        const variables: { [name: string]: any } = globalVariables.reduce(function(
            acc: { [name: string]: any },
            variable: string,
        ) {
            if ((window as any)[variable]) {
                acc[variable] = (window as any)[variable];
            }

            return acc;
        },
        {});

        return { ...variables };
    }

    public static getStartAttributionMessage(resourceType: string) {
        const resourceTypes: AttributionMessage = {
            icon: messages.attribution.icons_created_by,
            sticker: messages.attribution.stickers_created_by,
            standard: messages.attribution.icons_created_by,
            'animated-icon': messages.attribution.animatedIcon_created_by,
        };
        return resourceTypes[resourceType];
    }

    public static getAttributionTypeMessage(resourceType: string) {
        const resourceTypes: AttributionMessage = {
            icon: messages.attribution.type_icon,
            sticker: messages.attribution.type_sticker,
            standard: messages.attribution.type_icon,
            'animated-icon': messages.attribution.type_animated_icon,
        };
        return resourceTypes[resourceType];
    }
}

interface DetailAttributionIncentive {
    video: string;
    mask?: string;
    loop?: {
        beginning?: DetailAttributionVideoLoop;
        end?: DetailAttributionVideoLoop;
    };
    catch?: string;
    description?: string;
    title?: string;
}

interface DetailAttributionVideoLoop {
    start: number;
    end?: number;
}

interface AttributionMessage {
    [key: string]: string;
}
