var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "icon-mp4-container";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"style") : stack1)) != null ? lookupProperty(stack1,"styleClass") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <img \n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceTypes") : depth0)) != null ? lookupProperty(stack1,"uicon") : stack1),{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":5},"end":{"line":15,"column":17}}})) != null ? stack1 : "")
    + "      src=\" "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resourcePreviewFormat") : depth0),"==","svg",{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":93}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resourcePreviewFormat") : depth0),"==","png",{"name":"compare","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":94},"end":{"line":16,"column":184}}})) != null ? stack1 : "")
    + "\"\n      width=\"256\" \n      height=\"256\"\n      alt=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"alt") : stack1), depth0))
    + "\"\n      title=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\"\n      class=\"img-small\" />\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "     id=\"uicons__detail-img\"\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"svg") : stack1), depth0))
    + " ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + " ";
},"12":function(container,depth0,helpers,partials,data) {
    return "     <div class=\"uicon-detail-holder\"></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <video \n      width=\"256\" \n      height=\"256\" \n      preload=\"none\" \n      style=\"background: transparent  url('"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "') 50% 50% / fit no-repeat;\" \n      autoplay=\"autoplay\" \n      loop=\"true\" \n      muted=\"muted\" "
    + alias2(((helper = (helper = lookupProperty(helpers,"playsinline") || (depth0 != null ? lookupProperty(depth0,"playsinline") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"playsinline","hash":{},"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":37,"column":35}}}) : helper)))
    + " >\n        <source \n          src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"mp4") : stack1), depth0))
    + "\" \n          type=\"video/mp4\">\n    </video>				\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div \n  class=\"main-icon-without-slide icon-png-container pd-lv4 \n    "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resourcePreviewFormat") : depth0),"==","mp4",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":79}}})) != null ? stack1 : "")
    + " \n    "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"!=",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceTypes") : depth0)) != null ? lookupProperty(stack1,"standard") : stack1),{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":107}}})) != null ? stack1 : "")
    + "\" \n  data-type=\"img\" \n  data-png=\""
    + alias4(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"resource") : stack1)) != null ? lookupProperty(stack1,"png") : stack1), depth0))
    + "\" \n  data-id=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" \n  data-premium=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"isPremium") : stack1), depth0))
    + "\">									\n\n\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resourcePreviewFormat") : depth0),"!=","mp4",{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":22,"column":14}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceTypes") : depth0)) != null ? lookupProperty(stack1,"uicon") : stack1),{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":2},"end":{"line":26,"column":14}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resourcePreviewFormat") : depth0),"==","mp4",{"name":"compare","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":2},"end":{"line":42,"column":14}}})) != null ? stack1 : "")
    + "\n</div>";
},"useData":true});