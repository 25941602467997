/* eslint-disable @typescript-eslint/camelcase */
import { DataLayerEvent, EventTracker } from 'Project/ts/library/eventTacker';

import { ElementData } from './types';

export const buyLicenseG4Event = (planData: ElementData, currency: string): void => {
    const eventData: DataLayerEvent = {
        event: 'add_to_cart',
        ecommerce: {
            process_name: 'merchandising_checkout',
            currency,
            items: [planData],
        },
    };

    EventTracker.send('ga4dl', { ecommerce: null });
    EventTracker.send('ga4dl', eventData);
};
