import 'Variables';

import { ww } from 'bobjoll/ts/library/dom';
import { resourceTypes } from 'CommonApp/resource/resourceConfigService';
import { getTypeView } from 'CommonFI/datalayer.helpers';
import { EventTracker } from 'Library/eventTacker';
import { Follow } from 'Library/follow';
import { addSlashes } from 'Library/helpers';
import getXHR, { httpBuildQuery } from 'Partials/xhr';
import { addAnimatedIconListeners } from 'Pods/animatedIcons/animatedIconsHelpers';
import {
    copyNpmUicons,
    copyToClipboardHandleListeners,
    copyUrlCdnUicons,
    copyUrlShare,
} from 'Pods/copyPaste/copyToClipboard';
import { initProgressiveImages } from 'Project/ts/common/progressive-image';
import { getUrlParam, storeUrl } from 'Project/ts/common/urls.helper';
import { Downloads } from 'Project/ts/common-app/downloads';
import { renderResourceDetailComponents } from 'Project/ts/pages/icon-detail/componentsServices/detailComponentsService';
import { renderlistResourceComponents } from 'Project/ts/pages/icon-detail/componentsServices/listComponentsService';
import {
    AuthorStyleCollection,
    AuthorStyleData,
    DetailIconData,
    Icon,
    IconData,
    Metas,
    RelatedData,
    Tag,
} from 'Project/ts/pages/icon-detail/detailService.vm';
import { SearchAuthorBar } from 'Project/ts/partials/search/searchAuthorBar';
import { getModalDownload } from 'Project/ts/pods/modals/download/downloadModalService';
import { LazyModal } from 'Project/ts/pods/modals/lazyModal';
import User from 'Project/ts/pods/user/main';
import Lazyload from 'UI/lazyload';

require('Project/ts/templates/helpers');

export class Detail {
    private static container: HTMLElement;
    private static content: HTMLElement;
    private static resourceTypes = {
        icon: 'standard',
        sticker: 'sticker',
        animatedIcon: 'animated-icon',
        uicon: 'uicon',
    };

    public static init() {
        Downloads.initializeDownload('icon', 'icon-detail');
        if (!document.querySelector('#detail-content')?.hasChildNodes()) {
            const detailElement = document.querySelector('#detail') as HTMLElement;
            if (detailElement) {
                ww.Resource.setResourceFromElement(detailElement);
                if (ww.Resource.resource?.type) {
                    ww.Resource.setResourceConfig(ww.Resource.resource.type);
                }
            }
        }
        if (ww.Resource.resource.type == this.resourceTypes.icon && ww.Resource.resource.craft) {
            this.handleCraftLicense();
        }

        this.handleEditor();
    }

    private static getBlocksData(detailElement: HTMLElement) {
        const relatedData: RelatedData = {
            id: detailElement.dataset.id || '',
            styleId: detailElement.dataset.style,
            packId: detailElement.dataset.pack_id,
            relatedTags: detailElement.dataset.tags,
            equivalentIcons: detailElement.dataset.related,
            groupId: detailElement.dataset.group_id,
            type: ww.Resource.resource.type,
        };

        const url = APP_URL + 'ajax/detail-related?' + httpBuildQuery(relatedData);

        return getXHR(url, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-type': 'application/json',
            },
            withCredentials: true,
        });
    }

    private static getAuthorStyles(groupStylesId: string) {
        const url = `${APP_URL}ajax/author-styles/${groupStylesId}`;

        return getXHR(url, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-type': 'application/json',
            },
            withCredentials: true,
        });
    }

    private static handleBlocksData(detailElement: HTMLElement, modal: HTMLElement, data: DetailIconData) {
        if (data.uIconVersion) {
            const uiconVersionElement = modal.querySelector('.uicon-version');
            if (uiconVersionElement) {
                const uiconVersionTemplate = require('Project/ts/pages/icon-detail/detailComponents/aside/uiconVersionComponent.hbs');
                uiconVersionElement.innerHTML = uiconVersionTemplate({
                    version: data.uIconVersion,
                    messages,
                });
            }
        }

        if (data.iconsEquivalent) {
            const equivalentsElement = modal.querySelector('section.detail-styles');
            if (equivalentsElement) {
                equivalentsElement.innerHTML = '';
                equivalentsElement.appendChild(
                    this.renderIconListEquivalent(data.iconsEquivalent.icons, detailElement.dataset.id || ''),
                );
            }
        }


        if (data.iconsInTag) {
            let relatedTagsElement = modal.querySelector('section.related-tags');

            if (relatedTagsElement) {
                relatedTagsElement.innerHTML = '';
                if (ww.Resource.resource.type == this.resourceTypes.uicon) {
                    relatedTagsElement.appendChild(
                        this.renderHead(
                            data.iconsInTag.metas,
                            require('Project/ts/pages/icon-detail/head-uicons-related-tags.hbs'),
                        ),
                    );
                } else {
                    relatedTagsElement.appendChild(
                        this.renderHead(
                            data.iconsInTag.metas,
                            require('Project/ts/pages/icon-detail/head-related-tags.hbs'),
                        ),
                    );
                }
                relatedTagsElement.appendChild(this.renderIconList(data.iconsInTag.icons));
            }
        }

        let dataNewIconDetail =
            data.iconsInTagAndStyle?.total >= AMOUNT_IN_RELATED_BY_TAG_AND_STYLE
                ? data.iconsInTagAndStyle
                : data.iconsInTag;
        if (dataNewIconDetail) {
            let relatedTagsElement = modal.querySelector('section.related-tags-styles');
            if (relatedTagsElement) {
                relatedTagsElement.innerHTML = '';
                relatedTagsElement.appendChild(
                    this.renderHead(
                        dataNewIconDetail.metas,
                        require('Project/ts/pages/icon-detail/head-related-tags-styles.hbs'),
                        dataNewIconDetail.total,
                        data,
                    ),
                );
                relatedTagsElement.appendChild(this.renderIconList(dataNewIconDetail.icons));
            }
        }
        if (data.iconsInPack) {
            let relatedPackElement = modal.querySelector('section.related-tags-styles');
            let relatedPacksHead = require('Project/ts/pages/icon-detail/head-related-tags-styles.hbs');
            if (
                ww.Resource.resource.type == this.resourceTypes.animatedIcon ||
                ww.Resource.resource.type == this.resourceTypes.sticker
            ) {
                relatedPackElement = modal.querySelector('section.related-packs');
                relatedPacksHead = require('Project/ts/pages/icon-detail/head-related-pack.hbs');
            }
            if (relatedPackElement) {
                relatedPackElement.innerHTML = '';
                relatedPackElement.appendChild(
                    this.renderHead(data.iconsInPack.metas, relatedPacksHead, data.iconsInPack.total, data),
                );
                relatedPackElement.appendChild(this.renderIconList(data.iconsInPack.icons));
            }
        }
        if (data.iconsInStyle) {
            let relatedStylesElement = modal.querySelector('section.related-styles');
            if (relatedStylesElement) {
                relatedStylesElement.innerHTML = '';
                relatedStylesElement.appendChild(
                    this.renderHead(
                        data.iconsInStyle.metas,
                        require('Project/ts/pages/icon-detail/head-related-style.hbs'),
                    ),
                );
                relatedStylesElement.appendChild(this.renderIconList(data.iconsInStyle.icons));
            }
        }

        if (data.iconTags) {
            const tagsSection = modal.querySelector('.detail-footer');

            if (tagsSection) {
                if (
                    ww.Resource.resource.type !== this.resourceTypes.icon &&
                    ww.Resource.resource.type !== this.resourceTypes.uicon
                ) {
                    tagsSection.innerHTML = `<h2 class="font-h6 mg-bottom-lv2 alignLeft">${messages.detail.related_tags}</p>`;
                }
                tagsSection.appendChild(this.renderTagsList(data.iconTags));
            }
        }
        if (data.iconLicenseUrl) {
            let tooltipLicenseLink = modal.querySelectorAll('.tooltip.tooltip--right a');
            if (tooltipLicenseLink.length > 0) {
                tooltipLicenseLink.forEach(item => {
                    item.setAttribute('href', data.iconLicenseUrl);
                });
            }
        }

        setTimeout(() => Lazyload.run(), 500);
        modal.addEventListener('scroll', () => setTimeout(() => Lazyload.run(modal), 50));

        copyToClipboardHandleListeners();
        LazyModal.callModalPremium();
        LazyModal.callModalLicense();
        LazyModal.callModalAttribution();
        Downloads.initializeDownload('icon', 'icon-detail');
        this.handleIconTooltip(modal);
        ww.Resource.handleResourceListener(
            '.related .link-icon-detail, .related .icon--item__context-menu li, .detail_equivalent_icons .related-icon ',
        );
    }

    public static async getModalDetail(detailElement: HTMLElement, editDirectly: boolean = false) {
        const data: IconData = Detail.getDataByElement(detailElement);
        const containerDetail = document.getElementById('detail-overlay');
        const modalDetail = document.getElementById('detail-wrapper');
        const linkIcon: HTMLLinkElement | null = detailElement.querySelector('.link-icon-detail');
        const editContent = document.querySelector('.detail__editor') as HTMLElement;
        const resourceDetailTemplate = require('Project/ts/pages/icon-detail/resource-detail.hbs');
        const iconResourceDetailTemplate = require('Project/ts/pages/icon-detail/icon-resource-detail.hbs');
        const resourceData = ww.Resource.resource;
        const resourceConfig = ww.Resource.resourceConfig;
        const resourceComponents = renderResourceDetailComponents(resourceConfig, resourceTypes, resourceData, global);

        if (containerDetail && modalDetail && linkIcon) {
            const contentDetail = containerDetail.querySelector('#detail-content') as HTMLElement;

            if (contentDetail) {
                const groupId = detailElement.dataset.group_id as string;
                let url = linkIcon.href;

                Detail.container = containerDetail;
                Detail.content = contentDetail;
                if (data.keyword.name) {
                    data.keyword.name = addSlashes(data.keyword.name);
                }
                ga('send', 'pageview', `/${url.replace(APP_URL, '')}`);

                if (User.isPremium()) {
                    document.body.classList.add('user-premium');
                }

                this.container.classList.remove('hidden');
                this.container.classList.add('loading', 'ready');
                document.body.classList.add('unscrollable');
                const global = await this.getGlobalVariables();

                if (resourceData.type === this.resourceTypes.icon) {
                    this.content.innerHTML = iconResourceDetailTemplate({
                        resourceTypes,
                        resourceData,
                        initialDownloadSize: resourceConfig.detail.downloads.formats.free.png.defaultSize,
                        global,
                        resourceComponents,
                        messages,
                    });
                } else {
                    this.content.innerHTML = resourceDetailTemplate({
                        resourceTypes,
                        resourceData,
                        initialDownloadSize: resourceConfig.detail.downloads.formats.free.png.defaultSize,
                        global,
                        resourceComponents,
                    });
                }

                if (ww.Resource.resource.type != this.resourceTypes.animatedIcon) {
                    initProgressiveImages();
                }

                if (ww.Resource.resource.type === this.resourceTypes.uicon) {
                    import('Pods/uicons').then(module => {
                        module.Uicons.init();
                        module.Uicons.handleUiconsEditorEvent();
                        module.Uicons.isEdited = false;
                    });

                    copyUrlCdnUicons();
                    copyNpmUicons();
                }

                const detailContent: HTMLElement | null = document.getElementById('detail');
                if (detailContent) {
                    window.dispatchEvent(
                        new CustomEvent(`sectionView`, {
                            detail: {
                                section: 'icon-detail',
                                edit: editDirectly,
                                modal: true,
                            },
                        }),
                    );
                    Follow.init('#detail');

                    LazyModal.callModalPremium();
                    LazyModal.callModalLicense();
                    LazyModal.callModalAttribution();

                    if (data?.url?.href) {
                        storeUrl(data.url.href);
                    }

                    document.querySelector('.detail__sidebar__pack a')?.addEventListener('click', e => {
                        e.preventDefault();
                        storeUrl(data.pack.url);
                        window.location.href = GOOGLE_IDP_URL_REGISTER;
                    });

                    if (ww.Resource.resourceConfig.detail.autorSearchBar) {
                        await this.getAuthorStyles(groupId).then((iconData: AuthorStyleCollection) => {
                            const authorStylesList: HTMLElement | null = document.getElementById('authorStyles-list');
                            iconData.collection.forEach((liStyleElement: AuthorStyleData) => {
                                authorStylesList?.insertAdjacentHTML(
                                    'beforeend',
                                    `<li class="font-sm ${
                                        liStyleElement.id.toString() === data.style.id ? 'active' : ''
                                    }" data-value="${
                                        liStyleElement.id
                                    }"><span class="icon icon--check mg-right-lv1"></span>${
                                        liStyleElement.familyName
                                    } ${liStyleElement.name}</li>`,
                                );
                            });
                        });
                    }

                    let shareButton = modalDetail.querySelector('.share button');
                    const urlShare = modalDetail.querySelector('input.input-share-url') as HTMLInputElement;
                    if (shareButton) {
                        shareButton.addEventListener(
                            'click',
                            () => {
                                if (urlShare) {
                                    urlShare.value = document.location.href;
                                }
                            },
                            { passive: true },
                        );
                    }

                    if (urlShare) {
                        urlShare.addEventListener('click', e => {
                            let inputShareTarget = e.target as HTMLInputElement;
                            copyUrlShare(inputShareTarget);
                        });
                    }

                    await this.getBlocksData(detailElement).then(iconData => {
                        this.handleBlocksData(detailElement, modalDetail, iconData);
                        addAnimatedIconListeners();
                    });
                }

                if (!document.querySelector('.detail__editor')) {
                    editContent && document.body.appendChild(editContent);
                }

                if (ww.Resource.resource.type == this.resourceTypes.icon) {
                    const authorSearchBar = new SearchAuthorBar(
                        contentDetail.querySelector('.author_search'),
                        contentDetail.querySelector('.author_search .search__button'),
                        Array.from(contentDetail.querySelectorAll('.author_search .style-id__list li')),
                        contentDetail.querySelector('.author_search input[name="style_id"]'),
                    );

                    authorSearchBar.handleAuthorEvents();
                }

                if (ww.Resource.resource.type === this.resourceTypes.animatedIcon) {
                    modalDetail.querySelector('.download-dropdown-button')?.addEventListener('click', () => {
                        getModalDownload();
                    });
                }

                EventTracker.send('ga4dl', {
                    event: 'modalItem',
                    content_group: getTypeView(),
                });
            }
        }
    }

    public static close() {
        let editorElement = document.querySelector('.detail__editor') as HTMLElement;
        if (editorElement && ww.Editor) {
            ww.Editor.close();
        }

        if (
            ww.view.section === 'icon-detail' &&
            document.querySelector('body')?.classList.contains('overflow-hidden')
        ) {
            document.querySelector('body')?.classList.remove('overflow-hidden');
        }

        document.body.classList.remove('unscrollable');
        this.container.classList.remove('loading', 'ready');
        this.container.classList.remove('hidden');
        this.content.innerHTML = '';
    }

    private static renderHead(metas: Metas, tmpl: any, totalRelatedIcons?: number, data?: any) {
        let headElement = document.createElement('div');
        headElement.innerHTML = tmpl({
            ...metas,
            messages,
            totalRelatedIcons,
            global,
            resourceType: ww.Resource.resource.type,
            resourceName: ww.Resource.resource.name,
            resourceRelatedPackLink: ww.Resource.resourceConfig.detail.relatedPackLink,
            data,
        });

        return headElement;
    }

    private static renderIconList(data: Icon[]) {
        const containerRelatedIcons: HTMLElement | null = document.querySelector(
            '.detail.new-detail .related-tags-styles',
        );

        const resourceTemplate = require('Project/ts/pages/icon-detail/resource.hbs');

        const ulList = document.createElement('ul');
        ulList.className = 'icons';

        if (ww.Resource.resource.type === this.resourceTypes.uicon) {
            ulList.classList.add('uicons-list');
        }

        data.forEach(element => {
            let resourceElement = renderlistResourceComponents(ww.Resource.resourceConfig, element);
            ulList.insertAdjacentHTML(
                'beforeend',
                resourceTemplate({
                    resourceElement,
                }),
            );
        });

        for (let index = 0; index < 25; index++) {
            let fakeLiElement = document.createElement('li');
            fakeLiElement.className = 'icon--item icon-fill';
            ulList.appendChild(fakeLiElement);
        }

        if (containerRelatedIcons) {
            containerRelatedIcons.style.minHeight = 'unset';
        }
        return ulList;
    }

    private static renderIconListEquivalent(data: Icon[], iconActiveId: string) {
        let template = require('Project/ts/pages/icon-detail/icon-equivalent.hbs');
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let ulList = document.createElement('ul');
        ulList.className = 'detail_equivalent_icons';
        let listElements = '';
        let params = '';

        data.forEach(element => {
            if (urlParams.get('word') !== null || urlParams.get('term') !== null) {
                params = encodeURI(urlParams.get('term') !== null ? '?term=' + urlParams.get('term') : '');
            }

            listElements += template({
                ...element,
                iconActiveId: iconActiveId,
                messages: messages,
                global: global,
                params: params,
            });
        });

        ulList.innerHTML = listElements;
        return ulList;
    }

    private static renderTagsList(data: Tag[]) {
        let ulList = document.createElement('ul');
        ulList.className = 'tags';
        let listElements = '';
        data.forEach((tag: Tag) => {
            listElements += `<li><a class="tag--related" href="${tag.slug}" title="${tag.name}" data-le="event|click-tag,${tag.id}">${tag.name}</a></li>`;
        });
        ulList.innerHTML = listElements;

        return ulList;
    }

    private static getDataByElement(detailElement: HTMLElement) {
        let resource: HTMLImageElement | HTMLVideoElement = detailElement?.getElementsByTagName('img')[0]
            ? detailElement?.getElementsByTagName('img')[0]
            : detailElement?.getElementsByTagName('video')[0];
        let data = detailElement.dataset;
        let resourceData = resource.dataset;
        let downloadPremiumUrl = `${FLATICON_URL}${messages.url.pricing}${data.pack_id ? '/' + data.pack_id : ''}`;
        const linkIcon: HTMLLinkElement | null = detailElement.querySelector('.link-icon-detail');

        let iconData: IconData = {
            id: data.id as string,
            nameDetail: data.name_detail ? data.name_detail : '',
            description: data.description ? data.description : '',
            url: linkIcon,
            backgroundColor: data.background_color ? data.background_color : '',
            type: data.type as string,
            downloads: data.downloads as string,
            license: data.license as string,
            groupId: data.group_id as string,
            downloadPremium: downloadPremiumUrl,
            published: data.published as string,
            selection: data.selection as string,
            keyword: {
                name: data.keyword_name as string,
                link: data.keyword_link as string,
            },
            color: data.color as string,
            tags: data.tags as string,
            pack: {
                id: data.pack_id as string,
                name: data.pack_name as string,
                url: `${ww.APP_URL}${data.pack}`,
                link: `<a href="${APP_URL}${data.pack}" class='link--normal'
                title="${messages.detail.more_icons_pack} ${data.pack_name} ${messages.common.pack}">${data.pack_name}</a>`,
                totalItems: data.pack_items as string,
                sprite: data.pack_sprite as string,
                totalItemsText: data.pack_items_text as string,
            },
            category: {
                id: data.category_id as string,
                name: data.category_name as string,
                url: data.category_url as string,
                link: `<a href="${data.category_url}" class='text-link category-icon'
                title='${data.category_name}'>${data.category_name}</a>`,
            },
            family: {
                id: data.family_id as string,
                name: data.family_name as string,
            },
            team: {
                id: data.team_id as string,
                name: data.team_name as string,
            },

            resource: {
                alt:
                    ww.Resource.resource.type === this.resourceTypes.animatedIcon
                        ? ''
                        : (resource as HTMLImageElement).alt,
                title: ww.Resource.resource.type === this.resourceTypes.animatedIcon ? '' : resource.title,
                small: resourceData.src ? resourceData.src : '',
                png: data.png ? data.png : '',
                mp4: data.mp4 ? data.mp4 : '',
                gif: data.gif ? data.gif : '',
            },
            style: {
                id: data.style as string,
                name: data.style_name as string,
                slug: data.style_slug as string,
                url: `${ww.APP_URL}${data.style_slug}${
                    ww.Resource.resource.type != 'standard' ? `?type=${ww.Resource.resource.type}` : ''
                }`,
                styleClass: data.style_class as string,
            },
            author: {
                id: data.author as string,
                name: data.author_name as string,
                avatar: data.author_avatar as string,
                url: data.author_website as string,
                icons: data.author_icons as string,
                totalIconsText: data.author_icons_text as string,
            },
            resourceType: ww.Resource.resource.type,
            craft: data.craft || '0',
            hasRaw: data.raw === '1' ? true : false,
        };

        return iconData;
    }

    private static handleIconTooltip(containerDetail: HTMLElement) {
        containerDetail.querySelectorAll('.icon--item .tooltip').forEach(tooltip => {
            tooltip.addEventListener('mouseenter', e => {
                var tooltip = e.target as HTMLElement;
                var menuW = 230;
                this.renderTooltipWithHorizonalPossitionContext(tooltip, menuW);
                e.preventDefault();
            });
        });
    }

    private static renderTooltipWithHorizonalPossitionContext(tooltip: HTMLElement, menuW: number) {
        let tooltipContent = tooltip.querySelector('.tooltip__content');
        let detailIconsElement = document.querySelector('.detail--v2 .icons');
        if (tooltipContent) {
            if (detailIconsElement) {
                var diff =
                    tooltipContent.getBoundingClientRect().left - detailIconsElement.getBoundingClientRect().left;
            } else {
                var diff = tooltipContent.getBoundingClientRect().left;
            }

            if (tooltip.classList.contains('collectionIcon')) {
                let collectionsHolder = document.getElementById('collections-holder');
                if (collectionsHolder) {
                    menuW = 180 + collectionsHolder.getBoundingClientRect().left;
                }
            }

            if (!tooltip.classList.contains('icon--item__premium-badge')) {
                if (diff < menuW) {
                    tooltip.classList.remove('tooltip--left');
                    tooltip.classList.add('tooltip--right');
                } else {
                    tooltip.classList.add('tooltip--left');
                    tooltip.classList.remove('tooltip--right');
                }
            }
        }
    }

    private static async getGlobalVariables() {
        const globalVariables = [
            'SPONSOR_URL',
            'ACCOUNTS_BASE_URL',
            'ACCOUNTS_URL',
            'BASE_URL',
            'LANGUAGE',
            'URL_STATIC_IMG',
            'LICENSES',
            'SUPPORT_URL',
            'SUPPORT_LANG',
            'APP_URL',
            'GR_LANGUAGE',
            'GOOGLE_IDP_URL_REGISTER',
            'VISIT_PLANS_PRICING',
            'FLATICON_URL',
            'FLATICON_DOWNLOAD_URL',
            'AMOUNT_IN_RELATED_BY_TAG_AND_STYLE',
        ];
        const variables: { [name: string]: any } = globalVariables.reduce(function(
            acc: { [name: string]: any },
            variable: string,
        ) {
            if ((window as any)[variable]) {
                acc[variable] = (window as any)[variable];
            }

            return acc;
        },
        {});
        const userData = User.getData();
        return { ...variables, userData };
    }

    private static handleCraftLicense() {
        import('Project/ts/common/randomBackgroundImages').then(module => {
            const randomImages: string[] = [
                `${URL_STATIC_IMG}craft-banner/bag.png`,
                `${URL_STATIC_IMG}craft-banner/cup.png`,
                `${URL_STATIC_IMG}craft-banner/t-shirt.png`,
            ];
            module.randomBackgroundImage('#banner-craft .banner-craft__image', randomImages);
        });

        document.getElementById('more-info-craft')?.addEventListener('click', e => {
            e.preventDefault();
            const iconInfo = document.querySelector('#detail') as HTMLElement;
            if (iconInfo) {
                LazyModal.callModalCraftInfo(iconInfo?.dataset.elementid);
            }
        });

        import('Project/ts/pods/craft/index').then(module => {
            module.handleListenersForEvents();
        });
    }

    private static handleEditor() {
        let buttonEditElement = document.querySelector('.btn-edit-icon');
        buttonEditElement?.addEventListener(
            'click',
            () => {
                this.openEditor();
            },
            { passive: true },
        );

        if (getUrlParam('open-editor') && getUrlParam('open-editor') == '1') {
            this.openEditor();
        }
    }

    private static openEditor() {
        let inModalView = document.querySelector('#detail-overlay');
        import('Pods/editor/editor').then(module => {
            ww.Editor = module.Editor;
            ww.Editor.init(inModalView, true);
        });
    }
}
