import { delegate, delegateRemove } from 'bobjoll/ts/library/dom';
import getXHR from 'Partials/xhr';
import User from 'Project/ts/pods/user/main';
export class Follow {
    public static init(selector: string = 'body') {
        let selectorElement = (selector
            ? document.querySelector(selector)
                ? document.querySelector(selector)
                : document.body
            : document.body) as HTMLElement;
        if (gr.auth.logged) {
            Follow.initFollowsLib(selectorElement);
        }

        Follow.removeFollowButtonListener(selector);
        Follow.initFollowButtonListener(selector);
    }

    public static initFollowsLib(localSelector: HTMLElement) {
        let followKey = Follow.getLocalStorageKey();
        getXHR(APP_URL + 'ajax/user/follow/list', {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        }).then(async response => {
            localStorage.setItem(followKey, JSON.stringify(response));
            Follow.showFollowdItems(localSelector);
        });
    }

    public static initFollowButtonListener(selector: string) {
        delegate(`${selector} .js_follow`, 'click', this.followHandler);
    }

    public static removeFollowButtonListener(selector: string) {
        delegateRemove(`${selector} .js_follow`, 'click', this.followHandler);
    }

    private static followHandler(this: HTMLElement, e: Event) {
        e.stopPropagation();
        e.preventDefault();

        if (User.isLogged()) {
            if (this.classList.contains('active')) {
                Follow.removeFollow(this);
            } else {
                Follow.addFollow(this);
            }
        } else {
            window.location.href = GOOGLE_IDP_URL_LOGIN;
        }
    }

    public static addFollow(button: HTMLElement) {
        Follow.changeFollowButtonStatus(true, button);
        if (button.dataset.type && button.dataset.elementid) {
            Follow.saveFollowInLocalStorage(button.dataset.type, button.dataset.elementid);
            Follow.saveFollowInPersistenceStorage(button.dataset.type, button.dataset.elementid);
        }
    }

    public static removeFollow(button: HTMLElement) {
        Follow.changeFollowButtonStatus(false, button);
        if (button.dataset.type && button.dataset.elementid) {
            Follow.removeFollowInLocalStorage(button.dataset.type, button.dataset.elementid);
            Follow.removeFollowInPersistenceStorage(button.dataset.type, button.dataset.elementid);
        }
    }

    public static showFollowdItems(localSelector: HTMLElement) {
        let dbItems = localStorage.getItem(Follow.getLocalStorageKey());
        let localDb: any = [];
        if (dbItems) {
            localDb = JSON.parse(dbItems);
        }
        localSelector.querySelectorAll('.js_follow').forEach(function(item: HTMLElement) {
            let elementType: any = item.dataset.type;
            let elementId: string | undefined = item.dataset.elementid;
            if (elementType && elementId) {
                let elements = localDb[elementType];
                if (elements.includes(parseInt(elementId))) {
                    localSelector
                        .querySelectorAll('.js_follow[data-elementid="' + item.dataset.elementid + '"]')
                        .forEach(function(element) {
                            element.classList.add('active');
                        });
                } else {
                    localSelector
                        .querySelectorAll('.js_follow[data-elementid="' + item.dataset.elementid + '"]')
                        .forEach(function(element) {
                            element.classList.remove('active');
                        });
                }
            }
        });
    }

    public static saveFollowInLocalStorage(type: string, id: string) {
        let localDb = [];
        let followKey = Follow.getLocalStorageKey();
        let dbItems = localStorage.getItem(followKey);
        try {
            if (dbItems) {
                localDb = JSON.parse(dbItems);
            }
        } catch (e) {
            localDb[type] = [];
        }

        let index = localDb[type].indexOf(parseInt(id));
        if (index === -1) {
            localDb[type].push(parseInt(id));
            localStorage.setItem(followKey, JSON.stringify(localDb));
        }
    }

    public static saveFollowInPersistenceStorage(type: string, id: string) {
        let formData = new FormData();
        formData.append('id', id);
        formData.append(CSRF_TOKEN_NAME, CSRF_TOKEN);
        getXHR(`${APP_URL}ajax/follow/set/${type}`, {
            method: 'POST',
            data: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
    }

    public static removeFollowInLocalStorage(type: string, id: string) {
        let followKey = Follow.getLocalStorageKey();
        let dbItems = localStorage.getItem(followKey);
        if (dbItems) {
            let localDb = JSON.parse(dbItems);

            let index = localDb[type].indexOf(parseInt(id));
            if (index > -1) {
                localDb[type].splice(index, 1);
            }
            localStorage.setItem(followKey, JSON.stringify(localDb));
        }
    }

    public static removeFollowInPersistenceStorage(type: string, id: string) {
        let formData = new FormData();
        formData.append('id', id);
        formData.append(CSRF_TOKEN_NAME, CSRF_TOKEN);
        getXHR(`${APP_URL}ajax/follow/unset/${type}`, {
            method: 'POST',
            data: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
    }

    public static changeFollowButtonStatus(status: boolean, button: HTMLElement) {
        document
            .querySelectorAll(
                '.js_follow[data-elementid="' +
                    button.dataset.elementid +
                    '"][data-type="' +
                    button.dataset.type +
                    '"]',
            )
            .forEach(function(element: HTMLElement) {
                if (status === true) {
                    element.classList.add('active');
                } else {
                    element.classList.remove('active');
                }
                element.addEventListener('mouseleave', function(e) {
                    element.blur();
                });
            });
    }

    public static getLocalStorageKey() {
        if (gr.auth.logged) {
            return `fi_${gr.auth.me.id}_follows`;
        }

        return 'follows';
    }

    public static FollowsCallbackInit() {
        if (document.querySelectorAll('.following__block').length > 0) {
            document.getElementById('following-top')?.classList.remove('hidden');
            let followingTopElement = document.querySelector('#following-top span.success');
            if (followingTopElement) {
                followingTopElement.innerHTML = INFINITY.total;
            }

            document.getElementById('following-bottom')?.classList.add('hidden');
        } else {
            let followingListElement = document.querySelector('.following__list') as HTMLElement;
            if (followingListElement) {
                followingListElement.style.minHeight = '0px';
            }
        }
    }

    public static BookmarkCallbackInit() {
        if (document.querySelectorAll('.box--bookmark').length > 0) {
            if (INFINITY.total > 0) {
                document.getElementById('bookmarks-top')?.classList.remove('hidden');
                let bookmarksTopElement = document.querySelector('#bookmarks-top span.success');
                if (bookmarksTopElement) {
                    bookmarksTopElement.innerHTML = INFINITY.total;
                }

                document.getElementById('bookmarks-bottom')?.classList.add('hidden');
            }
        } else {
            let followingListElement = document.querySelector('.following__list') as HTMLElement;
            if (followingListElement) {
                followingListElement.style.minHeight = '0px';
            }
        }
    }
}
