import { ww } from 'bobjoll/ts/library/dom';
import { EventTracker } from 'Library/eventTacker';
import getXHR from 'Partials/xhr';
import { Downloads } from 'Project/ts/common-app/downloads';
import { isChrome, isFirefox, isSafari } from 'Project/ts/library/helpers/browser';
import {
    readClipImgSafari,
    readClipSvgSafari,
    writeClipImg,
    writeClipImgSafari,
    writeClipSvg,
    writeClipSvgSafari,
    writeClipText,
} from 'Project/ts/pods/copyPaste/copyPasteHelper';
import { getModalDownload } from 'Project/ts/pods/modals/download/downloadModalService';
import { LazyModal } from 'Project/ts/pods/modals/lazyModal';
import User from 'Project/ts/pods/user/main';
import { ViewTypes } from 'CommonApp/view';

interface AllIconInfo {
    id: string;
    urlPng: string;
    urlSvg: string;
    format: string;
    downloadType: string;
    teamId: string;
    iconType: string;
}

const PNG = 'png';
const DOWNLOAD_TYPE = 'icon-copy-paste';

export const copyToClipboardHandleListeners = () => {
    const copySvgPngButtons: HTMLElement[] | null = Array.from(
        document.querySelectorAll('.copy-svg-png .btn-copy button, .copypng--button, .copysvg--button'),
    );
    //@ts-ignore
    if (isFirefox && typeof navigator.clipboard.write === 'undefined') {
        if (copySvgPngButtons) {
            copySvgPngButtons.forEach(button => {
                if (!button.classList.contains('btn-warning')) {
                    button.addEventListener('click', () => {
                        User.alert(
                            'notification-copyClipboard',
                            messages.clipboard.errorFirefox,
                            'error',
                            'bottom-left',
                            4500,
                        );
                    });
                }
                if (button.classList.contains('btn-warning')) {
                    button.addEventListener('click', () => {
                        getModalDownload(true);
                    });
                }
            });
        }
        return;
    }

    if (isSafari) {
        if (copySvgPngButtons) {
            copySvgPngButtons.forEach(button => {
                if (!button.classList.contains('btn-warning')) {
                    button.dataset.modal = 'modal-confirm-copy-content';
                    button.classList.add('modal__trigger');
                    LazyModal.callModalConfirmCopyContent();
                    button.addEventListener(
                        'click',
                        () => {
                            User.isAllowedToDownload().then(allow => {
                                if (!allow) {
                                    window.location.href = `${APP_URL}${messages.common.url_downloads_limit}`;
                                    return;
                                } else {
                                    copyToClipBoard(button as HTMLElement);
                                }
                            });
                        },
                        { once: true },
                    );
                }
                if (button.classList.contains('btn-warning')) {
                    button.addEventListener('click', () => {
                        getModalDownload(true);
                    });
                }
            });
        }
        return;
    }

    copySvgPngButtons.forEach(button => {
        button.addEventListener(
            'click',
            (e: Event) => {
                User.isAllowedToDownload().then(allow => {
                    if (!allow) {
                        window.location.href = `${APP_URL}${messages.common.url_downloads_limit}`;
                        return;
                    }

                    const button =
                        (e.target as HTMLElement).closest('.copypng--button') ||
                        (e.target as HTMLElement).closest('.copysvg--button');

                    if (!button) {
                        return;
                    }

                    copyToClipBoard(button as HTMLElement);
                });
            }
        );
    });
};

const copyToClipBoard = async (targetElement: HTMLElement) => {
    const allIconInfo = getIconData(targetElement);
    let copyPasteUrl;

    if (!allIconInfo) {
        User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        return;
    }

    const iconData = {
        id: allIconInfo.id,
        teamId: allIconInfo.teamId,
        iconType: allIconInfo.iconType,
        format: targetElement.dataset.copyFormat || 'png',
    };
    if (User.isPremium()) {
        if (iconData.format === 'svg') {
            let copyPasteUrlWhitFormat = await getCopyPasteUrlWithFormat(iconData);
            copyPasteUrl = await getCopyPasteUrl(allIconInfo.id, allIconInfo.teamId, allIconInfo.iconType);
            callHelperCopyPasteSvg(copyPasteUrl, copyPasteUrlWhitFormat, allIconInfo.id);
            const dataToSendToDatalayer = getDataToSendDatalayer(targetElement);
            ww.EventTracker.send('ga4dl', dataToSendToDatalayer);
            counterDownload(allIconInfo);
            User.updateUserStats(3000);
        } else {
            copyPasteUrl = await getCopyPasteUrlWithFormat(iconData);
            callHelperCopyPaste(copyPasteUrl, allIconInfo.id);
            const dataToSendToDatalayer = getDataToSendDatalayer(targetElement);
            ww.EventTracker.send('ga4dl', dataToSendToDatalayer);
            counterDownload(allIconInfo);
            User.updateUserStats(3000);
        }
    } else if (!User.isPremium() && iconData.format === 'svg') {
        if ( ww.view.section === ViewTypes.iconSearch || ww.view.section === ViewTypes.packDetail || ww.view.section === ViewTypes.iconDetail ) {
            targetElement.classList.add('modal__trigger');
            targetElement.dataset.modal = 'modal-copy-svg-from-search-list-not-premium-user';
            LazyModal.callModalCopySvgFromSearchListNotPremiumUser();
            return;
        }
        getModalDownload(true);
    } else {
        copyPasteUrl = await getCopyPasteUrlWithFormat(iconData);
        callHelperCopyPaste(copyPasteUrl, allIconInfo.id);

        const dataToSendToDatalayer = getDataToSendDatalayer(targetElement);
        ww.EventTracker.send('ga4dl', dataToSendToDatalayer);

        counterDownload(allIconInfo);
        User.updateUserStats(3000);
    }
};

const getDataToSendDatalayer = (targetElement: HTMLElement) => {
    const isThumbnail = targetElement.closest('.icon--item');
    const location = isThumbnail ? 'thumbnail' : 'resource_detail';

    const dataToSend = {
        event: 'ga4event',
        event_name: 'file_download',
        category: ww.Resource.resource.type === 'standard' ? 'icons' : `${ww.Resource.resource.type}s`,
        location: location,
        label: ww.Resource.resource.pack.name,
        file_id: ww.Resource.resource.id,
        file_name: ww.Resource.resource.name,
        author_name: ww.Resource.resource.author.name,
        file_extension: targetElement.dataset.copyFormat ? targetElement.dataset.copyFormat : 'png',
        file_size: '512',
        action: 'copy',
    };

    if (dataToSend.category === 'uicons') {
        dataToSend.category = 'interface_icons';
        dataToSend.author_name = 'Uicons';
    }

    if (dataToSend.category === 'animated-icons') {
        dataToSend.category = 'animated_icons';
    }

    return dataToSend;
};

const getIconData = (targetElement: HTMLElement) => {
    let pngImgElement: HTMLImageElement | null;
    let pngLiElement: HTMLElement | null;

    if (ww.view.section === 'icon-detail' || ww.view.section === 'uicons-detail-page') {
        if (targetElement.classList.contains('related-icon-detail')) {
            pngImgElement = targetElement.closest('li.icon--item[data-png]');

            if (
                pngImgElement?.dataset.id &&
                pngImgElement?.dataset.png &&
                pngImgElement?.dataset.team_id &&
                pngImgElement?.dataset.icon_type
            ) {
                const { id, png, large, team_id: teamId, icon_type: iconType } = pngImgElement.dataset;
                return {
                    id: id as string,
                    urlPng: png,
                    urlSvg: large as string,
                    format: targetElement.dataset.copyFormat || PNG,
                    downloadType: DOWNLOAD_TYPE,
                    teamId,
                    iconType,
                };
            }
        } else {
            const resourceData = ww.Resource.resource;

            if (resourceData) {
                const { id, team, resource, large = 'large', type: iconType } = resourceData;

                return {
                    id: id as string,
                    urlPng: resource.png,
                    urlSvg: large,
                    format: targetElement.dataset.copyFormat || PNG,
                    downloadType: DOWNLOAD_TYPE,
                    teamId: team.id,
                    iconType,
                };
            }
        }
    } else if (ww.view.section === 'icon-search' || 'pack-detail' || '404') {
        pngLiElement = targetElement.closest('li.icon--item[data-png]');
        pngImgElement = pngLiElement?.querySelector('img') || null;

        if (
            pngImgElement?.src &&
            pngLiElement &&
            pngLiElement.dataset.id &&
            pngLiElement.dataset.team_id &&
            pngLiElement.dataset.icon_type
        ) {
            const { id, png, icon_src: iconSrc, team_id: teamId, icon_type: iconType } = pngLiElement?.dataset;
            return {
                id: id as string,
                urlPng: png as string,
                urlSvg: iconSrc as string,
                format: targetElement.dataset.copyFormat || PNG,
                downloadType: DOWNLOAD_TYPE,
                teamId,
                iconType,
            };
        }
    }

    return null;
};

const getCopyPasteUrl = async (id: string, teamId: string, iconType: string): Promise<string> => {
    const urlCopyPaste = `${BASE_URL}download/icon/copy-and-paste/`;
    const response = await getXHR(`${urlCopyPaste}${id}?team=${teamId}&type=${iconType}`)
        .then(response => {
            if (response.success) {
                return response.data.url;
            } else {
                User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
            }
        })
        .catch(() => {
            User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        });
    return response;
};

const getCopyPasteUrlWithFormat = async ({
    id,
    teamId,
    iconType,
    format,
}: CopyToClipboardIconData): Promise<string> => {
    const urlCopyPaste = `${BASE_URL}download/icon/copy-and-paste/`;
    const url = `${urlCopyPaste}${id}?team=${teamId}&type=${iconType}&format=${format}`;

    const response = await getXHR(url)
        .then(response => {
            if (response.success) {
                return response.data.url;
            } else {
                User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
            }
        })
        .catch(() => {
            User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        });
    return response;
};

const callHelperCopyPaste = (urlPng: string, iconId: string) => {
    const downloadType = RESOURCE_TYPE === 'sticker' ? 'download-sticker' : 'download';
    if (isSafari) {
        try {
            readClipImgSafari(urlPng).then(blobImg => {
                document.getElementById('confirm-download-button')?.addEventListener(
                    'click',
                    () => {
                        writeClipImgSafari(blobImg);
                        setProgressBar();
                        EventTracker.send('ga', 'event', downloadType, 'copyPNG', iconId);
                    },
                    { once: true },
                );
                setEventCloseModal();
            });
        } catch (err) {
            User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        }
    } else {
        try {
            writeClipImg(urlPng);
            User.alert('notification-copyClipboard', messages.clipboard.png.success, 'success', 'bottom-left', 4500);
            EventTracker.send('ga', 'event', downloadType, 'copyPNG', iconId);
        } catch (err) {
            User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        }
    }
};

const callHelperCopyPasteSvg = (urlPng: string, urlSvg: string, iconId: string) => {
    const downloadType = RESOURCE_TYPE === 'sticker' ? 'download-sticker' : 'download';

    if (isSafari) {
        try {
            readClipSvgSafari(urlSvg, iconId).then(blobAll => {
                document.getElementById('confirm-download-button')?.addEventListener(
                    'click',
                    () => {
                        writeClipSvgSafari(blobAll);
                        setProgressBar();
                        EventTracker.send('ga', 'event', downloadType, 'copySVG', iconId);
                    },
                    { once: true },
                );

                setEventCloseModal();
            });
        } catch (err) {
            User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        }
    } else {
        try {
            writeClipSvg(urlSvg, iconId);
            User.alert('notification-copyClipboard', messages.clipboard.png.success, 'success', 'bottom-left', 4500);
            EventTracker.send('ga', 'event', downloadType, 'copySVG', iconId);
        } catch (err) {
            User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        }
    }
};

const counterDownload = (iconData: AllIconInfo) => {
    let formData = new FormData();
    formData.append('id', iconData.id);
    formData.append('downloadType', iconData.downloadType);
    formData.append('format', iconData.format);
    formData.append('type', RESOURCE_TYPE);

    getXHR(`${BASE_URL}downloads/track-download`, {
        data: formData,
        method: 'POST',
        withCredentials: true,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    });

    Downloads.trackDownload(iconData.id, iconData.format);
};

export const copyUrlCdnUicons = () => {
    document.querySelectorAll('.copyCdn--button').forEach(copyCdnButton => {
        copyCdnButton.addEventListener('click', () => {
            let inputTarget = copyCdnButton.parentNode?.parentNode?.querySelector('.group__input .cdn--input');
            let inputValueTarget = inputTarget?.getAttribute('value');

            try {
                writeClipText(inputValueTarget);
                User.alert(
                    'notification-copyClipboard',
                    messages.clipboard.png.success,
                    'success',
                    'bottom-left',
                    4500,
                );
                EventTracker.send('ga', 'event', 'uicons', 'downloads-cdn');
            } catch (err) {
                User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
            }
        });
    });
};
export const copyNpmUicons = () => {
    const copyNpmButton = document.querySelector('.copyNpm--button');
    copyNpmButton?.addEventListener('click', () => {
        const inputTarget = copyNpmButton.parentNode?.parentNode?.querySelector('.group__input .npm--input');
        const inputValueTarget = inputTarget?.getAttribute('value');

        try {
            writeClipText(inputValueTarget);
            User.alert('notification-copyClipboard', messages.clipboard.png.success, 'success', 'bottom-left', 4500);
            EventTracker.send('ga', 'event', 'uicons', 'downloads-npm');
        } catch (err) {
            User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        }
    });
};

export const copyUrlShare = (input: HTMLInputElement) => {
    if (isChrome) {
        try {
            writeClipText(input.value);
            User.alert('notification-copyClipboard', messages.link.success, 'success', 'bottom-left', 4500);
        } catch (err) {
            User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        }
    } else {
        try {
            input.select();
            document.execCommand('copy');
            User.alert('notification-copyClipboard', messages.link.success, 'success', 'bottom-left', 4500);
        } catch (err) {
            User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        }
    }

    document.querySelector('.show-menu')?.classList.remove('show-menu');
};

export const copyApiKey = () => {
    document.querySelector('.copyApiKey--button')?.addEventListener('click', () => {
        let inputTarget = document.getElementById('apiKeyInput');
        let inputValueTarget = inputTarget?.getAttribute('value');

        try {
            writeClipText(inputValueTarget);
            User.alert('notification-copyClipboard', messages.clipboard.png.success, 'success', 'bottom-left', 4500);
        } catch (err) {
            User.alert('notification-copyClipboard', messages.clipboard.png.error, 'error', 'bottom-left', 4500);
        }
    });
};

const setProgressBar = () => {
    const progressBarTitle = document.querySelector('#copy-image-progress .progress-bar__title');
    const progressBarValue = document.querySelector('#copy-image-progress .progress-bar__value');
    if (progressBarTitle && progressBarValue) {
        progressBarTitle.classList.add('completed');
        progressBarTitle.innerHTML = `<i class="icon icon--check mg-right-lv1"></i>${messages.modal.confirmCopyContent.copySuccess}`;
        progressBarValue.classList.add('completed');
        setTimeout(() => {
            const closeModalButton: HTMLElement | null = document.querySelector(
                '#modal-confirm-copy-content .modal__close',
            );
            if (!closeModalButton) {
                return;
            }
            closeModalButton.click();
        }, 1500);
    }
};
const setEventCloseModal = () => {
    document.querySelector('.modal-actions .button__cancel')?.addEventListener(
        'click',
        () => {
            const closeModalButton: HTMLElement | null = document.querySelector(
                '#modal-confirm-copy-content .modal__close',
            );
            if (!closeModalButton) {
                return;
            }
            closeModalButton.click();
        },
        { once: true },
    );
};

interface CopyToClipboardIconData {
    id: string;
    teamId: string;
    iconType: string;
    format: string;
}
