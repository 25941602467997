import { ww } from 'bobjoll/ts/library/dom';
import { resourceTypes } from 'CommonApp/resource/resourceConfigService';
import { textHelpers } from 'CommonFI/text.helpers';
import { EventTracker } from 'Library/eventTacker';
import getXHR from 'Partials/xhr';

export const cleanSearch = (searchForm: HTMLFormElement) => {
    const cleanSearchButton = searchForm.querySelector('.clean-search__button') as HTMLButtonElement;
    const searchInputElement = searchForm.querySelector('input[type=search]') as HTMLInputElement;

    searchInputElement.addEventListener('input', () => {
        if (searchInputElement.value != '') {
            cleanSearchButton.classList.add('show-btn');
        } else {
            cleanSearchButton.classList.remove('show-btn');
        }
    });

    cleanSearchButton.addEventListener('click', () => {
        searchInputElement.value = '';
        searchInputElement.focus();
        cleanSearchButton.classList.remove('show-btn');
    });
};

export const handleSearchOnBar = () => {
    const searchForm: HTMLFormElement | null = document.querySelector('.search-holder form.home_search_input')
        ? document.querySelector('.search-holder form.home_search_input')
        : document.querySelector('.search-holder form.main_search_bar');
    const searchInputElement: HTMLInputElement | null = searchForm && searchForm.querySelector(' input[name=word]');
    const authorSearch: HTMLElement | null = document.querySelector('[data-section=main_search_author]');
    const typeSearchInputElement: HTMLInputElement | null =
        searchForm && searchForm.querySelector('input[name=type]:checked');
    const typeSearchInputPackElement: HTMLInputElement | null =
        searchForm && searchForm.querySelector('input[name=search-group]:checked');

    const searchValue = searchInputElement && `${searchInputElement.name}=${searchInputElement.value}`;
    const urlSearch = typeSearchInputElement?.dataset.url;
    const urlSearchPack = typeSearchInputPackElement?.dataset.url;
    let filtersArray: string[] = [];
    let querystring = '';

    searchValue && filtersArray.push(searchValue);
    authorSearch && filtersArray.push(`author_id=${authorSearch.dataset.value}`);

    const typeSearchType = (typeSearchInputElement && typeSearchInputElement.value) ?? '';

    if (typeSearchType === resourceTypes.uicon) {
        filtersArray = setUiconsFilters(filtersArray);
    } else {
        const filtersInputsElements: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
            '#form_filters input:checked',
        );

        filtersInputsElements.length > 0 && !isIconSearchType(typeSearchInputElement)
            ? setFilters(filtersInputsElements, filtersArray)
            : isIconSearchType(typeSearchInputElement) && filtersArray.push(`type=${typeSearchInputElement?.value}`);
    }

    if (filtersArray.length) {
        querystring = `?${filtersArray.join('&')}`;
    }

    if (urlSearchPack?.includes('/style/')) {
        querystring = querystring.split('&order_by')[0];
    }

    const targetUrl = typeSearchInputPackElement ? urlSearchPack?.concat(querystring) : urlSearch?.concat(querystring);
    if (targetUrl) {
        window.location.href = targetUrl;
    }
};

export const sendAutocompleteInfo = (elementPosition: string, searchTerm: string, searchOriginal: string): void => {
    const userId = ww.UserSession.userInfo.session.id;
    const language = ww.LANGUAGE_SHORT;

    EventTracker.sendINT(
        `position=${elementPosition}`,
        `term=${searchTerm}`,
        `original=${searchOriginal}`,
        `user_id=${userId}`,
        `language=${language}`,
        'autocomplete=1',
    );
};

const getFilter = (filterInputElement: HTMLInputElement): HTMLInputElement | null => {
    return typeof filterInputElement.name !== 'undefined' &&
        typeof filterInputElement.value !== 'undefined' &&
        filterInputElement.value !== 'all' &&
        filterInputElement.name !== '' &&
        filterInputElement.name.indexOf('-') === -1 &&
        filterInputElement.value !== resourceTypes.icon
        ? filterInputElement
        : null;
};

const setFilters = (filtersInputsElements: NodeListOf<HTMLInputElement>, filtersArray: string[]) => {
    if (filtersInputsElements.length > 0) {
        filtersInputsElements.forEach((filterInputElement: HTMLInputElement) => {
            if (getFilter(filterInputElement)) {
                filtersArray.push(`${getFilter(filterInputElement)?.name}=${getFilter(filterInputElement)?.value}`);
            }
        });
    }
};

const setUiconsFilters = (filtersArray: string[]): string[] => {
    const filtersInputsElements: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        '.uicons-filter input:checked',
    );

    const getName = (filter: HTMLInputElement): string | undefined => {
        const filterType = filter.dataset.filterType;
        return filterType ? filter.dataset.filterType : filter.name;
    };

    const getValue = (filter: HTMLInputElement, name: string) => {
        if (filter.dataset.filterType === 'brands') {
            return '1';
        }

        let value = filter.type === 'checkbox' ? filter.dataset.filterValue : filter.value;

        if (!value) {
            return null;
        }

        const isGroupedFilter = Boolean(filter.dataset.filterType);

        if (isGroupedFilter) {
            const currentRawValue = filtersArray.find(item => item.startsWith(`${name}=`))?.substring(name.length + 1);
            const currentValue = currentRawValue ? currentRawValue.split('+') : [];

            filtersArray = filtersArray.filter(item => !item.startsWith(`${name}=`));

            currentValue.push(value);
            value = currentValue.join('+');
        }

        return value;
    };

    filtersInputsElements.forEach(element => {
        const filter = getFilter(element);

        if (!filter) {
            return;
        }

        const name = getName(filter);

        if (!name) {
            return;
        }

        const value = getValue(filter, name);

        if (!value) {
            return;
        }

        filtersArray.push(`${filter.name}=${value}`);
    });

    filtersArray.push(`type=${resourceTypes.uicon}`);

    return filtersArray;
};

const isIconSearchType = (typeSearchInputElement: HTMLInputElement | null) => {
    return typeSearchInputElement && typeSearchInputElement.value !== resourceTypes.icon;
};

export const lauchSponsorPopup = () => {
    ww.gr.sponsor.popup.launch();
};

export const sendStatsSearch = (searchWord: string) => {
    const isHangul = textHelpers.isHangulWord(searchWord) && LANGUAGE == 'korean';
    const isCyrillic = textHelpers.isCyrillicWord(searchWord) && LANGUAGE == 'russian';
    const filteredWord = textHelpers.filterText(searchWord);
    const inputTypeSearch =
        (document.querySelector('.search-holder .type-selector input[name=type]:checked') as HTMLInputElement)?.value ||
        '';
    if (searchWord.length && (filteredWord !== '' || isHangul || isCyrillic)) {
        getXHR(`${APP_URL}stats/search`, {
            method: 'POST',
            withCredentials: true,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            data: {
                word: searchWord,
                type: inputTypeSearch,
            },
        });
    }
};
