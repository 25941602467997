/* eslint-disable @typescript-eslint/camelcase */
/// <reference types="google.analytics"/>
import 'Variables';

import { ww } from 'bobjoll/ts/library/dom';
import { getOriginalClientId, getTypeUser, getUserId } from 'CommonFI/datalayer.helpers';
import { initTracker, tracker } from 'event-tracking/src';
import { cookie } from 'Library/cookie';
import { stringEncode } from 'Library/helpers/text';
import getXHR from 'Partials/xhr';
import User from 'Project/ts/pods/user/main';

initTracker(URL_CRM_LOG);

export class EventTracker {
    public static readonly dataKey = 'le';
    private static catogoryFilters = ['premium', 'unpaid', 'campaign'];

    public static async send(...args: any[]) {
        let service = args.shift().toLowerCase();
        switch (service) {
            case 'all': {
                await EventTracker.sendGA(args);
                await EventTracker.sendHB(args);
                break;
            }
            case 'ga': {
                await EventTracker.sendGA(args);
                break;
            }
            case 'hb': {
                await EventTracker.sendHB(args);
                break;
            }
            case 'gtm': {
                await EventTracker.sendGTM(args);
                break;
            }
            case 'ga4dl': {
                await EventTracker.ga4DataLayer(args);
                break;
            }
        }
    }

    private static ga4DataLayer(args: any[]) {
        if ('undefined' === typeof ww.dataLayer) {
            ww.dataLayer = ww.dataLayer || [];
        }

        args.forEach(argument => {
            ww.dataLayer.push(argument);
        });
    }

    public static evaluateIfNoIterationEventAndSend(...args: any[]) {
        let action = args[1] ? args[1] : null;

        if (action !== 'click') {
            args.push({ nonInteraction: true });
        }

        this.send(...args);
    }

    private static sendGA(args: any[]) {
        ga('send', ...args);
        if (args.length > 0 && this.catogoryFilters.indexOf(args[1]) != -1) {
            this.sendINT(...args);
        }
    }

    public static sendGA4Event(eventName: string, pageLocation: string) {
        if ('undefined' === typeof ww.dataLayer) {
            ww.dataLayer = ww.dataLayer || [];
        }
        const event: GA4Event = {
            event: 'ga4event',
            clientId: 'flaticon',
            eventName: eventName,
            userId: getUserId(),
            userType: getTypeUser(),
            pageLocation: pageLocation,
            originalClientId: getOriginalClientId(),
        };

        ww.dataLayer.push({
            event: event.event,
            client_id: event.clientId,
            event_name: event.eventName,
            user_id: event.userId,
            user_type: event.userType,
            page_location: event.pageLocation,
            original_client_id: event.originalClientId,
        });
    }

    private static sendGTM(args: any[]) {
        if ('undefined' === typeof ww.dataLayer) {
            ww.dataLayer = ww.dataLayer || [];
        }
        ww.dataLayer.push({ event: args[1] ? args[1] : null });
    }

    private static sendHB(args: any[]) {
        args = args.slice(1, 4);
        let params: EventData = {
            category: args[0] ? args[0] : null,
            action: args[1] ? args[1] : null,
            label: args[2] ? args[2] : null,
        };

        tracker('send', 'event', params);
    }

    public static sendINT(...args: any[]) {
        let argsCleaned = args.filter(arg => !arg.nonInteraction);
        getXHR(`${APP_URL}_ga?${argsCleaned.join('&')}`, {
            withCredentials: false,
        });
    }

    public static getArgsFromElementAndSend(element: HTMLElement) {
        let tracksArguments = element.dataset.trackArguments;
        if (tracksArguments && tracksArguments.indexOf(',') >= 0) {
            tracksArguments.split('|').forEach(trackArguments => {
                let trackerArguments = trackArguments.split(',').reduce((acc: string[], str) => {
                    let string = str.trim();

                    try {
                        string = JSON.parse(string);
                    } catch (err) {}

                    acc.push(string);
                    return acc;
                }, []);

                EventTracker.send(...trackerArguments);
            });
        }
    }
    public static getArgsFromElementGa4AndSend(element: HTMLElement) {
        let tracksArguments = element.dataset.trackGa4Arguments;
        if (tracksArguments) {
            tracksArguments.split('|').forEach(trackArguments => {
                let ga4event: DataLayerEvent = {};
                trackArguments.split(',').forEach(argument => {
                    let event = argument.split(':');
                    ga4event[event[0].trim()] = event[1].trim();
                });
                EventTracker.send('ga4dl', ga4event);
            });
        }
    }

    public static printGA(data: PixelData) {
        let pixel = `${BASE_URL}_ga?`;
        let location = '';
        if (data.type === 'popup') {
            pixel += 'sponsor_pixel=popup';
            data.type = 'clk';
            location = 'pop_up';
        } else {
            switch (data.section) {
                case 'search': {
                    data.type == 'clk' ? EventTracker.markUserAsInterested('srp') : '';
                    pixel += 'sponsor_pixel=srp';
                    location = 'banner_search_result';
                    break;
                }
                case 'detail': {
                    data.type == 'clk' ? EventTracker.markUserAsInterested('idp') : '';
                    pixel += 'sponsor_pixel=idp';
                    var elementId = (document.querySelector('section#detail') as HTMLElement)?.dataset.elementid;
                    pixel += `&resource_id=${elementId}`;
                    location = 'banner_resource_detail';
                    break;
                }
                case 'empty': {
                    data.type == 'clk' ? EventTracker.markUserAsInterested('404') : '';
                    pixel += 'sponsor_pixel=404';
                    location = 'banner_search_result_404';
                    break;
                }
                case 'stockbanner': {
                    data.type == 'clk' ? EventTracker.markUserAsInterested('stockbanner') : '';
                    pixel += 'sponsor_pixel=stockbanner';
                    location = 'pop_up_right_corner';
                    break;
                }
            }
        }

        pixel += `&sponsor=${data.sponsor}&action=${data.type}`;

        if (data.link) {
            pixel += `&link=${data.link}`;
        }

        if (data.searchTerm) {
            pixel += `&term=${stringEncode(data.searchTerm || '')}`;
        }

        if (data.clickElement) {
            pixel += `&adobe_item_id=${data.clickElement.alt}`;
        }

        if (data.idList) {
            pixel += `&id_list=${stringEncode(data.idList.join(','))}`;
        }
        pixel += `&r=${Math.random()}&country=${USER_COUNTRY_CODE}`;

        ww.dataLayer.push({
            event: 'ga4event',
            event_name: 'affiliate',
            location: location,
            interaction_type: data.type == 'clk' ? 'click' : 'onload',
            action: 'go_adobe',
            type: data.link ? data.link : 'item',
        });

        new Image().src = pixel;
    }

    public static setInterestingPremiumContent(type: string) {
        if (!User.isPremium()) {
            const sectionTypes = [
                {
                    name: 'icon-detail',
                    elementIdentifier: 'icon_id',
                    premiumIdentifier: 'premium',
                },
                {
                    name: 'pack-detail',
                    elementIdentifier: 'pack_id',
                    premiumIdentifier: 'premium',
                },
            ];
            const section = sectionTypes.find(section => section.name == type) || null;
            if (section && null !== section.elementIdentifier) {
                const element = section.elementIdentifier || null;
                if (element) {
                    const id = (document.getElementById(element) as HTMLInputElement)?.value;
                    if (
                        section.premiumIdentifier &&
                        (document.getElementById(section.premiumIdentifier) as HTMLInputElement)?.value == '1'
                    ) {
                        let interestingContent = User.getInterestedInPremiumContent();

                        if (null !== interestingContent) {
                            if (interestingContent[type] != null) {
                                let typeElements = interestingContent[type].split(',');
                                if (!typeElements.find((element: string) => element == id)) {
                                    typeElements.push(id);
                                }
                                interestingContent[type] = typeElements.join();
                            } else {
                                interestingContent[type] = id;
                            }
                        } else {
                            interestingContent = {};
                            interestingContent[type] = id;
                        }

                        const expiredCookiePurchasePromo = new Date().getTime() + 2 * 24 * 60 * 60 * 1000; // 2 days
                        const maxLengthPurchasePromo = 3;

                        cookie.setItem(User.interestedPremiumContent, JSON.stringify(interestingContent), {
                            expires: new Date(expiredCookiePurchasePromo),
                        });

                        if (
                            !cookie.getItem('purchase-promo') &&
                            interestingContent['pack-detail'] != undefined &&
                            interestingContent['pack-detail'].split(',').length >= maxLengthPurchasePromo
                        ) {
                            cookie.setItem('purchase-promo', '1', {
                                expires: new Date(expiredCookiePurchasePromo),
                            });
                        }

                        if (
                            !cookie.getItem('purchase-promo') &&
                            interestingContent['icon-detail'] != undefined &&
                            interestingContent['icon-detail'].split(',').length >= maxLengthPurchasePromo
                        ) {
                            cookie.setItem('purchase-promo', '1', {
                                expires: new Date(expiredCookiePurchasePromo),
                            });
                        }
                    }
                }
            }
        }
    }

    private static markUserAsInterested(value: string) {
        let interested = User.getUserSponsorInterested();
        if (Object.keys(interested).length) {
            interested[value] = interested[value] ? interested[value] + 1 : 1;
        } else {
            interested[value] = 1;
        }
        cookie.setItem(User.interestedCookieName, JSON.stringify(interested), {
            expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000), // 1 week
        });
    }

    public static setGA(...args: any[]) {
        ga('set', ...args);
    }
}

export interface EventData {
    category?: string;
    action?: string;
    label?: string;
    service?: 'all' | 'ga' | 'hb' | '_ga' | 'gtm';
}

export interface PixelData {
    type: 'imp' | 'clk' | 'popup';
    section?: string;
    link?: string;
    clickElement?: HTMLImageElement;
    searchTerm?: string;
    idList?: string[];
    domain?: string;
    sponsor?: string;
}

export interface GA4Event {
    event: 'ga4event';
    clientId: 'flaticon';
    eventName: string;
    userType?: string;
    userId?: number;
    pageLocation?: string;
    originalClientId?: string;
}

export interface DataLayerEvent {
    [key: string]: string | number | undefined | object;
}
