var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"URL_STATIC_IMG") || (depth0 != null ? lookupProperty(depth0,"URL_STATIC_IMG") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"URL_STATIC_IMG","hash":{},"data":data,"loc":{"start":{"line":1,"column":10},"end":{"line":1,"column":30}}}) : helper)))
    + "logos/flaticon-positive.svg\" width=\"143\" height=\"28\">\n<p>"
    + ((stack1 = alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"cart") : stack1)) != null ? lookupProperty(stack1,"claim_text") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n<p>"
    + ((stack1 = alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"cart") : stack1)) != null ? lookupProperty(stack1,"dto_text") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n<p id=\"countdown-promo\" class=\"countdown-promo\"></p>\n<a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"couponUrl") || (depth0 != null ? lookupProperty(depth0,"couponUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"couponUrl","hash":{},"data":data,"loc":{"start":{"line":5,"column":9},"end":{"line":5,"column":24}}}) : helper)))
    + "\" class=\"btn track\" data-track-arguments=\"ga, event, premium, cart_coupon, click\">"
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"cart") : stack1)) != null ? lookupProperty(stack1,"button") : stack1), depth0))
    + "</a>\n";
},"useData":true});