import { ww } from 'bobjoll/ts/library/dom';
import { ResoruceListData, ResourceConfig } from 'CommonApp/resource/resource.vm';
import { isMobile } from 'Library/helpers/mobile';
import { Icon } from 'Project/ts/pages/icon-detail/detailService.vm';

export const listResourceComponents = {
    addCollectionBtnComponent: require('Project/ts/pages/icon-detail/listComponents/addCollectionBtnComponent.hbs'),
    contextMenuComponent: require('Project/ts/pages/icon-detail/listComponents/contextMenuComponent.hbs'),
    copyPngBtnComponent: require('Project/ts/pages/icon-detail/listComponents/copyPngBtnComponent.hbs'),
    directDownloadBtnComponent: require('Project/ts/pages/icon-detail/listComponents/directDownloadBtnComponent.hbs'),
    holderComponent: require('Project/ts/pages/icon-detail/listComponents/holderComponent.hbs'),
    itemComponent: require('Project/ts/pages/icon-detail/listComponents/itemComponent.hbs'),
    previewComponent: require('Project/ts/pages/icon-detail/listComponents/previewComponent.hbs'),
};

export const renderlistResourceComponents = (
    resourceConfig: ResourceConfig,
    resourceListData: ResoruceListData | Icon,
) => {
    const templateComponent = listResourceComponents.itemComponent;
    const isUserPremium = ww.UserSession.userInfo.session.premium;

    const urlStaticImg: string = ww.URL_STATIC_IMG;
    const showAnimatedIcon: boolean = resourceConfig.previewResourceList.animatedIcon && !isMobile();

    if (resourceListData.iconType === 'uicon') {
        resourceListData.groupId = resourceListData.author;
        resourceListData.styleId = resourceListData.style;
        resourceListData.tagsId = resourceListData.tags;

        resourceListData.familyName ? resourceListData.familyName : resourceListData.family_name;
    }

    if (resourceListData.iconType === 'standard') {
        listResourceComponents.copyPngBtnComponent = require('Project/ts/pages/icon-detail/listComponents/copyPngSvgBtnComponent.hbs');
    }

    if (resourceListData.iconType === 'standard' && ww.FeatureFlag.isEnabled('download_svg_search')) {
        listResourceComponents.directDownloadBtnComponent = require('Project/ts/pages/icon-detail/listComponents/directDownloadPngSvgComponent.hbs');
    }

    return {
        itemComponent: templateComponent({
            resourceListData,
            holderComponent: listResourceComponents.holderComponent({
                previewComponent: listResourceComponents.previewComponent({
                    resourceListData,
                    urlStaticImg,
                    showAnimatedIcon,
                }),
                contextMenuComponent: resourceConfig.previewResourceList.contextMenu
                    ? listResourceComponents.contextMenuComponent({
                          addCollectionBtnComponent: resourceConfig.previewResourceList.addCollectionBtn
                              ? listResourceComponents.addCollectionBtnComponent({ messages })
                              : null,
                          copyPngBtnComponent: resourceConfig.previewResourceList.copyBtn
                              ? listResourceComponents.copyPngBtnComponent({
                                    messages,
                                    isUserPremium,
                                    resourceListData,
                                })
                              : null,
                          directDownloadBtnComponent: resourceConfig.previewResourceList.directDownloadBtn
                              ? listResourceComponents.directDownloadBtnComponent({
                                    messages,
                                    isUserPremium,
                                    resourceListData,
                                })
                              : null,
                      })
                    : null,
            }),
        }),
    };
};
