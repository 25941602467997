var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"overlay\" aria-hidden=\"true\">\n    <ul  class=\"icon--item__context-menu\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"addCollectionBtnComponent") || (depth0 != null ? lookupProperty(depth0,"addCollectionBtnComponent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"addCollectionBtnComponent","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":39}}}) : helper))) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"copyPngBtnComponent") || (depth0 != null ? lookupProperty(depth0,"copyPngBtnComponent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"copyPngBtnComponent","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":33}}}) : helper))) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"directDownloadBtnComponent") || (depth0 != null ? lookupProperty(depth0,"directDownloadBtnComponent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"directDownloadBtnComponent","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":40}}}) : helper))) != null ? stack1 : "")
    + "\n    </ul>\n</div>";
},"useData":true});