import { delegate, q } from 'bobjoll/ts/library/dom';
import { Settings } from 'bobjoll/ts/library/settings';
import { cookie } from 'Library/cookie';
import notify from 'Partials/notify';
import User from 'Project/ts/pods/user/main';
export class Autopromo {
    static cookieVisitPricing: string = 'visit-plans-pricing';
    static cookiePurchasePromo: string = 'purchase-promo';

    public static couponAbandonedCart() {
        let priority = 6;
        let section = document.querySelector('body')?.dataset.section;
        if (
            USER_COUNTRY_CODE == 'IN' ||
            User.isPremium() ||
            User.isPaused() ||
            window.innerWidth < parseFloat(Settings.breakpoints.sm) ||
            (URL_CAMPAIGN !== '' && EXCLUSIVE_CAMPAIGN) ||
            section === 'downloads-limit' ||
            section === 'page-pricing' ||
            document.getElementById('layer_coupon') ||
            (!cookie.getItem(Autopromo.cookieVisitPricing) && !cookie.getItem(Autopromo.cookiePurchasePromo))
        ) {
            return;
        }

        if (URL_CAMPAIGN !== '' && !EXCLUSIVE_CAMPAIGN) {
            priority = 4;
        }

        try {
            const couponUrl = `${FLATICON_URL}${messages.url.pricing}?coupon=${COUPON_PRICING}`;
            const template = require(`Project/ts/common-app/autopromo/coupon-abandoned-cart.hbs`);
            notify.addNotification({
                class: 'bg__background--secondary',
                expires: () => new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
                html: template({ URL_STATIC_IMG, messages, couponUrl }),
                id: `notification-cart`,
                position: 'bottom-right',
                priority: priority,
                showPremium: false,
                callback: (coupon: HTMLElement) => {
                    //EventTracker.send('ga', 'event', 'premium', 'cart_coupon', 'view');
                    if (coupon) {
                        const couponContainer = coupon.parentElement;
                        if (couponContainer) {
                            couponContainer.classList.add('autopromo-coupon-container');
                        }

                        delegate('#notification-cart a', 'click', () => {
                            q('.notification__close', coupon)?.click();
                        });
                    }
                    Autopromo.countDownPromo();
                },
            });
        } catch (err) {
            throw Error(`Error rendering coupon, ${err}`);
        }
    }

    public static loadSearchPremiumBanner(element: HTMLElement) {
        if (User.isPremium()) return;
        let bannerTemplate = require('Project/ts/common-app/autopromo/banner-search-premium.hbs');
        element.innerHTML = bannerTemplate({ messages, ACCOUNTS_BASE_URL, GR_LANGUAGE });
        let searchResultElement = document.querySelector('.icons-search-result') as HTMLElement;
        if (searchResultElement) {
            searchResultElement.style.visibility = 'visible';
        }
    }

    public static countDownPromo() {
        const countdownStartTime = 51707000; //14h 21m 47seg
        const countdownIntervalTime = 1000;
        const countdownKey = 'countdown-time-left';
        const countdownElement = document.getElementById('countdown-promo') as HTMLElement;

        function millisecondsToTime(s: number) {
            function timeConversor(n: number, z: number) {
                var z = z;
                return ('00' + n).slice(-z);
            }

            var ms: number = s % 1000;
            s = (s - ms) / 1000;
            var secs: number = s % 60;
            s = (s - secs) / 60;
            var mins: number = s % 60;
            var hrs: number = (s - mins) / 60;

            return timeConversor(hrs, 2) + ':' + timeConversor(mins, 2) + ':' + timeConversor(secs, 2);
        }

        function resetCountDown() {
            localStorage.countdownTimeLeft = +new Date() + countdownStartTime;
        }

        setInterval(function() {
            var countdownRemaining = localStorage.countdownTimeLeft - <any>new Date();

            switch (true) {
                case countdownRemaining >= 0:
                    countdownElement.textContent = millisecondsToTime(countdownRemaining);
                    break;
                case isNaN(countdownRemaining):
                    resetCountDown();
                    break;
                case !isNaN(countdownRemaining) && countdownRemaining < 0:
                    notify.hide('notification-cart');
                    localStorage.removeItem('countdownTimeLeft');
                    break;
            }
        }, countdownIntervalTime);
    }
}
