const sprintf = require("sprintf-js").sprintf;

export default function(str: string, ...args: (string|number)[]): string {
    const argumentsLength = arguments.length - 1;
    const string = 'undefined' !== typeof languageStrings && languageStrings[str] ? languageStrings[str] : str;

    if (argumentsLength < 1) {
        return string;
    } else {
        try {
            const settings = args.reduce((acc: (string|number)[], item: any, index: number) => {
                acc.push(item);
    
                return acc;            
            }, [string]);
    
            return sprintf.apply(null, settings);
        } catch(err) {
            console.error(err, languageStrings[str] || str);

            return str;
        }
    }
}