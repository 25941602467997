var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"modal-funnel-premium-add-icon\" class=\"modal-funnel-premium modal modal--xs\" data-disable-mouse-up>\n	<div class=\"modal__container\">\n		<div class=\"content pd-none-i text__general--heading\">\n			<button class=\"modal__close modal__close--absolute modal__close--top-right button button--icon button--icon--only button--sm button--inverted nostyle\">\n				<i class=\"icon icon--md icon--mg-md icon--cross\"></i>\n			</button>\n\n			<div class=\"row\">\n				<div class=\"img\">\n					<i class=\"icon icon--premium\"></i>					\n				</div>\n				<div class=\"text\">\n					<p class=\"text__items\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"premium_add_icon") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n					<p class=\"text__items\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"modal") : stack1)) != null ? lookupProperty(stack1,"premium_add_icon") : stack1)) != null ? lookupProperty(stack1,"subtitle") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n				</div>\n				<button class=\"modal__close\">\n					<a class=\"button button--md modal__close__register btn btn-warning track\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"pricingUrl") || (depth0 != null ? lookupProperty(depth0,"pricingUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pricingUrl","hash":{},"data":data,"loc":{"start":{"line":17,"column":85},"end":{"line":17,"column":99}}}) : helper)))
    + "\" data-track-arguments=\"ga, event, premium, add-collection-premium\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"autopromo") : stack1)) != null ? lookupProperty(stack1,"go_premium") : stack1), depth0)) != null ? stack1 : "")
    + "</a>\n				</button>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});