import { q, ww } from 'bobjoll/ts/library/dom';
import { Autopromo } from 'CommonApp/autopromo/autopromo';
import { resourceTypes } from 'CommonApp/resource/resourceConfigService';
import { SponsorBanner, SponsorBannerSettings } from 'CommonApp/sponsor/sponsor-banner';
import { cookie } from 'Library/cookie';
import { EventTracker } from 'Library/eventTacker';
import { Follow } from 'Library/follow';
import { isMobile } from 'Library/helpers/mobile';
import Scroll from 'Library/scroll';
import { AnimationScroll } from 'Partials/animation';
import { addAnimatedIconListeners, animatedCardHomeOnHover } from 'Pods/animatedIcons/animatedIconsHelpers';
import { Uicons } from 'Pods/uicons';
import SearchBarUi from 'Pods/uicons/searchBarUi';
import { isBannedCountry } from 'Project/ts/common-app/sponsor/common/isBannedCountry';
import { isAdBlockEnabled, sendAdblockToLog } from 'Project/ts/common/adBlock';
import { checkStickyFooter } from 'Project/ts/common/element';
import { getUrlParam } from 'Project/ts/common/urls.helper';
import { Downloads } from 'Project/ts/common-app/downloads';
import { Detail } from 'Project/ts/pages/icon-detail/detailService';
import { PackDetail } from 'Project/ts/pages/pack-detail/packDetailService';
import User from 'Project/ts/pods/user/main';

interface SectionView {
    section: string;
    modal: boolean;
}
export class View {
    public section: string;

    constructor() {
        this.handleEvents();
    }

    public setView(name: string) {
        this.section = name;
    }

    public showResults(specificClassElement?: string) {
        let searchResultElement = (specificClassElement
            ? document.querySelector(specificClassElement)
            : document.querySelector('.icons-search-result')) as HTMLElement;

        if (searchResultElement) {
            searchResultElement.style.visibility = 'visible';
        }
    }

    private handleEvents() {
        window.addEventListener(
            'sectionView',
            (e: CustomEvent) => {
                if (isAdBlockEnabled()) {
                    sendAdblockToLog();
                }

                if (document.querySelector('.js_follow') && !document.querySelector('#detail-overlay #detail')) {
                    if (e.detail.section != 'following-styles' && e.detail.section != 'following-authors') {
                        if (e.detail.section == ViewTypes.packDetail) {
                            Follow.init('#detail-pack-aside');
                        } else {
                            Follow.init();
                        }
                    }
                }

                EventTracker.setInterestingPremiumContent(e.detail.section);
                this.setView(e.detail.section);

                const isEmptySearch = e.detail.section === ViewTypes.empty404 && !!q('.empty-search');

                if (
                    !cookie.getItem('notify--sponsor-coupon') &&
                    1 === Math.floor(Math.random() * 12) + 1 &&
                    ww.view.section !== 'page-pricing' &&
                    !User.isPremium() &&
                    window.innerWidth >= 640 &&
                    !isBannedCountry() &&
                    SPONSOR
                ) {
                    import('./sponsor/common/stockbanner.service').then(module => {
                        const stockbannerSettings = {
                            components: 'stockbanner',
                            country: USER_COUNTRY_CODE,
                            placement: 'stockbanner',
                            projectId: 'www.flaticon.com',
                            locale: LANGUAGE_SHORT || 'en',
                            userId: cookie.getItem('_ga') || '',
                        } as const;
                        const element = document.getElementById('stockbanner');
                        element && module.StockbannerService(stockbannerSettings, element);
                    });
                }

                if (
                    e.detail.section == ViewTypes.iconSearch ||
                    e.detail.section == ViewTypes.styleSearch ||
                    e.detail.section == ViewTypes.packSearch ||
                    e.detail.section == ViewTypes.empty404 ||
                    e.detail.section == ViewTypes.iconSearch
                ) {
                    const searchFilterInput: HTMLInputElement | null = document.querySelector(
                        "input[type='checkbox']#search__filters",
                    );
                    if (searchFilterInput && window.innerWidth < 992) {
                        if (!cookie.getItem('fo')) {
                            searchFilterInput.checked = false;
                            cookie.setItem('fo', '0');
                        }
                    }
                    const adobeBanner: HTMLElement | null = document.querySelector('.spirit-a');
                    if (adobeBanner) {
                        this.checkElementOutOfView(adobeBanner, '-117px 0px 0%');
                    } else {
                        const viewport = document.getElementById('viewport');
                        viewport?.classList.add('has-scrolled');
                    }
                    document.querySelector('.collapsed-filters__close')?.addEventListener('click', () => {
                        if (
                            document.getElementById('collections')?.classList.contains('visible') &&
                            ww.innerWidth < 1700
                        ) {
                            ww.Widget.toggle();
                        }
                    });

                    if (document.getElementById('collections')?.classList.contains('visible') && ww.innerWidth < 1700) {
                        const searchFilterInput: HTMLInputElement | null = document.querySelector(
                            "input[type='checkbox']#search__filters:checked",
                        );
                        if (searchFilterInput) {
                            searchFilterInput.checked = false;
                            cookie.setItem('fo', '0');
                        }
                    } else {
                        const searchFilterInput: HTMLInputElement | null = document.querySelector(
                            "input[type='checkbox']#search__filters",
                        );
                        if (searchFilterInput && window.innerWidth > 992) {
                            if (!cookie.getItem('fo')) {
                                searchFilterInput.checked = true;
                                cookie.setItem('fo', '1');
                            }
                        }
                    }

                    const collectionToggleButton = document.querySelector('.collections-toggle');

                    if (collectionToggleButton && ww.innerWidth < 1700) {
                        collectionToggleButton.addEventListener('click', () => {
                            const searchFilterInput: HTMLInputElement | null = document.querySelector(
                                "input[type='checkbox']#search__filters:checked",
                            );
                            if (searchFilterInput) {
                                searchFilterInput.checked = false;
                                cookie.setItem('fo', '0');
                            }
                        });
                    }
                }

                if (e.detail.section == ViewTypes.iconSearch && getUrlParam('license') == 'premium') {
                    let sponsorBannerElement =
                        ACTIVE_CAMPAIGN == ''
                            ? document.getElementById('banner-autopromo')
                            : document.getElementById('s-p');
                    if (sponsorBannerElement?.id === 'banner-autopromo') {
                        sponsorBannerElement.classList.add('active');
                        document.getElementById('s-p')?.classList.add('hidden');
                    }
                    sponsorBannerElement?.classList.remove('hidden');
                    if (sponsorBannerElement && ACTIVE_CAMPAIGN == '') {
                        Autopromo.loadSearchPremiumBanner(sponsorBannerElement);
                    } else if (sponsorBannerElement && !sponsorBannerElement.querySelector('#unicorn')) {
                        const settings: SponsorBannerSettings = {
                            premium: true,
                            element: sponsorBannerElement,
                            term: sponsorBannerElement.dataset.term || '',
                            page: e.detail.page || 1,
                        };
                        if (sponsorBannerElement.dataset.section) {
                            settings.section = sponsorBannerElement.dataset.section;
                        }
                        SponsorBanner.insert(settings);
                    }
                    Downloads.initializeDownload('icon', ViewTypes.iconSearch);
                } else if (e.detail.section == ViewTypes.packSearch) {
                    let bannerAutopromoElement = document.getElementById('banner-autopromo');
                    bannerAutopromoElement?.classList.remove('hidden');
                    if (null !== bannerAutopromoElement) {
                        getUrlParam('license') == 'premium' && ACTIVE_CAMPAIGN == ''
                            ? Autopromo.loadSearchPremiumBanner(bannerAutopromoElement)
                            : (bannerAutopromoElement.innerHTML = '');
                    }
                } else {
                    if (isEmptySearch) {
                        Downloads.initializeDownload('icon', ViewTypes.iconSearch);
                    }

                    if (e.detail.section == ViewTypes.empty404 && User.isPremium()) {
                        let emptySearchElement = q('.empty-search');
                        if (emptySearchElement) {
                            EventTracker.sendINT(
                                'event=404',
                                'action=imp',
                                'term=' + emptySearchElement.dataset.term || '',
                            );
                        }
                    }
                    if (
                        e.detail.section == ViewTypes.empty404 &&
                        (isMobile() || isBannedCountry() || ww.FeatureFlag.isEnabled('adobe_maintenance'))
                    ) {
                        this.showResults('.icons-search-empty-result');
                    }
                    let sponsorBannerElement =
                        e.detail.section == ViewTypes.iconDetail && document.querySelector('#detail .spirit-a') != null
                            ? (document.querySelector('#detail .spirit-a') as HTMLElement)
                            : (document.querySelector('.spirit-a') as HTMLElement);
                    if (null !== sponsorBannerElement) {
                        const settings: SponsorBannerSettings = {
                            premium: true,
                            element: sponsorBannerElement,
                            term: sponsorBannerElement.dataset.term || '',
                            page: e.detail.page || 1,
                        };
                        if (sponsorBannerElement.dataset.section) {
                            settings.section = sponsorBannerElement.dataset.section;
                        }
                        if (e.detail.section == ViewTypes.empty404) {
                            setTimeout(() => SponsorBanner.insert(settings), 500); //for adobe test
                        } else {
                            SponsorBanner.insert(settings);
                        }
                    }

                    if (e.detail.section == ViewTypes.packDetail) {
                        PackDetail.init();
                    } else if (e.detail.section == ViewTypes.iconDetail) {
                        Detail.init();
                        import('Library/google').then(module => {
                            let iconDataInfoPremium = q('#detail')?.dataset.premium;
                            if(ww.FeatureFlag.isEnabled('enable_google_ads_detail')) {
                                if (!User.isPremium()) {
                                    module.default.push({
                                        ads: [
                                            {
                                                id: 'fi_ssm_detail',
                                                list: 'DETAIL_1',
                                                container: 'fi_ssm_detail',
                                            },
                                        ],
                                        force: true,
                                        allowMobile: false,
                                    });
                                }
                            } else {
                                if (!User.isPremium() && iconDataInfoPremium == '0') {
                                    module.default.push({
                                        ads: [
                                            {
                                                id: 'div-gpt-ad-1547629421790-0',
                                                list: 'DETAIL_1',
                                            },
                                        ],
                                        force: true,

                                    });
                                }

                            }    

                        });
                    } else if (
                        e.detail.section == ViewTypes.iconSearch ||
                        e.detail.section == ViewTypes.categoryDetail ||
                        e.detail.section == ViewTypes.styleSearch
                    ) {
                        Downloads.initializeDownload('icon', ViewTypes.iconSearch);
                        if (e.detail.section == ViewTypes.iconSearch) {
                            import('Library/google').then(module => {
                                module.default.push({
                                    ads: [
                                        {
                                            id: 'div-gpt-ad-1547629514781-0',
                                            list: 'SEARCH_1',
                                            container: 'bn-icon-list',
                                        },
                                        {
                                            id: 'div-gpt-ad-1550131834126-0',
                                            list: 'SEARCH_2',
                                            container: 'bn-icon-list-second',
                                        },
                                    ],
                                    allowMobile: false,
                                });
                            });
                        }
                    }
                    if (ww.view.section == ViewTypes.packDetail || ww.view.section == ViewTypes.iconDetail) {
                        Scroll.add(() => checkStickyFooter());
                    }

                    if (
                        (ww.view.section === ViewTypes.authorDetail ||
                            ww.view.section === ViewTypes.iconSearch ||
                            ww.view.section === ViewTypes.styleDetail ||
                            ww.view.section === ViewTypes.empty404 ||
                            ww.view.section === ViewTypes.iconDetail) &&
                        document.querySelector('.author_search')
                    ) {
                        import('Partials/search/searchAuthorBar').then(module => {
                            const authorSearchBar = new module.SearchAuthorBar(
                                document.querySelector('.author_search'),
                                document.querySelector('.author_search .search__button'),
                                Array.from(document.querySelectorAll('.author_search .style-id__list li')),
                                document.querySelector('.author_search input[name="style_id"]'),
                            );

                            authorSearchBar.handleAuthorEvents();
                        });
                    }

                    if (ww.view.section === 'home_icons') {
                        document
                            .querySelectorAll('#fi-latest-packs article')
                            .forEach((lastElementPack: HTMLElement) => {
                                lastElementPack.addEventListener('click', () => {
                                    EventTracker.send('ga', 'event', 'subhome', 'icons', 'trending-pack');
                                });
                            });
                    }
                }

                if (
                    (e.detail.section == ViewTypes.iconSearch || e.detail.section == ViewTypes.packSearch) &&
                    (getUrlParam('license') == 'selection' || !getUrlParam('license'))
                ) {
                    document.getElementById('banner-autopromo')?.classList.add('hidden');
                    document.getElementById('s-p')?.classList.remove('hidden');
                }

                if (window.innerWidth <= 992 || isMobile()) {
                    this.handleNoScrollWithMenu();
                }

                if (
                    (ww.view.section === ViewTypes.iconSearch ||
                        ww.view.section === ViewTypes.homeAnimatedIcons ||
                        ww.view.section === ViewTypes.iconDetail ||
                        RESOURCE_TYPE === resourceTypes.animatedIcon) &&
                    !isMobile()
                ) {
                    addAnimatedIconListeners();
                }

                if (ww.view.section === ViewTypes.home && !isMobile()) {
                    animatedCardHomeOnHover();
                }

                if (ww.view.section === ViewTypes.uicons) {
                    document.querySelectorAll('.filterLabel-tag').forEach((filterLabel: HTMLSpanElement) => {
                        if (filterLabel?.dataset.filter) {
                            SearchBarUi.handleFiltersLabelEvents(filterLabel.dataset.filter);
                        }
                    });

                    SearchBarUi.handleClearAllFiltersEvent();
                }

                if (initializerUIcons.hasOwnProperty(ww.view?.section)) {
                    initializerUIcons[ww.view?.section]();
                    const detailElement = document.querySelector('#detail') as HTMLElement;
                    if (detailElement) {
                        ww.Resource.setResourceFromElement(detailElement);
                    }
                }

                if (
                    ww.view.section !== ViewTypes.iconDetail &&
                    ww.view.section !== ViewTypes.uiconsGetStarted &&
                    ww.view.section !== ViewTypes.uiconsLanding &&
                    RESOURCE_TYPE === resourceTypes.uicon
                ) {
                    import('Pods/uicons/searchBarUi').then(module => {
                        module.SearchBarUi.init();
                    });
                }
            },
            { passive: true },
        );
    }

    private handleNoScrollWithMenu() {
        const html = document.querySelector('html') as HTMLElement;

        document.getElementById('main-menu__toggle')?.addEventListener('change', (e: Event) => {
            const target = e.target as HTMLInputElement;
            target?.checked ? (html.style.overflow = 'hidden') : (html.style.overflow = 'visible');
        });
        document.getElementById('search__filters')?.addEventListener('change', (e: Event) => {
            const target = e.target as HTMLInputElement;
            target?.checked ? (html.style.overflow = 'hidden') : (html.style.overflow = 'visible');
        });
    }
    public checkElementOutOfView = (element: HTMLElement, rootMargin = '0px 0px 0%', callback?: Function) => {
        const viewport = document.getElementById('viewport');
        const observer = new IntersectionObserver(
            ([e]) =>
                callback
                    ? callback(e.intersectionRatio < 1)
                    : viewport?.classList.toggle('has-scrolled', e.intersectionRatio < 1),
            {
                threshold: [1],
                rootMargin,
            },
        );

        observer.observe(element);
    };

    public static obtainSectionAndDispatch() {
        const viewType = document.body.dataset.section || '';
        const sectionViewEvent = new CustomEvent(`sectionView`, {
            detail: {
                section: viewType,
                modal: false,
            },
        });
        window.dispatchEvent(sectionViewEvent);
    }
}

export const ViewTypes = {
    empty404: '404',
    authorDetail: 'author-detail',
    categoryDetail: 'category-detail',
    home: 'home',
    homeIcons: 'home_icons',
    iconDetail: 'icon-detail',
    iconSearch: 'icon-search',
    packSearch: 'pack-search',
    packDetail: 'pack-detail',
    styleDetail: 'style-detail',
    styleSearch: 'style-search',
    homeAnimatedIcons: 'home-animated-icons',
    uiconDetail: 'uicons-detail-page',
    uicons: 'uicons',
    uiconsGetStarted: 'uicons-get-started',
    uiconsLanding: 'uicons-landing',
    uiconsTag: 'uicons-tag',
};

const initializerUIcons = {
    [ViewTypes.uiconsGetStarted]: () => {
        Uicons.initMin();
    },
    [ViewTypes.uiconsLanding]: () => {
        Uicons.initType();
        Uicons.init();
        AnimationScroll.setup();
    },
    [ViewTypes.uicons]: () => {
        Uicons.init();
        SearchBarUi.init();
        SearchBarUi.handlePaginationEvents({
            allPaginationButton: true,
            paginationButton: false,
            paginationInput: true,
        });
    },
    [ViewTypes.uiconsTag]: () => {
        Uicons.init();
        SearchBarUi.init();
        SearchBarUi.handlePaginationEvents({
            allPaginationButton: true,
            paginationButton: false,
            paginationInput: true,
        });
    },
    [ViewTypes.uiconDetail]: () => {
        Uicons.handleUiconsEditorEvent();
        Uicons.initMin();
        if(ww.FeatureFlag.isEnabled('enable_google_ads_detail')) {
            import('Library/google').then(module => {
                if (!User.isPremium()) {
                    module.default.push({
                        ads: [
                            {
                                id: 'fi_ssm_detail',
                                list: 'DETAIL_1',
                                container: 'fi_ssm_detail',
                            },
                        ],
                        force: true,
                        allowMobile: false,
                    });
                }
            });

        }
        
        if (SearchBarUi) {
            SearchBarUi.init();
        }
    },
};
