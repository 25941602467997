import { cleanSearch } from 'Partials/search/search.helpers';

export class SearchAuthorBar {
    private authorForm: HTMLFormElement;
    private searchButton: HTMLButtonElement;
    private arrayStyleElements: HTMLLIElement[];
    private styleIdInput: HTMLInputElement;
    private newFilterParameters: string[] = [];

    constructor(
        form: HTMLFormElement | null,
        searchButton: HTMLButtonElement | null,
        arrayStyleElements: HTMLLIElement[] | null,
        styleIdInput: HTMLInputElement | null,
    ) {
        if (form && searchButton && arrayStyleElements && styleIdInput) {
            this.authorForm = form;
            this.searchButton = searchButton;
            this.arrayStyleElements = arrayStyleElements;
            this.styleIdInput = styleIdInput;
        }
    }

    public handleAuthorEvents() {
        this.arrayStyleElements.forEach((styleElement: HTMLLIElement) => {
            styleElement.addEventListener('click', (event: Event) => {
                event.preventDefault();
                let targetElement = event.target as HTMLLIElement;

                styleElement.dataset.value ? this.setStyleId(styleElement.dataset.value) : '';
                this.handleActiveElementStyle(this.arrayStyleElements);
                targetElement.classList.add('active');
                this.handleSelectorAuthorStyle(targetElement.textContent, targetElement);
            });
        });

        this.searchButton.addEventListener('click', () => {
            this.searchAuthor();
        });

        this.authorForm.addEventListener('submit', () => {
            this.searchAuthor();
        });

        cleanSearch(this.authorForm);
    }

    private searchAuthor() {
        window.location.href = this.setUrl(this.newFilterParameters);
    }

    private setUrl(filterParameters: string[]): string {
        this.getFilterParameters();
        return `${this.authorForm.action}?${filterParameters.join('&')}`;
    }

    private getFilterParameters() {
        const formData = new FormData(this.authorForm);
        this.newFilterParameters.length = 0;

        formData.forEach((data, index) => !!data && this.newFilterParameters.push(`${index}=${data}`));
    }

    private setStyleId(styleId: string) {
        this.styleIdInput.value = styleId;
    }

    private handleActiveElementStyle(elementsArray: HTMLElement[]) {
        elementsArray.forEach((element: HTMLElement) => (element.classList.contains('active') ? element.classList.remove('active') : ''));
    }

    private handleSelectorAuthorStyle(styleName: string | null, targetElement: HTMLLIElement) {
        const targetSearchHolder: HTMLLIElement | undefined | null = targetElement.parentElement?.closest('.search-holder');

        if (targetSearchHolder) {
            const styleSelector: HTMLSpanElement | null = targetSearchHolder.querySelector('.style__selector span');
            const tagField: HTMLInputElement | null = targetSearchHolder.querySelector('.search-style-word .tag-field__input');

            if (styleSelector && styleName) {
                styleSelector.innerHTML = styleName;
            }
            if (tagField && tagField.value !== '') {
                const formOfSearch = targetSearchHolder.querySelector('form');
                if (formOfSearch) {
                    this.authorForm = formOfSearch;
                }
                this.searchAuthor();
            }
        }
    }
}
