import { ww } from 'bobjoll/ts/library/dom';

export const getGlobalVariables = function() {
    return [
        'STATIC_URL',
        'ACCOUNTS_BASE_URL',
        'ACCOUNTS_URL',
        'ACCOUNTS_API_KEY',
        'FACEBOOK_APP_ID',
        'FACEBOOK_APP_VERSION',
        'GOOGLE_CLIENT_ID',
        'GOOGLE_API_KEY',
        'LANGUAGE',
        'language',
        'LANGUAGE_SHORT',
        'URL_LOG',
        'RE_CAPTCHA_KEY',
        'RE_CAPTCHA_SECRET',
        'SPONSOR_BASE_URL',
        'APP_URL',
        'URL_STATIC_IMG',
        'SPONSOR_CACHE',
        'SPONSOR_FREEPIK',
        'ACTIVE_AX_DETAIL',
        'LANDING',
        'url_pre_ax_detail',
        'URL_CRM_LOG',
        'MONTHLY_PRICE',
        'YEARLY_PRICE',
        'IS_API_ORIGIN',
    ].reduce((acc, key) => {
        if (ww[key]) {
            acc[key] = ww[key];
        }
        return acc;
    }, <indexSignature>{});
};

interface indexSignature {
    [name: string]: any;
}
